import $ from 'jquery'


const disableTypeNumberMouseWheel = () => {
    return (
    $('input[type=number]').on('mousewheel', function(e) {
        $(e.target).blur();
    })
    )
}

export default disableTypeNumberMouseWheel;