import React, { useEffect, useState } from "react";
import CebForm from "../../components/Form/CebForm";
import useApi from "../../hooks/useApi";
import mainApi from "../../api/main";
import { useParams } from "react-router-dom";
import moment from "moment";
import { modules } from "../../config/constants";

export default function RadioPage() {
  const { patientId } = useParams();
  const [viewMode, setViewMode] = useState("cards");
  const [listData, setListData] = useState([]);
  const [chosenCardId, setChosenCardId] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  //api
  const getRadioList = useApi(mainApi.getRadioList);
  const getRadio = useApi(mainApi.getRadio);

  useEffect(() => {
    getRadioList.request(patientId, pageNumber);
  }, [pageNumber]);

  const orderCardItems = [
    {
      header: "Date & Time",
      type: "formattedDate",
      keyName: "data.updated_at",
      format: "YY-MM-DD, h:mm a",
    },
    {
      header: "Investigation",
      keyName:
        "data.label.length > 15 ? (data.label.substring(0, 15) + '...') : data.label",
      removeInDetail: true,
    },
    // {
    //   header: "Image",
    //   type: "link",
    //   keyName: "data.images[0]?.file",
    //   title: "data.images[0]?.file_name",
    // },
    {
      header: "Report",
      type: "link",
      keyName: "data.documents[0]?.file",
      title: "data.documents[0]?.file_name",
    },
  ];
  const data = [
    { title: getRadio.data.label, fieldType: "header" },
    {
      label: "Date",
      data: moment(getRadio.data.updated_at).format("YY-MM-DD, h:mm a"),
      cardItem: true,
    },
    { label: "Ordering doctor", data: getRadio.data.fulfilled_by?.full_name },
    { label: "Images", fieldType: "gallery", data: getRadio.data.images },
    {
      label: "Report",
      fieldType: "link",
      data: getRadio.data.documents,
      cardItem: true,
    },
    { label: "Notes", data: getRadio.data.comment, multiline: true },
  ];

  //data to print

  // const dataToPrint = {
  //   header: {
  //     title: `Radiology (${getRadio.data.label})`,
  //     left: [
  //       moment(getRadio.data.updated_at).format("DD-MM-YYYY, h:mm a"),
  //       `Doctor: ${getRadio.data.fulfilled_by?.full_name}`,
  //     ],
  //   },
  //   content: [
  //     {
  //       label: "Notes",
  //       data: getRadio.data.comment,
  //     },
  //     {
  //       label: "Reports",
  //       data: getRadio.data.documents,
  //       type: "link",
  //     },
  //     {
  //       label: "Images",
  //       type: "image",
  //       data: getRadio.data.images?.map((item) => item.file),
  //     },
  //   ],
  // };

  //   { title: getRadio.data.label, fieldType: "header" },
  //   {
  //     label: "Date",
  //     data: moment(getRadio.data.updated_at).format("YY-MM-DD, h:mm a"),
  //     cardItem: true,
  //   },
  //   { label: "Ordering doctor", data: getRadio.data.fulfilled_by?.full_name },
  //   { label: "Images", fieldType: "gallery", data: getRadio.data.images },
  //   {
  //     label: "Report",
  //     fieldType: "link",
  //     data: getRadio.data.documents,
  //     cardItem: true,
  //   },
  //   { label: "Notes", data: getRadio.data.comment },
  // ];

  const handleViewCard = (order) => {
    setViewMode("list");
    getRadio.request(patientId, order.order_id);
    setChosenCardId(order.order_id);
  };

  useEffect(() => {
    setListData(data);
  }, [getRadio.data]);

  return (
    <>
      <CebForm
        // onSubmit={onSubmit}
        listData={listData}
        cardsData={getRadioList.data.map((item) => ({
          ...item,
          id: item.order_id,
        }))}
        cardItems={orderCardItems}
        viewModeState={[viewMode, setViewMode]}
        handleViewButton={handleViewCard}
        loading={getRadioList.loading || getRadio.loading}
        chosenCardId={chosenCardId}
        module={modules.RADIO}
        totalCount={getRadioList.count}
        paginationState={[pageNumber, setPageNumber]}
      />
    </>
  );
}
