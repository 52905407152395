import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import mainApi from "../../../api/main";
import CebForm from "../../../components/Form/CebForm";
import useApi from "../../../hooks/useApi";
import { t } from "i18next";

const VitalSigns = ({
  generalExaminationId,
  encounterViewModeKey,
  detailView,
  tabState,
}) => {
  const { patientId } = useParams();
  const [viewMode, setViewMode] = useState(detailView ? "list" : "form");
  const [encounterViewMode, setEncounterViewMode] = encounterViewModeKey;
  const [tabId, setTabId] = tabState;
  const [success, setSuccess] = useState();
  const [submitType, setSubmitType] = useState();

  //api
  const addGeneralExaminationVitalSigns = useApi(
    mainApi.addGeneralExaminationVitalSigns
  );
  const getGeneralExaminationFeltAtList = useApi(
    mainApi.getGeneralExaminationFeltAtList
  );
  const getGeneralExaminationPulseSpecialCharacter = useApi(
    mainApi.getGeneralExaminationPulseSpecialCharacter
  );
  const getGeneralExaminationPulsePeripheralPulsations = useApi(
    mainApi.getGeneralExaminationPulsePeripheralPulsations
  );
  const getGeneralExaminationRespiratoryRateRhythms = useApi(
    mainApi.getGeneralExaminationRespiratoryRateRhythms
  );
  const getGeneralExaminationRespiratoryRatetype = useApi(
    mainApi.getGeneralExaminationRespiratoryRatetype
  );
  const getGeneralExaminationTemperaturePattern = useApi(
    mainApi.getGeneralExaminationTemperaturePattern
  );

  const getGeneralExaminationVitalSigns = useApi(
    mainApi.getGeneralExaminationVitalSigns
  );

  useEffect(() => {
    if (viewMode === "form") {
      getGeneralExaminationFeltAtList.request();
      getGeneralExaminationPulseSpecialCharacter.request();
      getGeneralExaminationPulsePeripheralPulsations.request();
      getGeneralExaminationRespiratoryRateRhythms.request();
      getGeneralExaminationRespiratoryRatetype.request();
      getGeneralExaminationTemperaturePattern.request();
    } else if (viewMode === "list") {
      getGeneralExaminationVitalSigns.request(patientId, generalExaminationId);
    }
  }, [viewMode]);

  const fields = [
    {
      relatedFields: true,
      displayCondition: [true],
      fieldsTitle: "Pulse",
      fields: [
        {
          childField: true,
          multiple: true,
          items: getGeneralExaminationFeltAtList.data,
          type: "select",
          label: "Felt At",
          name: "felt_ats",
        },
        {
          childField: true,
          type: "number",
          label: "Rate",
          name: "rate",
        },
        {
          childField: true,
          isEnum: true,
          items: ["Regular", "Irregular"],
          type: "select",
          label: "Rhythm",
          name: "rhythm",
        },
        {
          childField: true,
          isEnum: true,
          items: [">10 Beats/min", "<10 Beats/min"],
          type: "select",
          label: "Pulse Defficit",
          name: "pulse_deficit",
        },
        {
          childField: true,
          type: "decimal",
          label: "Force",
          name: "force",
        },
        {
          childField: true,
          type: "decimal",
          label: "Tension",
          name: "tension",
        },
        {
          childField: true,
          type: "number",
          label: "Volume",
          name: "volume",
        },
        {
          childField: true,
          items: getGeneralExaminationPulseSpecialCharacter.data,
          type: "select",
          label: "Special Characters",
          name: "special_characters",
        },
        {
          childField: true,
          isEnum: true,
          items: ["Normal", "Felt"],
          type: "select",
          label: "Vessel Wall Condition",
          name: "vessel_wall_condition",
        },
        {
          childField: true,
          isEnum: true,
          items: ["Equal", "Unequal"],
          type: "select",
          label: "Equality on both sides",
          name: "equality_both_sides",
        },
        {
          childField: true,
          isEnum: true,
          items: ["Absent", "Present"],
          type: "select",
          label: "Radiofemoral Delay",
          name: "radiofemoral_delay",
        },
        {
          childField: true,
          isEnum: true,
          items: ["Absent", "Present"],
          type: "select",
          label: "Pistol shot",
          name: "pistol_shot",
        },
        {
          childField: true,
          isEnum: true,
          items: ["Absent", "Present"],
          type: "select",
          label: "Capillary Pulsation",
          name: "capillary_pulsation",
        },
        {
          childField: true,
          items: getGeneralExaminationPulsePeripheralPulsations.data,
          type: "select",
          label: "Peripheral Pulsation",
          name: "peripheral_pulsation",
        },
      ],
    },
    {
      relatedFields: true,
      displayCondition: [true],
      fieldsTitle: "Blood Pressure",
      fields: [
        {
          childField: true,
          type: "number",
          label: "Sytolic",
          name: "systolic",
        },
        {
          childField: true,
          type: "number",
          label: "Diastolic",
          name: "diastolic",
        },
        {
          childField: true,
          type: "number",
          label: "Systolic BLPR in UL",
          name: "systolic_bkpr_ul",
        },
        {
          childField: true,
          type: "number",
          label: "Systolic BLPR in LL",
          name: "systolic_bkpr_ll",
        },
      ],
    },
    {
      relatedFields: true,
      displayCondition: [true],
      fieldsTitle: "Respiratory Rate",
      fields: [
        {
          childField: true,
          type: "number",
          label: "Rate",
          name: "rr_rate",
        },
        {
          childField: true,
          items: getGeneralExaminationRespiratoryRateRhythms.data,
          type: "select",
          label: "Rhythm",
          name: "rr_rhythm",
        },
        {
          childField: true,
          items: getGeneralExaminationRespiratoryRatetype.data,
          type: "select",
          label: "Type",
          name: "type",
        },
        {
          childField: true,
          isEnum: true,
          items: ["Hperpnea", "Hypopnea"],
          type: "select",
          label: "Depth",
          name: "depth",
        },
      ],
    },
    {
      relatedFields: true,
      displayCondition: [true],
      fieldsTitle: "Temprature",
      fields: [
        {
          childField: true,
          type: "decimal",
          label: "Measurement",
          name: "measurement",
        },
        {
          childField: true,
          items: getGeneralExaminationTemperaturePattern.data,
          type: "select",
          label: "Pattern",
          name: "pattern",
        },
        {
          childField: true,
          label: "Pulse : Temp",
          name: "pulse_temp_ratio",
          type: "ratio",
        },
      ],
    },
  ];
  // const data = [
  //   {
  //     relatedFields: true,
  //     displayCondition: [true],
  //     fieldsTitle: "Pulse",
  //     fields: [
  //       {
  //         childField: true,
  //         multiple: true,
  //         items: getGeneralExaminationFeltAtList.data,
  //         type: "select",
  //         label: "Felt At",
  //         name: "felt_ats",
  //       },
  //       {
  //         childField: true,
  //         type: "number",
  //         label: "Rate",
  //         name: "rate",
  //       },
  //       {
  //         childField: true,
  //         isEnum: true,
  //         items: ["Regular", "Irregular"],
  //         type: "select",
  //         label: "Rhythm",
  //         name: "rhythm",
  //       },
  //       {
  //         childField: true,
  //         isEnum: true,
  //         items: [">10 Beats/min", "<10 Beats/min"],
  //         type: "select",
  //         label: "Pulse Defficit",
  //         name: "pulse_deficit",
  //       },
  //       {
  //         childField: true,
  //         type: "decimal",
  //         label: "Force",
  //         name: "force",
  //       },
  //       {
  //         childField: true,
  //         type: "decimal",
  //         label: "Tension",
  //         name: "tension",
  //       },
  //       {
  //         childField: true,
  //         type: "number",
  //         label: "Volume",
  //         name: "volume",
  //       },
  //       {
  //         childField: true,
  //         items: getGeneralExaminationPulseSpecialCharacter.data,
  //         type: "select",
  //         label: "Special Characters",
  //         name: "special_characters",
  //       },
  //       {
  //         childField: true,
  //         isEnum: true,
  //         items: ["Normal", "Felt"],
  //         type: "select",
  //         label: "Vessel Wall Condition",
  //         name: "vessel_wall_condition",
  //       },
  //       {
  //         childField: true,
  //         isEnum: true,
  //         items: ["Equal", "Unequal"],
  //         type: "select",
  //         label: "Equality on both sides",
  //         name: "equality_both_sides",
  //       },
  //       {
  //         childField: true,
  //         isEnum: true,
  //         items: ["Absent", "Present"],
  //         type: "select",
  //         label: "Radiofemoral Delay",
  //         name: "radiofemoral_delay",
  //       },
  //       {
  //         childField: true,
  //         isEnum: true,
  //         items: ["Absent", "Present"],
  //         type: "select",
  //         label: "Pistol shot",
  //         name: "pistol_shot",
  //       },
  //       {
  //         childField: true,
  //         isEnum: true,
  //         items: ["Absent", "Present"],
  //         type: "select",
  //         label: "Capillary Pulsation",
  //         name: "capillary_pulsation",
  //       },
  //       {
  //         childField: true,
  //         items: getGeneralExaminationPulsePeripheralPulsations.data,
  //         type: "select",
  //         label: "Peripheral Pulsation",
  //         name: "peripheral_pulsation",
  //       },
  //     ],
  //   },
  //   {
  //     relatedFields: true,
  //     displayCondition: [true],
  //     fieldsTitle: "Blood Pressure",
  //     fields: [
  //       {
  //         childField: true,
  //         type: "number",
  //         label: "Sytolic",
  //         name: "systolic",
  //       },
  //       {
  //         childField: true,
  //         type: "number",
  //         label: "Diastolic",
  //         name: "diastolic",
  //       },
  //       {
  //         childField: true,
  //         type: "number",
  //         label: "Systolic BLPR in UL",
  //         name: "systolic_bkpr_ul",
  //       },
  //       {
  //         childField: true,
  //         type: "number",
  //         label: "Systolic BLPR in LL",
  //         name: "systolic_bkpr_ll",
  //       },
  //     ],
  //   },
  //   {
  //     relatedFields: true,
  //     displayCondition: [true],
  //     fieldsTitle: "Respiratory Rate",
  //     fields: [
  //       {
  //         childField: true,
  //         type: "number",
  //         label: "Rate",
  //         name: "rr_rate",
  //       },
  //       {
  //         childField: true,
  //         items: getGeneralExaminationRespiratoryRateRhythms.data,
  //         type: "select",
  //         label: "Rhythm",
  //         name: "rr_rhythm",
  //       },
  //       {
  //         childField: true,
  //         items: getGeneralExaminationRespiratoryRatetype.data,
  //         type: "select",
  //         label: "Type",
  //         name: "type",
  //       },
  //       {
  //         childField: true,
  //         isEnum: true,
  //         items: ["Hperpnea", "Hypopnea"],
  //         type: "select",
  //         label: "Depth",
  //         name: "depth",
  //       },
  //     ],
  //   },
  //   {
  //     relatedFields: true,
  //     displayCondition: [true],
  //     fieldsTitle: "Temprature",
  //     fields: [
  //       {
  //         childField: true,
  //         type: "decimal",
  //         label: "measurement",
  //         name: "measurement",
  //       },
  //       {
  //         childField: true,
  //         items: getGeneralExaminationTemperaturePattern.data,
  //         type: "select",
  //         label: "Pattern",
  //         name: "pattern",
  //       },
  //       {
  //         childField: true,
  //         label: "Pulse : Temp",
  //         name: "pulse_temp_ratio",
  //         type: "ratio",
  //       },
  //     ],
  //   },
  // ];

  const getVitalSignObj = (data) => ({
    pulse: {
      felt_ats: data.felt_ats,
      rate: data.rate,
      rhythm: data.rhythm,
      pulse_deficit: data.pulse_deficit,
      force: data.force,
      tension: data.tension,
      volume: data.volume,
      special_characters: data.special_characters,
      vessel_wall_condition: data.vessel_wall_condition,
      equality_both_sides: data.equality_both_sides,
      radiofemoral_delay: data.radiofemoral_delay,
      pistol_shot: data.pistol_shot,
      capillary_pulsation: data.capillary_pulsation,
      peripheral_pulsation: data.peripheral_pulsation,
    },
    blood_pressure: {
      systolic: data.systolic,
      diastolic: data.diastolic,
      systolic_bkpr_ul: data.systolic_bkpr_ul,
      systolic_bkpr_ll: data.systolic_bkpr_ll,
    },
    respiratory_rate: {
      rate: data.rr_rate,
      rhythm: data.rr_rhythm,
      type: data.type,
      depth: data.depth,
    },
    temperature: {
      measurement: data.measurement,
      pattern: data.pattern,
      pulse_temp_ratio: data.pulse_temp_ratio,
    },
  });

  const onSubmit = async (data) => {
    const obj = getVitalSignObj(data);
    console.log("obj", obj);
    const res = await addGeneralExaminationVitalSigns.requestWithToast(
      t("Added successfully"),
      patientId,
      generalExaminationId,
      obj
    );
    setSuccess(res.ok);
  };

  useEffect(() => {
    console.log("submit", success, submitType);
    if (success && submitType) {
      if (submitType === "next") {
        setTabId((prev) => prev + 1);
        window.scrollTo(0, 0);
      } else {
        setEncounterViewMode("encounter");
      }
      setSuccess(false);
      setSubmitType(null);
    }
  }, [success, submitType]);

  return (
    <CebForm
      viewModeState={[viewMode, setViewMode]}
      fields={fields}
      onSubmit={onSubmit}
      nextButtonHandler={() => {
        setSubmitType("next");
      }}
      cancelButtonLabel="Back"
      saveHandler={() => setSubmitType("save")}
      cancelHandler={() => setEncounterViewMode("encounter")}
      requestErrors={addGeneralExaminationVitalSigns.error}
    />
  );
};

export default VitalSigns;
