import React, { useContext, useEffect } from "react";

import ThirdNav from "../../../components/Navigation/ThirdNav";
import { Routes, Route, useNavigate } from "react-router-dom";
import ProtectedRoute from "../../../auth/protectedRoute";
import InstructionsPage from "./InstructionsPage";
import Drugs from "./DrugsPage";
import OrdersPage from "../OrdersPage";
import DischargePage from "./DischargePage";
import SummaryPage from "./SummaryPage";
import Followup from "./Followup";
import roleMapper from "../../../helpers/roleMapper";
import { Context } from "../../../context/Context";
import { modules } from "../../../config/constants";

export default () => {
  // const permissions = roleMapper()?.admission;
  const navigate = useNavigate();
  const store = useContext(Context);
  const permissions = store?.permissions?.["Admission"];
  const navItems = [
    {
      name: "Summary",
      link: "summary",
      element: <SummaryPage />,
    },
    permissions?.[modules.ADMISSIOM_INSTRUCTION] && {
      name: "Instructions",
      link: "instructions",
      element: <InstructionsPage />,
    },
    permissions?.[modules.ADMISSIOM_ORDER] && {
      name: "Orders",
      link: "orders",
      element: <OrdersPage />,
    },
    permissions?.[modules.ADMISSIOM_MEDICATION] && {
      name: "Medications",
      link: "medications",
      element: <Drugs />,
    },
    permissions?.[modules.ADMISSIOM_FOLLOWUP] && {
      name: "Followup",
      link: "followup",
      element: <Followup />,
    },
    permissions?.["sheets"] && {
      name: "Sheets",
      link: "sheets",
      element: <div>Sheets</div>,
    },
    permissions?.[modules.ADMISSIOM_DISCHARGE] && {
      name: "Discharge",
      link: "discharge",
      element: <DischargePage />,
    },
  ].filter((item) => item);

  // useEffect(() => {
  //   console.log(window.location.pathname);
  //   const redirectTab = navItems.find((item) =>
  //     window.location.pathname.includes(item.link)
  //   );
  //   if (!redirectTab) {
  //     navigate(navItems[0]?.link);
  //   }
  // }, [navItems]);

  return (
    <>
      <ThirdNav navItems={navItems} />
      <Routes>
        {navItems.map((item) => (
          <Route
            key={item.name}
            path={"/" + item.link}
            element={<ProtectedRoute />}
          >
            <Route path={"/" + item.link} element={item.element} />
          </Route>
        ))}
      </Routes>
    </>
  );
};
