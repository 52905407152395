import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import mainApi from "../../../api/main";
import CebForm from "../../../components/Form/CebForm";
import useApi from "../../../hooks/useApi";
import { t } from "i18next";

const Back = ({ generalExaminationId, encounterViewModeKey, tabState }) => {
  const { patientId } = useParams();
  const [viewMode, setViewMode] = useState("form");
  const [encounterViewMode, setEncounterViewMode] = encounterViewModeKey;
  const [tabId, setTabId] = tabState;
  const [success, setSuccess] = useState();
  const [submitType, setSubmitType] = useState();

  //api
  const addGeneralExaminationBack = useApi(mainApi.addGeneralExaminationBack);
  const getGeneralExaminationBackSkinList = useApi(
    mainApi.getGeneralExaminationBackSkinList
  );
  const getGeneralExaminationCrepitation = useApi(
    mainApi.getGeneralExaminationCrepitation
  );
  const getGeneralExaminationMurmursList = useApi(
    mainApi.getGeneralExaminationMurmursList
  );
  const getGeneralExaminationScapulaList = useApi(
    mainApi.getGeneralExaminationScapulaList
  );
  const getGeneralExaminationVertebraeList = useApi(
    mainApi.getGeneralExaminationVertebraeList
  );

  useEffect(() => {
    getGeneralExaminationBackSkinList.request();
    getGeneralExaminationCrepitation.request();
    getGeneralExaminationMurmursList.request();
    getGeneralExaminationScapulaList.request();
    getGeneralExaminationVertebraeList.request();
  }, []);

  useEffect(() => {
    if (viewMode === "cards") {
      setEncounterViewMode("encounter");
      setViewMode("form");
    }
  }, [viewMode]);

  const fields = [
    {
      type: "select",
      multiple: true,
      name: "murmur_bruits",
      label: "Murmurs – Bruit",
      items: getGeneralExaminationMurmursList.data,
    },
    {
      name: "crepitation",
      label: "Crepitations",
      type: "select",
      items: getGeneralExaminationCrepitation.data,
    },
    {
      name: "ewart_sign",
      label: "Ewart Sign",
      type: "select",
      isEnum: true,
      items: ["Absent", "Present"],
    },
    {
      name: "vertebras",
      label: "Vertebrae",
      type: "select",
      items: getGeneralExaminationVertebraeList.data,
    },
    {
      name: "scapula",
      label: "Scapula",
      type: "select",
      items: getGeneralExaminationScapulaList.data,
    },
    {
      name: "skin",
      label: "Skin",
      type: "select",
      items: getGeneralExaminationBackSkinList.data,
    },
  ];

  const onSubmit = async (data) => {
    const res = await addGeneralExaminationBack.requestWithToast(
      t("Added successfully"),
      patientId,
      generalExaminationId,
      data
    );
    setSuccess(res.ok);
  };

  useEffect(() => {
    console.log("submit", success, submitType);
    if (success && submitType) {
      if (submitType === "next") {
        setTabId((prev) => prev + 1);
        window.scrollTo(0, 0);
      } else {
        setEncounterViewMode("encounter");
      }
      setSuccess(false);
      setSubmitType(null);
    }
  }, [success, submitType]);

  return (
    <CebForm
      viewModeState={[viewMode, setViewMode]}
      fields={fields}
      onSubmit={onSubmit}
      nextButtonHandler={() => {
        setSubmitType("next");
      }}
      cancelButtonLabel="Back"
      saveHandler={() => setSubmitType("save")}
      cancelHandler={() => setEncounterViewMode("encounter")}
      requestErrors={addGeneralExaminationBack.error}
    />
  );
};

export default Back;
