import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Collapse,
  Pagination,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import main from "../api/main";
import PatientCard, {
  PatientCardTitles,
} from "../components/patient/PatientCard";
import ActionButton from "../components/UI/ActionButton";
import useApi from "../hooks/useApi";
import Modal from "../components/UI/Modal";
import CebForm from "../components/Form/CebForm";
import usePermissions from "../hooks/usePermissions";
import { defaultPageSize, modules } from "../config/constants";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

const Referral = ({ myReferrals }) => {
  const [viewCollapse, setViewCollapse] = useState({});
  const [referralList, setreferralList] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  //mobile/web view
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));

  //api
  const getReferralList = useApi(main.getReferralList);
  const getMyReferralList = useApi(main.getMyReferralList);

  const acceptReferral = useApi(main.acceptReferral);
  const rejectReferral = useApi(main.rejectReferral);

  useEffect(() => {
    if (myReferrals) getMyReferralList.request(pageNumber);
    else getReferralList.request(pageNumber);
    setViewCollapse({});
  }, [acceptReferral.data, rejectReferral.data, myReferrals, pageNumber]);

  useEffect(() => {
    if (myReferrals) {
      setreferralList(getMyReferralList.data);
      setTotalCount(getMyReferralList.count);
    } else {
      setreferralList(getReferralList.data);
      setTotalCount(getReferralList.count);
    }
  }, [getReferralList.data, getMyReferralList.data, myReferrals]);

  //direct navigation
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  useEffect(() => {
    const referralId = urlParams.get("id");
    if (referralId)
      setViewCollapse((state) => ({
        ...state,
        [referralId]: true,
      }));
  }, [urlParams.get("id")]);

  const items = (id) =>
    [
      { header: "Type", keyName: "data.type" },
      !myReferrals && {
        header: "Referred From",
        keyName: "data.practitioner_name",
      },
      myReferrals && {
        header: "Referred To",
        keyName: "data.referred_to_name",
      },
      {
        header: "Request Date",
        keyName: "data.created_at",
        type: "formattedDate",
        format: "DD-MM-YYYY hh:mm A",
      },
      {
        header: "Status",
        keyName: "data.status",
        type: "status",
        statusColor: `data.status === "Pending" ? "#8B8000" : data.status === "Accepted" ? "green" : "secondary.main" `,
      },
      {
        header: "Reply Date",
        keyName: "data.reply_date",
        type: "formattedDate",
        format: "DD-MM-YYYY hh:mm A",
      },
      {
        header: !mobileView ? "View" : "",
        type: "element",
        element: viewCollapse[id] ? (
          !mobileView ? (
            <ExpandLess />
          ) : (
            <Button sx={{ textTransform: "none" }}>Hide details</Button>
          )
        ) : !mobileView ? (
          <ExpandMore />
        ) : (
          <Button sx={{ textTransform: "none" }}>View details</Button>
        ),
        onClickHandler: () => {
          setViewCollapse((state) => ({
            ...state,
            [id]: !state[id],
          }));
        },
      },
    ].filter((item) => item);

  return (
    <Stack gap={2} paddingBottom={2} alignItems="center">
      {!mobileView && (
        <PatientCardTitles items={items()} data={referralList?.[0]} />
      )}
      {getMyReferralList.loading || getReferralList.loading ? (
        <CircularProgress />
      ) : (
        referralList.map((card, index) => (
          <Stack key={card.id} width={"100%"}>
            <PatientCard
              key={index}
              items={items(card.id)}
              data={card}
              index={index}
            />
            <ReferralData
              referralId={card.id}
              myReferrals={myReferrals}
              reloadList={() =>
                myReferrals
                  ? getMyReferralList.request()
                  : getReferralList.request()
              }
              view={viewCollapse[card.id]}
              mobileView={mobileView}
            />
          </Stack>
        ))
      )}
      {totalCount > 0 && (
        <Stack alignItems={"center"}>
          <Pagination
            dir="ltr"
            color="primary"
            count={Math.ceil(totalCount / defaultPageSize)}
            onChange={(event, value) => setPageNumber(value)}
            page={pageNumber}
          />
        </Stack>
      )}
    </Stack>
  );
};

export default Referral;

const ReferralData = ({
  referralId,
  myReferrals,
  reloadList,
  view,
  mobileView,
}) => {
  const { t, i18n } = useTranslation();
  const [openRejectionModal, setOpenRejetionModal] = useState();
  const [viewRejectionMode, setViewRejectionMode] = useState("form");

  const handleRejectionModal = () => {
    setOpenRejetionModal((s) => !s);
  };

  //api
  const getReferral = useApi(
    myReferrals ? main.getMyReferral : main.getReferral
  );
  const acceptReferral = useApi(main.acceptReferral);
  const rejectReferral = useApi(main.rejectReferral);

  //permissions
  const permissions = usePermissions(
    modules.ADMISSIOM,
    modules.ADMISSIOM_REFERRAL
  );

  useEffect(() => {
    if (view) {
      getReferral.request(referralId);
    }
  }, [acceptReferral.data, rejectReferral.data, view]);

  const fields = [
    {
      header: "Patient Name",
      label: getReferral.data?.patient?.full_name,
    },
    {
      header: "Age",
      label: getReferral.data?.patient?.age,
    },
    {
      header: "MRN",
      label: getReferral.data?.patient?.username,
    },
    {
      header: "Gender",
      label: t(getReferral.data?.patient?.gender),
    },
    {
      header: "Referred From",
      label: getReferral.data?.practitioner_name,
    },
    {
      header: "Referred To",
      label: getReferral.data?.referred_to_name,
    },
    {
      header: "Date of Request",
      label: moment(getReferral.data?.created_at).format("DD-MM-YYYY"),
    },
    {
      header: "Time of Request",
      label: moment(getReferral.data?.created_at).format("hh:mm A"),
    },
    {
      header: "Reason",
      label: getReferral.data?.reason,
    },
    {
      header: "Diagnosis",
      label: getReferral.data?.diagnosis?.map((item) => item.label),
    },
    getReferral.data?.rejection_reason && {
      header: "Rejection Reason",
      label: getReferral.data?.rejection_reason,
    },
    {
      header: "Notes",
      label: getReferral.data?.notes,
    },
  ].filter((item) => item);

  const handleAcceptReferral = async () => {
    const res = await acceptReferral.requestWithToast(
      t("Accepted successfully"),
      referralId
    );
    if (res.ok) reloadList();
  };

  const handleRejectReferral = async (data) => {
    const res = await rejectReferral.requestWithToast(
      t("Rejected successfully"),
      referralId,
      data
    );
    if (res.ok) {
      handleRejectionModal();
      reloadList();
    }
  };

  return (
    <>
      {getReferral.loading && (
        <Stack
          sx={{
            backgroundColor: !mobileView && "card.main",
            borderBottomLeftRadius: "20px",
            borderBottomRightRadius: "20px",
          }}
          alignItems="center"
          p={2}
        >
          <CircularProgress />
        </Stack>
      )}
      <Collapse in={view && !getReferral.loading}>
        <Stack
          sx={{
            backgroundColor: "card.main",
            borderBottomLeftRadius: "20px",
            borderBottomRightRadius: "20px",
          }}
          p={2}
          marginTop={-2}
          alignItems="center"
        >
          <Stack
            sx={{ backgroundColor: "#fff" }}
            gap={"32px"}
            padding={4}
            borderRadius="10px"
            width="100%"
          >
            <Stack
              width={"100%"}
              direction={!mobileView ? "row" : "column"}
              justifyContent="space-between"
              rowGap={3}
              flexWrap="wrap"
            >
              {fields.map((item, index) => (
                <Stack
                  key={index}
                  width={!mobileView ? "47%" : "100%"}
                  borderBottom="1px solid rgba(0,0,0,0.1)"
                  paddingBottom={"16px"}
                  justifyContent="space-between"
                  spacing="10%"
                  direction="row"
                >
                  <Stack width="45%">
                    <Typography fontWeight={500}>
                      {i18n.language === "en"
                        ? item.header.toUpperCase()
                        : t(item.header)}
                    </Typography>
                  </Stack>
                  {Array.isArray(item.label) ? (
                    <Stack gap={2}>
                      {item.label?.map((el, index) => (
                        <Typography key={index}>&#x2022; {el}</Typography>
                      ))}
                    </Stack>
                  ) : (
                    <Stack
                      width="45%"
                      flexDirection={
                        i18n.language === "ar" ? "row-reverse" : "row"
                      }
                      style={{ direction: "ltr" }}
                    >
                      <Typography>{item.label}</Typography>
                    </Stack>
                  )}
                </Stack>
              ))}
            </Stack>
            {getReferral.data?.status === "Pending" &&
              !myReferrals &&
              permissions?.edit && (
                <Stack width={"100%"} direction="row" justifyContent="flex-end">
                  <Stack gap={2} direction="row">
                    <ActionButton type="approve" onClick={handleAcceptReferral}>
                      {t("Accept")}
                    </ActionButton>
                    <ActionButton
                      type="reject"
                      onClick={() => {
                        setOpenRejetionModal(true);
                      }}
                    >
                      {t("Reject")}
                    </ActionButton>
                  </Stack>
                </Stack>
              )}
            <Modal open={openRejectionModal} handleOpen={handleRejectionModal}>
              <CebForm
                modalForm
                modalTitle={"Add Rejection Reason"}
                fields={[
                  {
                    name: "rejection_reason",
                    label: "Reason",
                    multiline: true,
                  },
                ]}
                viewModeState={[viewRejectionMode, setViewRejectionMode]}
                cancelHandler={handleRejectionModal}
                onSubmit={handleRejectReferral}
              />
            </Modal>
          </Stack>
        </Stack>
      </Collapse>
    </>
  );
};
