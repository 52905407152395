import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import mainApi from "../../../api/main";
import CebForm from "../../../components/Form/CebForm";
import useApi from "../../../hooks/useApi";
import { t } from "i18next";

const LowerLimb = ({
  generalExaminationId,
  encounterViewModeKey,
  tabState,
}) => {
  const { patientId } = useParams();
  const [viewMode, setViewMode] = useState("form");
  const [encounterViewMode, setEncounterViewMode] = encounterViewModeKey;
  const [tabId, setTabId] = tabState;
  const [success, setSuccess] = useState();
  const [submitType, setSubmitType] = useState();

  //api

  const addGeneralExaminationLowerLimb = useApi(
    mainApi.addGeneralExaminationLowerLimb
  );
  const getGeneralExaminationLowerLimbShapeList = useApi(
    mainApi.getGeneralExaminationLowerLimbShapeList
  );
  const getGeneralExaminationLowerLimbEdemaList = useApi(
    mainApi.getGeneralExaminationLowerLimbEdemaList
  );
  const getGeneralExaminationLimbSkinList = useApi(
    mainApi.getGeneralExaminationLimbSkinList
  );
  const getGeneralExaminationLimbNailList = useApi(
    mainApi.getGeneralExaminationLimbNailList
  );

  useEffect(() => {
    getGeneralExaminationLowerLimbShapeList.request();
    getGeneralExaminationLimbNailList.request();
    getGeneralExaminationLimbSkinList.request();
    getGeneralExaminationLowerLimbEdemaList.request();
  }, []);

  useEffect(() => {
    if (viewMode === "cards") {
      setEncounterViewMode("encounter");
      setViewMode("form");
    }
  }, [viewMode]);

  const fields = [
    {
      name: "shapes",
      label: "Shape",
      type: "select",
      multiple: true,
      items: getGeneralExaminationLowerLimbShapeList.data,
    },
    {
      name: "nails",
      label: "Nails",
      type: "select",
      // multiple: true,
      items: getGeneralExaminationLimbNailList.data,
    },
    {
      name: "skin",
      label: "Skin",
      type: "select",
      // multiple: true,
      items: getGeneralExaminationLimbSkinList.data,
    },
    {
      name: "edema",
      label: "Edema",
      type: "select",
      // multiple: true,
      items: getGeneralExaminationLowerLimbEdemaList.data,
    },
  ];

  const onSubmit = async (data) => {
    const res = await addGeneralExaminationLowerLimb.requestWithToast(
      t("Added successfully"),
      patientId,
      generalExaminationId,
      data
    );
    setSuccess(res.ok);
  };

  useEffect(() => {
    console.log("submit", success, submitType);
    if (success && submitType) {
      if (submitType === "next") {
        setTabId((prev) => prev + 1);
        window.scrollTo(0, 0);
      } else {
        setEncounterViewMode("encounter");
      }
      setSuccess(false);
      setSubmitType(null);
    }
  }, [success, submitType]);

  return (
    <CebForm
      viewModeState={[viewMode, setViewMode]}
      fields={fields}
      onSubmit={onSubmit}
      nextButtonHandler={() => {
        setSubmitType("next");
      }}
      cancelButtonLabel="Back"
      saveHandler={() => setSubmitType("save")}
      cancelHandler={() => setEncounterViewMode("encounter")}
      requestErrors={addGeneralExaminationLowerLimb.error}
    />
  );
};

export default LowerLimb;
