const formatTime = (time) => {
  const timeArr = time.split(":"); //[hours:minutes:seconds]
  if (timeArr[0] === "00") return `12:${timeArr[1]} am`;
  let newHours = timeArr[0];
  let type = "am";
  const afterNoonHours = Number(timeArr[0] - 12);
  if (afterNoonHours >= 0) {
    newHours = afterNoonHours
      ? `${afterNoonHours}`.length < 2
        ? `0${afterNoonHours}`
        : `${afterNoonHours}`
      : "12";
    type = "pm";
  }
  return `${newHours}:${timeArr[1]} ${type}`;
};

export default formatTime;
