import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width="40"
    height="30"
    viewBox="0 0 40 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.80078 0.599998C5.20948 0.599998 3.68336 1.23214 2.55814 2.35736C1.43292 3.48258 0.800781 5.0087 0.800781 6.6V23.4C0.800781 24.9913 1.43292 26.5174 2.55814 27.6426C3.68336 28.7679 5.20948 29.4 6.80078 29.4H33.2008C34.7921 29.4 36.3182 28.7679 37.4434 27.6426C38.5686 26.5174 39.2008 24.9913 39.2008 23.4V6.6C39.2008 5.0087 38.5686 3.48258 37.4434 2.35736C36.3182 1.23214 34.7921 0.599998 33.2008 0.599998H6.80078ZM13.1512 8.1504C13.2627 8.03865 13.3951 7.94998 13.5409 7.88949C13.6866 7.82899 13.8429 7.79785 14.0008 7.79785C14.1586 7.79785 14.3149 7.82899 14.4607 7.88949C14.6065 7.94998 14.7389 8.03865 14.8504 8.1504L20.0008 13.3032L25.1512 8.1504C25.3765 7.92507 25.6821 7.79848 26.0008 7.79848C26.3194 7.79848 26.6251 7.92507 26.8504 8.1504C27.0757 8.37573 27.2023 8.68134 27.2023 9C27.2023 9.31866 27.0757 9.62427 26.8504 9.8496L21.6976 15L26.8504 20.1504C27.0757 20.3757 27.2023 20.6813 27.2023 21C27.2023 21.3187 27.0757 21.6243 26.8504 21.8496C26.6251 22.0749 26.3194 22.2015 26.0008 22.2015C25.6821 22.2015 25.3765 22.0749 25.1512 21.8496L20.0008 16.6968L14.8504 21.8496C14.6254 22.0749 14.3201 22.2016 14.0016 22.2019C13.6832 22.2021 13.3777 22.0758 13.1524 21.8508C12.9271 21.6258 12.8003 21.3205 12.8001 21.002C12.7999 20.6836 12.9262 20.3781 13.1512 20.1528L18.304 15L13.1512 9.8496C13.0394 9.73813 12.9508 9.60571 12.8903 9.45992C12.8298 9.31413 12.7986 9.15784 12.7986 9C12.7986 8.84216 12.8298 8.68587 12.8903 8.54008C12.9508 8.39429 13.0394 8.26187 13.1512 8.1504Z"
      fill="#8E0201"
    />
  </svg>
);

export default SvgComponent;
