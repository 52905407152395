import React, { useEffect, useState } from "react";
import CebForm from "../../components/Form/CebForm";
import useApi from "../../hooks/useApi";
import mainApi from "../../api/main";
import { useParams } from "react-router-dom";
import moment from "moment";
import { modules } from "../../config/constants";

export default function LabPage() {
  const { patientId } = useParams();
  const [viewMode, setViewMode] = useState("cards");
  const [listData, setListData] = useState([]);
  const [chosenCardId, setChosenCardId] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  //api
  const getLabList = useApi(mainApi.getLabList);
  const getLab = useApi(mainApi.getLab);

  useEffect(() => {
    getLabList.request(patientId, pageNumber);
  }, [pageNumber]);

  const orderCardItems = [
    {
      header: "Date & Time",
      type: "formattedDate",
      keyName: "data.updated_at",
      format: "YY-MM-DD, h:mm a",
    },
    {
      header: "Investigation",
      keyName:
        "data.label.length > 15 ? (data.label.substring(0, 15) + '...') : data.label",
      removeInDetail: true,
    },
    {
      header: "View",
      type: "link",
      keyName: "data.documents[0]?.file",
      title: "data.documents[0]?.file_name",
    },
  ];
  const data = [
    { title: getLab.data.label, fieldType: "header" },
    {
      label: "Date",
      data: moment(getLab.data.updated_at).format("YY-MM-DD, h:mm a"),
      cardItem: true,
    },
    {
      label: "Ordering doctor",
      data: getLab.data.fulfilled_by?.full_name,
    },
    {
      label: "View",
      fieldType: "link",
      data: getLab.data.documents,
      cardItem: true,
    },
    { label: "Notes", data: getLab.data.comment, multiline: true },
  ];

  // const dataToPrint = {
  //   header: {
  //     title: `Laboratory (${getLab.data.label})`,
  //     left: [
  //       moment(getLab.data.updated_at).format("DD-MM-YYYY, h:mm a"),
  //       `Doctor: ${getLab.data.fulfilled_by?.full_name}`,
  //     ],
  //   },
  //   content: [
  //     {
  //       label: "Notes",
  //       data: getLab.data.comment,
  //     },
  //     {
  //       label: "Images",
  //       data: getLab.data.documents?.map((item) => item.file),
  //       type: "image",
  //     },
  //   ],
  // };

  const handleViewCard = (order) => {
    setChosenCardId(order.order_id);
    setViewMode("list");

    getLab.request(patientId, order.order_id);
  };

  useEffect(() => {
    setListData(data);
  }, [getLab.data]);

  return (
    <>
      <CebForm
        // onSubmit={onSubmit}
        listData={listData}
        cardsData={getLabList.data.map((item) => ({
          ...item,
          id: item.order_id,
        }))}
        cardItems={orderCardItems}
        viewModeState={[viewMode, setViewMode]}
        handleViewButton={handleViewCard}
        loading={getLabList.loading || getLab.loading}
        chosenCardId={chosenCardId}
        module={modules.LAB}
        totalCount={getLabList.count}
        paginationState={[pageNumber, setPageNumber]}
      />
    </>
  );
}
