import { Stack, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export default function SemiBoldTypography({ title, content }) {
  const { t } = useTranslation();
  return (
    <Stack direction="row" alignItems={"center"} gap={1}>
      <Typography variant="body2" color="background.default">
        <span style={{ fontWeight: 700 }}>{t(title)}</span>:
      </Typography>
      <div style={{ color: "white", direction: "ltr" }}>{t(content)}</div>
    </Stack>
  );
}
