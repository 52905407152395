import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.2 12.6a.6.6 0 1 1 1.2 0 .6.6 0 0 1-1.2 0Zm.6 1.8a.6.6 0 1 0 0 1.2.6.6 0 0 0 0-1.2Zm-.6 3a.6.6 0 1 1 1.2 0 .6.6 0 0 1-1.2 0Zm3-5.4a.6.6 0 0 0 0 1.2h6a.6.6 0 0 0 0-1.2h-6Zm-.6 3a.6.6 0 0 1 .6-.6h6a.6.6 0 0 1 0 1.2h-6a.6.6 0 0 1-.6-.6Zm.6 1.8a.6.6 0 0 0 0 1.2h6a.6.6 0 0 0 0-1.2h-6Zm-3-14.4a2.4 2.4 0 0 0-2.4 2.4v14.4a2.4 2.4 0 0 0 2.4 2.4h9.6a2.4 2.4 0 0 0 2.4-2.4V8.897a1.8 1.8 0 0 0-.528-1.272l-4.697-4.698a1.8 1.8 0 0 0-1.272-.527H7.2ZM6 4.8a1.2 1.2 0 0 1 1.2-1.2H12v4.2a1.8 1.8 0 0 0 1.8 1.8H18v9.6a1.2 1.2 0 0 1-1.2 1.2H7.2A1.2 1.2 0 0 1 6 19.2V4.8Zm11.752 3.6H13.8a.6.6 0 0 1-.6-.6V3.848L17.752 8.4Z"
      fill="#003468"
    />
  </svg>
);

export default SvgComponent;
