import { Button } from "@mui/material";
import React from "react";

import theme from "../../styles/MUITheme";

const ActionButton = ({ children, type, onClick, width = 146, ...props }) => {
  return (
    <Button
      variant="outlined"
      sx={{
        color: type === "reject" ? theme.palette.secondary.main : "green",
        borderColor: type === "reject" ? theme.palette.secondary.main : "green",
        textTransform: "capitalize",
        height: 46,
        width: width,
        borderRadius: 2,
        ":hover": {
          backgroundColor:
            type === "reject" ? theme.palette.secondary.main : "green",
          color: "#fff",
          borderColor:
            type === "reject" ? theme.palette.secondary.main : "green",
        },
      }}
      onClick={onClick}
      {...props}
    >
      {children}
    </Button>
  );
};

export default ActionButton;
