import React from "react";

//MUI components
import { styled, alpha } from "@mui/material/styles";
import { Box, FormControl, InputAdornment, InputBase } from "@mui/material";

//icons
import SearchIcon from "@mui/icons-material/Search";

const SearchInput = styled(InputBase)(({ theme }) => ({
  "&": {
    borderRadius: 10,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    padding: "0px 12px",
    fontSize: 13,
    height: 29,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

export default function NavSearchField() {
  return (
    <Box
      style={{ width: 281, borderRadius: 10, marginLeft: 23, marginRight: 30 }}
    >
      <FormControl variant="standard" fullWidth>
        <SearchInput
          placeholder="Search in patients"
          id="bootstrap-input"
          endAdornment={
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          }
        />
      </FormControl>
    </Box>
  );
}
