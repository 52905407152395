import React, { useState, useEffect, useContext } from "react";
import { modules } from "../config/constants";
import { Context } from "../context/Context";

const usePermissions = (module, subModule) => {
  const [permissions, setPermissions] = useState({});

  const store = useContext(Context);
  const storePermissions = store.permissions;

  useEffect(() => {
    if (module && storePermissions?.[module]) {
      let permArr = [];
      let permObj = {};
      if (subModule) permArr = storePermissions[module][subModule];
      else permArr = storePermissions[module][module];
      if (permArr) {
        if (
          subModule === modules.PATIENT_FIELDS ||
          module === modules.EXTRA_PERMISSIONS
        )
          for (const perm of permArr) {
            permObj[perm.name] = true;
          }
        else
          for (const perm of permArr) {
            if (perm.codename.includes("view")) permObj.view = true;
            else if (perm.codename.includes("add")) permObj.add = true;
            else if (perm.codename.includes("change")) permObj.edit = true;
            else if (perm.codename.includes("access")) {
              permObj.add = true;
              permObj.view = true;
            } else if (perm.codename === "filter_patient_active_medications") {
              permObj.active_medications = true;
            }
          }
        setPermissions(permObj);
      } else setPermissions([]);

      // console.log("perm arr", permObj);
    }
  }, [module, subModule, storePermissions]);

  return permissions;
};

export default usePermissions;
