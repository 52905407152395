import React from "react";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import { IconButton } from "@mui/material";
import theme from "../../styles/MUITheme";

const CustomAddButton = ({
  onClick,
  onKeyDown,
  submitBtn = false,
  light,
  disabled = false,
}) => (
  <>
    {!submitBtn && (
      <IconButton
        sx={{
          width: 30,
          height: 30,
          backgroundColor: light ? "#fff" : "primary.main",
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={onClick}
        onKeyDown={onKeyDown}
        disabled={disabled}
      >
        <AddSharpIcon
          sx={{
            color: light ? theme.palette.primary.main : "#fff",
            backgroundColor: light ? "#fff" : theme.palette.primary.main,
            borderRadius: "50%",
          }}
        />
      </IconButton>
    )}

    {submitBtn && (
      <IconButton
        sx={{
          width: 30,
          height: 30,
          backgroundColor: light ? "#fff" : "primary.main",
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        type="submit"
        onClick={onClick}
        onKeyDown={onKeyDown}
      >
        <AddSharpIcon
          sx={{
            color: light ? theme.palette.primary.main : "#fff",
            backgroundColor: light ? "#fff" : theme.palette.primary.main,
            borderRadius: "50%",
          }}
        />
      </IconButton>
    )}
  </>
);

export default CustomAddButton;
