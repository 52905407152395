import React, { useEffect, useState } from "react";
import CebForm from "../../../components/Form/CebForm";
import useApi from "../../../hooks/useApi";
import mainApi from "../../../api/main";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CebList from "../../../components/Form/CebList";
import { LoadingBox } from "../../../components/UI/LoadingBox";
import conditionalObj from "../../../helpers/getConditionalObj";
import selectFieldWithOther from "../../../helpers/getSelectFieldWithOther";
import { getUniqueValues } from "../../../components/patient/DrugsForm";
import Modal from "../../../components/UI/Modal";
import HemoDymanics from "./components/Hemodynamics";
import IntraoperativeMonitorings from "./components/IntraoperativeMonitorings";
import { t } from "i18next";

const medicationPrimaryText =
  "`${dataItem.generic_name} ${dataItem.number_units ? `- ${dataItem.number_units} ${`${dataItem.number_units > 1 ? 'units':'unit'}`}` : ''} `";

export const getNow = () => {
  return moment(new Date()).format("YYYY-MM-DDTHH:mm:ss");
};

export const formIsEmpty = (data) => {
  let empty = true;
  for (var key of Object.keys(data)) {
    if (key === "date") continue;
    if (data[key].length != 0) empty = false;
  }
  return empty;
};

export default function ProcedureAnaethesia() {
  const { patientId, procedureId, age, gender } = useParams();
  const [viewMode, setViewMode] = useState();
  const [watchedFields, setWatchedFields] = useState([]);
  const [selectedMonitorIsOther, setSelectedMonitorIsOther] = useState(false);
  const [selectedDeviceTypeIsOther, setSelectedDeviceTypeIsOther] =
    useState(false);
  const [selectedDeviceIntubationIsOther, setSelectedDeviceIntubationIsOther] =
    useState(false);
  const [airwayDevice, setAirwayDevice] = useState(false);
  const [procedureAnaethesia, setProcedureAnaethesia] = useState();
  const [loading, setLoading] = useState(true);
  const [filteredDrugList, setFilteredDrugList] = useState({});
  const [drugList, setDrugList] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [hemoArr, setHemoArr] = useState([]);
  const [hemoForm, setHemoForm] = useState({});
  const [hemoFormTime, setHemoFormTime] = useState(null);
  const [monArr, setMonArr] = useState([]);
  const [monForm, setMonForm] = useState({});
  const [monFormTime, setMonFormTime] = useState(null);
  const [aneasthesiaStartTimeReadOnly, setAneasthesiaStartTimeReadOnly] =
    useState(null);

  const navigate = useNavigate();

  //mobile/web view
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));

  //api
  const addProcedureAnaesthesia = useApi(mainApi.addProcedureAnaesthesia);
  const getProcedureAnaesthesiaMonitoringList = useApi(
    mainApi.getProcedureAnaesthesiaMonitoringList
  );
  const getProcedureAnaesthesiaVenousList = useApi(
    mainApi.getProcedureAnaesthesiaVenousList
  );
  const getProcedureAnaesthesiaDeviceTypeList = useApi(
    mainApi.getProcedureAnaesthesiaDeviceTypeList
  );
  const getProcedureAnaesthesiaDeviceIntubationList = useApi(
    mainApi.getProcedureAnaesthesiaDeviceIntubationList
  );
  const getProcedureAnaesthesiaPostOperativeTransferList = useApi(
    mainApi.getProcedureAnaesthesiaPostOperativeTransferList
  );

  const getDrugList = useApi(mainApi.getDrugList);

  const handleProcedureView = async () => {
    const res = await mainApi.getProcedureAnaesthesia(patientId, procedureId);
    if (res.ok) {
      if (res.data?.data) {
        setProcedureAnaethesia(res.data.data);
        setViewMode("list");
        setHemoArr(res.data.data.hemodynamics);
        setMonArr(res.data.data.intraoperative_monitorings);
      } else {
        setViewMode("form");
      }
      setLoading(false);
    }
  };

  const handleOpenModal = () => {
    setOpenModal((state) => !state);
    console.log("formTime", hemoFormTime);
    console.log("startTime", typeof watchedFields[4], watchedFields[4]);
  };

  const handleOpenModal2 = () => {
    setOpenModal2((state) => !state);
    console.log("formTime", monFormTime);
    console.log("startTime", typeof watchedFields[4], watchedFields[4]);
  };

  useEffect(() => {
    getProcedureAnaesthesiaMonitoringList.request();
    getProcedureAnaesthesiaVenousList.request();
    getProcedureAnaesthesiaDeviceTypeList.request();
    getProcedureAnaesthesiaDeviceIntubationList.request();
    getProcedureAnaesthesiaPostOperativeTransferList.request();
    getDrugList.request();
  }, []);

  useEffect(() => {
    handleProcedureView();
    console.log("entered");
  }, [addProcedureAnaesthesia.data]);

  useEffect(() => {
    setDrugList(getDrugList.data?.map((item) => item.name));
  }, [getDrugList.data]);

  useEffect(() => {
    if (watchedFields[0])
      setSelectedMonitorIsOther(
        watchedFields[0].id ===
          getProcedureAnaesthesiaMonitoringList.data.length
      );
    if (watchedFields[1]) setAirwayDevice(watchedFields[1][0]);
    if (watchedFields[2])
      setSelectedDeviceTypeIsOther(
        watchedFields[2].id ===
          getProcedureAnaesthesiaDeviceTypeList.data.length
      );
    if (watchedFields[3])
      setSelectedDeviceIntubationIsOther(
        watchedFields[3].id ===
          getProcedureAnaesthesiaDeviceIntubationList.data.length
      );
  }, [watchedFields]);

  useEffect(() => {
    if (!aneasthesiaStartTimeReadOnly) {
      if (hemoArr.length > 0)
        setAneasthesiaStartTimeReadOnly(
          moment(hemoArr[hemoArr.length - 1].date).format("DD-MM-YYYY, HH:mm")
        );
      if (monArr.length > 0)
        setAneasthesiaStartTimeReadOnly(
          moment(monArr[monArr.length - 1].date).format("DD-MM-YYYY, HH:mm")
        );
    }
  }, [hemoArr, monArr]);

  // const getFilteredStrengthUnit = async (value, nestedId) => {
  //   if (value) {
  //     const res = await mainApi.getFilteredDrugList(
  //       `?generic_name=${filteredDrugList[nestedId]?.generic_name}&strength=${value}`
  //     );
  //     const reloadedList = getUniqueValues(res.data.data, "strength_unit");
  //     setFilteredDrugList((state) => ({
  //       ...state,
  //       [nestedId]: {
  //         ...state.nestedId,
  //         strength_unit: reloadedList,
  //       },
  //     }));
  //     //   setEmptyDrugFields((state) => ({
  //     //     ...state,
  //     //     [nestedId]: { strength_unit: true },
  //     //   }));
  //   }
  // };

  const getFilteredDrugNameList = async (value, nestedId) => {
    if (value) {
      const res = await mainApi.getDrugList(`?search=${value}`);
      setFilteredDrugList((state) => ({
        ...state,
        [nestedId]: res.data.data?.map((item) => item.name),
      }));
    }
  };

  const fields = [
    {
      relatedFields: true,
      // addBorder: false,
      displayCondition: [true],
      fieldsTitle: "PreInduction Assessment",
      fields: [
        {
          name: "blpr",
          label: "BLPR",
          childField: true,
        },
        {
          name: "hr",
          label: "HR",
          type: "number",
          childField: true,
        },
        {
          name: "rr",
          label: "RR",
          type: "number",
          childField: true,
        },
        {
          name: "spot",
          label: "SPOT",
          type: "number",
          childField: true,
        },
      ],
    },
    {
      title: "Monitoring",
      name: "monitories",
      fieldType: "nested",
      nestedId: 1,
      primaryListText: {
        value:
          "field.monitor.label === 'Other'? field.other_monitor : field.monitor.label",
      },
      fields: selectFieldWithOther(selectedMonitorIsOther, {
        name: "monitor",
        otherName: "other_monitor",
        label: "Detail",
        type: "select",
        autoComplete: true,
        items: getProcedureAnaesthesiaMonitoringList.data,
        validation: { required: true },
        childField: true,
      }),
    },
    {
      title: "Venous Access and lines",
      name: "venus_access_line_sites",
      fieldType: "nested",
      nestedId: 2,
      primaryListText: { key: "Site", value: "field.site.label" },
      secondaryListText: { key: "Cannula size", value: "field.cannula_size" },
      fields: [
        {
          type: "select",
          items: getProcedureAnaesthesiaVenousList.data,
          label: "Site",
          name: "site",
          autoComplete: true,
          validation: { required: true },
          childField: true,
        },
        {
          label: "Cannula size",
          name: "cannula_size",
          type: "decimal",
          childField: true,
          // validation: { required: true },
        },
      ],
      // width: 530,
      // validation: { required: true },
      // required: true,
    },
    {
      relatedFields: true,
      // addBorder: false,
      displayCondition: [true],
      fieldsTitle: "Airway Management",
      fields: [
        {
          name: "airway_management",
          label: "Airway Management",
          childField: true,
          multiline: true,
        },
        {
          label: "Airway device",
          name: "airway_device",
          type: "boolean",
          childField: true,
        },
        conditionalObj(airwayDevice, {
          name: "type",
          label: "Type",
          type: "select",
          autoComplete: true,
          items: getProcedureAnaesthesiaDeviceTypeList.data,
          childField: true,
          validation: { required: true },
        }),
        conditionalObj(airwayDevice && selectedDeviceTypeIsOther, {
          label: "Other",
          name: "type_other",
          validation: { required: true },
          childField: true,
        }),
        conditionalObj(airwayDevice, {
          name: "intubation",
          label: "Intubation",
          type: "select",
          autoComplete: true,
          items: getProcedureAnaesthesiaDeviceIntubationList.data,
          childField: true,
          validation: { required: true },
        }),
        conditionalObj(airwayDevice && selectedDeviceIntubationIsOther, {
          label: "Other",
          name: "intubation_other",
          validation: { required: true },
          childField: true,
        }),
        conditionalObj(airwayDevice, {
          name: "Aiding",
          label: "aiding",
          childField: true,
          multiline: true,
        }),
      ].filter((field) => field),
    },
    {
      relatedFields: true,
      // addBorder: false,
      fieldsTitle: "Anaethesia Medications",
      displayCondition: [true],
      fields: [
        {
          title: "PreInduction",
          name: "pre_inductions",
          fieldType: "nested",
          nestedId: 3,
          fields: [
            {
              type: "select",
              label: "Name",
              name: "generic_name",
              isEnum: true,
              items: filteredDrugList["3"] || drugList,
              autoComplete: true,
              disableFilter: true,
              onInputChange: (value) => {
                getFilteredDrugNameList(value, 3);
              },
              validation: { required: true },
            },
            // {
            //   type: "select",
            //   label: "Strength",
            //   name: "strength",
            //   isEnum: true,
            //   items: filteredDrugList["3"]?.strength
            //     ? filteredDrugList["3"]?.strength
            //     : [],
            //   autoComplete: true,
            //   onInputChange: (value) => getFilteredStrengthUnit(value, 3),
            //   validation: { required: true },
            //   addNewValue: true,
            // },
            // {
            //   type: "select",
            //   label: "Strength Unit",
            //   name: "strength_unit",
            //   autoComplete: true,
            //   addNewValue: true,
            //   isEnum: true,
            //   items: filteredDrugList["3"]?.strength_unit
            //     ? filteredDrugList["3"]?.strength_unit
            //     : [],
            //   autoComplete: true,
            //   validation: { required: true },
            //   addNewValue: true,
            // },
            {
              label: "Number of Units",
              name: "number_units",
              type: "decimal",
              validation: { required: true, positive: true },
            },
          ],
        },
        {
          title: "Induction",
          name: "inductions",
          fieldType: "nested",
          nestedId: 4,
          fields: [
            {
              type: "select",
              label: "Name",
              name: "generic_name",
              isEnum: true,
              items: filteredDrugList["4"] || drugList,
              autoComplete: true,
              disableFilter: true,
              onInputChange: (value) => {
                getFilteredDrugNameList(value, 4);
              },
              validation: { required: true },
            },
            // {
            //   type: "select",
            //   label: "Strength",
            //   name: "strength",
            //   isEnum: true,
            //   items: filteredDrugList["4"]?.strength
            //     ? filteredDrugList["4"]?.strength
            //     : [],
            //   autoComplete: true,
            //   onInputChange: (value) => getFilteredStrengthUnit(value, 4),
            //   validation: { required: true },
            //   addNewValue: true,
            // },
            // {
            //   type: "select",
            //   label: "Strength Unit",
            //   name: "strength_unit",
            //   autoComplete: true,
            //   addNewValue: true,
            //   isEnum: true,
            //   items: filteredDrugList["4"]?.strength_unit
            //     ? filteredDrugList["4"]?.strength_unit
            //     : [],
            //   autoComplete: true,
            //   validation: { required: true },
            //   addNewValue: true,
            // },
            {
              label: "Number of Units",
              name: "number_units",
              type: "decimal",
              validation: { required: true, positive: true },
            },
          ],
        },
        {
          title: "Maintenance",
          name: "maintenances",
          fieldType: "nested",
          nestedId: 5,
          fields: [
            {
              type: "select",
              label: "Name",
              name: "generic_name",
              isEnum: true,
              items: filteredDrugList["5"] || drugList,
              autoComplete: true,
              disableFilter: true,
              onInputChange: (value) => {
                getFilteredDrugNameList(value, 5);
              },
              validation: { required: true },
            },
            // {
            //   type: "select",
            //   label: "Strength",
            //   name: "strength",
            //   isEnum: true,
            //   items: filteredDrugList["5"]?.strength
            //     ? filteredDrugList["5"]?.strength
            //     : [],
            //   autoComplete: true,
            //   onInputChange: (value) => getFilteredStrengthUnit(value, 5),
            //   validation: { required: true },
            //   addNewValue: true,
            // },
            // {
            //   type: "select",
            //   label: "Strength Unit",
            //   name: "strength_unit",
            //   autoComplete: true,
            //   addNewValue: true,
            //   isEnum: true,
            //   items: filteredDrugList["5"]?.strength_unit
            //     ? filteredDrugList["5"]?.strength_unit
            //     : [],
            //   autoComplete: true,
            //   validation: { required: true },
            //   addNewValue: true,
            // },
            {
              label: "Number of Units",
              name: "number_units",
              type: "decimal",
              validation: { required: true, positive: true },
            },
          ],
        },
        {
          title: "Reversals",
          name: "reversals",
          fieldType: "nested",
          nestedId: 6,
          fields: [
            {
              type: "select",
              label: "Name",
              name: "generic_name",
              isEnum: true,
              items: filteredDrugList["6"] || drugList,
              autoComplete: true,
              disableFilter: true,
              onInputChange: (value) => {
                getFilteredDrugNameList(value, 6);
              },
              validation: { required: true },
            },
            // {
            //   type: "select",
            //   label: "Strength",
            //   name: "strength",
            //   isEnum: true,
            //   items: filteredDrugList["6"]?.strength
            //     ? filteredDrugList["6"]?.strength
            //     : [],
            //   autoComplete: true,
            //   onInputChange: (value) => getFilteredStrengthUnit(value, 6),
            //   validation: { required: true },
            //   addNewValue: true,
            // },
            // {
            //   type: "select",
            //   label: "Strength Unit",
            //   name: "strength_unit",
            //   autoComplete: true,
            //   addNewValue: true,
            //   isEnum: true,
            //   items: filteredDrugList["6"]?.strength_unit
            //     ? filteredDrugList["6"]?.strength_unit
            //     : [],
            //   autoComplete: true,
            //   validation: { required: true },
            //   addNewValue: true,
            // },
            {
              label: "Number of Units",
              name: "number_units",
              type: "decimal",
              validation: { required: true, positive: true },
            },
          ],
        },
      ],
    },
    {
      title: "Intraoperative Medications",
      name: "intraoperative_medications",
      fieldType: "nested",
      nestedId: 7,
      fields: [
        {
          type: "select",
          label: "Name",
          name: "generic_name",
          isEnum: true,
          items: filteredDrugList["7"] || drugList,
          autoComplete: true,
          disableFilter: true,
          onInputChange: (value) => {
            getFilteredDrugNameList(value, 7);
          },
          validation: { required: true },
        },
        // {
        //   type: "select",
        //   label: "Strength",
        //   name: "strength",
        //   isEnum: true,
        //   items: filteredDrugList["7"]?.strength
        //     ? filteredDrugList["7"]?.strength
        //     : [],
        //   autoComplete: true,
        //   onInputChange: (value) => getFilteredStrengthUnit(value, 7),
        //   validation: { required: true },
        //   addNewValue: true,
        // },
        // {
        //   type: "select",
        //   label: "Strength Unit",
        //   name: "strength_unit",
        //   autoComplete: true,
        //   addNewValue: true,
        //   isEnum: true,
        //   items: filteredDrugList["7"]?.strength_unit
        //     ? filteredDrugList["7"]?.strength_unit
        //     : [],
        //   autoComplete: true,
        //   validation: { required: true },
        //   addNewValue: true,
        // },
        {
          label: "Number of Units",
          name: "number_units",
          type: "decimal",
          validation: { required: true, positive: true },
        },
        {
          label: "Date and Time",
          name: "date",
          type: "dateTime",
          validation: { required: true },
        },
      ],
    },

    {
      name: "anaesthesia_start_date",
      label: "Aneasthesia Start Time",
      type: aneasthesiaStartTimeReadOnly ? "readOnly" : "dateTime",
      value: aneasthesiaStartTimeReadOnly,
      validation: { required: true },
    },
    {
      name: "anaesthesia_end_date",
      label: "Aneasthesia End time",
      type: "dateTime",
      validation: { minDate: new Date("2022-11-18T15:30:00") },
    },
    {
      type: "button",
      label: "Timeline",
      buttonTitle: "Hemodynamics & Intraoperative monitoring Timeline",
      onClick: handleOpenModal,
    },
    // {
    //   type: "button",
    //   // label: "Timeline",
    //   buttonTitle: "Intraoperative monitoring Timeline",
    //   onClick: handleOpenModal2,
    // },

    {
      name: "intraoperative_anaesthesia_incidents",
      label: "Intraoperative Anesthesia Incidents",
      multiline: true,
    },
    {
      name: "extubation",
      label: "Extubating",
      multiline: true,
    },
    {
      name: "surgery_start_date",
      label: "Surgery Start time",
      type: "dateTime",
    },
    {
      name: "surgery_end_date",
      label: "Surgery End time",
      type: "dateTime",
    },
    {
      name: "post_operative_transfer",
      label: "Postoperative transfer",
      type: "select",
      items: getProcedureAnaesthesiaPostOperativeTransferList.data,
    },
    {
      name: "ward_transfer_date",
      label: "To Be transferred to the ward",
      type: "dateTime",
    },
    {
      name: "post_operative_instructions",
      label: "Postoperative Instructions",
      multiline: true,
    },
    {
      name: "pain_serving",
      label: "Pain Serving",
      multiline: true,
    },
  ];

  const data = [
    {
      fieldType: "labelField",
      data: "PreInduction Assessment",
    },
    {
      label: "BLPR",
      data: procedureAnaethesia?.blpr,
    },
    {
      label: "HR",
      data: procedureAnaethesia?.hr,
    },
    {
      label: "RR",
      data: procedureAnaethesia?.rr,
    },
    {
      label: "SPOT",
      data: procedureAnaethesia?.spot,
    },

    {
      label: "Monitoring",
      data: procedureAnaethesia?.monitories?.map((item) =>
        item?.monitor?.label === "Other"
          ? item?.other_monitor
          : item?.monitor?.label
      ),
    },

    {
      fieldType: "nested",
      label: "Venous Access and lines",
      data: procedureAnaethesia?.venus_access_line_sites,
      primaryListText: { key: "Site", value: "dataItem?.site?.label" },
      secondaryListText: {
        key: "Cannula size",
        value: "dataItem?.cannula_size",
      },
    },

    {
      fieldType: "labelField",
      data: "Airway Managment",
    },
    {
      label: "Airway Management",
      data: procedureAnaethesia?.airway_management,
    },
    {
      fieldType: "boolean",
      label: "Airway device",
      data: procedureAnaethesia?.airway_device?.hasOwnProperty("type"),
    },
    conditionalObj(procedureAnaethesia?.airway_device?.hasOwnProperty("type"), {
      label: "Type",
      data:
        procedureAnaethesia?.airway_device?.type_other ||
        procedureAnaethesia?.airway_device?.type?.label,
    }),

    conditionalObj(procedureAnaethesia?.airway_device?.hasOwnProperty("type"), {
      label: "Intubation",
      data:
        procedureAnaethesia?.airway_device?.intubation_other ||
        procedureAnaethesia?.airway_device?.intubation?.label,
    }),

    conditionalObj(procedureAnaethesia?.airway_device?.hasOwnProperty("type"), {
      label: "Aiding",
      data: procedureAnaethesia?.airway_device?.aiding,
    }),

    {
      fieldType: "labelField",
      data: "Anaesthesia Medications",
    },
    {
      fieldType: "nested",
      label: "PreInduction",
      data: procedureAnaethesia?.pre_inductions,
      primaryListText: {
        value: medicationPrimaryText,
      },
    },
    {
      fieldType: "nested",
      label: "Induction",
      data: procedureAnaethesia?.inductions,
      primaryListText: {
        value: medicationPrimaryText,
      },
    },
    {
      fieldType: "nested",
      label: "Maintenance",
      data: procedureAnaethesia?.maintenances,
      primaryListText: {
        value: medicationPrimaryText,
      },
    },
    {
      fieldType: "nested",
      label: "Reversal",
      data: procedureAnaethesia?.reversals,
      primaryListText: {
        value: medicationPrimaryText,
      },
    },
    {
      fieldType: "nested",
      label: "Intraoperative Medications",
      data: procedureAnaethesia?.intraoperative_medications,
      primaryListText: {
        value: medicationPrimaryText,
      },
      secondaryListText: {
        key: "Date",
        value: "dataItem?.date",
      },
    },
    {
      label: "Anaethesia Start time",
      data: moment(procedureAnaethesia?.anaesthesia_start_date).format(
        "DD-MM-YYYY, HH:mm"
      ),
    },
    {
      label: "Anaethesia End time",
      data: moment(procedureAnaethesia?.anaesthesia_end_date).format(
        "DD-MM-YYYY, HH:mm"
      ),
    },

    procedureAnaethesia?.hemodynamics.length > 0 && {
      label: "Timeline",
      fieldType: "element",
      data: procedureAnaethesia?.hemodynamics,
      element: (
        <Button
          sx={{ textTransform: "none" }}
          onClick={handleOpenModal}
          variant="contained"
        >
          {t("HemoDymanics & Intraoperative monitoring Timeline")}
        </Button>
      ),
    },
    // {
    //   // label: "Timeline",
    //   fieldType: "element",
    //   data: procedureAnaethesia?.intraoperative_monitorings,
    //   element: (
    //     <>
    //       {procedureAnaethesia?.intraoperative_monitorings.length ? (
    //         <Button
    //           sx={{ textTransform: "none" }}
    //           onClick={handleOpenModal2}
    //           variant="contained"
    //         >
    //          Intraoperative monitoring Timeline
    //         </Button>
    //       ) : (
    //         <Typography>No data</Typography>
    //       )}
    //     </>
    //   ),
    // },

    {
      label: "Intraoperative Anaethesia Incidents",
      data: procedureAnaethesia?.intraoperative_anaesthesia_incidents,
    },

    { label: "Extubation", data: procedureAnaethesia?.extubation },

    {
      label: "Surgery Start time",
      data: moment(procedureAnaethesia?.surgery_start_date).format(
        "DD-MM-YYYY, HH:mm"
      ),
    },
    {
      label: "Surgery End time",
      data: moment(procedureAnaethesia?.surgery_end_date).format(
        "DD-MM-YYYY, HH:mm"
      ),
    },
    {
      label: "Postoperative transfer",
      data: procedureAnaethesia?.post_operative_transfer?.label,
    },
    {
      label: "To Be transferred to ward",
      data: moment(procedureAnaethesia?.ward_transfer_date).format(
        "DD-MM-YYYY, HH:mm"
      ),
    },
    {
      label: "Postoperative Instructions",
      data: procedureAnaethesia?.post_operative_instructions,
    },
    {
      label: "Pain Serving",
      data: procedureAnaethesia?.pain_serving,
    },
  ].filter((item) => item);

  const onSubmit = async (data) => {
    console.log("data", data, hemoArr);
    data.monitories.forEach((item) => (item.monitor = item.monitor?.id));
    data.venus_access_line_sites.forEach((item) => (item.site = item.site?.id));

    let airway_device = {
      type: data.type?.id,
      type_other: data.type_other,
      intubation: data.intubation?.id,
      intubation_other: data.intubation_other,
    };

    let obj = {};
    if (airwayDevice) {
      obj = {
        ...data,
        airway_device,
      };
    } else {
      delete data["airway_device"];
      obj = { ...data };
    }

    const hemoDynamicsArr = formIsEmpty(hemoForm)
      ? hemoArr
      : [hemoForm, ...hemoArr];
    const intraoperative_monitorings = formIsEmpty(monForm)
      ? monArr
      : [monForm, ...monArr];

    obj =
      hemoArr.length > 0
        ? { ...obj, hemodynamics: hemoDynamicsArr }
        : { ...obj };
    obj =
      monArr.length > 0
        ? { ...obj, intraoperative_monitorings: intraoperative_monitorings }
        : { ...obj };

    console.log("obj", obj);

    const res = await addProcedureAnaesthesia.requestWithToast(
      t("Added successfully"),
      patientId,
      procedureId,
      obj
    );

    if (res.ok) {
      setViewMode("list");
    }
  };

  return (
    <>
      {loading && <LoadingBox loading={true} />}
      {viewMode === "form" && !mobileView && (
        <CebForm
          onSubmit={onSubmit}
          fields={fields.filter((field) => field)}
          viewModeState={[viewMode, setViewMode]}
          fieldsToWatch={{
            name: [
              "monitories_monitor",
              "airway_device",
              "type",
              "intubation",
              "anaesthesia_start_date",
              "anaesthesia_end_date",
            ],
            defaultValue: ["", [false]],
          }}
          getWatchedFields={(fields) => setWatchedFields(fields)}
          formWidth="90%"
          cancelHandler={() =>
            navigate(`/patients/${patientId}/${age}/${gender}/procedures`)
          }
          requestErrors={addProcedureAnaesthesia.error}
        />
      )}
      {viewMode === "list" && (
        <Box
          width={970}
          sx={{
            border: "1px solid black",
            borderRadius: "10px",
            borderColor: "#00346880",
            p: 2,
          }}
        >
          <CebList data={data} />
        </Box>
      )}

      <Modal open={openModal} handleOpen={handleOpenModal}>
        <HemoDymanics
          startTime={
            (watchedFields[4] &&
              watchedFields[4] !== "Invalid date" &&
              watchedFields[4]) ||
            getNow()
          }
          endTime={watchedFields[5]}
          hemoArrKey={[hemoArr, setHemoArr]}
          hemoFormKey={[hemoForm, setHemoForm]}
          hemoFormTimeKey={[hemoFormTime, setHemoFormTime]}
          handleOpenModal={handleOpenModal}
          viewModeKey={[viewMode, setViewMode]}
          monArrKey={[monArr, setMonArr]}
          monFormKey={[monForm, setMonForm]}
          monFormTimeKey={[monFormTime, setMonFormTime]}
        />
      </Modal>

      {/* <Modal open={openModal2} handleOpen={handleOpenModal2}>
      <IntraoperativeMonitorings 
        startTime={watchedFields[4] && watchedFields[4] !== 'Invalid date' && watchedFields[4] || getNow()} 
        endTime={watchedFields[5]}
        handleOpenModal={handleOpenModal2}
        monArrKey={[monArr, setMonArr]}
        monFormKey={[monForm, setMonForm]}
        monFormTimeKey={[monFormTime, setMonFormTime]}
        viewModeKey={[viewMode, setViewMode]}
      />
    </Modal> */}
    </>
  );
}
