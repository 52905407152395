import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import theme from "../styles/MUITheme";
import Modal from "./UI/Modal";
import ActionButton from "./UI/ActionButton";
import OtpForm from "./patient/OtpForm";
import { Context } from "../context/Context";
import useApi from "../hooks/useApi";
import main from "../api/main";
import { useTranslation } from "react-i18next";

const localizer = momentLocalizer(moment);

const ScheduleCalendar = ({
  appointmentList = [],
  onChangeDateRange,
  cancelHandler,
  confirmHandler,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState();
  const [currentView, setCurrentView] = useState("week");
  const [currentDate, setCurrentDate] = useState(new Date());
  const [events, setEvents] = useState([]);

  useEffect(() => {
    let startDate, endDate;
    if (currentView === "day") {
      startDate = moment(currentDate).startOf("day");
      endDate = moment(currentDate).endOf("day");
    } else if (currentView === "week") {
      startDate = moment(currentDate.setDate(currentDate.getDate() + 1))
        .startOf("isoWeek")
        .subtract(1, "days");
      endDate = moment(currentDate.setDate(currentDate.getDate() + 1))
        .endOf("isoWeek")
        .subtract(1, "days");
    } else if (currentView === "month") {
      startDate = moment(currentDate).startOf("month");
      endDate = moment(currentDate).endOf("month");
    }

    onChangeDateRange(
      startDate.format("YYYY-MM-DD"),
      endDate.format("YYYY-MM-DD")
    );
  }, [currentView, currentDate]);

  const createEvents = (appointments) => {
    const events = [];
    for (const obj of appointments) {
      for (const avail of obj.items) {
        const newEvents = avail.appointments?.map((item, index) => {
          if (!item.is_cancelled) {
            const startTime = moment(
              new Date(`${obj.date} ${avail.start_time}`)
            ).add(index * 30, "minutes");

            return {
              shiftId: avail.id,
              id: item.id,
              title: `${item.patient?.full_name}`,
              start: new Date(startTime.format("YYYY-MM-DD HH:mm")),
              end: new Date(
                startTime.add(30, "minutes").format("YYYY-MM-DD HH:mm")
              ),
              data: item,
            };
          }
        });
        events.push(...newEvents.filter((item) => item));
      }
    }
    return events;
  };
  useEffect(() => {
    setEvents(createEvents(appointmentList));
  }, [appointmentList]);

  const handleOpenModal = () => {
    setOpenModal((state) => !state);
  };

  const eventPropGetterHandler = (event, start, end, isSelected) => {
    const backgroundColor = event.data?.is_confirmed
      ? "green"
      : isSelected
      ? theme.palette.primary.main
      : event.color;
    const style = {
      backgroundColor,
      color: "white",
      display: "flex",
      flexDirection: "column",
      fontSize: "15px",
      width: "100%", // Set the width to 100% to cover the full slot width
    };
    return {
      style,
    };
  };

  const handleSelectEvent = (event) => {
    if (selectedEvent?.id !== event.id) setSelectedEvent(event);
    handleOpenModal();
  };

  return (
    <Stack>
      <div style={{ direction: "ltr" }}>
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: "500px" }}
          eventPropGetter={eventPropGetterHandler}
          onSelectEvent={handleSelectEvent}
          views={["month", "week", "day"]}
          defaultView="week"
          onNavigate={(date) => {
            setCurrentDate(date);
          }}
          onView={(view) => {
            setCurrentView(view);
          }}
          step={15}
          timeslots={2}
        />
      </div>

      <Modal
        open={openModal}
        handleOpen={handleOpenModal}
        minHeight={230}
        width={700}
      >
        <AppointmentsDetails
          data={selectedEvent?.data}
          cancelHandler={() => {
            cancelHandler(selectedEvent?.shiftId, selectedEvent?.id);
            handleOpenModal();
          }}
          confirmHandler={() => {
            confirmHandler(selectedEvent?.shiftId, selectedEvent?.id);
            handleOpenModal();
          }}
        />
      </Modal>
    </Stack>
  );
};

export default ScheduleCalendar;

const AppointmentsDetails = ({ data = {}, cancelHandler, confirmHandler }) => {
  const { t, i18n } = useTranslation();
  const [openModal, setOprenModal] = useState(false);
  const handleOpenModal = () => {
    setOprenModal((state) => !state);
  };
  const { confirmAppointment, setConfirmAppointment } = useContext(Context);

  //api
  const requestClinicPatientApproval = useApi(
    main.requestClinicPatientApproval
  );

  //mobile view
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (confirmAppointment) {
      confirmHandler();
      setConfirmAppointment(false);
    }
  }, [confirmAppointment]);

  return (
    <>
      {openModal && (
        <OtpForm
          userId={data?.patient?.id}
          handleOpenModal={handleOpenModal}
          onSuccessHandler={confirmHandler}
        />
      )}
      {!openModal && (
        <Stack
          width={"100%"}
          gap={2}
          sx={{ direction: i18n.language === "en" ? "ltr" : "rtl" }}
        >
          <Typography fontWeight={700}>{t("Appointment Details")}</Typography>
          <Stack
            width={"100%"}
            key={data.id}
            gap={2}
            borderBottom={!mobileView && "0.5px solid rgba(0,0,0,0.2)"}
            paddingBottom={!mobileView && 2}
          >
            <Stack
              width={"100%"}
              direction={mobileView ? "column" : "row"}
              gap={2}
            >
              <Stack
                width={mobileView ? "100%" : "50%"}
                direction={"row"}
                gap={2}
              >
                <Typography color="primary" fontWeight={500}>
                  {t("Name")}
                </Typography>
                <Typography>{data.patient?.full_name}</Typography>
              </Stack>
              <Stack
                width={mobileView ? "100%" : "50%"}
                direction={"row"}
                gap={2}
              >
                <Typography color="primary" fontWeight={500}>
                  {t("Phone")}
                </Typography>
                <Typography style={{ direction: "ltr" }}>
                  {data.patient?.phone_number}
                </Typography>
              </Stack>
            </Stack>
            <Stack direction={"row"} gap={2}>
              <Typography color="primary" fontWeight={500}>
                {t("Notes")}
              </Typography>
              <Typography>{data.notes}</Typography>
            </Stack>
          </Stack>
          {!data?.is_confirmed && !mobileView && (
            <Stack
              width={"100%"}
              gap={2}
              direction="row"
              justifyContent={"flex-end"}
            >
              <ActionButton
                type={"approve"}
                width={200}
                onClick={() => {
                  handleOpenModal();
                  requestClinicPatientApproval.request(data?.patient?.id);
                }}
              >
                {t("Confirm Attendance")}
              </ActionButton>
              <ActionButton type={"reject"} width={200} onClick={cancelHandler}>
                {t("Cancel Appointment")}
              </ActionButton>
            </Stack>
          )}
        </Stack>
      )}
    </>
  );
};
