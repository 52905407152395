import React, { useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/material/styles";
import * as Constants from "../Navigation/Constants";

//icons
import PastHistoryIcon from "../../assets/icons/PastHistory";
import SummaryIcon from "../../assets/icons/Summary";
import EncountersIcon from "../../assets/icons/Encounters";
import OrdersIcon from "../../assets/icons/Orders";
import RadiologyIcon from "../../assets/icons/Radiology";
import LaboratoryIcon from "../../assets/icons/Laboratory";
import AdmissionIcon from "../../assets/icons/Admission";
import PrescriotionIcon from "../../assets/icons/Prescription";
import MediaIcon from "../../assets/icons/Media";
import ConsentsIcon from "../../assets/icons/Consents";
import ProceduresIcon from "../../assets/icons/Procedures";
import ReportLettersIcon from "../../assets/icons/ReportLetters";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import roleMapper from "../../helpers/roleMapper";
import { Context } from "../../context/Context";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const PatientTab = styled((props) => (
  <Tab onClick={props.onClickHandler} {...props} />
))(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 300,
  textTransform: "none",
  "&.Mui-selected": {
    fontWeight: 700,
  },
}));

export default function PatientTabs() {
  const { t } = useTranslation();
  let location = useLocation();
  let navigate = useNavigate();

  const { patientId, age, gender } = useParams();
  const [value, setValue] = useState();
  const { permissions } = useContext(Context);

  const tabs = [
    permissions?.["Past History"] && {
      label: "Past History",
      value: Constants.PAST_HISTORY,
      icon: <PastHistoryIcon />,
      link: `/patients/${patientId}/${age}/${gender}/past-history`,
    },
    permissions?.summary && {
      label: "Summary",
      value: Constants.SUMMARY,
      icon: <SummaryIcon />,
      link: `Summary`,
    },
    permissions?.["Encounter"] && {
      label: "Encounters",
      value: Constants.ENCOUNTERS,
      icon: <EncountersIcon />,
      link: `/patients/${patientId}/${age}/${gender}/encounters`,
    },
    permissions?.["Order"] && {
      label: "Orders",
      value: Constants.ORDERS,
      icon: <OrdersIcon />,
      link: `/patients/${patientId}/${age}/${gender}/orders`,
    },
    permissions?.["Radio"] && {
      label: "Radiology",
      value: Constants.RADIOLOGY,
      icon: <RadiologyIcon />,
      link: `/patients/${patientId}/${age}/${gender}/radio`,
    },
    permissions?.["Lab"] && {
      label: "Laboratory",
      value: Constants.LABORATORY,
      icon: <LaboratoryIcon />,
      link: `/patients/${patientId}/${age}/${gender}/lab`,
    },
    permissions?.["Admission"] && {
      label: "Admission",
      value: Constants.ADMISSION,
      icon: <AdmissionIcon />,
      link: `/patients/${patientId}/${age}/${gender}/admission`,
    },
    permissions?.["Prescription"] && {
      label: "Prescriptions",
      value: Constants.PRESCRIPTION,
      icon: <PrescriotionIcon />,
      link: `/patients/${patientId}/${age}/${gender}/prescription`,
    },
    permissions?.["Media"] && {
      label: "Media",
      value: Constants.MEDIA,
      icon: <MediaIcon />,
      link: `media`,
    },
    permissions?.["Consent"] && {
      label: "Consents",
      value: Constants.CONSENTS,
      icon: <ConsentsIcon />,
      link: `consents`,
    },
    permissions?.["Procedure"] && {
      label: "Procedures",
      value: Constants.PROCEDURES,
      icon: <ProceduresIcon />,
      link: `procedures`,
    },
    permissions?.reprortsAndLetters && {
      label: "Report & letters",
      value: Constants.REPORT_LETTERS,
      icon: <ReportLettersIcon />,
      link: `reports`,
    },
  ].filter((item) => item);

  if (i18next.language === "ar") tabs.reverse();

  useEffect(() => {
    if (tabs.length > 0) {
      const selectedTab = tabs.find((item) =>
        location.pathname.includes(item.link)
      );
      if (selectedTab) {
        setValue(selectedTab.value);
      } else {
        setValue(
          i18next.language === "en"
            ? tabs[0].value
            : tabs[tabs.length - 1].value
        );
        navigate(
          i18next.language === "en" ? tabs[0].link : tabs[tabs.length - 1].link
        );
      }
    }
  }, [tabs.length]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div dir="ltr">
      <Box
        sx={{ borderBottom: 1, borderColor: "divider" }}
        style={{ width: "99%" }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="primary"
          indicatorColor="primary"
          aria-label="patient tabs"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          {tabs.map((tab, index) => (
            <PatientTab
              key={index}
              value={tab.value}
              label={t(tab.label)}
              icon={tab.icon}
              iconPosition={i18next.language === "en" ? "end" : "start"}
              onClickHandler={() => {
                if (!(value === Constants.PAST_HISTORY && tab.value === value))
                  navigate(tab.link);
              }}
            />
          ))}
        </Tabs>
      </Box>
    </div>
  );
}
