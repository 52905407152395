import React, { useEffect, useState } from "react";
import CebForm from "../Form/CebForm";
import Modal from "../UI/Modal";
import useApi from "../../hooks/useApi";
import mainApi from "../../api/main";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { t } from "i18next";

export const getUniqueValues = (list, key) => {
  const set = new Set();
  for (let i = 0; i < list.length; i++) {
    set.add(list[i][key]);
  }
  return [...set];
};

const DrugsForm = ({
  handleOpenModal,
  openModal,
  onSubmitHandler,
  loading = false,
  requestErrors,
  module,
  subModule,
}) => {
  const { patientId, admissionId } = useParams();
  const location = useLocation();
  const [viewMode, setViewMode] = useState("form");
  const [fieldsToWatch, setFieldsToWatch] = useState([
    "frequency_rate",
    "frequency_type",
    "starting_from",
    "rate",
    "rate_type",
  ]);
  const [watchedFields, setWatchedFields] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [dosesTime, setDosesTime] = useState();
  const [validDosesTime, setValidDosesTime] = useState(false);

  const multipleDrugsCondition =
    location.pathname.includes("prescription") ||
    location.pathname.includes("encounters") ||
    location.pathname.includes("discharge");
  const { addToast } = useToasts();

  //api
  const getDrugList = useApi(mainApi.getDrugList);
  const getRouteAdministrationList = useApi(mainApi.getRouteAdministrationList);
  const getMealRelationList = useApi(mainApi.getMealRelationList);
  //////////////////////////////////////////////

  //helpers

  const getDosesTime = async (obj) => {
    const res = await mainApi.getDosesTime(patientId, obj);
    if (res.ok) {
      setDosesTime(res.data.data.doses_time);
      setValidDosesTime(true);
    } else {
      setDosesTime([`Error: ${res.data.message}`]);
      setValidDosesTime(false);
    }
  };

  const getWatchedFields = (f) => {
    setWatchedFields(f);
  };

  //////////////////////////////////////////////

  //useEffect

  useEffect(() => {
    getDrugList.request();
    getRouteAdministrationList.request();
    getMealRelationList.request();
  }, []);

  useEffect(() => {
    if (multipleDrugsCondition) {
      setFieldsToWatch((state) => state.map((item) => `drugs_${item}`));
    }
  }, [multipleDrugsCondition]);

  useEffect(() => {
    if (
      watchedFields[2] &&
      (watchedFields[3] || watchedFields[1] === "once") &&
      watchedFields[4] &&
      watchedFields[0] &&
      watchedFields[1]
    ) {
      const dosesObj = {
        starting_from: watchedFields[2],
        rate: watchedFields[1] === "once" ? "1" : watchedFields[3],
        rate_type: watchedFields[4],
        frequency_rate: watchedFields[0],
        frequency_type: watchedFields[1],
      };
      console.log("doses", dosesObj);
      getDosesTime(dosesObj);
    }
  }, [
    watchedFields[2],
    watchedFields[3],
    watchedFields[4],
    watchedFields[0],
    watchedFields[1],
  ]);

  ///////////////////////////////////////////////

  //Fields
  const medicationFields = [
    {
      type: "select",
      label: "Name",
      name: "generic_name",
      isEnum: true,
      items: getDrugList.data?.map((item) => item.name),
      autoComplete: true,
      disableFilter: true,
      onInputChange: (val) => getDrugList.request(`?search=${val}`),
      validation: { required: true },
    },
    {
      label: "Route of Administration",
      name: "route_administration",
      type: "select",
      isEnum: true,
      autoComplete: true,
      addNewValue: true,
      items: getRouteAdministrationList.data?.map((item) => item.name),
      validation: { required: true },
    },
    {
      label: "Number of Units",
      name: "num_units",
      type: "decimal",
      validation: { required: true, positive: true },
    },
    {
      label: "Relation to meal",
      name: "meal_relation",
      type: "select",
      items: getMealRelationList.data,
      validation: { required: true },
    },
    {
      label: "Frequency rate",
      name: "frequency_rate",
      type: "number",
      validation: { required: true, positive: true },
    },
    {
      label: "Frequency Type",
      name: "frequency_type",
      type: "select",
      isEnum: true,
      validation: { required: true },
      items: [
        "day",
        "week",
        "hour",
        "morning",
        "evening",
        "night",
        "needed",
        "once",
      ],
    },
    {
      name: "starting_from",
      type: "dateTime",
      label: "Starting From",
      validation: { disablePast: true },
      defaultValue: new Date(),
    },

    watchedFields[1] === "once"
      ? {
          label: "For",
          type: "readOnly",
          value: "1",
        }
      : {
          label: "For",
          name: "rate",
          type: "number",
          validation: { required: true, positive: true },
        },
    {
      label: "Days/Doses",
      name: "rate_type",
      type: "select",
      isEnum: true,
      items: ["days", "doses"],
      validation: { required: true },
    },

    {
      label: "Doses time",
      name: "doses_time",
      type: "readOnly",
      value: dosesTime
        ? dosesTime.join("\r\n")
        : t("Enter the fields above to calculate doses time"),
    },
    {
      label: "Comment",
      name: "comments",
      multiline: true,
    },
  ];
  //////////////////////////////////////////////

  const fields = multipleDrugsCondition
    ? [
        {
          label: "Date",
          type: "readOnly",
          value: moment(new Date()).format("DD-MM-YYYY"),
        },
        {
          name: "drugs",
          fieldType: "nested",
          nestedId: 1,
          title: "Medication List",
          label: "New Medication",
          multiple: true,
          required: true,
          fields: medicationFields,
          onReset: () => setDosesTime(),
          disableNested: !validDosesTime,
          primaryListText: {
            key: "Drug",
            value: "`${field.generic_name}`",
          },
        },
      ]
    : medicationFields;

  //Submit
  const onSubmit = async (data) => {
    if (!validDosesTime) {
      addToast("Cannot calculate doses time", {
        appearance: "error",
        autoDismiss: "true",
      });
      return;
    }
    if (multipleDrugsCondition) {
      onSubmitHandler(
        data.drugs.map((item) => ({
          ...item,
          rate: item.frequency_type === "once" ? "1" : item.rate,
        }))
      );
    } else {
      const newPresc = {
        ...data,
        rate: data.frequency_type === "once" ? "1" : data.rate,
        entity_department: 1,
      };
      onSubmitHandler(newPresc);
      getDrugList.request();
      setDosesTime();
    }
  };
  //////////////////////////////////////////////

  useEffect(() => {
    console.log("mmm", watchedFields[1]);
    if (watchedFields[1] === "once") {
      setInitialValues({ rate: 1 });
    }
  }, [watchedFields[1]]);

  return (
    <>
      {openModal && (
        <Modal open={openModal} handleOpen={handleOpenModal}>
          <CebForm
            onSubmit={onSubmit}
            fields={fields}
            viewModeState={[viewMode, setViewMode]}
            modalForm
            handleOpenModal={handleOpenModal}
            modalTitle={`Add new ${
              multipleDrugsCondition ? "Prescription" : "Medication"
            }`}
            fieldsToWatch={{ name: fieldsToWatch }}
            getWatchedFields={getWatchedFields}
            initialValues={initialValues}
            loading={loading}
            requestErrors={requestErrors}
            module={module}
            subModule={subModule}
          />
        </Modal>
      )}
    </>
  );
};

export default DrugsForm;
