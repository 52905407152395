import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.75 4.5A2.261 2.261 0 0 0 1.5 6.75v10.5a2.261 2.261 0 0 0 2.25 2.25h16.5a2.261 2.261 0 0 0 2.25-2.25V6.75a2.262 2.262 0 0 0-2.25-2.25H3.75Zm0 1.5h16.5c.424 0 .75.325.75.75v10.5c0 .424-.326.75-.75.75H3.75a.737.737 0 0 1-.75-.75V6.75c0-.425.325-.75.75-.75Zm4.5 1.5c-1.65 0-3 1.35-3 3 0 .835.358 1.588.914 2.133A3.777 3.777 0 0 0 4.5 15.75H6a2.24 2.24 0 0 1 2.25-2.25 2.24 2.24 0 0 1 2.25 2.25H12a3.778 3.778 0 0 0-1.664-3.117 2.985 2.985 0 0 0 .914-2.133c0-1.65-1.35-3-3-3Zm5.25.75v1.5h6v-1.5h-6ZM8.25 9c.838 0 1.5.662 1.5 1.5S9.088 12 8.25 12s-1.5-.662-1.5-1.5S7.412 9 8.25 9Zm5.25 2.25v1.5h6v-1.5h-6Zm0 3v1.5h3.75v-1.5H13.5Z"
      fill="#003468"
    />
  </svg>
);

export default SvgComponent;
