import { useState } from "react";
import useApi from "./useApi";
import mainApi from "../api/main";
import { useEffect } from "react";

const useDiseasesField = () => {
    const [diseases, setDiseases] = useState([]);

    //diseases
    const getDiseases = useApi(mainApi.getDiseases);
    const getFilteredDiseases = useApi(mainApi.getFilteredDiseases);


    useEffect(() => {
        getDiseases.request();
    }, []);

    useEffect(() => {
        setDiseases(getDiseases.data);
    }, [getDiseases.data]);

    useEffect(() => {
        setDiseases(getFilteredDiseases.data);
    }, [getFilteredDiseases.data]);
    

    const handleInputChange = (value) => {
        getFilteredDiseases.request(value);
    };

    return { diseases, handleInputChange };

}

export default useDiseasesField;