import {
  Box,
  Button,
  Grid,
  Stack,
  TabScrollButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useLayoutEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import CustomAddButton from "../../../../components/UI/CustomAddButton";
import { useState } from "react";
import { useEffect } from "react";
import moment from "moment";
import { formIsEmpty, getNow } from "../Anaethesia";
import { t } from "i18next";

const styles = {
  hemoHeader: { fontSize: 16, marginBottom: 16 },
  form: {
    scrollBehavior: "smooth",
    width: "100%",
  },
};

export default function HemoDymanics({
  startTime,
  endTime,
  hemoArrKey,
  hemoFormKey,
  hemoFormTimeKey,
  handleOpenModal,
  viewModeKey,

  monArrKey,
  monFormKey,
  monFormTimeKey,
}) {
  const stackHemoRef = useRef();
  const stackMonRef = useRef();
  let clicked = "";
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const [hemoArr, setHemoArr] = hemoArrKey;
  const [hemoForm, setHemoForm] = hemoFormKey;
  const [hemoFormTime, setHemoFormTime] = hemoFormTimeKey;
  const [isHemoOverflow, setHemoOverflow] = useState(false);
  const [stopAddingHemoCols, setStopAddingHemoCols] = useState(false);
  const [viewMode, setViewMode] = viewModeKey;

  const [monArr, setMonArr] = monArrKey;
  const [monForm, setMonForm] = monFormKey;
  const [monFormTime, setMonFormTime] = monFormTimeKey;
  const [stopAddingMonCols, setStopAddingMonCols] = useState(false);
  const [isMonOverflow, setMonOverflow] = useState(false);

  const checkHemoSize = () => {
    const { clientWidth, scrollWidth } = stackHemoRef.current;
    setHemoOverflow(scrollWidth > clientWidth);
  };

  const checkMonSize = () => {
    const { clientWidth, scrollWidth } = stackMonRef.current;
    setMonOverflow(scrollWidth > clientWidth);
  };

  const checkKeyDown = (e) => {
    if (e.code === "Enter") e.preventDefault();
  };

  useEffect(() => {
    // console.log('Arr', startTime);
    // if(first time to open the modal || no data filled yets)
    if (hemoFormTime === null || hemoArr.length === 0)
      setHemoFormTime(startTime);

    if (monFormTime === null || monArr.length === 0) setMonFormTime(startTime);

    //get last values in form before closing the modal
    if (hemoForm.hasOwnProperty("date")) {
      for (var key of Object.keys(hemoForm)) {
        setValue(key, hemoForm[key]);
      }
    }

    if (monForm.hasOwnProperty("date")) {
      for (var key of Object.keys(monForm)) {
        setValue(key, monForm[key]);
      }
    }
  }, []);

  const checkHemoEndDate = () => {
    if (
      endTime !== null &&
      moment(hemoFormTime).add(30, "minutes").isAfter(endTime, "minutes")
    )
      setStopAddingHemoCols(true);
  };

  const checkMonEndDate = () => {
    if (
      endTime !== null &&
      moment(monFormTime).add(3, "minutes").isAfter(endTime, "minutes")
    )
      setStopAddingMonCols(true);
  };

  // useEffect(() => {
  //     console.log('hemoTime', hemoFormTime)
  // }, [hemoFormTime])

  const getHemoObj = (data) => ({
    date: hemoFormTime,
    bl_loss: data.bl_loss,
    urine_output: data.urine_output,
    rbcs_transfusion: data.rbcs_transfusion,
    plasma_transfusion: data.plasma_transfusion,
    platelets_transfusion: data.platelets_transfusion,
    cryoprecipitate: data.cryoprecipitate,
    colloids: data.colloids,
    crystalloids: data.crystalloids,
  });

  const getMonObj = (data) => ({
    date: monFormTime,
    non_invasive_blpr: data.non_invasive_blpr,
    invasive_blpr: data.invasive_blpr,
    hr: data.hr,
    spo2: data.spo2,
    cvp: data.cvp,
    rr: data.rr,
    etco2: data.etco2,
    temperature: data.temperature,
  });

  const onSubmit = (data, e) => {
    // console.log('data', data, e, clicked)
    if (clicked === "hemoPlus") {
      setHemoArr((oldArray) => [getHemoObj(data), ...oldArray]);
      setHemoFormTime((lastState) =>
        moment(lastState).add(30, "minutes").format("YYYY-MM-DDTHH:mm:ss")
      );
      setHemoForm({});
      reset();
    }
    if (clicked === "monPlus") {
      setMonArr((oldArray) => [getMonObj(data), ...oldArray]);
      setMonFormTime((lastState) =>
        moment(lastState).add(3, "minutes").format("YYYY-MM-DDTHH:mm:ss")
      );
      setMonForm({});
      reset();
    }
    if (clicked === "close") {
      if (!formIsEmpty(data)) setHemoForm(getHemoObj(data));
      else setHemoForm({});

      if (!formIsEmpty(data)) setMonForm(getMonObj(data));
      else setMonForm({});

      handleOpenModal();
    }
    clicked = "";
  };

  useEffect(() => {
    checkHemoSize(); //array
    checkMonSize();
    checkHemoEndDate(); // formTime
    checkMonEndDate();
  }, [hemoArr, hemoFormTime, monArr, monFormTime]);

  const TimeBox = ({ mb = 3, time, bgc = "gray.secondary", color }) => (
    <Box
      justifyContent="center"
      backgroundColor={bgc}
      display="flex"
      alignItems="center"
      width={250}
      height={41}
      mb={mb}
      style={{ borderRadius: 10, border: "1px solid black" }}
    >
      <Typography style={{ fontSize: 16, color: color && color }}>
        {moment(time).format("HH:mm")}
      </Typography>
    </Box>
  );

  const HemoHeader = ({ label, time }) => (
    <Stack
      flexDirection={"row"}
      mb={3}
      alignItems="center"
      justifyContent="space-between"
      sx={{ width: "550px" }}
    >
      <Typography style={{ fontSize: 16 }}>{t(label)}</Typography>
      <TimeBox mb={0} time={time} bgc="primary.main" color="white" />
    </Stack>
  );

  const HemoField = ({ label, name, type = "number" }) => (
    <Stack
      flexDirection={"row"}
      mb={2}
      type={type}
      alignItems="center"
      justifyContent="space-between"
      sx={{ width: "550px" }}
    >
      <Typography style={{ fontSize: 16 }}>{t(label)}</Typography>
      <TextField
        name={name}
        inputProps={{ step: "any" /* min: 0 */ }}
        // label="Required"
        {...register(name)}
        type={type}
        size="small"
        sx={{
          width: "250px",
          border: "1px solid",
          borderRadius: "10px",
          height: "44px",
        }}
        //with type no. maxLength doesn't work
        //maxLength: 5
        // onInput = {(e) =>{
        //     e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,5)
        // }}
      />
    </Stack>
  );

  const Header = () => (
    <Stack
      display="flex"
      flexDirection="row"
      mb={3}
      py={1}
      borderBottom="2px solid lightgrey"
    >
      <Typography
        justifySelf="start"
        textAlign="start"
        flexGrow={1}
        style={{ fontSize: "25px", fontWeight: 700 }}
      >
        {t("Timeline")}
      </Typography>
      <Button
        // onClick={}
        variant="outlined"
        color="primary"
        style={{
          width: 145,
          height: 46,
          borderRadius: 10,
          textTransform: "none",
          fontWeight: 500,
          marginRight: 16,
        }}
        type="submit"
        onClick={() => (clicked = "close")}
      >
        {t("Close")}
      </Button>
    </Stack>
  );

  const HemoColumns = ({ overflowState, arr }) => (
    <>
      <Stack direction="row" gap={3} paddingTop={overflowState ? 5 : 0}>
        {arr.map((obj) => (
          <Stack>
            <TimeBox time={obj.date} />
            {Object.values(obj)
              .slice(1)
              .map((item) => (
                <Box
                  justifyContent="center"
                  display="flex"
                  alignItems="center"
                  mb={2}
                  width={250}
                  height={43.28}
                >
                  <Typography style={{ fontSize: 16 }}>{item || ""}</Typography>
                </Box>
              ))}
          </Stack>
        ))}
      </Stack>
    </>
  );
  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => checkKeyDown(e)}
      >
        <Header />

        {/* ********* Hemodynamics ************** */}
        <Stack direction="row" gap={1}>
          <Typography
            style={{
              fontSize: 16,
              fontWeight: 500,
              marginBottom: isHemoOverflow ? 16 : 24,
            }}
          >
            {t("Hemodynamics")}
          </Typography>
          {!stopAddingHemoCols && viewMode === "form" && (
            <CustomAddButton submitBtn onClick={() => (clicked = "hemoPlus")} />
          )}
        </Stack>

        <Stack
          direction="row"
          gap={3}
          style={styles.form}
          className="timline-overflow"
          ref={stackHemoRef}
        >
          <Stack
            position="absolute"
            flexDirection="row"
            justifyContent="space-between"
            style={{ width: "95%" }}
          >
            {isHemoOverflow && (
              <TabScrollButton
                direction="left"
                style={{
                  backgroundColor: "#003468",
                  borderRadius: "50%",
                  width: 30,
                  height: 30,
                  color: "white",
                  opacity: 1,
                }}
                onClick={() => (stackHemoRef.current.scrollLeft -= 900)}
              />
            )}
            {isHemoOverflow && (
              <TabScrollButton
                direction="right"
                color="primary.main"
                style={{
                  backgroundColor: "#003468",
                  borderRadius: "50%",
                  width: 30,
                  height: 30,
                  color: "white",
                  opacity: 1,
                }}
                onClick={() => (stackHemoRef.current.scrollLeft += 900)}
              />
            )}
          </Stack>
          {viewMode === "form" && (
            <Stack paddingTop={isHemoOverflow ? 5 : 0}>
              {" "}
              {/* titles and form */}
              <HemoHeader label="Timeline" time={hemoFormTime} />
              <HemoField label="BL Loss (CC)" name="bl_loss" />
              <HemoField label="Urine Output (CC)" name="urine_output" />
              <HemoField
                label="RBCS Transfusion (CC)"
                name="rbcs_transfusion"
              />
              <HemoField
                label="Plasma Transfusion (CC)"
                name="plasma_transfusion"
              />
              <HemoField
                label="Plattlet Transfusion (CC)"
                name="platelets_transfusion"
              />
              <HemoField label="Cryoprecepitate (CC)" name="cryoprecipitate" />
              <HemoField label="Colloid (CC)" name="colloids" />
              <HemoField label="Crystalloid (CC)" name="crystalloids" />
            </Stack>
          )}
          <HemoColumns overflowState={isHemoOverflow} arr={hemoArr} />{" "}
          {/* data array */}
        </Stack>

        {/* ********* Monitoring ************** */}
        <Stack direction="row" gap={1} mt={3}>
          <Typography
            style={{
              fontSize: 16,
              fontWeight: 500,
              marginBottom: isMonOverflow ? 16 : 24,
            }}
          >
            {t("Intraoperative monitoring")}
          </Typography>
          {!stopAddingMonCols && viewMode === "form" && (
            <CustomAddButton submitBtn onClick={() => (clicked = "monPlus")} />
          )}
        </Stack>

        <Stack
          direction="row"
          gap={3}
          style={styles.form}
          className="timline-overflow"
          ref={stackMonRef}
        >
          <Stack
            position="absolute"
            flexDirection="row"
            justifyContent="space-between"
            style={{ width: "98%" }}
          >
            {isMonOverflow && (
              <TabScrollButton
                direction="left"
                style={{
                  backgroundColor: "#003468",
                  borderRadius: "50%",
                  width: 30,
                  height: 30,
                  color: "white",
                  opacity: 1,
                }}
                onClick={() => (stackMonRef.current.scrollLeft -= 900)}
              />
            )}
            {isMonOverflow && (
              <TabScrollButton
                direction="right"
                color="primary.main"
                style={{
                  backgroundColor: "#003468",
                  borderRadius: "50%",
                  width: 30,
                  height: 30,
                  color: "white",
                  opacity: 1,
                }}
                onClick={() => (stackMonRef.current.scrollLeft += 900)}
              />
            )}
          </Stack>
          {viewMode === "form" && (
            <Stack paddingTop={isMonOverflow ? 5 : 0}>
              {" "}
              {/* titles and form */}
              <HemoHeader label="Timeline" time={monFormTime} type="text" />
              <HemoField
                label="Non Invasive BLPR"
                name="non_invasive_blpr"
                type="text"
              />
              <HemoField
                label="Invasive BLPR"
                name="invasive_blpr"
                type="text"
              />
              <HemoField label="HR" name="hr" type="text" />
              <HemoField label="SPO2" name="spo2" type="text" />
              <HemoField label="CVP" name="cvp" type="text" />
              <HemoField label="RR" name="rr" type="text" />
              <HemoField label="ETCO2" name="etco2" type="text" />
              <HemoField label="TEMP" name="temperature" type="text" />
            </Stack>
          )}
          <HemoColumns overflowState={isMonOverflow} arr={monArr} />{" "}
          {/* data array */}
        </Stack>
      </form>
    </>
  );
}
