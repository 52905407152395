import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const CustomAccordion = ({ title, items }) => {
  const [accordionExpanded, setAccordionExpanded] = useState();

  return items?.length > 0 ? (
    <Accordion
      onChange={(e, expanded) => {
        e.stopPropagation();
        setAccordionExpanded(expanded);
      }}
      className="accordion-content"
      style={{
        backgroundColor: !accordionExpanded && "transparent",
        borderRadius: 10,
        width: accordionExpanded && 350,
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography style={{ fontWeight: accordionExpanded && "bold" }}>
          {accordionExpanded ? title : `${items[0].substring(0, 10)}...`}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ maxWidth: 100 }}>
        {items.map((item) => (
          <Typography key={item} sx={{ width: 330, overflow: "auto", pb: 1 }}>
            {item}
          </Typography>
        ))}
      </AccordionDetails>
    </Accordion>
  ) : (
    <Typography> _ </Typography>
  );
};

export default CustomAccordion;
