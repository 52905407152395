import React, { useEffect, useState } from "react";

//MUI components
import { styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import {
  Box,
  FormControl,
  InputBase,
  Select,
  useMediaQuery,
} from "@mui/material";

//icons
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

//styles
import { withStyles } from "@mui/styles";
import clsx from "clsx";
import theme from "../../styles/MUITheme";

const iconStyles = {
  selectIcon: {
    display: "flex",
    backgroundColor: "#A5D2FF",
    height: "100%",
    alignItems: "center",
    // borderRadius: '0 10px 10px  0',
    cursor: "pointer",
  },
};

const CustomSelectIcon = withStyles(iconStyles)(({ className, classes }) => {
  return (
    <Box className={clsx(classes.selectIcon)}>
      <KeyboardArrowDownIcon fontSize="small" className={clsx(className)} />
    </Box>
  );
});

export default function DepartmentField({ departments = [] }) {
  const [department, setDepartment] = useState();
  const tableView = useMediaQuery(theme.breakpoints.down("md"));

  const handleChange = (event) => {
    setDepartment(event.target.value);
    if (event.target.value === "entity") {
      localStorage.removeItem("dep_id");
    } else {
      localStorage.setItem("dep_id", event.target.value);
    }
    window.location.reload();
  };

  return (
    <Box style={{ width: tableView ? 200 : 300, borderRadius: 10 }}>
      <FormControl fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={department}
          label="Accessing Department"
          onChange={handleChange}
          IconComponent={CustomSelectIcon}
          sx={{ height: 35, backgroundColor: "white", borderRadius: 2 }}
          defaultValue={localStorage.getItem("dep_id") || "entity"}
        >
          {departments.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
