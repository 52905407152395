import React, { useContext, useEffect, useState } from "react";
import CebForm from "../../../components/Form/CebForm";
import useApi from "../../../hooks/useApi";
import mainApi from "../../../api/main";
import { useNavigate, useParams } from "react-router-dom";
import roleMapper from "../../../helpers/roleMapper";
import { getCurrentUser } from "../../../auth/authService";
import usePermissions from "../../../hooks/usePermissions";
import Modal from "../../../components/UI/Modal";
import LayersTree from "../../../components/LayersTree";
import { t } from "i18next";

const module = "Admission";

export default function AdmissionListPage() {
  const { patientId, age, gender } = useParams();
  const { type } = getCurrentUser();
  const navigate = useNavigate();
  const [viewMode, setViewMode] = useState("cards");
  const [resetForm, setResetForm] = useState();
  const [doctors, setDoctors] = useState([]);
  const [admissionList, setAdmissionList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);

  //select admitted to layer
  const [openHeirarchy, setOpenHeirarchy] = useState(false);
  const [selectedLayer, setSelectedLayer] = useState({});

  //apis
  const getFilteredDiseases = useApi(mainApi.getFilteredDiseases);
  const getDoctors = useApi(mainApi.getDoctorList);
  const getAdmissionList = useApi(mainApi.getAdmissionList);
  const addAdmission = useApi(mainApi.addAdmission);
  const hasActiveAdmission = useApi(mainApi.hasActiveAdmission);
  const getLayerList = useApi(mainApi.getLayerList);

  //permissions
  const permissions = usePermissions(module);

  useEffect(() => {
    hasActiveAdmission.request(patientId);
  }, []);

  useEffect(() => {
    if (permissions?.view) {
      getAdmissionList.request(patientId, pageNumber);
    }
  }, [permissions, addAdmission.data, pageNumber]);

  useEffect(() => {
    if (viewMode === "form") {
      getFilteredDiseases.request("*");
      getDoctors.request();
      getLayerList.request();
    }
  }, [viewMode]);

  useEffect(() => {
    if (getAdmissionList.data) {
      const adList = getAdmissionList.data.map((item) => ({
        ...item,
        created_at: item.created_at.toString().split("T")[0],
        discharge_date: item.discharge_date
          ? item.discharge_date.toString().split("T")[0]
          : "pending",
      }));
      setAdmissionList(adList);
    }
  }, [getAdmissionList.data]);

  useEffect(() => {
    const doctorList = getDoctors.data.map((doc) => ({
      label: doc.full_name,
      id: doc.id,
    }));
    setDoctors(doctorList);
  }, [getDoctors.data]);

  const diseasesChangeHandler = (value) => {
    getFilteredDiseases.request(value);
  };

  //direct navigation
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  useEffect(() => {
    if (urlParams.get("add")) setViewMode("form");
  }, [urlParams.get("add")]);

  const handleOpenHeirarchy = () => {
    setOpenHeirarchy((state) => !state);
  };

  const cardItems = [
    {
      header: "Date of admission",
      keyName: "data.created_at",
      type: "formattedDate",
      format: "DD-MM-YYYY",
    },
    { header: "Date of discharge", keyName: "data.discharge_date" },
    { header: "Hospital", keyName: "data.entity?.full_name" },
    {
      header: "Department",
      keyName: `data.is_transferred ? data.admitted_to_layer?.name + ' *':data.admitted_to_layer?.name`,
    },
    { header: "Cause of admission", keyName: "data.cause" },
  ];

  const fields = [
    {
      type: "readOnly",
      label: "Date of admission",
      name: "created_at",
      value: new Date().toString(),
    },

    {
      label: "Cause of admission",
      name: "cause",
      validation: { required: true },
    },
    {
      label: "Responsible Physicians",
      type: "select",
      items: doctors,
      name: "doctors",
      multiple: true,
      autoComplete: true,
      validation: { required: true },
    },
    {
      label: "Diagnosis",
      name: "diagnosis",
      type: "select",
      onInputChange: diseasesChangeHandler,
      items: getFilteredDiseases.data,
      multiple: true,
      autoComplete: true,
      disableFilter: true,
      validation: { required: true },
    },
    {
      twoFieldsInRow: true,
      fields: [
        {
          type: "select",
          items: getLayerList.data.map((item) => ({
            id: item.id,
            label: item.name,
          })),
          label: "Department",
          name: "admitted_to_layer",
          autoComplete: true,
          // defaultValue: selectedLayerId,
          validation: { required: true },
          xs: 8,
        },
        {
          type: "button",
          width: 150,
          label: "",
          buttonTitle: "View Hierarchy",
          onClick: handleOpenHeirarchy,
          xs: 4,
        },
      ],
    },
    {
      label: "Note",
      name: "note",
      height: 100,
      multiline: true,
    },
  ];

  const onSubmit = async (data) => {
    //console.log(data);
    const newAdmission = {
      ...data,
      doctors: data.doctors.map((item) => item.id),
      diagnosis: data.diagnosis.map((item) => item.id),
      admitted_to_layer: data.admitted_to_layer.id,
    };
    const res = await addAdmission.requestWithToast(
      t("Added successfully"),
      newAdmission,
      patientId
    );
    if (res.ok) {
      if (!permissions?.view) navigate("/patients/list");
      else {
        setViewMode("cards");
        setPageNumber(1);
      }
      setResetForm(true);
    }
  };

  const handleViewCard = (admission) => {
    navigate(`${admission.id}/summary`);
  };
  const handleLayerClick = (id) => {
    if (id !== "root") {
      const layer = getLayerList.data.find((item) => item.id === id);
      setSelectedLayer({ id: layer.id, label: layer.name });
      handleOpenHeirarchy();
    }
  };

  return (
    <>
      <Modal open={openHeirarchy} handleOpen={handleOpenHeirarchy} width={1200}>
        <LayersTree onLayerClick={handleLayerClick} />
      </Modal>
      <CebForm
        onSubmit={onSubmit}
        fields={fields}
        cardsData={admissionList}
        cardItems={cardItems}
        viewModeState={[viewMode, setViewMode]}
        handleViewButton={handleViewCard}
        formWidth="90%"
        cardsWidth="100%"
        resetState={[resetForm, setResetForm]}
        loading={
          getAdmissionList.loading ||
          addAdmission.loading ||
          hasActiveAdmission.loading
        }
        disableAddNew={
          hasActiveAdmission.loading || hasActiveAdmission.data === true
        }
        requestErrors={addAdmission.error}
        // cancelHandler={() => {
        //   type === "Receptionist"
        //     ? navigate("/patients/list")
        //     : setViewMode("cards");
        // }}
        module={module}
        initialValues={{
          admitted_to_layer: Object.keys(selectedLayer).length
            ? selectedLayer
            : "",
        }}
        totalCount={getAdmissionList.count}
        paginationState={[pageNumber, setPageNumber]}
      />
    </>
  );
}
