import { Stack, TabScrollButton, Typography } from "@mui/material";
import React, { useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const ThirdNavTabs = ({ tabs, tabState, encounterViewMode }) => {
  const { t } = useTranslation();
  const [tabId, setTabId] = tabState;
  const stackRef = useRef();
  const [isOverflow, setOverflow] = useState(false);

  useLayoutEffect(() => {
    const { clientWidth, scrollWidth } = stackRef.current;
    setOverflow(scrollWidth > clientWidth);
  }, [stackRef, encounterViewMode]);

  return (
    <Stack
      direction="row"
      width="100%"
      gap={1}
      position="relative"
      paddingLeft={isOverflow ? 5 : 0}
      paddingRight={isOverflow ? 5 : 0}
    >
      {isOverflow && (
        <TabScrollButton
          direction="left"
          color="secondary"
          style={{ position: "absolute", left: 0, top: "20%" }}
          onClick={() => (stackRef.current.scrollLeft -= 900)}
        />
      )}
      <Stack
        gap={2}
        direction="row"
        sx={{
          borderBottom: "2px solid #8E0201",
          width: "100%",
          overflow: "auto",
          scrollBehavior: "smooth",
        }}
        className="scroll-container"
        ref={stackRef}
      >
        {tabs.map((item, index) => (
          <Stack
            key={index}
            sx={{
              cursor: "pointer",
              backgroundColor: index === tabId ? "secondary.main" : "#fff",
              color: index === tabId ? "#fff" : "secondary.main",
              border: "0.2px solid #8E0201",
              borderBottomColor: "rgba(0,0,0,0)",
              padding: "7px 15px",
              borderWidth: "2px",
              borderTopLeftRadius: 20,
              borderTopRightRadius: 20,
            }}
            onClick={() => setTabId(index)}
          >
            <Typography noWrap>{t(item.name)}</Typography>
          </Stack>
        ))}
      </Stack>
      {isOverflow && (
        <TabScrollButton
          direction="right"
          style={{ position: "absolute", right: 0, top: "20%" }}
          onClick={() => (stackRef.current.scrollLeft += 900)}
        />
      )}
    </Stack>
  );
};

export default ThirdNavTabs;
