import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Typography,
  Stack,
  useMediaQuery,
} from "@mui/material";
import FiberManualRecordSharpIcon from "@mui/icons-material/FiberManualRecordSharp";

import CebList from "../../../components/Form/CebList";
import useApi from "../../../hooks/useApi";
import mainApi from "../../../api/main";
import CebForm from "../../../components/Form/CebForm";
import { useParams } from "react-router-dom";
import DrugsForm from "../../../components/patient/DrugsForm";
import { Delete } from "@mui/icons-material";
import { LoadingBox } from "../../../components/UI/LoadingBox";
import moment from "moment";
import Print from "../../../components/Print";
import theme from "../../../styles/MUITheme";

const SummaryPage = () => {
  const { patientId, admissionId } = useParams();
  const [preDiagnosis, setPreDiagnosis] = useState();

  //mobile view
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));

  //api
  const getAdmission = useApi(mainApi.getAdmission);

  //helpers

  //useEffect
  useEffect(() => {
    getAdmission.request(patientId, admissionId);
  }, []);

  useEffect(() => {
    if (getAdmission.data?.diagnosis) {
      setPreDiagnosis(getAdmission.data.diagnosis.map((item) => item.label));
    }
  }, [getAdmission.data]);

  //fields and data

  const data = [
    {
      label: "Date",
      data: moment(getAdmission.data.created_at).format("DD-MM-YYYY hh:mm a"),
    },
    { label: "Cause", data: getAdmission.data.cause },
    {
      label: "Hospital",
      data: getAdmission.data.entity?.full_name,
    },
    { label: "Department", data: getAdmission.data.admitted_to_layer?.name },

    { label: "Pre-admission Diagnosis", data: preDiagnosis },

    {
      label: "Responsible Physician",
      data: getAdmission.data.doctors?.map((item) => item.full_name),
    },
    getAdmission.data.inner_transfers?.length > 0 && {
      label: "Previous Transfers",
      data: getAdmission.data.inner_transfers?.map(
        (item) => item.old_admission_layer?.name
      ),
    },
    getAdmission.data.discharge_date && {
      label: "Date of Discharge",
      data: moment(getAdmission.data.discharge_date).format(
        "DD-MM-YYYY hh:mm a"
      ),
    },
  ].filter((item) => item);

  //printing
  const dataToPrint = {
    header: {
      left: [
        moment(getAdmission.data.created_at).format("DD MMM YYYY"),
        `Department: ${getAdmission.data.admitted_to_layer?.name}`,
      ],
      title: "Admission Summary",
    },
    content: data?.filter(
      (item) =>
        item.label === "Cause" ||
        item.label === "Pre-admission Diagnosis" ||
        item.label === "Responsible Physician"
    ),
  };

  //render
  if (getAdmission.loading) {
    return <LoadingBox loading={true} />;
  }

  return (
    <>
      <Box
        width={"100%"}
        sx={{
          border: "1px solid black",
          borderRadius: "10px",
          borderColor: "#00346880",
          p: 2,
          position: "relative",
          paddingBottom: 6,
        }}
      >
        <CebList data={data} />
        {dataToPrint && (
          <Print
            dataToPrint={dataToPrint}
            style={{
              position: "absolute",
              bottom: "15px",
              right: "15px",
            }}
          />
        )}
      </Box>
    </>
  );
};

export default SummaryPage;
