import { Stack, Typography, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Tree, TreeNode } from "react-organizational-chart";
import mainApi from "../api/main";
import useApi from "../hooks/useApi";
import { StyledNode } from "../components/LayersTree";
import Modal from "../components/UI/Modal";
import { LoadingBox } from "../components/UI/LoadingBox";
import i18next, { t } from "i18next";

export default function MyTeam() {
  const [myTeam, setMyTeam] = useState(null);

  //api
  const getMyTeam = useApi(mainApi.getMyTeam);

  useEffect(() => {
    getMyTeam.request();
  }, []);

  useEffect(() => {
    if (getMyTeam.data?.length > 0) setMyTeam(getMyTeam.data[0]);
  }, [getMyTeam.data]);

  return (
    <Stack width={window.innerWidth} alignItems="center">
      {getMyTeam.loading ? (
        <LoadingBox loading={true} />
      ) : myTeam ? (
        <TeamTree
          teamName={myTeam.name}
          head={myTeam.members.find((item) => item.is_head)?.practitioner}
          members={myTeam.members
            .filter((item) => !item.is_head)
            ?.map((item) => item.practitioner)}
        />
      ) : (
        <Typography fontSize={30} pt={8}>
          {t("You are not assigned to any team")}
        </Typography>
      )}
    </Stack>
  );
}

const TeamTree = ({ teamName, head, members }) => {
  const [openMemberModal, setOpenMemeberModal] = useState(false);
  const [selectedMember, setSelectedMember] = useState({});

  const handleOpenMemberModal = () => {
    setOpenMemeberModal((old) => !old);
  };
  const onClickNodeHandler = (name, specialty) => {
    handleOpenMemberModal();
    setSelectedMember({ full_name: name, specialty });
  };
  return (
    <>
      <Modal
        open={openMemberModal}
        handleOpen={handleOpenMemberModal}
        width={500}
        minHeight={200}
      >
        <div dir={i18next.language === "en" ? "ltr" : "rtl"}>
          <ViewMemberModal
            closeHandler={handleOpenMemberModal}
            member={selectedMember}
          />
        </div>
      </Modal>
      <Stack alignItems="center" width="100%" pt={7} gap={4}>
        <Typography
          color="secondary"
          fontSize={30}
          fontWeight={700}
          alignSelf="flex-start"
          mx={7}
        >
          {t("My Team")} {`(${teamName})`}
        </Typography>
        <div dir="ltr">
          <Stack width={window.innerWidth - 70} overflow={"auto"} pb={2}>
            <Tree
              label={teamName}
              lineWidth={"2px"}
              lineColor={"#B3B3B3"}
              lineBorderRadius={"10px"}
            >
              <TreeNode
                label={
                  <StyledNode
                    name={head.full_name}
                    onClick={() =>
                      onClickNodeHandler(head.full_name, head.specialty)
                    }
                    id={head.id}
                    layer={0}
                    type={head.specialty}
                  />
                }
              >
                {members?.length > 0 &&
                  members.map((item) => (
                    <TreeNode
                      key={item.id}
                      label={
                        <StyledNode
                          id={item.id}
                          name={item.full_name}
                          layer={1}
                          type={item.specialty}
                          onClick={() =>
                            onClickNodeHandler(item.full_name, item.specialty)
                          }
                        />
                      }
                    />
                  ))}
              </TreeNode>
            </Tree>
          </Stack>
        </div>
      </Stack>
    </>
  );
};

const ViewMemberModal = ({ member, closeHandler }) => {
  return (
    <Stack gap={4}>
      <Stack direction="row" alignItems={"center"}>
        <Typography fontWeight={600} color="primary" width={90}>
          {t("Name")}:
        </Typography>
        <Typography>{member.full_name}</Typography>
      </Stack>
      <Stack direction="row" alignItems={"center"}>
        <Typography fontWeight={600} color="primary" width={90}>
          {t("Role")}:
        </Typography>
        <Typography>{member.specialty}</Typography>
      </Stack>
      <Stack direction="row" justifyContent={"center"} gap={2}>
        <Button
          variant="outlined"
          color="primary"
          sx={{ textTransform: "none" }}
          onClick={closeHandler}
        >
          {t("Close")}
        </Button>
      </Stack>
    </Stack>
  );
};
