import {
  Button,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import main from "../api/main";
import CebForm from "../components/Form/CebForm";
import { WEEK_DAYS } from "../config/constants";
import useApi from "../hooks/useApi";
import React, { useEffect, useState } from "react";
import Modal from "../components/UI/Modal";
import moment from "moment";
import formatTime from "../helpers/formatTime";
import ScheduleCalendar from "../components/ScheduleCalendar";
import SecondaryNav from "../components/Navigation/SecondaryNav";
import { getCurrentUser } from "../auth/authService";
import CebSelectField from "../components/Form/CebSelectField";
import { useForm } from "react-hook-form";
import { Delete } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import { t } from "i18next";

const SchedulePage = () => {
  const [openModal, setOpenModal] = useState(false);
  const [doctorId, setDoctorId] = useState(
    getCurrentUser()?.is_doctor_profile && getCurrentUser().user_id
  );
  const [doctorName, setDoctorName] = useState(
    getCurrentUser()?.is_doctor_profile && getCurrentUser().full_name
  );
  const clinicId = localStorage.getItem("dep_id");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  //mobile view
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const tableView = useMediaQuery(theme.breakpoints.down("md"));

  //api

  const addDoctorAvailability = useApi(main.addDoctorAvailability);
  const getDoctorAvailabilityList = useApi(main.getDoctorAvailabilityList);
  const getAppointmentList = useApi(main.getAppointmentList);
  const getClincDoctorList = useApi(main.getClincDoctorList);
  const cancelAppointment = useApi(main.cancelAppintment);
  const deleteAvailability = useApi(main.deleteAvailability);
  const confirmAppointment = useApi(main.confirmAppointment);

  useEffect(() => {
    if (!getCurrentUser()?.is_doctor_profile) {
      getClincDoctorList.request(clinicId);
    }
  }, []);

  useEffect(() => {
    if (doctorId) {
      getDoctorAvailabilityList.request(clinicId, doctorId);
    }
  }, [doctorId]);

  useEffect(() => {
    if (startDate && endDate && doctorId)
      getAppointmentList.request(clinicId, doctorId, startDate, endDate);
  }, [startDate, endDate, doctorId]);

  const handleOpenModal = () => {
    setOpenModal((state) => !state);
  };

  const onSubmitNewAvailability = async (data) => {
    const obj = {
      days: data.days,
      start_time: moment(data.start_time).format("HH:mm"),
      end_time: moment(data.end_time).format("HH:mm"),
    };
    const res = await addDoctorAvailability.requestWithToast(
      t("Added successfully"),
      clinicId,
      doctorId,
      obj
    );
    if (res.ok) {
      handleOpenModal();
      getDoctorAvailabilityList.request(clinicId, doctorId);
    }
  };

  const handleDoctorChange = (id) => {
    const doctor = getClincDoctorList.data.practitioners?.find(
      (item) => item.id === id
    );
    setDoctorId(id);
    setDoctorName(doctor.full_name);
  };

  const cancelAppointmentHandler = async (shiftId, appointmentId) => {
    const res = await cancelAppointment.requestWithToast(
      t("Cancelled successfully"),
      doctorId,
      shiftId,
      appointmentId
    );
    if (res.ok) {
      getAppointmentList.request(clinicId, doctorId, startDate, endDate);
    }
  };

  const confirmAppointmentHandler = async (shiftId, appointmentId) => {
    const res = await confirmAppointment.requestWithToast(
      t("Confirmed successfully"),
      doctorId,
      shiftId,
      appointmentId
    );
    if (res.ok) {
      getAppointmentList.request(clinicId, doctorId, startDate, endDate);
    }
  };

  const deleteAvailabilityHandler = async (availabilityId) => {
    const response = window.confirm(
      "Are you sure to permanent delete this shift ?"
    );
    if (response) {
      const res = await deleteAvailability.requestWithToast(
        t("Deleted successfully"),
        doctorId,
        clinicId,
        availabilityId
      );
      if (res.ok) {
        getDoctorAvailabilityList.request(clinicId, doctorId);
      }
    }
  };

  return (
    <Stack
      sx={{
        mx: tableView ? 2 : 9,
        mt: 6,
        width: "100%",
        overflow: "auto",
      }}
      gap={2}
    >
      <Modal open={openModal} handleOpen={handleOpenModal} width={800}>
        <NewAvailaibilityForm
          handleOpenModal={handleOpenModal}
          onSubmit={onSubmitNewAvailability}
        />
      </Modal>

      <SecondaryNav />

      {getClincDoctorList.data?.practitioners?.length > 0 && (
        <Stack gap={2}>
          <Stack pb={2} direction="row" alignItems={"center"} gap={2}>
            <Typography color="primary" fontWeight={500}>
              Select Doctor
            </Typography>
            <SelectDoctorField
              items={getClincDoctorList.data.practitioners.map((item) => ({
                id: item.id,
                name: item.full_name,
              }))}
              onDoctorChange={handleDoctorChange}
            />
          </Stack>
        </Stack>
      )}

      {doctorId && (
        <Stack gap={2} minWidth={650} overflow="auto">
          <Stack
            width={"100%"}
            p={2}
            sx={{ backgroundColor: "#F9FAFC" }}
            gap={2}
          >
            <Stack
              width={"100%"}
              direction="row"
              justifyContent="space-between"
            >
              <Stack gap={1}>
                <Typography fontWeight={600} fontSize={25}>
                  {`${
                    getCurrentUser().is_doctor_profile
                      ? t("My Schedule")
                      : `Dr. ${doctorName} ${t("Schedule")}`
                  }`}
                </Typography>
                {getDoctorAvailabilityList.data.length > 0 && (
                  <AvailabilityList
                    list={getDoctorAvailabilityList.data}
                    deleteHandler={!mobileView && deleteAvailabilityHandler}
                  />
                )}
              </Stack>
              {!mobileView && (
                <Stack gap={2} alignItems="flex-end">
                  <Typography color="primary" fontWeight={600} fontSize={20}>
                    {t("Manage Time & Availability")}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ textTransform: "none" }}
                    onClick={handleOpenModal}
                  >
                    {t("Add New")}
                  </Button>
                </Stack>
              )}
            </Stack>
            <ScheduleCalendar
              appointmentList={getAppointmentList.data}
              onChangeDateRange={(start, end) => {
                setStartDate(start);
                setEndDate(end);
              }}
              cancelHandler={cancelAppointmentHandler}
              confirmHandler={confirmAppointmentHandler}
            />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default SchedulePage;

const AvailabilityList = ({ list, deleteHandler }) => {
  return (
    <Stack>
      <Typography>{t("Availability")}</Typography>
      {list.map((item) => (
        <Stack key={item.id} gap={1} direction="row" alignItems={"center"}>
          <Stack width={250} gap={1} direction="row" alignItems={"center"}>
            <Typography fontSize={13}>{t(item.day)}</Typography>
            <Typography fontSize={13}>{`(${formatTime(
              item.start_time
            )} - ${formatTime(item.end_time)})`}</Typography>
          </Stack>

          {deleteHandler && (
            <IconButton
              sx={{ padding: 0 }}
              edge="end"
              aria-label="delete"
              onClick={deleteHandler ? () => deleteHandler(item.id) : () => {}}
            >
              <Delete />
            </IconButton>
          )}
        </Stack>
      ))}
    </Stack>
  );
};

const NewAvailaibilityForm = ({ handleOpenModal, onSubmit }) => {
  const [viewMode, setViewMode] = useState("form");

  const fields = [
    {
      name: "days",
      label: "Available Days",
      type: "select",
      items: WEEK_DAYS.map((item) => ({ id: item.id, label: t(item.label) })),
      multiple: true,
      validation: { required: true },
    },
    {
      name: "start_time",
      label: "Start Time",
      type: "time",
      validation: { required: true, maxTimeField: "end_time" },
    },
    {
      name: "end_time",
      label: "End Time",
      type: "time",
      validation: { required: true, minTimeField: "start_time" },
    },
  ];

  return (
    <Stack>
      <CebForm
        viewModeState={[viewMode, setViewMode]}
        modalForm
        modalTitle={"Time & Availability"}
        fields={fields}
        cancelHandler={handleOpenModal}
        formWidth={700}
        onSubmit={onSubmit}
      />
    </Stack>
  );
};

const SelectDoctorField = ({ items = [], onDoctorChange }) => {
  const { register, control, watch } = useForm();

  useEffect(() => {
    if (watch("doctor")) onDoctorChange(watch("doctor"));
  }, [watch("doctor")]);

  return (
    <Stack width={250}>
      <CebSelectField
        register={register}
        control={control}
        name="doctor"
        items={items}
        idName="id"
      />
    </Stack>
  );
};
