import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Box,
  Stack,
  Button,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import FiberManualRecordSharpIcon from "@mui/icons-material/FiberManualRecordSharp";
import { Delete } from "@mui/icons-material";
import { useToasts } from "react-toast-notifications";
import { useWatch } from "react-hook-form";
import _ from "lodash";
import CustomAddButton from "../UI/CustomAddButton";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

export default function NestedForm({
  label,
  title,
  nestedId,
  fields,
  primaryListText,
  secondaryListText,
  width = "100%",
  errors,
  index,
  parentIndex,
  onChangeCondition,
  watch,
  setValue,
  required = false,
  register,
  control,
  name,
  uniqueItems,
  cebFieldFunc,
  resetNested,
  disableNested,
  onReset,
  submittedData,
}) {
  const { t } = useTranslation();
  const [resultCondition, setResultCondition] = useState(required);
  const [data, setData] = useState([]);

  const { addToast } = useToasts();

  // useEffect(() => {
  //   if (Object.keys(submittedData)?.length > 0) {
  //     setData(submittedData[name]);
  //     resetFields();
  //   }
  // }, [JSON.stringify(submittedData[name])]);

  useEffect(() => {
    if (required) onChangeCondition(required, index, parentIndex, nestedId);
  }, [required]);

  const watchedFields = useWatch({
    control,
    name: fields.map((item) => item.name),
  });

  const resetFields = () => {
    for (const i in fields) {
      if (
        fields[i].type === "file" ||
        (fields[i].type === "select" && fields[i].multiple) ||
        fields[i].multipleRecords
      )
        setValue(fields[i].name, []);
      else if (fields[i].type === "date" || fields[i].type === "dateTime")
        setValue(
          fields[i].name,
          fields[i].defaultValue ? fields[i].defaultValue : null
        );
      else if (fields[i].type === "boolean") setValue(fields[i].name, [false]);
      else if (fields[i].defaultValue)
        setValue(fields[i].name, fields[i].defaultValue);
      else setValue(fields[i].name, "");
    }
    if (onReset) onReset();
  };

  const isDataExists = (newData, data, uniqueItems = []) => {
    let uniqueData = {};
    if (!uniqueItems.length) {
      uniqueData = newData;
    } else {
      for (const item of uniqueItems) {
        uniqueData[item] = newData[item];
      }
    }
    const isExist = data.find((item) => _.isMatch(item, uniqueData));
    return isExist ? true : false;
  };

  const onAddHandler = () => {
    //if false, change to true and add nested field to yup resolver
    if (resultCondition === false) {
      setResultCondition(true);
      onChangeCondition(true, index, parentIndex, nestedId);
      return;
    }
    //if true check if there are any errors before adding the current form.
    let isValid = true;
    for (const field of fields) {
      if (
        errors[field.name] ||
        (field.validation?.required && !watch(field.name))
      ) {
        isValid = false;
        break;
      }
    }

    if (isValid && !disableNested) {
      const newData = {};
      for (const i in fields) {
        newData[fields[i].name.substr(name.length + 1)] =
          fields[i].type === "boolean" ? watchedFields[i][0] : watchedFields[i];
      }
      if (!isDataExists(newData, data, uniqueItems)) {
        // add data
        setData((state) => [...state, newData]);
        //reset Fields
        resetFields();
        return true;
      } else
        addToast(t("This record has already been added"), {
          appearance: "error",
          autoDismiss: true,
        });
    } else {
      addToast(t("Enter the fields properly and try again"), {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const onDeleteHandler = (index) => {
    if (data.length === 1 && required) setResultCondition(true);
    const newData = data.filter((_, i) => index !== i);
    setData(newData);
  };

  useEffect(() => {
    setValue(name, data);
  }, [data]);

  return (
    <>
      <input hidden disabled {...register(name)} />
      <Stack direction="row" gap={3}>
        <Typography variant="h6" color="primary">
          {title}
        </Typography>
        {required ? (
          <IconButton
            sx={{
              // width: 30,
              // height: 30,
              backgroundColor: "primary.main",
              borderRadius: 3,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#fff",
              "&:hover": {
                color: "#000",
              },
              fontSize: "16px",
            }}
            onClick={onAddHandler}
          >
            {t("Add another")}
          </IconButton>
        ) : (
          <CustomAddButton
            onClick={onAddHandler}
            disabled={
              resultCondition &&
              !fields.find(
                (item) =>
                  (Array.isArray(watch(item.name)) &&
                    watch(item.name).length > 0) ||
                  (!Array.isArray(watch(item.name)) && watch(item.name))
              )
            }
          />
        )}
      </Stack>

      <>
        {data.length > 0 && (
          <ItemsList
            items={data}
            onDeleteHandler={onDeleteHandler}
            primaryListText={primaryListText}
            secondaryListText={secondaryListText}
            fields={fields.map((f) => ({
              label: f.label,
              type: f.type,
              items: f.items,
              isEnum: f.isEnum,
              multiple: f.multiple,
            }))}
          />
        )}
        {resultCondition && (
          <Box
            width={width}
            sx={{
              border: "1px solid black",
              borderRadius: "10px",
              p: 2,
              my: 2,
            }}
          >
            <Stack gap={2}>
              <Typography>{t(label)}</Typography>
              {fields.map((f, i) => cebFieldFunc(f, nestedId))}

              <Stack
                display="flex"
                flexDirection="row"
                justifyContent="end"
                alignItems="flex-end"
                gap={2}
              >
                {!(required && !data.length) && (
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setResultCondition(false);
                      onChangeCondition(false, index, parentIndex, nestedId);
                    }}
                    color="primary"
                    style={{
                      width: 100,
                      height: 42,
                      borderRadius: 10,
                      textTransform: "none",
                      fontWeight: 500,
                    }}
                  >
                    {t("Cancel")}
                  </Button>
                )}
                <Button
                  variant="contained"
                  onClick={() => {
                    const isAdded = onAddHandler();
                    if (isAdded) {
                      setResultCondition(false);
                      onChangeCondition(false, index, parentIndex, nestedId);
                    }
                  }}
                  color="primary"
                  style={{
                    width: 100,
                    height: 42,
                    borderRadius: 10,
                    textTransform: "none",
                    fontWeight: 500,
                  }}
                  disabled={
                    !fields.find(
                      (item) =>
                        (Array.isArray(watch(item.name)) &&
                          watch(item.name).length > 0) ||
                        (!Array.isArray(watch(item.name)) && watch(item.name))
                    )
                  }
                >
                  {t("Save")}
                </Button>
              </Stack>
            </Stack>
          </Box>
        )}
      </>
    </>
  );
}

const ItemsList = ({
  items,
  onDeleteHandler,
  primaryListText,
  secondaryListText,
  fields,
}) => {
  const getSelectFieldValue = (index, value) => {
    if (fields[index].isEnum) {
      return fields[index].multiple ? value?.join(", ") : value;
    } else {
      if (fields[index].multiple) {
        const selectedItems = fields[index].items
          ?.filter((item) => value.includes(item.id))
          ?.map((item) => item.name || item.label);
        return selectedItems?.join(", ");
      } else {
        const selectedItem = fields[index].items?.find(
          (item) => item.id == value
        );
        return selectedItem.name || selectedItem.label;
      }
    }
  };
  return (
    <>
      {primaryListText || secondaryListText ? (
        <List>
          {items.map((field, index) => (
            <ListItem
              key={index}
              value={field}
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => onDeleteHandler(index)}
                >
                  <Delete />
                </IconButton>
              }
            >
              <ListItemIcon>
                <FiberManualRecordSharpIcon
                  style={{ fontSize: 15, color: "black" }}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  primaryListText.key
                    ? `${t(primaryListText.key)}: ${eval(
                        primaryListText.value
                      )}`
                    : `${t(eval(primaryListText.value))}`
                }
                secondary={
                  secondaryListText !== undefined &&
                  eval(secondaryListText.value) !== "" &&
                  eval(secondaryListText.value) !== null &&
                  eval(secondaryListText.value) !== undefined &&
                  `${t(secondaryListText.key)}: ${
                    typeof eval(secondaryListText.value) === "string"
                      ? t(eval(secondaryListText.value))
                      : eval(secondaryListText.value)
                  }`
                }
              />
            </ListItem>
          ))}
        </List>
      ) : (
        <>
          {items.map((item, index) => (
            <Stack
              border="1px solid"
              p={2}
              borderRadius={"10px"}
              key={index}
              direction="row"
              justifyContent={"space-between"}
              alignItems="flex-start"
            >
              <Stack gap={1}>
                {Object.keys(item).map((key, i) => (
                  <>
                    {((Array.isArray(item[key]) && item[key]?.length > 0) ||
                      (!Array.isArray(item[key]) && item[key])) && (
                      <Stack direction="row" gap={1}>
                        <Typography fontWeight={500}>
                          {t(fields[i].label)}:
                        </Typography>
                        <Typography>
                          {fields[i].type == "select"
                            ? getSelectFieldValue(i, item[key])
                            : fields[i].type == "file"
                            ? item[key].map((file) => file.name)?.join(", ")
                            : item[key]}
                        </Typography>
                      </Stack>
                    )}
                  </>
                ))}
              </Stack>
              <IconButton onClick={() => onDeleteHandler(index)}>
                <Delete />
              </IconButton>
            </Stack>
          ))}
        </>
      )}
    </>
  );
};
