import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import mainApi from "../../../api/main";
import CebForm from "../../../components/Form/CebForm";
import useApi from "../../../hooks/useApi";
import { t } from "i18next";

const Parotid = ({ generalExaminationId, encounterViewModeKey, tabState }) => {
  const { patientId } = useParams();
  const [viewMode, setViewMode] = useState("form");
  const [encounterViewMode, setEncounterViewMode] = encounterViewModeKey;
  const [tabId, setTabId] = tabState;
  const [success, setSuccess] = useState();
  const [submitType, setSubmitType] = useState();

  //api
  const addGeneralExaminationParotid = useApi(
    mainApi.addGeneralExaminationParotid
  );
  const getGeneralExaminationParotidList = useApi(
    mainApi.getGeneralExaminationParotidList
  );

  useEffect(() => {
    getGeneralExaminationParotidList.request();
  }, []);

  useEffect(() => {
    if (viewMode === "cards") {
      setEncounterViewMode("encounter");
      setViewMode("form");
    }
  }, [viewMode]);

  console.log("parotid", getGeneralExaminationParotidList.data);

  const fields = [
    {
      name: "parotid",
      label: "Parotid",
      type: "select",
      items: getGeneralExaminationParotidList.data,
    },
  ];

  const onSubmit = async (data) => {
    const res = await addGeneralExaminationParotid.requestWithToast(
      t("Added successfully"),
      patientId,
      generalExaminationId,
      data
    );
    setSuccess(res.ok);
  };

  useEffect(() => {
    console.log("submit", success, submitType);
    if (success && submitType) {
      if (submitType === "next") {
        setTabId((prev) => prev + 1);
        window.scrollTo(0, 0);
      } else {
        setEncounterViewMode("encounter");
      }
      setSuccess(false);
      setSubmitType(null);
    }
  }, [success, submitType]);

  return (
    <CebForm
      viewModeState={[viewMode, setViewMode]}
      fields={fields}
      onSubmit={onSubmit}
      cancelButtonLabel="Back"
      saveHandler={() => setSubmitType("save")}
      cancelHandler={() => setEncounterViewMode("encounter")}
      requestErrors={addGeneralExaminationParotid.error}
    />
  );
};

export default Parotid;
