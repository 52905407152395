import { Grid } from "@mui/material";
import React from "react";
import { useLocation } from "react-router";
import useWebSocket from "../hooks/useWebSocket";
import MainNav from "./Navigation/MainNav";
import { RouterConfig } from "./Navigation/RouterConfig";

export default function MainContainer() {
  const location = useLocation();
  const webSocket = useWebSocket();
  return (
    <>
      {location.pathname !== "/login" &&
        !location.pathname.includes("reset-password") && (
          <MainNav websocket={webSocket} />
        )}
      <Grid item container sx={{ overflow: "hidden" }}>
        <RouterConfig webSocket={webSocket} />
      </Grid>
    </>
  );
}
