import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Stack } from "@mui/material";

import mainApi from "../../../api/main";
import CebForm from "../../../components/Form/CebForm";
import useApi from "../../../hooks/useApi";
import CebList from "../../../components/Form/CebList";

//{data: '', preData: '', afterData: ''}
const getFormattedText = (textArr) => {
  let finalText = ``;
  for (const text of textArr) {
    if (text.data || text.data === 0) {
      const preData = text.preData ? `${text.preData} ` : "";
      const afterData = text.afterData ? ` ${text.afterData}` : "";
      finalText = finalText + preData + text.data + afterData + ", ";
    }
  }
  console.log("final", finalText);
  return finalText ? finalText.substring(0, finalText.length - 2) : null;
};

const GeneralExaminationDetailView = ({
  generalExaminationId,
  encounterViewModeKey,
  getListOnly,
}) => {
  const { patientId } = useParams();
  const [viewMode, setViewMode] = useState("list");

  const [encounterViewMode, setEncounterViewMode] = encounterViewModeKey;

  //api
  const getGeneralExamination = useApi(mainApi.getGeneralExamination);

  useEffect(() => {
    getGeneralExamination.request(patientId, generalExaminationId);
  }, []);

  useEffect(() => {
    if (getListOnly) {
      console.log(data);
      getListOnly(data);
    }
  }, [getGeneralExamination.data]);

  const geData = getGeneralExamination.data;

  const data = [
    {
      fieldType: "labelField",
      data: "General Examination",
    },
    // geData?.pulse && {
    //   fieldType: "labelField",
    //   data: "Vital Signs",
    // },
    {
      label: "Vital Signs",
      data: true,
    },
    {
      label: "Pulse",
      data: getFormattedText([
        {
          data: geData?.pulse?.rate,
          afterData: geData?.pulse?.rate_label,
        },
        {
          data: geData?.pulse?.rhythm,
        },
        {
          data: geData?.pulse?.felt_ats?.map((item) => item.label).join(" - "),
          preData: "felt at",
        },
        {
          preData: "with pulse deficit",
          data: geData?.pulse?.pulse_deficit,
        },
        {
          data: geData?.pulse?.force,
          preData: "Tension is",
        },
        {
          data: geData?.pulse?.volume,
          preData: "Pulse volume is",
        },
        {
          data: geData?.pulse?.equality_both_sides,
          afterData: "on both sides",
        },
        {
          data: geData?.pulse?.special_characters?.label,
          preData: "special character is",
        },
        {
          data: geData?.pulse?.vessel_wall_condition,
          preData: "vessel wall is",
        },
        {
          data: geData?.pulse?.radiofemoral_delay,
          afterData: "Radiofemoral Delay",
        },
        {
          data: geData?.pulse?.pistol_shot,
          afterData: "pistol shot",
        },
        {
          data: geData?.pulse?.capillary_pulsation,
          afterData: "capillary pulsations",
        },
        {
          data: geData?.pulse?.peripheral_pulsation?.label,
          afterData: "peripheral pulsations",
        },
      ]),
      flowDirection: "column",
    },

    {
      label: "Blood Pressure",
      data: getFormattedText([
        {
          data:
            geData?.blood_pressure?.systolic &&
            `${geData?.blood_pressure?.systolic}/${geData?.blood_pressure?.diastolic}`,
          afterData: "mmHg",
        },
        {
          data: geData?.blood_pressure?.systolic_bkpr_ul,
          preData: "BLPR in LL",
        },
        {
          data: geData?.blood_pressure?.difference,
          preData: "Difference between UL and LL is",
        },
      ]),
    },

    {
      label: "Respiratory Rate",
      data: getFormattedText([
        {
          data: geData?.respiratory_rate?.rate,
        },
        {
          data: geData?.respiratory_rate?.rhythm?.label,
        },
        {
          data: geData?.respiratory_rate?.depth,
        },
        {
          data: geData?.respiratory_rate?.type?.label,
        },
      ]),
    },
    {
      label: "Temperature",
      data: getFormattedText([
        {
          data: geData?.temperature?.measurement,
          afterData: "°C",
        },
        {
          data: geData?.temperature?.pattern?.label,
          afterData: "pattern",
        },
        {
          data: geData?.temperature?.pulse_temp_ratio,
          preData: "pulse: ",
        },
      ]),
    },

    {
      label: "Mentality",
      data: getFormattedText([
        {
          data: geData?.mentality?.gcs,
          preData: "GCS",
        },
        {
          data: geData?.mentality?.orientation,
        },
        {
          data: geData?.mentality?.memories
            ?.map((item) => item.label)
            .join(" - "),
          preData: " memories:",
        },
        {
          data: geData?.mentality?.mood?.label,
          afterData: "mood",
        },
        {
          data: geData?.mentality?.behavior,
          afterData: "behavior",
        },
        {
          data: geData?.mentality?.perception?.label,
          afterData: "perception",
        },
      ]),
      flowDirection: "column",
    },
    {
      label: "Body Build",
      data: getFormattedText([
        {
          data: geData?.body_built?.weight,
          preData: "weight is",
          afterData: "kg",
        },
        {
          data: geData?.body_built?.height,
          preData: "height is",
          afterData: "m",
        },
        {
          data:
            geData?.body_built?.bmi &&
            Math.round(geData?.body_built?.bmi * 100) / 100,
          preData: "BMI is",
        },
      ]),
    },
    {
      label: "Decubitus",
      data: geData?.decubitus?.label,
    },
    {
      label: "Skin",
      data: getFormattedText([
        {
          data: geData?.skin?.textures?.map((item) => item.label).join(" - "),
          preData: "textures:",
        },
        {
          data: geData?.skin?.textures?.map((item) => item.label).join(" - "),
          preData: "complexions:",
        },
        {
          data: geData?.skin?.hairsutism,
          afterData: "hairsutism",
        },
      ]),
      flowDirection: "column",
    },
    {
      label: "Head",
      data: getFormattedText([
        {
          data: geData?.head?.inspection?.label,
        },
        {
          data: geData?.head?.palpation?.label,
          afterData: "on palpation",
        },
        {
          data: geData?.head?.percussion?.label,
          afterData: "on percussion",
        },
        {
          data: geData?.head?.auscultation?.label,
          afterData: "on auscultation",
        },
      ]),
    },
    {
      label: "Face",
      data: geData?.face?.label,
    },
    {
      label: "Eyes",
      data: getFormattedText([
        {
          data: geData?.eyes?.eyebrows?.label,
          afterData: "eyebrows",
        },
        {
          data: geData?.eyes?.lids?.label,
          afterData: "lids",
        },
        {
          data: geData?.eyes?.balls?.label,
          afterData: "balls",
        },
        {
          data: geData?.eyes?.conjunctiva?.label,
          afterData: "conjunctiva",
        },
        {
          data: geData?.eyes?.cornea?.map((item) => item.label).join(" - "),
          adterData: "cornea",
        },
        {
          data: geData?.eyes?.sclera?.label,
          preData: "sclera",
        },
        {
          data: geData?.eyes?.movements?.map((item) => item.label).join(" - "),

          preData: "movements:",
        },
        {
          data: geData?.eyes?.pupils?.map((item) => item.label).join(" - "),
          preData: "pupils:",
        },
        {
          data: geData?.eyes?.lacrimal_glands?.label,
          preData: "lacrimal glands are",
        },
        {
          data: geData?.eyes?.lens?.label,
          preData: "lens:",
        },
        {
          data: geData?.eyes?.fundus?.label,
          preData: "fundus:",
        },
      ]),
      flowDirection: "column",
    },

    {
      label: "Nose and Cheeks",
      data: geData?.nose_and_cheeks?.label,
    },
    {
      label: "Ear",
      data: geData?.ear?.label,
    },
    {
      label: "Parotid",
      data: geData?.parotid?.label,
    },
    {
      label: "Mouth and Throat",
      data: geData?.mouth_and_throat?.map((item) => item.label).join(" - "),
    },
    {
      label: "Neck",
      data: getFormattedText([
        {
          data: geData?.neck?.cervical_lymph_nodes,
          afterData: "Cervical Lymph nodes",
        },
        {
          data: geData?.neck?.neck_general_examination?.label,
          afterData: "neck",
        },
        {
          data: geData?.neck?.thyroid_gland?.label,
          preData: "thyroid gland is",
        },
        {
          data: geData?.neck?.veins?.label,
          preData: "veins show",
        },
        {
          data: geData?.neck?.carotid_artery?.label,
          preData: "carotid arteries show",
        },
      ]),
      flowDirection: "column",
    },
    {
      label: "Upper Limb",
      data: getFormattedText([
        {
          data: geData?.upper_limb?.grip?.label,
          preData: "grip is",
        },
        {
          data: geData?.upper_limb?.shapes
            ?.map((item) => item.label)
            .join(" - "),
          preData: "shapes:",
        },
        {
          data: geData?.upper_limb?.skin?.label,
          preData: "skin show",
        },
        {
          data: geData?.upper_limb?.unitary_movement?.label,
          preData: "unitary movement show",
        },
        {
          data: geData?.upper_limb?.nails?.label,
          preData: "nails show",
        },
      ]),
      flowDirection: "column",
    },
    {
      label: "Lower Limb",
      data: getFormattedText([
        {
          preData: "shapes:",
          data: geData?.lower_limb?.shapes
            ?.map((item) => item.label)
            .join(" - "),
        },
        {
          data: geData?.lower_limb?.nails?.label,
          preData: "nails show",
        },
        {
          data: geData?.lower_limb?.skin?.label,
          preData: "skin show",
        },
        {
          data: geData?.lower_limb?.edema?.label,
          afterData: "Edema",
        },
      ]),
      flowDirection: "column",
    },
    {
      label: "Abdominal Examination",
      data: getFormattedText([
        {
          data: geData?.abdominal?.livers
            ?.map((item) => item.label)
            .join(" - "),
          preData: "liver show",
        },
        {
          data: geData?.abdominal?.spleen?.label,
          preData: "spleen",
        },
        {
          data: geData?.abdominal?.ascites?.label,
          preData: "ascites",
        },
      ]),
      flowDirection: "column",
    },
    {
      label: "Back Examination",
      data: getFormattedText([
        {
          data: geData?.back?.murmur_bruits
            ?.map((item) => item.label)
            .join(" - "),
          preData: "murmur bruits:",
        },
        {
          data: geData?.back?.crepitation?.label,
          afterData: "crepitation",
        },
        {
          data: geData?.back?.ewart_sign,
          afterData: "ewart sign",
        },
        {
          data: geData?.back?.vertebras?.label,
          preData: "vertebras show",
        },
        {
          data: geData?.back?.scapula?.label,
          preData: "scapula show",
        },
        {
          data: geData?.back?.skin?.label,
          preData: "skin show",
        },
      ]),
      flowDirection: "column",
    },
  ].filter((item) => item);

  //printing
  const dataToPrint = {
    header: { title: "General Examination" },
    content: data.filter((item) => item.fieldType !== "labelField"),
  };

  if (!getListOnly)
    return (
      <Stack gap={1}>
        <CebForm
          viewModeState={[viewMode, setViewMode]}
          listData={data}
          disableAddNew={true}
          backHandler={() => setEncounterViewMode("encounter")}
          loading={getGeneralExamination.loading}
          module="Encounter"
          dataToPrint={dataToPrint}
        />
      </Stack>
    );
};

export default GeneralExaminationDetailView;
