import React from "react";
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  Link,
  Font,
} from "@react-pdf/renderer";

import logoImg from "../assets/images/logo.png";

import CairoLight from "../assets/fonts/Cairo/Cairo-Light.ttf";
import CairoBold from "../assets/fonts/Cairo/Cairo-Bold.ttf";
import CairoSemiBold from "../assets/fonts/Cairo/Cairo-SemiBold.ttf";
import { getCurrentUser } from "../auth/authService";
import { Button, Stack } from "@mui/material";
import { PrintOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useApi from "../hooks/useApi";
import main from "../api/main";
import { t } from "i18next";

Font.register({
  family: "Cairo",
  fonts: [
    {
      src: CairoLight,
      fontWeight: 200,
    },
    {
      src: CairoSemiBold,
      fontWeight: 400,
    },
    {
      src: CairoBold,
      fontWeight: 700,
    },
  ],
});

// header = {
//   data: {
//     left: [new Date().toDateString(), "Doctor: Dr. Amr Elbakry"],
//     right: ["Doctor: Dr. Amr Elbakry"],
//   },
//   title: "Prescription",
// },
// content = [
//   {
//     label: "Medications",
//     data: [
//       "Metronidazole, 40g, Oral suspension, 1 unit 2 doses / day, for 3 days",
//       "Metronidazole, 40g, Oral suspension, 1 unit 2 doses / day, for 3 days",
//       "Metronidazole, 40g, Oral suspension, 1 unit 2 doses / day, for 3 days",
//       "Metronidazole, 40g, Oral suspension, 1 unit 2 doses / day, for 3 days",
//     ],
//   },
// ],
// footer = { printedBy: "Dr. Ahmed", printDate: new Date().toDateString() },

const viewData = (data, type) => (
  <>
    {data && typeof data === "object" && type !== "link" ? (
      <View>
        {Object.keys(data).map((key) => (
          <>
            {data[key] && key !== "id" && (
              <Text>
                <Text style={{ fontWeight: 700 }}>{`${`${
                  key.toUpperCase()[0] + key.substring(1)
                }`.replace("_", " ")}: `}</Text>
                {`${data[key]}`}
              </Text>
            )}
          </>
        ))}
      </View>
    ) : (
      <>
        {type === "image" ? (
          <View style={{ maxWidth: "100%", height: "400px", padding: "10px" }}>
            <Image src={data} style={{ objectFit: "contain" }} />
          </View>
        ) : type === "link" ? (
          <Link src={data.file} style={{ fontSize: "12px" }}>
            {data.file_name}
          </Link>
        ) : (
          <Text style={styles.dataItem}>{data}</Text>
        )}
      </>
    )}
  </>
);

const MyDoc = ({ header, content, patientName }) => {
  return (
    <Document title={header.title} subject={header.title} author="Cerebrotech">
      <Page style={styles.page}>
        <View style={styles.container}>
          {/* header */}
          <View style={styles.header}>
            <View style={styles.headerDataContainer}>
              {/* <Image src={logoImg} style={styles.logo} /> */}
              <View>
                {header?.left?.map((item, index) => (
                  <Text key={index} style={styles.headerData}>
                    {item}
                  </Text>
                ))}
                <Text style={styles.headerData}>Patient: {patientName}</Text>
              </View>
              <View>
                <Text style={styles.headerData}>
                  Entity: {getCurrentUser().workplace_name}
                </Text>
                {header?.right?.map((item, index) => (
                  <Text key={index} style={styles.headerData}>
                    {item}
                  </Text>
                ))}
              </View>
            </View>
            <View style={styles.titleContainer}>
              <Text style={styles.title}>{header.title}</Text>
            </View>
          </View>

          {/* content */}
          {content.length > 0 && (
            <View>
              {content.map((item, i) => (
                <>
                  {(item.data || item.data === 0 || item.type === "label") &&
                    !(Array(item.data).isArray && item.data?.length === 0) && (
                      <View
                        key={i}
                        style={{ marginBottom: "20px" }}
                        wrap={item.type === "image"}
                      >
                        <Text
                          style={{
                            ...styles.label,
                            ...(item.type === "label" && {
                              fontSize: "14px",
                              textDecoration: "none",
                              marginBottom: "2px",
                            }),
                          }}
                        >
                          {item.label}
                        </Text>
                        {Array.isArray(item.data) ? (
                          item.data.map((el, index) => (
                            <View
                              style={styles.dataContainer}
                              key={index}
                              wrap={false}
                            >
                              <Text style={{ marginRight: "5px" }}>&bull;</Text>
                              {viewData(el, item.type)}
                            </View>
                          ))
                        ) : (
                          <>{viewData(item.data, item.type)}</>
                        )}
                      </View>
                    )}
                </>
              ))}
            </View>
          )}

          {/* footer */}

          <View style={styles.footer} wrap={false}>
            <Text style={styles.footerText}>
              {`Printed by: ${getCurrentUser()?.full_name}`}
            </Text>
            <Text
              style={styles.footerText}
            >{`Date: ${new Date().toDateString()}`}</Text>
            <Image src={logoImg} style={styles.logo} />
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default function Print({ dataToPrint, style = {} }) {
  const { patientId } = useParams();
  const [patientName, setPatientName] = useState();

  const getPatientPersonalHistory = useApi(main.getPatientPersonalHistory);
  useEffect(() => {
    getPatientPersonalHistory.request(patientId);
  }, []);

  useEffect(() => {
    if (getPatientPersonalHistory.data?.full_name) {
      setPatientName(getPatientPersonalHistory.data?.full_name);
    }
  }, [getPatientPersonalHistory.data]);

  return (
    <PDFDownloadLink
      document={<MyDoc {...dataToPrint} patientName={patientName} />}
      fileName={`${dataToPrint?.header?.title}.pdf`}
    >
      {({ blob, url, loading, error }) => (
        <Button
          variant="outlined"
          style={{
            fontSize: "15px",
            textDecoration: "none",
            textTransform: "none",
            ...style,
          }}
        >
          <Stack direction={"row"} alignItems={"center"} gap={"6px"}>
            {loading || getPatientPersonalHistory.loading
              ? t("Loading document...")
              : t("Print")}
            <PrintOutlined />
          </Stack>
        </Button>
      )}
    </PDFDownloadLink>
  );
}

const styles = StyleSheet.create({
  page: {
    padding: "10px",
    fontFamily: `Cairo`,
    fontSize: "11px",
    fontWeight: 400,
  },
  container: {
    border: "0.5px solid black",
    padding: "10px",
    paddingBottom: "30px",
    flex: 1,
    position: "relative",
  },
  header: {
    position: "relative",
    minHeight: "10%",
    justifyContent: "space-between",
    borderBottom: "0.8px solid black",
    paddingBottom: "10px",
    marginBottom: "10px",
  },
  // logo: {
  //   width: "90px",
  //   height: "60px",
  //   position: "absolute",
  //   top: "-13px",
  //   left: "50%",
  //   transform: "translate(-50%, 0)",
  //   marginLeft: "auto",
  //   marginRight: "auto",
  // },
  logo: {
    width: "55px",
    height: "38px",
  },
  headerDataContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "flex-start",
    width: "100%",
  },

  headerData: { fontSize: "11px", marginBottom: "2px", fontWeight: "medium" },
  titleContainer: { width: "100%", textAlign: "center" },
  title: {
    marginTop: "10px",
    fontSize: "14px",
    textDecoration: "underline",
    fontWeight: 700,
  },
  label: {
    marginBottom: "10px",
    fontSize: "12px",
    fontWeight: 700,
    textDecoration: "underline",
  },
  dataContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
    marginBottom: "5px",
  },
  dataItem: {
    fontSize: "11px",
    marginLeft: "5px",
  },
  footer: {
    borderTop: "0.6px solid black",
    paddingHorizontal: "10px",
    paddingVertical: "-2px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    position: "absolute",
    bottom: 0,
    left: "10px",
    width: "100%",
  },
  footerText: {
    fontSize: "8px",
  },
});
