import React from "react";
import { Stack, IconButton } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useWatch, useFieldArray } from "react-hook-form";

export const StyledIconButton = styled((props) => <IconButton {...props} />)(
  ({ theme }) => ({
    "&": {
      width: 24,
      height: 24,
      border: `2px solid black`,
      borderColor: theme.palette.primary.main,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 0,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
  })
);

export default function CebBooleanField({ control, register, name }) {
  const { fields, replace } = useFieldArray({
    control,
    name: name,
  });

  useEffect(() => {
    register(name);
    console.log("entered");
  }, []);

  //set default value
  useEffect(() => {
    if (fields.length === 0) replace(false);
  }, [fields]);

  const choice = useWatch({
    control,
    name: name,
  });

  let styleCondition = choice && choice[0];

  return (
    <Stack gap={3} my={2} direction="row">
      <StyledIconButton
        sx={{
          backgroundColor: styleCondition ? "primary.main" : "common.white",
        }}
        // onClick={() => onChange(true)}
        onClick={() => replace(true)}
      >
        <DoneIcon
          fontSize="small"
          style={{ color: styleCondition ? "white" : "#003468" }}
        />
      </StyledIconButton>
      <StyledIconButton
        sx={{
          backgroundColor: styleCondition ? "common.white" : "primary.main",
        }}
        // onClick={() => onChange(false)}
        onClick={() => replace(false)}
      >
        <CloseIcon
          fontSize="small"
          style={{ color: styleCondition ? "#003468" : "white" }}
        />
      </StyledIconButton>
    </Stack>
  );
}
