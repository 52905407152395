import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import mainApi from "../../../api/main";
import CebForm from "../../../components/Form/CebForm";
import useApi from "../../../hooks/useApi";
import { t } from "i18next";

const Eyes = ({ generalExaminationId, encounterViewModeKey, tabState }) => {
  const { patientId } = useParams();
  const [viewMode, setViewMode] = useState("form");
  const [encounterViewMode, setEncounterViewMode] = encounterViewModeKey;
  const [tabId, setTabId] = tabState;
  const [success, setSuccess] = useState();
  const [submitType, setSubmitType] = useState();

  //api
  const addGeneralExaminationEye = useApi(mainApi.addGeneralExaminationEye);
  const getGeneralExaminationEyeBrowsList = useApi(
    mainApi.getGeneralExaminationEyeBrowsList
  );
  const getGeneralExaminationEyeLidsList = useApi(
    mainApi.getGeneralExaminationEyeLidsList
  );
  const getGeneralExaminationEyeBallsList = useApi(
    mainApi.getGeneralExaminationEyeBallsList
  );
  const getGeneralExaminationEyeConjunctivaList = useApi(
    mainApi.getGeneralExaminationEyeConjunctivaList
  );
  const getGeneralExaminationEyeCorneaList = useApi(
    mainApi.getGeneralExaminationEyeCorneaList
  );
  const getGeneralExaminationEyeScleraList = useApi(
    mainApi.getGeneralExaminationEyeScleraList
  );
  const getGeneralExaminationEyePupilsList = useApi(
    mainApi.getGeneralExaminationEyePupilsList
  );
  const getGeneralExaminationEyeMovementList = useApi(
    mainApi.getGeneralExaminationEyeMovementList
  );
  const getGeneralExaminationEyeLacrimalGlandsList = useApi(
    mainApi.getGeneralExaminationEyeLacrimalGlandsList
  );
  const getGeneralExaminationEyeLensList = useApi(
    mainApi.getGeneralExaminationEyeLensList
  );
  const getGeneralExaminationEyeFundusList = useApi(
    mainApi.getGeneralExaminationEyeFundusList
  );

  useEffect(() => {
    getGeneralExaminationEyeBrowsList.request();
    getGeneralExaminationEyeLidsList.request();
    getGeneralExaminationEyeBallsList.request();
    getGeneralExaminationEyeConjunctivaList.request();
    getGeneralExaminationEyeCorneaList.request();
    getGeneralExaminationEyeScleraList.request();
    getGeneralExaminationEyePupilsList.request();
    getGeneralExaminationEyeMovementList.request();
    getGeneralExaminationEyeLacrimalGlandsList.request();
    getGeneralExaminationEyeLensList.request();
    getGeneralExaminationEyeFundusList.request();
  }, []);

  useEffect(() => {
    if (viewMode === "cards") {
      setEncounterViewMode("encounter");
      setViewMode("form");
    }
  }, [viewMode]);

  const fields = [
    {
      name: "eyebrows",
      label: "Brows",
      type: "select",
      // autoComplete: true,
      // multiple: true,
      items: getGeneralExaminationEyeBrowsList.data,
    },
    {
      name: "lids",
      label: "Eye Lid",
      type: "select",
      // autoComplete: true,
      // multiple: true,
      items: getGeneralExaminationEyeLidsList.data,
    },
    {
      name: "balls",
      label: "Eye balls",
      type: "select",
      // autoComplete: true,
      // multiple: true,
      items: getGeneralExaminationEyeBallsList.data,
    },
    {
      name: "conjunctiva",
      label: "Conjunctiva",
      type: "select",
      // autoComplete: true,
      // multiple: true,
      items: getGeneralExaminationEyeConjunctivaList.data,
    },
    {
      name: "cornea",
      label: "CORNEA",
      type: "select",
      // autoComplete: true,
      multiple: true,
      items: getGeneralExaminationEyeCorneaList.data,
    },
    {
      name: "sclera",
      label: "Sclera",
      type: "select",
      // autoComplete: true,
      // multiple: true,
      items: getGeneralExaminationEyeScleraList.data,
    },
    {
      name: "pupils",
      label: "Pupils",
      type: "select",
      // autoComplete: true,
      multiple: true,
      items: getGeneralExaminationEyePupilsList.data,
    },
    {
      name: "movements",
      label: "Eye movement",
      type: "select",
      // autoComplete: true,
      multiple: true,
      items: getGeneralExaminationEyeMovementList.data,
    },
    {
      name: "lacrimal_glands",
      label: "Lacrimal Gland",
      type: "select",
      // autoComplete: true,
      // multiple: true,
      items: getGeneralExaminationEyeLacrimalGlandsList.data,
    },
    {
      name: "lens",
      label: "Lens",
      type: "select",
      // autoComplete: true,
      // multiple: true,
      items: getGeneralExaminationEyeLensList.data,
    },
    {
      name: "fundus",
      label: "Fundus",
      type: "select",
      // autoComplete: true,
      // multiple: true,
      items: getGeneralExaminationEyeFundusList.data,
    },
  ];

  const onSubmit = async (data) => {
    const res = await addGeneralExaminationEye.requestWithToast(
      t("Added successfully"),
      patientId,
      generalExaminationId,
      data
    );
    setSuccess(res.ok);
  };

  useEffect(() => {
    console.log("submit", success, submitType);
    if (success && submitType) {
      if (submitType === "next") {
        setTabId((prev) => prev + 1);
        window.scrollTo(0, 0);
      } else {
        setEncounterViewMode("encounter");
      }
      setSuccess(false);
      setSubmitType(null);
    }
  }, [success, submitType]);

  return (
    <CebForm
      viewModeState={[viewMode, setViewMode]}
      fields={fields}
      onSubmit={onSubmit}
      nextButtonHandler={() => {
        setSubmitType("next");
      }}
      cancelButtonLabel="Back"
      saveHandler={() => setSubmitType("save")}
      cancelHandler={() => setEncounterViewMode("encounter")}
      requestErrors={addGeneralExaminationEye.error}
    />
  );
};

export default Eyes;
