import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.65 11.895A4.305 4.305 0 0 0 11.805 7.5 4.32 4.32 0 0 0 7.65 3a4.32 4.32 0 0 0-4.17 4.5 4.32 4.32 0 0 0 4.17 4.395Zm0-7.005A2.43 2.43 0 0 1 9.93 7.5a2.445 2.445 0 0 1-2.28 2.52A2.445 2.445 0 0 1 5.37 7.5a2.43 2.43 0 0 1 2.28-2.61Zm10.785 7.575a3.585 3.585 0 0 0 3.45-3.69 3.585 3.585 0 0 0-3.45-3.705A3.585 3.585 0 0 0 15 8.775a3.585 3.585 0 0 0 3.435 3.69Zm0-5.52a1.725 1.725 0 0 1 1.575 1.83 1.725 1.725 0 0 1-1.575 1.815 1.725 1.725 0 0 1-1.59-1.815 1.725 1.725 0 0 1 1.59-1.83Zm-.105 7.395a5.776 5.776 0 0 0-4.605 2.265A7.816 7.816 0 0 0 7.65 13.77 7.5 7.5 0 0 0 0 21h1.875a5.58 5.58 0 0 1 5.775-5.355A5.565 5.565 0 0 1 13.41 21h1.875a6.75 6.75 0 0 0-.48-2.535 3.81 3.81 0 0 1 3.525-2.25 3.66 3.66 0 0 1 3.795 3.495V21H24v-1.29a5.535 5.535 0 0 0-5.67-5.37Z"
      fill="#003468"
    />
  </svg>
);

export default SvgComponent;
