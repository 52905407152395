import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import Success from "../../assets/images/Success.png";
import CebTextField from "../Form/CebTextField";
import { useForm } from "react-hook-form";
import useApi from "../../hooks/useApi";
import mainApi from "../../api/main";
import useYupResolver from "../../hooks/useYupResolver";
import { useNavigate } from "react-router-dom";
import Timer from "../Timer";
import i18next, { t } from "i18next";

const OtpForm = ({
  userId,
  handleOpenModal,
  enrollExistingPatient = false,
  onSuccessHandler,
}) => {
  const {
    register,
    trigger,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: useYupResolver([
      {
        name: "otp",
        validation: { required: true, length: 4, message: "Must be 4 digits" },
      },
    ]),
    mode: "onTouched",
  });
  // const { addToast } = useToasts();
  const [resetTimerTrigger, setResetTimerTrigger] = useState(false);
  const [startTimer, setStartTimer] = useState(false);

  let navigate = useNavigate();
  const [pk, setPk] = useState();
  const validateOtpEnrollUser = useApi(
    enrollExistingPatient
      ? mainApi.entityEnrollPatient
      : mainApi.validateOtpEnrollUser
  );

  const onSubmit = async (data) => {
    let obj = {};
    obj[enrollExistingPatient ? "token_id" : "pk"] = pk;
    obj["otp"] = data.otp;
    const res = await validateOtpEnrollUser.requestWithToast(
      t("patient enrolled successfully"),
      obj
    );
    if (res?.ok) {
      handleOpenModal();
      if (onSuccessHandler) {
        onSuccessHandler();
      } else {
        if (localStorage.getItem("dep_id"))
          navigate(
            `/patients/${res?.data?.data?.patient?.id}/${res?.data?.data?.patient?.age}/${res?.data?.data?.patient?.gender}/encounters`
          );
        else
          navigate(
            `/patients/${res?.data?.data?.patient?.id}/${res?.data?.data?.patient?.age}/${res?.data?.data?.patient?.gender}/admission`
          );
      }
    }
    // else {
    //   addToast("Wrong OTP", { appearance: "error", autoDismiss: true });
    // }
  };

  const sendOtp = async () => {
    try {
      const res = await mainApi.sendOtpById({
        by_phone: true,
        user_id: userId,
      });
      if (!res.ok) throw Error("error");
      setPk(res.data.data.pk);
      setResetTimerTrigger((old) => !old);
      if (!startTimer) setStartTimer(true);

      console.log("OTP", res.data.data.debug);
    } catch (err) {
      console.log("error", err);
    }
  };

  useEffect(() => {
    if (userId) {
      sendOtp();
    }
  }, [userId]);

  console.log(startTimer);

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            borderBottom: "2px solid #D9D9D9",
            pb: 2,
            direction: i18next.language === "en" ? "ltr" : "rtl",
          }}
        >
          <Typography style={{ fontWeight: 700, fontSize: 25 }} color="primary">
            {t("Enter OTP")}
          </Typography>
          <Button
            variant="contained"
            sx={{
              borderRadius: "10px",
              textTransform: "none",
              width: "145px",
              height: "46px",
            }}
            type="submit"
          >
            {t("Next")}
          </Button>
        </Stack>

        <Stack justifyContent="center" alignItems="center" gap={2}>
          <img src={Success} width="270px" height="203px" />
          <Typography
            sx={{ fontSize: "25px", fontWeight: 400, textAlign: "center" }}
          >
            {t("OTP has been sent to the patient’s registered email")}
          </Typography>
          <CebTextField
            register={register}
            width="588px"
            height="48px"
            name="otp"
            type="number"
            trigger={trigger}
            setValue={setValue}
          />
          {errors?.otp && (
            <Typography variant="error">{errors?.otp?.message}</Typography>
          )}
          {startTimer && <Timer resetTrigger={resetTimerTrigger} />}
          <Typography sx={{ fontSize: 20, fontWeight: 400 }}>
            {t("Didn’t recieve OTP?")}
          </Typography>
          <Button onClick={sendOtp}>{t("Resend Now")}</Button>
        </Stack>
      </form>
    </Box>
  );
};

export default OtpForm;
