import React from "react";
import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "../../auth/protectedRoute";
import EncountersPage from "./EncountersPage";
import GeneralExamination from "./generalExamination/index";
import OrdersPage from "./OrdersPage";
import PastHistory from "./pastHistory/index";
import SinglePatientPage from "./SinglePatientPage";
import AdmissionListPage from "./admission/AdmissionListPage";
import Admission from "./admission/index";
import RadioPage from "./RadioPage";
import LabPage from "./LabPage";
import DrugsPage from "./admission/DrugsPage";
import ProcedureList from "./Procedures/ProcedureList";
import Procedures from "./Procedures";
import ConsentsPage from "./ConsentsPage";

const PAST_HISTORY = "/past-history/*";
const ENCOUNTERS = "/encounters";
const ENCOUNTERS_GENERAL_EXAMINATION = "/encounters/GA/*";
const ORDERS = "/orders";
const RADIO = "/radio";
const LAB = "/lab";
const ADMISSION_LIST = "/admission";
const PRESCRIPTION_LIST = "/prescription";
const ADMISSION_DETAILS = "/admission/:admissionId/*";
const PROCEDURE_LIST = "/procedures";
const PROCEDURE_DETAILS = "/procedures/:procedureId/*";
const CONSENT = "/consents";

export default () => {
  return (
    <SinglePatientPage>
      <Routes>
        <Route path={PAST_HISTORY} element={<PastHistory />} />

        <Route path={ENCOUNTERS} element={<ProtectedRoute />}>
          <Route path={ENCOUNTERS} element={<EncountersPage />} />
        </Route>

        <Route
          path={ENCOUNTERS_GENERAL_EXAMINATION}
          element={<GeneralExamination />}
        />

        <Route path={ORDERS} element={<ProtectedRoute />}>
          <Route path={ORDERS} element={<OrdersPage />} />
        </Route>

        <Route path={RADIO} element={<ProtectedRoute />}>
          <Route path={RADIO} element={<RadioPage />} />
        </Route>

        <Route path={LAB} element={<ProtectedRoute />}>
          <Route path={LAB} element={<LabPage />} />
        </Route>

        <Route path={ADMISSION_LIST} element={<ProtectedRoute />}>
          <Route path={ADMISSION_LIST} element={<AdmissionListPage />} />
        </Route>

        <Route path={PROCEDURE_LIST} element={<ProtectedRoute />}>
          <Route path={PROCEDURE_LIST} element={<ProcedureList />} />
        </Route>

        <Route path={PRESCRIPTION_LIST} element={<ProtectedRoute />}>
          <Route path={PRESCRIPTION_LIST} element={<DrugsPage />} />
        </Route>

        <Route path={CONSENT} element={<ProtectedRoute />}>
          <Route path={CONSENT} element={<ConsentsPage />} />
        </Route>

        <Route path={ADMISSION_DETAILS} element={<Admission />} />
        <Route path={PROCEDURE_DETAILS} element={<Procedures />} />
      </Routes>
    </SinglePatientPage>
  );
};
