import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width="25"
    height="23"
    viewBox="0 0 25 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.3102 7.09559L14.0602 2.46324C13.9102 2.33089 13.7602 2.26471 13.5352 2.26471H6.03516C5.21016 2.26471 4.53516 2.8603 4.53516 3.58824V19.4706C4.53516 20.1985 5.21016 20.7941 6.03516 20.7941H18.0352C18.8602 20.7941 19.5352 20.1985 19.5352 19.4706V7.55883C19.5352 7.3603 19.4602 7.22794 19.3102 7.09559ZM13.5352 3.85294L17.7352 7.55883H13.5352V3.85294ZM18.0352 19.4706H6.03516V3.58824H12.0352V7.55883C12.0352 8.28677 12.7102 8.88236 13.5352 8.88236H18.0352V19.4706Z"
      fill="white"
    />
    <path
      d="M7.53516 15.5H16.5352V16.8235H7.53516V15.5ZM7.53516 11.5294H16.5352V12.8529H7.53516V11.5294Z"
      fill="white"
    />
  </svg>
);

export default SvgComponent;
