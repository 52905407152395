import { Grid, Typography, Button, Box } from "@mui/material";
import React, { useState } from "react";
import CebTextField from "./CebTextField";
import CebSelectField from "./CebSelectField";

import CebBooleanField from "./CebBooleanField";
import { useWatch } from "react-hook-form";
import CebDateField from "./CebDateField";
import MultipleRecordsField from "./MultipleRecordsField";
import CebFileField from "./CebFileField";
import CebDateTimeField from "./CebDateTimeField";
import CustomAddButton from "../UI/CustomAddButton";
import CebTimeField from "./CebTimeField";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

export default function CebField({
  label,
  type,
  childField, //child field has label with less font weight
  multipleRecords, //if true  plus icon and list appears
  control,
  autoComplete,
  multiple,
  isEnum,
  items,
  rows,
  idName,
  onInputChange,
  readOnlyValue,
  errors,
  nestedId,
  resetNestedState,
  ...props
}) {
  const { t } = useTranslation();
  const [booleanChoice, setBooleanChoice] = useState(false); //boolean
  const [fieldArr, setFieldArr] = useState([]); //multipleRecords
  const [currentFieldValue, setCurrentFieldValue] = useState(""); //multipleRecords
  const [resetNested, setResetNested] = resetNestedState; //multipleRecords

  //this watch for logging field value only
  const fieldValue = useWatch({
    control,
    name: props.name,
    defaultValue: "",
  });

  React.useEffect(() => {
    console.log("fieldValue", fieldValue);
  }, [fieldValue]);

  React.useEffect(() => {
    if (resetNested.state === true && resetNested.nestedId === nestedId)
      setFieldArr([]);
  }, [resetNested.state, resetNested.nestedId]);

  const handleAddFieldItem = () => {
    if (currentFieldValue === "" || currentFieldValue === null) return; //validation for empty field
    setFieldArr((oldArray) => [...oldArray, currentFieldValue]);
  };

  const handleAddFieldItemByKey = (e) => {
    console.log("eeee");
    if (e.key === "Enter") handleAddFieldItem();
  };

  const handleDeleteFieldItem = (index) => {
    const newItems = fieldArr.filter((f, i) => i !== index);
    setFieldArr(newItems);
  };

  const addField = () => {
    if (type === "select")
      return (
        <CebSelectField
          items={items}
          idName={idName}
          isEnum={isEnum}
          autoComplete={autoComplete}
          multiple={multiple}
          onInputChange={onInputChange}
          control={control}
          {...props}
          errors={errors}
        />
      );
    else if (type === "boolean")
      return <CebBooleanField control={control} {...props} errors={errors} />;
    else if (type === "date")
      return <CebDateField control={control} {...props} errors={errors} />;
    else if (type === "readOnly")
      return i18next.language === "en" ? (
        <Typography
          textAlign="start"
          style={{ whiteSpace: "pre" }}
          color="gray.main"
        >
          {readOnlyValue}
        </Typography>
      ) : (
        <Typography
          textAlign="end"
          style={{ whiteSpace: "pre", direction: "ltr" }}
          color="gray.main"
        >
          {readOnlyValue}
        </Typography>
      );
    else if (type === "file")
      return <CebFileField multiple={multiple} errors={errors} {...props} />;
    else if (type === "text" && multipleRecords)
      return (
        <MultipleRecordsField
          control={control}
          fieldArr={fieldArr}
          onKeyDown={handleAddFieldItemByKey}
          onDelete={handleDeleteFieldItem}
          currentFieldValueKey={[currentFieldValue, setCurrentFieldValue]} //watched value
          nestedId={nestedId}
          resetNestedState={resetNestedState}
          errors={errors}
          {...props}
        />
      );
    else if (type === "button")
      return (
        <Button
          onClick={props.onClick}
          variant="contained"
          color="primary"
          style={{
            width: props.width,
            height: 41,
            borderRadius: 10,
            textTransform: "none",
            fontWeight: 500,
          }}
        >
          {t(props.buttonTitle)}
        </Button>
      );
    else if (type === "dateTime")
      return <CebDateTimeField control={control} {...props} errors={errors} />;
    else if (type === "time")
      return <CebTimeField control={control} {...props} errors={errors} />;
    else if (type === "element") return <>{props.element}</>;
    else return <CebTextField type={type} {...props} errors={errors} />;
  };

  return (
    <Grid
      container
      style={{ width: "100%", flexWrap: "nowrap" }}
      alignItems={multipleRecords || errors[props.name] ? "start" : "center"}
      my={0.5}
    >
      <Box width={210} mt={multipleRecords && 1} mr={1}>
        {label && (
          <Typography
            textAlign="start"
            style={{ fontWeight: childField ? 300 : 500 }}
          >
            {t(label)}
          </Typography>
        )}
      </Box>
      {/* <Grid item xs={childField? 7 : 8} textAlign='start'> */}
      <Box flexGrow={1} ml={1}>
        {addField()}
        {errors[props.name] && (
          <Box sx={{ mt: "4px", width: props.width && props.width }}>
            <Typography variant="error">
              {t(errors[props.name].message)}
            </Typography>
          </Box>
        )}
        {/* </Grid> */}
      </Box>

      {multipleRecords && (
        <Grid
          item
          xs={1}
          mt={multipleRecords && 0.7}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "start",
          }}
        >
          <CustomAddButton onClick={handleAddFieldItem} />
        </Grid>
      )}
    </Grid>
  );
}
