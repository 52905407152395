import React, { useContext, useEffect, useState } from "react";
import CebForm from "../../../components/Form/CebForm";
import { Context } from "../../../context/Context";
import useApi from "../../../hooks/useApi";
import mainApi from "../../../api/main";
import { useNavigate, useParams } from "react-router-dom";
import {
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  Stack,
  Box,
  Button,
} from "@mui/material";
import GeneralExamination from "../generalExamination/index";
import GeneralExaminationDetailView from "../generalExamination/DetailView";
import moment from "moment";
import usePermissions from "../../../hooks/usePermissions";
import { modules } from "../../../config/constants";
import { t } from "i18next";

const module = modules.ADMISSIOM;
const subModule = modules.ADMISSIOM_FOLLOWUP;

export default function Followup() {
  const navigate = useNavigate();
  const { patientId, admissionId } = useParams();
  const [viewMode, setViewMode] = useState("cards");

  const [resetForm, setResetForm] = useState();
  const [generalExaminationId, setGeneralExaminationId] = useState(null);
  const [encounterViewMode, setEncounterViewMode] = useState("encounter");
  const [chosenCardId, setChosenCardId] = useState(0);
  const [generalExaminationList, setGeneralExaminationList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);

  //Enums

  //api

  const createGeneralExamination = useApi(mainApi.createGeneralExamination);

  const getAdmissionFollowupList = useApi(mainApi.getAdmissionFollowupList);
  const addAdmissionFollowup = useApi(mainApi.addAdmissionFollowup);
  const getAdmissionFollowup = useApi(mainApi.getAdmissionFollowup);
  const getAdmission = useApi(mainApi.getAdmission);

  //permissions
  const permissions = usePermissions(module, subModule);

  //nav of notifications
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  useEffect(() => {
    if (urlParams.get("view")) handleViewCard({ id: urlParams.get("id") });
  }, [urlParams.get("view")]);

  useEffect(() => {
    if (permissions?.view)
      getAdmissionFollowupList.request(patientId, admissionId, pageNumber);
    getAdmission.request(patientId, admissionId);
  }, [addAdmissionFollowup.data, permissions, pageNumber]);

  const handleGeneralExamination = async () => {
    if (!generalExaminationId) {
      const res = await createGeneralExamination.request(patientId);
      if (res?.ok) {
        setGeneralExaminationId(res.data.data.id);
        setEncounterViewMode("generalExamination");
      }
    } else setEncounterViewMode("generalExamination");
  };

  const cardItems = [
    {
      header: "Date & Time",
      keyName: "data.created_at",
      type: "formattedDate",
      format: "DD-MM-YYYY, hh:mm a",
    },
    { header: "Enternat", keyName: "data.practitioner?.full_name" },
  ];

  const fields = [
    {
      label: "Date",
      type: "readOnly",
      value: moment(new Date()).format("DD-MM-YYYY, hh:mm a"),
    },

    {
      label: "Notes",
      name: "notes",
      multiline: true,
    },
    {
      type: "button",
      label: "General Examination",
      buttonTitle: "Open",
      onClick: handleGeneralExamination,
    },
  ];

  const data = [
    {
      label: "Notes",
      data: getAdmissionFollowup.data?.notes,
      multiline: true,
    },
    ...generalExaminationList,
  ];

  const handleViewCard = (card) => {
    setChosenCardId(card.id);
    getAdmissionFollowup.request(patientId, admissionId, card.id);
    setViewMode("list");
  };

  const onSubmit = async (data) => {
    const obj = {
      notes: data.notes,
      general_examination: generalExaminationId,
    };
    const res = await addAdmissionFollowup.requestWithToast(
      t("Added successfully"),
      patientId,
      admissionId,
      obj
    );
    if (res.ok) {
      if (permissions?.view) {
        setViewMode("cards");
        setPageNumber(1);
      }
      setResetForm(true);
    }
  };

  //printing
  const datatoPrint = {
    header: {
      title: "Follow-up",
      left: [
        moment(getAdmissionFollowup.data.created_at).format(
          "DD MMM YYYY hh:mm a"
        ),
        `Enternat: ${getAdmissionFollowup.data.practitioner?.full_name}`,
      ],
    },
    content: data.filter((item) => item.fieldType !== "labelField"),
  };

  return (
    <>
      <Stack
        gap={3}
        style={{
          display: encounterViewMode === "generalExamination" && "none",
        }}
      >
        <CebForm
          onSubmit={onSubmit}
          fields={fields}
          listData={data}
          cardsData={
            viewMode === "list"
              ? [getAdmissionFollowup.data]
              : getAdmissionFollowupList.data
          }
          cardItems={cardItems}
          viewModeState={[viewMode, setViewMode]}
          handleViewButton={handleViewCard}
          resetState={[resetForm, setResetForm]}
          loading={
            getAdmissionFollowupList.loading ||
            addAdmissionFollowup.loading ||
            getAdmission.loading ||
            getAdmissionFollowup.loading
          }
          chosenCardId={chosenCardId}
          disableAddNew={getAdmission.data?.discharge_date}
          requestErrors={addAdmissionFollowup.error}
          module={module}
          subModule={subModule}
          dataToPrint={datatoPrint}
          totalCount={getAdmissionFollowupList.count}
          paginationState={[pageNumber, setPageNumber]}
        />
      </Stack>
      {viewMode === "form" &&
        Object.keys(getAdmission.data).length > 0 &&
        !getAdmission.data?.discharge_date && (
          <Stack
            gap={3}
            style={{
              display: encounterViewMode === "encounter" && "none",
            }}
          >
            <GeneralExamination
              generalExaminationId={generalExaminationId}
              encounterViewModeKey={[encounterViewMode, setEncounterViewMode]}
            />
          </Stack>
        )}
      {viewMode === "list" && getAdmissionFollowup.data.general_examination && (
        <GeneralExaminationDetailView
          generalExaminationId={
            getAdmissionFollowup.data.general_examination.id
          }
          encounterViewModeKey={[encounterViewMode, setEncounterViewMode]}
          getListOnly={(list) => setGeneralExaminationList(list)}
        />
      )}
    </>
  );
}
