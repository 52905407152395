import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={20}
    height={20}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='m10.814 10.197-7.532 1.256a.5.5 0 0 0-.386.318L.3 18.728c-.248.64.421 1.25 1.035.943l18-9a.75.75 0 0 0 0-1.342l-18-9C.72.022.051.633.3 1.272l2.598 6.957a.5.5 0 0 0 .386.319l7.532 1.255a.2.2 0 0 1 0 .394Z'
      fill='#fff'
    />
  </svg>
);

export default SvgComponent;
