import React, { useEffect, useState } from "react";
import main from "../api/main";
import useApi from "../hooks/useApi";
import { getCurrentUser } from "../auth/authService";
import { modules } from "../config/constants";

export const Context = React.createContext(null);

export default ({ children }) => {
  const [viewMode, setViewMode] = useState("cards");
  const [permissions, setPermissions] = useState();
  const [toastClick, setToastClick] = useState(null);
  const [unreadNotificationCount, setUnreadNotificationCount] = useState();
  const [confirmAppointment, setConfirmAppointment] = useState(false);

  const getPermissions = async () => {
    console.log("get perm");
    try {
      const response = await main.getMyPermissions();
      if (!response.ok) throw new Error();
      const data = response.data?.data;
      const labPermission =
        data[modules.ORDER]?.[modules.ORDER]?.find(
          (item) => item.codename === "view_lab"
        ) ||
        data[modules.ADMISSIOM]?.[modules.ADMISSIOM_ORDER]?.find(
          (item) => item.codename === "view_lab"
        );
      const radioPermission =
        data[modules.ORDER]?.[modules.ORDER]?.find(
          (item) => item.codename === "view_radiology"
        ) ||
        data[modules.ADMISSIOM]?.[modules.ADMISSIOM_ORDER]?.find(
          (item) => item.codename === "view_radiology"
        );

      if (labPermission) {
        data[modules.LAB] = { [modules.LAB]: [labPermission] };
      }
      if (radioPermission) {
        data[modules.RADIO] = { [modules.RADIO]: [radioPermission] };
      }
      setPermissions(data);
      console.log("role perm", data);
    } catch {
      alert("something went wrong");
      console.log("cannot get my permissions");
    }
  };

  const getNotificationUnreadCount = async () => {
    const res = await main.getNotificationUnreadCount();
    if (res.ok) setUnreadNotificationCount(res.data?.count);
  };

  useEffect(() => {
    if (getCurrentUser()) getPermissions();
  }, []);

  // const [loggedIn, setLoggedIn] = React.useState(true)
  console.log("store perm", permissions);
  const store = {
    viewModeKey: [viewMode, setViewMode],
    // permissionsKey: [permissions, setPermissions],
    permissions,
    getPermissions: () => getPermissions(),
    toastClickKey: [toastClick, setToastClick],
    getNotificationUnreadCount: () => getNotificationUnreadCount(),
    unreadNotificationCount,
    setConfirmAppointment: (value) => setConfirmAppointment(value),
    confirmAppointment,
    // loggedInKey: [loggedIn, setLoggedIn],
  };

  return <Context.Provider value={store}>{children}</Context.Provider>;
};
