import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import mainApi from "../../../api/main";
import CebForm from "../../../components/Form/CebForm";
import useApi from "../../../hooks/useApi";
import { t } from "i18next";

const Head = ({
  generalExaminationId,
  encounterViewModeKey,
  detailView,
  tabState,
}) => {
  const { patientId } = useParams();
  const [viewMode, setViewMode] = useState(detailView ? "list" : "form");
  const [encounterViewMode, setEncounterViewMode] = encounterViewModeKey;
  const [tabId, setTabId] = tabState;
  const [success, setSuccess] = useState();
  const [submitType, setSubmitType] = useState();

  //api
  const addGeneralExaminationHead = useApi(mainApi.addGeneralExaminationHead);
  const getGeneralExaminationHead = useApi(mainApi.getGeneralExaminationHead);
  const getInspectionList = useApi(mainApi.getInspectionList);
  const getPalpationList = useApi(mainApi.getPalpationList);
  const getPercussionList = useApi(mainApi.getPercussionList);
  const getAuscultationList = useApi(mainApi.getAuscultationList);

  useEffect(() => {
    getInspectionList.request();
    getPalpationList.request();
    getPercussionList.request();
    getAuscultationList.request();
  }, []);

  useEffect(() => {
    if (viewMode === "list") {
      getGeneralExaminationHead.request(patientId, generalExaminationId);
    }
  }, [viewMode]);

  const fields = [
    {
      name: "inspection",
      label: "Inspection",
      type: "select",
      items: getInspectionList.data,
    },
    {
      name: "palpation",
      label: "Palpation",
      type: "select",
      items: getPalpationList.data,
    },
    {
      name: "percussion",
      label: "Percussion",
      type: "select",
      items: getPercussionList.data,
    },
    {
      name: "auscultation",
      label: "Auscultation",
      type: "select",
      items: getAuscultationList.data,
    },
  ];

  const data = [
    {
      data: getGeneralExaminationHead.data?.inspection,
      label: "Inspection",
    },
    {
      data: getGeneralExaminationHead.data?.palpation,
      label: "Palpation",
    },
    {
      data: getGeneralExaminationHead.data?.percussion,
      label: "Percussion",
    },
    {
      data: getGeneralExaminationHead.data?.auscultation,
      label: "Auscultation",
    },
  ];

  const onSubmit = async (data) => {
    const res = await addGeneralExaminationHead.requestWithToast(
      t("Added successfully"),
      patientId,
      generalExaminationId,
      data
    );
    setSuccess(res.ok);
  };

  useEffect(() => {
    console.log("submit", success, submitType);
    if (success && submitType) {
      if (submitType === "next") {
        setTabId((prev) => prev + 1);
        window.scrollTo(0, 0);
      } else {
        setEncounterViewMode("encounter");
      }
      setSuccess(false);
      setSubmitType(null);
    }
  }, [success, submitType]);

  return (
    <Stack gap={1}>
      <CebForm
        viewModeState={[viewMode, setViewMode]}
        fields={fields}
        listData={data}
        onSubmit={onSubmit}
        disableAddNew={true}
        nextButtonHandler={() => {
          setSubmitType("next");
        }}
        cancelButtonLabel="Back"
        saveHandler={() => setSubmitType("save")}
        cancelHandler={() => setEncounterViewMode("encounter")}
        requestErrors={addGeneralExaminationHead.error}
      />
    </Stack>
  );
};

export default Head;
