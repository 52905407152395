import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="M12 0 7.385 2.77 12 5.537V3.692c4.574 0 8.308 3.734 8.308 8.308S16.574 20.308 12 20.308 3.692 16.574 3.692 12c0-2.264.815-4.218 2.308-5.712L4.702 4.991C2.871 6.821 1.846 9.28 1.846 12c0 5.58 4.574 10.154 10.154 10.154S22.154 17.58 22.154 12 17.58 1.846 12 1.846V0Zm-1.933 6.058-1.673.808 2.337 4.615c-.071.164-.11.34-.116.519v.058l-2.971 2.97 1.328 1.329 2.972-2.971H12A1.385 1.385 0 0 0 13.385 12a1.37 1.37 0 0 0-1.01-1.327l-2.308-4.616Z"
        fill="#003468"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgComponent;
