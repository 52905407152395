import React, { useEffect, useState } from "react";
import {
  Stack,
  Button,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useParams } from "react-router-dom";

import CebForm from "../Form/CebForm";
import useApi from "../../hooks/useApi";
import mainApi from "../../api/main";
import FiberManualRecordSharpIcon from "@mui/icons-material/FiberManualRecordSharp";
import conditionalObj from "../../helpers/getConditionalObj";
import moment from "moment";
import Print from "../Print";
import { t } from "i18next";

const DrugsDetailView = ({
  prescriptionId,
  prescId,
  prescription,
  viewModeState,
  multiple,
  cardItems,
  cardsData,
  module,
  subModule,
  deactivationReason,
}) => {
  const [listData, setListData] = useState();
  const [chosenCardId, setChosenCardId] = useState(0);

  const { patientId, admissionId } = useParams();

  //api
  const getMealRelationList = useApi(mainApi.getMealRelationList);
  const getAdmissionPrescription = useApi(mainApi.getAdmissionPrescription);

  //helpers
  const createList = (prescr) => {
    console.log("pres", prescr);
    return [
      {
        data: (
          <Stack direction="column">
            <Stack direction="row" alignItems={"center"} gap={2}>
              <FiberManualRecordSharpIcon
                style={{ fontSize: 15, color: "black" }}
              />
              <Typography fontSize={"1.14rem"} fontWeight={400}>
                {`${prescr.generic_name}, ${prescr.route_administration}`}
              </Typography>
            </Stack>
            <Typography fontSize={"1.14rem"} fontWeight={400} marginLeft={4}>
              {`${prescr.num_units} unit, ${prescr.meal_relation}, ${
                prescr.frequency_rate
              } doses ${prescr.frequency_type !== "once" ? "every " : ""}${
                prescr.frequency_type
              }, for ${prescr.rate} ${prescr.rate_type}`}
            </Typography>
          </Stack>
        ),
        printDrug: `${prescr.generic_name}, ${prescr.route_administration} 
        ${prescr.num_units} unit, ${prescr.meal_relation}, ${
          prescr.frequency_rate
        } doses ${prescr.frequency_type !== "once" ? "every " : ""}${
          prescr.frequency_type
        }, for ${prescr.rate} ${prescr.rate_type}`,
      },
      conditionalObj(admissionId, {
        label: "Doses time",
        fieldType: "element",
        element: prescr.doses_time?.map((item, index) => (
          <ListItem key={index} value={item.time_string}>
            <ListItemIcon>
              <FiberManualRecordSharpIcon
                style={{
                  fontSize: 15,
                  color: moment().isAfter(moment(item.time)) ? "grey" : "black",
                }}
              />
            </ListItemIcon>
            <ListItemText
              primary={item.time_string}
              style={{
                color: moment().isAfter(moment(item.time)) ? "grey" : "black",
              }}
            />
          </ListItem>
        )),
        printDoses: prescr.doses_time?.map((item) => item.time_string),
      }),
      conditionalObj(admissionId, {
        label: "Comments",
        data: prescr.comments,
        multiline: true,
      }),
      conditionalObj(true, {
        label: "Deactivation reason",
        data: prescr.deactivation_reason,
        multiline: true,
      }),
    ];
  };

  const getPrescription = async () => {
    if (multiple) {
      const newPrescMultipleList = [];
      for (const prescr of prescription) {
        newPrescMultipleList.push(...createList(prescr));
      }
      if (deactivationReason) {
        newPrescMultipleList.push({
          label: "Deactivation reason",
          data: deactivationReason,
        });
      }
      setListData(newPrescMultipleList.filter((item) => item));
    } else {
      let prescr;
      if (prescriptionId && admissionId) {
        const res = await getAdmissionPrescription.request(
          patientId,
          admissionId,
          prescriptionId
        );
        prescr = res.data.data;
      } else prescr = prescription;
      setListData(createList(prescr).filter((item) => item));
    }
  };

  //useEffect
  useEffect(() => {
    getMealRelationList.request();
    setChosenCardId(prescriptionId || prescId);
  }, []);

  useEffect(() => {
    if (getMealRelationList.data.length > 0) getPrescription();
  }, [prescriptionId, getMealRelationList.data]);

  //printing

  const selectedCard = admissionId
    ? getAdmissionPrescription.data
    : cardsData.find((item) => item.id === chosenCardId);
  const drugList = listData
    ?.map((item) => item.printDrug)
    .filter((item) => item);
  const dataToPrint = {
    header: {
      title: admissionId ? "Medication" : "Prescription",
      left: [
        moment(selectedCard?.created_at).format("DD-MM-YYYY, h:mm a"),
        `Doctor: ${selectedCard?.practitioner?.full_name}`,
      ],
      right: admissionId ? [`Status: ${selectedCard?.state}`] : [],
    },
    content: [
      {
        label: "Medication",
        data: drugList,
      },
      admissionId && {
        label: "Doses time",
        data: listData?.find((item) => item.label === "Doses time")?.printDoses,
      },
      admissionId && listData?.find((item) => item.label === "Comments"),
    ].filter((item) => item),
  };

  console.log("prescr", prescription);

  return (
    <>
      <CebForm
        listData={listData}
        cardItems={cardItems}
        cardsData={admissionId ? [getAdmissionPrescription.data] : cardsData}
        viewModeState={viewModeState}
        chosenCardId={chosenCardId}
        module={module}
        subModule={subModule}
        dataToPrint={dataToPrint}
      />
    </>
  );
};

export default DrugsDetailView;
