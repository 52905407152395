import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.25 15.75h-4.5A2.25 2.25 0 0 0 7.5 18v1.5H9V18a.75.75 0 0 1 .75-.75h4.5A.75.75 0 0 1 15 18v1.5h1.5V18a2.25 2.25 0 0 0-2.25-2.25ZM12 15a3 3 0 1 0 0-5.999A3 3 0 0 0 12 15Zm0-4.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z"
      fill="#003468"
    />
    <path
      d="M18.75 3.75H16.5V3A1.5 1.5 0 0 0 15 1.5H9A1.5 1.5 0 0 0 7.5 3v.75H5.25a1.5 1.5 0 0 0-1.5 1.5V21a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5V5.25a1.5 1.5 0 0 0-1.5-1.5ZM9 3h6v3H9V3Zm9.75 18H5.25V5.25H7.5V7.5h9V5.25h2.25V21Z"
      fill="#003468"
    />
  </svg>
);

export default SvgComponent;
