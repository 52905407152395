import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={30}
    height={30}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m20.711 6.112 3.177 3.175m-1.134-5.973-8.59 8.591a3.177 3.177 0 0 0-.87 1.623L12.5 17.5l3.972-.795a3.174 3.174 0 0 0 1.623-.868l8.59-8.591a2.78 2.78 0 0 0-3.931-3.932v0Z"
      stroke="#F8F8F8"
      strokeWidth={4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.5 20.5V25a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V8.5a3 3 0 0 1 3-3h4.5"
      stroke="#F8F8F8"
      strokeWidth={4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgComponent;
