const selectFieldWithOther = (otherSelected, obj) => {

    if (otherSelected) return [
          obj,
          {
            label: 'Other',
            name: obj.otherName,
            validation: { required: true },
            childField: true,
          }
        ];
    return [obj];
  };
  
  export default selectFieldWithOther;