import { Typography } from "@mui/material";
import React, { useState, useEffect } from "react";

const Timer = ({ time = 600, resetTrigger }) => {
  const [seconds, setSeconds] = useState(time); // 10 minutes in seconds

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds > 1) {
          return prevSeconds - 1;
        } else {
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };
  useEffect(() => {
    // Reset the timer when resetTrigger changes
    setSeconds(time); // Reset to 10 minutes
  }, [resetTrigger, time]);

  return (
    <Typography sx={{ color: "darkred" }}>{formatTime(seconds)}</Typography>
  );
};

export default Timer;
