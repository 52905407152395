import React, { useContext, useEffect, useState } from "react";
import CebForm from "../../../components/Form/CebForm";
import { Context } from "../../../context/Context";
import useApi from "../../../hooks/useApi";
import mainApi from "../../../api/main";
import { useParams } from "react-router-dom";
import moment from "moment";
import auth from "../../../auth/authService";
import {
  Stack,
  Typography,
  Button,
  Pagination,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useToasts } from "react-toast-notifications";
import PatientCard from "../../../components/patient/PatientCard";
import Filter from "../../../assets/icons/Filter";
import { LoadingBox } from "../../../components/UI/LoadingBox";
import conditionalObj from "../../../helpers/getConditionalObj";
import usePermissions from "../../../hooks/usePermissions";
import { defaultPageSize, modules } from "../../../config/constants";
import Print from "../../../components/Print";
import { t } from "i18next";

const module = modules.ADMISSIOM;
const subModule = modules.ADMISSIOM_INSTRUCTION;

export default function InstructionsPage() {
  const { patientId, admissionId } = useParams();
  const [viewMode, setViewMode] = useState("cards");
  const [listData, setListData] = useState([]);
  const [watchedFields, setWatchedFields] = useState([]);
  const [currentInstructionsId, setCurrentInstructionsId] = useState();
  const [activeInstructions, setActiveInstructions] = useState([]);
  const [inActiveInstructions, setInActiveInstructions] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);

  //api
  const getInstructionsList = useApi(mainApi.getInstructionsList);
  const getInstruction = useApi(mainApi.getInstruction);
  const addInstruction = useApi(mainApi.addInstruction);
  const getAdmission = useApi(mainApi.getAdmission);
  const updateInstructionsStatus = useApi(mainApi.updateInstructionsStatus);

  const [resetForm, setResetForm] = useState();
  const { addToast } = useToasts();

  //mobile/web view
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));

  //permissions
  const permissions = usePermissions(module, subModule);

  useEffect(() => {
    if (
      viewMode === "cards" &&
      !permissions?.view &&
      permissions?.add &&
      Object.keys(getAdmission.data).length > 0 &&
      !getAdmission.data?.discharge_date
    ) {
      setViewMode("form");
    }
  }, [permissions, getAdmission.data]);

  //nav of notification
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  useEffect(() => {
    if (urlParams.get("view")) handleViewCard({ id: urlParams.get("id") });
  }, [urlParams.get("view")]);

  //useEffect
  useEffect(() => {
    getAdmission.request(patientId, admissionId);
  }, []);

  useEffect(() => {
    if (permissions?.view)
      getInstructionsList.request(patientId, admissionId, pageNumber);
  }, [
    addInstruction.data,
    updateInstructionsStatus.data,
    permissions,
    pageNumber,
  ]);

  useEffect(() => {
    const activeList = [];
    const inActiveList = [];
    for (const instr of getInstructionsList.data) {
      if (instr.status === "Active") activeList.push(instr);
      else inActiveList.push(instr);
    }
    setActiveInstructions(activeList);
    setInActiveInstructions(inActiveList);
  }, [getInstructionsList.data]);

  useEffect(() => {
    setListData(data);
  }, [getInstruction.data]);

  //helpers
  const handleActivation = async () => {
    let res;
    if (getInstruction.data.status === "Non-Active") {
      res = await updateInstructionsStatus.request(
        {
          status: "Active",
        },
        patientId,
        admissionId,
        currentInstructionsId
      );
    } else {
      res = await updateInstructionsStatus.request(
        {
          status: "Non-Active",
        },
        patientId,
        admissionId,
        currentInstructionsId
      );
    }
    if (res.ok) {
      addToast(
        `${
          getInstruction.data.status === "Non-Active"
            ? t("Activated successfully")
            : t("Deactivated successfully")
        }`,
        { appearance: "success", autoDismiss: true }
      );
      getInstruction.request(patientId, admissionId, currentInstructionsId);
      if (viewMode === "list") {
        setViewMode("cards");
      }
    }
  };

  const handleViewCard = (instruction) => {
    setViewMode("list");
    console.log("instruction", instruction);
    getInstruction.request(patientId, admissionId, instruction.id);
    setCurrentInstructionsId(instruction.id);
  };

  //fields and data
  const instructionsCardItems = [
    { header: null, keyName: "data.note" },
    // {
    //   header: null,
    //   type: "elementWithEval",
    //   element: (
    //     <Button
    //       variant="contained"
    //       color={
    //         getInstruction.data.status === "Non-Active"
    //           ? "success"
    //           : "secondary"
    //       }
    //       sx={{
    //         textTransform: "none",
    //         borderRadius: "10px",
    //       }}
    //       onClick={handleActivation}
    //     >
    //       {getInstruction.data.status === "Non-Active"
    //         ? "Activate"
    //         : "De-activate"}
    //     </Button>
    //   ),
    // },
  ];

  const detailCardItems = [
    ...instructionsCardItems,
    {
      header: null,
      type: "element",
      element: (
        <Button
          variant="contained"
          color={
            getInstruction.data.status === "Non-Active"
              ? "success"
              : "secondary"
          }
          sx={{
            textTransform: "none",
            borderRadius: "10px",
          }}
          onClick={handleActivation}
        >
          {getInstruction.data.status === "Non-Active"
            ? t("Activate")
            : t("De-activate")}
        </Button>
      ),
    },
  ];

  const fields = [
    {
      type: "readOnly",
      label: "Date",
      value: moment(new Date()).format("YYYY-MM-DD"),
    },
    {
      type: "readOnly",
      label: "Enternat",
      value: auth.getCurrentUser()?.full_name,
    },
    { label: "Notes", name: "note", multiline: true, cardItem: true },
    {
      type: "select",
      isEnum: true,
      items: ["Repeated", "Once"],
      label: "Occurrence",
      name: "occurrence",
      validation: { required: true },
    },
    conditionalObj(watchedFields[0] === "Once", {
      type: "select",
      isEnum: true,
      items: ["Routine", "Urgent", "Stat"],
      label: "Type",
      name: "once_type",
      validation: { required: true },
    }),
    {
      twoFieldsInRow: true,
      fields: [
        {
          condition: watchedFields[0] === "Repeated",
          type: "number",
          label: "Repeat Every",
          name: "repeat_rate",
          validation: { positive: true },
          width: 200,
        },
        {
          condition: watchedFields[0] === "Repeated",
          type: "select",
          isEnum: true,
          items: ["Days", "Hours", "Mins"],
          name: "repeat_type",
          width: 200,
        },
      ],
    },
    conditionalObj(
      (watchedFields[1] !== "Urgent" && watchedFields[1] !== "Stat") ||
        watchedFields[0] === "Repeated",
      {
        type: "dateTime",
        label:
          watchedFields[0] === "Once" && watchedFields[1] === "Routine"
            ? "Time"
            : "Starting Time",
        name: "date",
        validation: {
          maxDateField: "termination_date",
          disablePast: true,
          required: true,
        },
        defaultValue: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
      }
    ),
    conditionalObj(watchedFields[0] === "Repeated", {
      type: "date",
      label: "Termination Date",
      name: "termination_date",
      validation: { minDateField: "date", disablePast: true },
    }),
  ];

  const data = [
    // {
    //   fieldType: "header",
    //   title: "Instructions",
    //   actionBox: (
    //     <Stack direction="row" alignItems="center" gap={2}>
    //       <Typography>Status</Typography>
    //       <Typography color="#005AB3">{getInstruction.data.status}</Typography>
    //       <Button
    //         variant="outlined"
    //         color={
    //           getInstruction.data.status === "Non-Active"
    //             ? "success"
    //             : "secondary"
    //         }
    //         sx={{
    //           textTransform: "none",
    //           borderRadius: "10px",
    //         }}
    //         onClick={handleActivation}
    //       >
    //         {getInstruction.data.status === "Non-Active"
    //           ? "Activate"
    //           : "De-activate"}
    //       </Button>
    //     </Stack>
    //   ),
    // },
    {
      label: "Date & Time",
      data: moment(getInstruction.data.created_at).format("DD-MM-YYYY hh:mm a"),
    },
    { label: "Enternat", data: getInstruction.data.practitioner?.full_name },

    { label: "Note", data: getInstruction.data.note, cardItem: true },
    { label: "Occurrence", data: getInstruction.data.occurrence },
    getInstruction.data.occurrence === "Repeated" && {
      label: "Sequence",
      data:
        getInstruction.data.repeat_rate + " " + getInstruction.data.repeat_type,
    },
    getInstruction.data.occurrence === "Once" && {
      label: "Type",
      data: getInstruction.data.once_type,
    },
    getInstruction.data.once_type !== "Urgent" &&
      getInstruction.data.once_type !== "Stat" && {
        label:
          getInstruction.data.once_type !== "Routine"
            ? "Starting Time"
            : "Time",
        data: moment(getInstruction.data.date).format("YYYY-MM-DD, hh:mm"),
      },
    getInstruction.data.occurrence === "Repeated" && {
      label: "Termination Date",
      data: getInstruction.data.termination_date,
    },
  ].filter((item) => item);

  //submit
  const onSubmit = async (data) => {
    const res = await addInstruction.requestWithToast(
      t("Added successfully"),
      data,
      patientId,
      admissionId
    );
    if (res.ok) {
      if (permissions?.view) setViewMode("cards");
      setResetForm(true);
    }
  };

  //printing
  const dataToPrint = {
    header: {
      left: [
        moment(getInstruction.data.created_at).format("DD-MM-YYYY hh:mm a"),
        `Enternat: ${getInstruction.data.practitioner?.full_name}`,
      ],
      title: "Admission Instruction",
    },
    content: data?.filter(
      (item) => item.label !== "Date & Time" && item.label !== "Enternat"
    ),
  };

  if (
    getInstructionsList.loading ||
    getInstruction.loading ||
    addInstruction.loading ||
    getAdmission.loading
  )
    return <LoadingBox loading={true} />;

  return (
    <>
      {(viewMode === "form" || viewMode === "list") && (
        <CebForm
          onSubmit={onSubmit}
          fields={fields.filter((item) => item)}
          listData={listData}
          cardsData={
            viewMode === "list"
              ? [getInstruction.data]
              : getInstructionsList.data.map((item) => ({
                  ...item,
                  date: moment(item.date).format("DD-MM-YYYY, hh:mm a"),
                  created_at: moment(item.created_at).format(
                    "DD-MM-YYYY, hh:mm a"
                  ),
                }))
          }
          cardItems={
            viewMode === "cards" ? instructionsCardItems : detailCardItems
          }
          fieldsToWatch={{
            name: ["occurrence", "once_type"],
            defaultValue: [""],
          }}
          getWatchedFields={(fields) => setWatchedFields(fields)}
          viewModeState={[viewMode, setViewMode]}
          handleViewButton={handleViewCard}
          resetState={[resetForm, setResetForm]}
          disableAddNew={getAdmission.data?.discharge_date}
          loading={
            getInstructionsList.loading ||
            getInstruction.loading ||
            addInstruction.loading ||
            getAdmission.loading
          }
          chosenCardId={currentInstructionsId}
          requestErrors={addInstruction.error}
          module={module}
          subModule={subModule}
          dataToPrint={dataToPrint}
        />
      )}
      {viewMode === "cards" && permissions?.view && (
        <>
          <Stack direction="row" gap={2}>
            {!getAdmission.data?.discharge_date &&
              !mobileView &&
              permissions?.add && (
                <Button
                  variant="outlined"
                  onClick={() => setViewMode("form")}
                  style={{
                    padding: "5px 20px",
                    borderRadius: 10,
                    textTransform: "none",
                    fontWeight: 500,
                  }}
                >
                  {t("Add New")}
                </Button>
              )}
          </Stack>
          {activeInstructions?.length > 0 && (
            <>
              <Typography variant="body2">
                {t("Active Instructions")}
              </Typography>
              {activeInstructions.map((item, index) => (
                <PatientCard
                  items={instructionsCardItems}
                  data={item}
                  key={index}
                  index={index}
                  handleView={handleViewCard}
                />
              ))}
            </>
          )}

          {inActiveInstructions?.length > 0 && (
            <>
              <Typography variant="body2">
                {t("Non-Active Instructions")}
              </Typography>
              {inActiveInstructions.map((item, index) => (
                <PatientCard
                  items={instructionsCardItems}
                  data={item}
                  key={index}
                  index={index}
                  handleView={handleViewCard}
                />
              ))}
            </>
          )}
          {getInstructionsList.count > 0 && (
            <Stack alignItems={"center"} pt={4}>
              <Pagination
                dir="ltr"
                color="primary"
                count={Math.ceil(getInstructionsList.count / defaultPageSize)}
                onChange={(event, value) => {
                  setPageNumber(value);
                }}
                page={pageNumber}
              />
            </Stack>
          )}
        </>
      )}
      {viewMode === "cards" &&
        (!permissions?.view || getInstructionsList.data?.length === 0) && (
          <Typography variant="h5" color="fieldBorder" textAlign="center">
            {t("There is no data available")}
          </Typography>
        )}
    </>
  );
}
