import React, { useEffect } from "react";
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Stack, Box, Typography, Button, useMediaQuery } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import CustomAccordion from "../UI/CustomAccordion";
import theme from "../../styles/MUITheme";
import { useTheme } from "@emotion/react";
import { t } from "i18next";

const checkCondition = (item, data) => {
  if (item.hasOwnProperty("condition")) return eval(item.condition);
  else return true;
};

export default function PatientCard({
  data = {},
  index,
  items,
  handleView,
  onClickHandler,
  width = "100%",
  headersInCard = false,
  dark,
}) {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));

  const getValue = (item) => {
    switch (item.type) {
      case "element":
        return (
          <div
            onClick={(e) => {
              e.stopPropagation();
              item.onClickHandler && item.onClickHandler(data);
            }}
          >
            {item.element}
          </div>
        );
      case "button":
        return (
          <Button
            key={index}
            style={{
              background: "#A5D2FF",
              color: "#003468",
              padding: "10px",
              borderRadius: 10,
              fontWeight: 400,
            }}
            // stop propagation to prevent parent click that views card when pressing on the child
            onClick={(e) => {
              e.stopPropagation();
              onClickHandler(data);
            }}
          >
            {t(item.header)}
          </Button>
        );
      case "link":
        return (
          <a
            href={eval(item.keyName)}
            target="_blank"
            rel="noreferrer"
            style={{
              background: "#A5D2FF",
              color: "#003468",
              width: 140,
              height: 36,
              borderRadius: 10,
              fontSize: 14,
              fontWeight: 400,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textDecoration: "none",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            {" "}
            {item.title
              ? `${eval(item.title)?.substring(0, 13)}...`
              : "Document"}
          </a>
        );
      case "formattedDate":
        return (
          <div dir="ltr">
            <Typography
              textAlign={"center"}
              style={{ color: dark ? "#fff" : "#000" }}
            >
              {eval(item.keyName)
                ? moment(eval(item.keyName)).format(item.format)
                : "_"}
            </Typography>
          </div>
        );
      case "accordion":
        return (
          <CustomAccordion
            title={t(item.header)}
            items={eval(item.keyName)?.map(
              (el) => el.label || el.full_name || el.name
            )}
            dark={dark}
          />
        );
      case "status":
        return (
          <Box
            width={100}
            height={32}
            backgroundColor={eval(item?.statusColor)}
            borderRadius={10}
            display="flex"
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography textAlign={"center"} color="white" fontSize={16}>
              {eval(item.keyName)
                ? typeof eval(item.keyName) === "string"
                  ? t(eval(item.keyName))
                  : eval(item.keyName)
                : "_"}
            </Typography>
          </Box>
        );
      default:
        return (
          <div style={{ direction: "ltr" }}>
            <Typography
              style={{ color: dark ? "#fff" : "#000" }}
              textAlign={!mobileView ? "center" : "left"}
            >
              {eval(item.keyName) || eval(item.keyName) === 0
                ? typeof eval(item.keyName) === "string"
                  ? t(eval(item.keyName))
                  : eval(item.keyName)
                : "_"}
            </Typography>
          </div>
        );
    }
  };

  return (
    <>
      {!mobileView && (
        <Box
          position="relative"
          key={index}
          // value={data.id}
          sx={{
            width,
            minHeight:
              headersInCard && items.find((item) => item.header) ? 120 : 90,
            height:
              document.getElementsByClassName(".accordion-content")
                .clientHeight,
            backgroundColor: dark ? "primary.main" : "card.main",
            borderRadius: "20px",
            "&:hover": {
              backgroundColor: dark ? "primary.main" : "card.mainHover",
            },
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: !headersInCard && "pointer",
          }}
          onClick={handleView && (() => handleView(data))}
        >
          <Stack
            direction="row"
            justifyContent="space-around"
            alignItems="center"
            gap={2}
            sx={{
              width: "100%",
            }}
          >
            {items.map((item, index) => (
              <>
                {checkCondition(item, data) && (
                  <Stack
                    key={index}
                    value={item.header}
                    gap={2}
                    alignItems="center"
                    width={`${100 / items.length}%`}
                  >
                    {headersInCard && item.header && (
                      <Typography
                        variant="body2"
                        style={{
                          fontWeight: 500,
                          color: dark ? "#fff" : "#000",
                        }}
                        textAlign="center"
                      >
                        {t(item.header)}
                      </Typography>
                    )}
                    {getValue(item)}
                  </Stack>
                )}
              </>
            ))}
            {/* {handleView && (
          <Stack alignItems="center" justifyContent="center">
            <Button
              style={{
                width: 145,
                height: 46,
                textTransform: "none",
                fontWeight: 500,
              }}
              onClick={() => handleView(data)}
            >
              View
            </Button>
          </Stack>
        )} */}
          </Stack>
          {/* <IconButton style={{ position: 'absolute', right: 24, top: 40 }} onClick={() => navigate(`/patients/${card.id}/past-history`)}>
                <ArrowForwardIosIcon />
            </IconButton> */}
        </Box>
      )}
      {mobileView && (
        <Stack
          // border={"0.2px solid"}
          paddingY={2}
          width="100%"
          borderRadius={"20px"}
          direction="row"
          flexWrap="wrap"
          rowGap={2}
          sx={{
            backgroundColor: dark ? "primary.main" : "card.main",
            // boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
            cursor: !headersInCard && handleView && "pointer",
            "&:hover": handleView && {
              backgroundColor: dark ? "primary.main" : "card.mainHover",
            },
          }}
          onClick={handleView && (() => handleView(data))}
        >
          {items.map((item, index) => (
            <Stack key={index} width="50%" alignItems={"flex-start"} px={2}>
              {checkCondition(item, data) && (
                <>
                  <Typography
                    variant="body2"
                    mb={0.5}
                    style={{
                      fontWeight: 500,
                      color: dark ? "#fff" : theme.palette.primary.main,
                    }}
                  >
                    {t(item.header)}
                  </Typography>

                  {getValue(item)}
                </>
              )}
            </Stack>
          ))}
        </Stack>
      )}
    </>
  );
}

export function PatientCardTitles({
  items,
  width = "100%",
  // handleView=false,
  data,
}) {
  return (
    <Box
      position="relative"
      sx={{
        width,
        height: 67,
        borderRadius: "10px",
        border: "1px solid #003468",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "primary.main",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-around"
        alignItems="center"
        gap={2}
        sx={{
          width: "100%",
        }}
      >
        {items.map((item, index) => (
          <>
            {checkCondition(item, data) && (
              <Stack
                key={index}
                value={item.header}
                gap={2}
                alignItems="center"
                width={`${100 / items.length}%`}
              >
                <Typography
                  variant="body2"
                  style={{ fontWeight: 500 }}
                  textAlign="center"
                  color={"white"}
                >
                  {t(item.header)}
                </Typography>
              </Stack>
            )}
          </>
        ))}
        {/* empty stack to to make titles aligned with values when there is a view button */}
        {/* {handleView && (
          <Stack alignItems="center" justifyContent="center">
            <Stack
              style={{
                width: 145,
                height: 46,
              }}
            ></Stack>
          </Stack>
        )} */}
      </Stack>
    </Box>
  );
}
