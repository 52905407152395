//components
import React from "react";
import MainNav from "./components/Navigation/MainNav";

//MUI components
import { CssBaseline, Grid } from "@mui/material";

//styles
import { ThemeProvider } from "@mui/material/styles";
import "normalize.css";
import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { DefaultToast, ToastProvider } from "react-toast-notifications";
import "./App.css";
import Chat from "./components/ChatComponent";
import MainContainer from "./components/MainContainer";
import { Context } from "./context/Context";
import disableTypeNumberMouseWheel from "./helpers/disableTypeNumberMouseWheel";
import Theme from "./styles/MUITheme";
import { useTranslation } from "react-i18next";
import moment from "moment";

const loadLocale = async (locale) => {
  try {
    // Dynamically import the locale
    await import(`moment/locale/${locale}`);
    moment.locale(locale); // Set the locale in moment
  } catch (err) {
    console.error(`Failed to load ${locale} locale for moment.js:`, err);
  }
};

function App() {
  const { toastClickKey } = useContext(Context);
  const [toastClick, setToastClick] = toastClickKey;
  const { i18n } = useTranslation();

  let location = useLocation();

  useEffect(() => {
    disableTypeNumberMouseWheel();
  }, []);

  useEffect(() => {
    // loadLocale(localStorage.getItem("lng"));
    if (localStorage.getItem("lng") === "ar") {
      i18n.changeLanguage("ar");
    }
  }, []);

  const MyCustomToast = ({ children, ...props }) => {
    return (
      <div
        style={{
          // marginTop: toastClick?.notification ? "0" : "100%",
          cursor: "pointer",
        }}
        onClick={toastClick?.onToastClick}
      >
        <DefaultToast {...props}>{children}</DefaultToast>
      </div>
    );
  };

  return (
    <ThemeProvider theme={Theme}>
      <ToastProvider
        components={{ Toast: MyCustomToast }}
        // placement={toastClick?.notification ? "top-right" : "top-center"}
      >
        <CssBaseline />
        <Grid
          container
          direction="column"
          styles={{ minHeight: "100vh" }}
          sx={{ direction: i18n.language === "en" ? "ltr" : "rtl" }}
        >
          {/* {loggedIn?
                <>
                  <MainNav />
                  <MainContainer />
                </>
                :
                <LoginPage />
              } */}
          {/* {location.pathname !== "/login" &&
            !location.pathname.includes("reset-password") && (
              <MainNav websocket={webSocket} />
            )} */}
          <MainContainer />
          {/* <Chat /> */}
        </Grid>
      </ToastProvider>
    </ThemeProvider>
  );
}

export default App;
