import { Button, Stack } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import main from "../../api/main";
import fieldsMapper from "../../helpers/fieldsMapper";
import getBase64 from "../../helpers/getBase64";
import useApi from "../../hooks/useApi";
import ThirdNavTabs from "../Navigation/ThirdNavTabs";
import CebForm from "../Form/CebForm";
import ImagesGallery from "../ImagesGallery";
import { t } from "i18next";

// special examination form
const SpecialExamination = ({
  encounterViewModeKey,
  specialExaminationId,
  specialExaminationForm,
}) => {
  const [encounterViewMode, setEncounterViewMode] = encounterViewModeKey;

  //api
  // const getSpecialExaminationForm = useApi(main.getSpecialExaminationForm);

  // useEffect(() => {
  //   if (specialExaminationId) getSpecialExaminationForm.request();
  // }, [specialExaminationId]);

  return (
    <Stack>
      {specialExaminationId && specialExaminationForm?.length > 0 && (
        <CustomForm
          formId={specialExaminationForm?.[0]?.id}
          tabs={specialExaminationForm?.[0]?.tab_set}
          backHandler={() => setEncounterViewMode("encounter")}
          specialExaminationId={specialExaminationId}
        />
      )}
    </Stack>
  );
};

export default SpecialExamination;

// const CustomForm = ({
//   formId,
//   tabs = [],
//   backHandler,
//   specialExaminationId,
// }) => {
//   const [tabId, setTabId] = useState(0);

//   //api
//   const addSpecialExaminationTabValues = useApi(
//     main.addSpecialExaminationTabValues
//   );

//   const createFieldValue = async (field, fieldValue) => {
//     let value = fieldValue;
//     if (field?.type === "file") {
//       value = fieldValue?.[0] && (await getBase64(fieldValue?.[0]));
//     } else if (field?.type === "select" && field?.options?.multiple) {
//       value = fieldValue?.join(",");
//     }
//     return value
//       ? {
//           field: field.id,
//           value,
//         }
//       : null;
//   };

//   const onSubmitHandler = async (data, tab) => {
//     const singleFieldList = [];
//     const nestedFieldsList = [];
//     const fieldsData = {};
//     for (const field of tab?.field_set) {
//       fieldsData[field.id] = field;
//     }
//     console.log("data", data);
//     for (const key in data) {
//       const nestedFields = tab?.nestedfield_set?.find((item) => item.id == key);
//       const singleField = fieldsData[key];
//       if (nestedFields) {
//         const sets = [];
//         for (const item of data[key]) {
//           const set = [];
//           for (const itemKey in item) {
//             const obj = await createFieldValue(
//               fieldsData[itemKey],
//               item[itemKey]
//             );
//             set.push(obj);
//           }
//           if (set.length > 0) sets.push({ field_value_list: set });
//         }
//         if (sets.length > 0)
//           nestedFieldsList.push({
//             nested_field: nestedFields?.id,
//             field_value_list: sets,
//           });
//       } else if (singleField) {
//         const obj = await createFieldValue(singleField, data[key]);
//         if (obj) singleFieldList.push(obj);
//       }
//     }
//     console.log("field_value_list", singleFieldList);
//     console.log("nested_value_list", nestedFieldsList);
//     const body = {
//       field_value_list: singleFieldList,
//       nested_value_list: nestedFieldsList,
//     };
//     await addSpecialExaminationTabValues.requestWithToast(
//       "Saved successfully",
//       formId,
//       tab.id,
//       specialExaminationId,
//       body
//     );
//   };

//   return (
//     <>
//       {tabs.length > 0 && (
//         <Stack>
//           <ThirdNavTabs
//             tabState={[tabId, setTabId]}
//             tabs={tabs.map((item) => ({ name: item.tab_title }))}
//           />
//           <Stack mt={2}>
//             {tabs.map((item, index) => (
//               <div key={index} style={{ display: index !== tabId && "none" }}>
//                 <ViewCustomForm
//                   fields={item.field_set?.map((el) => ({
//                     ...el,
//                     type: el.type === "number" ? "decimal" : el.type,
//                   }))}
//                   groups={item.groupedfield_set?.map((el) => ({
//                     title: el.group_label,
//                     fields: el.field_set?.map((el) => `${el}`),
//                     images: el.imagepdf_set?.map((item) => item.image),
//                   }))}
//                   nestedForm={item.nestedfield_set?.map((el) => ({
//                     id: `${el.id}`,
//                     title: el.nestedfield_label,
//                     fields: el.field_set?.map((el) => `${el}`),
//                   }))}
//                   backHandler={backHandler}
//                   saveHandler={(data) => onSubmitHandler(data, item)}
//                   loading={addSpecialExaminationTabValues.loading}
//                 />
//               </div>
//             ))}
//           </Stack>
//         </Stack>
//       )}
//     </>
//   );
// };

const CustomForm = ({ backHandler, formId, tabs, specialExaminationId }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [tabsFields, setTabsFields] = useState({});
  const [tabId, setTabId] = useState();

  //api
  const getTabFields = useApi(main.getSpecialExaminationTabFields);
  const getTabSingleFields = useApi(main.getSpecialExaminationTabSingleFields);
  const addSpecialExaminationTabValues = useApi(
    main.addSpecialExaminationTabValues
  );

  const createFieldValue = async (field, fieldValue) => {
    let value = fieldValue;
    if (field?.type === "file") {
      value = fieldValue?.[0] && (await getBase64(fieldValue?.[0]));
    } else if (field?.type === "select" && field?.options?.multiple) {
      value = fieldValue?.join(",");
    }
    return value
      ? {
          field: field.id,
          value,
        }
      : null;
  };

  const onSubmitTabHandler = async (data, tab) => {
    const singleFieldList = [];
    const nestedFieldsList = [];
    const singleFieldsData = {};
    for (const field of tab?.field_set) {
      singleFieldsData[field.id] = field;
    }
    for (const group of tab?.groups) {
      for (const field of group?.field_set) singleFieldsData[field.id] = field;
    }
    for (const key in data) {
      const nestedFields = tab?.nested_fields?.find((item) => item.id == key);
      const singleField = singleFieldsData[key];
      if (nestedFields) {
        const sets = [];
        for (const item of data[key]) {
          const set = [];
          for (const itemKey in item) {
            const obj = await createFieldValue(
              nestedFields?.field_set?.find((el) => el.id == itemKey),
              item[itemKey]
            );
            set.push(obj);
          }
          if (set.length > 0) sets.push({ field_value_list: set });
        }
        if (sets.length > 0)
          nestedFieldsList.push({
            nested_field: nestedFields?.id,
            field_value_list: sets,
          });
      } else if (singleField) {
        const obj = await createFieldValue(singleField, data[key]);
        if (obj) singleFieldList.push(obj);
      }
    }
    const body = {
      field_value_list: singleFieldList,
      nested_value_list: nestedFieldsList,
    };
    await addSpecialExaminationTabValues.requestWithToast(
      t("Saved successfully"),
      formId,
      tab.id,
      specialExaminationId,
      body
    );
  };

  useEffect(() => {
    if (tabIndex >= 0 && tabs.length > 0) {
      const currentTabId = tabs[tabIndex].id;
      setTabId(currentTabId);
      if (!tabsFields[currentTabId]) {
        getTabFields.request(formId, currentTabId);
        getTabSingleFields.request(formId, currentTabId);
      }
    }
  }, [tabIndex, tabs]);

  useEffect(() => {
    if (getTabFields.data?.id)
      setTabsFields((old) => ({
        ...old,
        [getTabFields.data?.id]: getTabFields.data,
      }));
  }, [getTabFields.data]);

  return (
    <Stack>
      <ThirdNavTabs
        tabState={[tabIndex, setTabIndex]}
        tabs={tabs?.map((item) => ({ name: item.tab_title }))}
      />

      <Stack mt={2}>
        {Object.keys(tabsFields).map((key) => (
          <div
            key={key}
            style={{
              display: (getTabFields.loading || key != tabId) && "none",
            }}
          >
            <CustomFormFields
              tabFields={tabsFields[key]}
              backHandler={backHandler}
              onSubmitHandler={(data) =>
                onSubmitTabHandler(data, tabsFields[key])
              }
              conditionalFields={getTabSingleFields.data.filter(
                (item) => item?.options?.condition
              )}
            />
          </div>
        ))}
      </Stack>
    </Stack>
  );
};

export const CustomFormFields = ({
  tabFields,
  backHandler,
  onSubmitHandler,
  loading,
  conditionalFields,
}) => {
  const [viewMode, setViewMode] = useState("form");
  const [showConditionalFieldsIds, setShowConditinalFieldsIds] = useState([]);
  const [conditionFieldsNames, setConditionFieldsNames] = useState([]);
  const [conditionFieldsValues, setConditionFieldsValues] = useState([]);

  //get all fields names that have condition to hide/appear other fields
  useEffect(() => {
    const conditionsFields = conditionalFields.map(
      (item) => `${Object.keys(item.options.condition)[0]}`
    );
    setConditionFieldsNames(
      conditionsFields.filter(
        (value, index) => conditionsFields.indexOf(value) === index
      )
    );
  }, [conditionalFields]);

  //check conditional fields
  useEffect(() => {
    for (const index in conditionFieldsValues) {
      //get conditional fields related to this condition
      const currentConditionalFields = conditionalFields.filter(
        (item) => item.options.condition[conditionFieldsNames[index]] >= 0
      );
      for (const field of currentConditionalFields) {
        if (
          field.options.condition[conditionFieldsNames[index]] ===
          conditionFieldsValues[index]
        ) {
          setShowConditinalFieldsIds((old) => [...old, field.id]);
        } else {
          setShowConditinalFieldsIds((old) =>
            old.filter((item) => item !== field.id)
          );
        }
      }
    }
  }, [conditionFieldsValues.join()]);

  return (
    <CebForm
      fields={fieldsMapper(tabFields, showConditionalFieldsIds)}
      viewModeState={[viewMode, setViewMode]}
      saveButtonLabel="Save"
      cancelHandler={backHandler}
      loading={loading}
      cancelButtonLabel="back"
      // onSubmit={onSubmitHandler}
      onSubmit={(data) => {
        const body = {};
        for (const key in data) {
          if (
            !conditionalFields.find((item) => `${item.id}` === key) ||
            showConditionalFieldsIds.includes(Number(key))
          ) {
            body[key] = data[key];
          }
        }
        onSubmitHandler(body);
      }}
      fieldsToWatch={{ name: conditionFieldsNames }}
      getWatchedFields={(f) => setConditionFieldsValues(f)}
      // requestErrors={createTeam.error}
    />
  );
};

//special examination detail view
export const SpecialExaminationDetailView = ({
  specialExaminationId,
  encounterViewModeKey,
}) => {
  const [tabId, setTabId] = useState(0);
  const [encounterViewMode, setEncounterViewMode] = encounterViewModeKey;
  const [tabs, setTabs] = useState([]);

  //api
  const getSpecialExaminationValues = useApi(main.getSpecialExaminationValues);

  useEffect(() => {
    if (specialExaminationId) {
      getSpecialExaminationValues.request(specialExaminationId);
    }
  }, [specialExaminationId]);

  useEffect(() => {
    const tabsData = getSpecialExaminationValues.data?.form?.map((item) => ({
      id: item.id,
      name: item.tab_title,
      component: (encounterViewModeKey) => (
        <CustomDetailView
          encounterViewModeKey={encounterViewModeKey}
          singleFields={item?.fieldvalue_set}
          nestedFields={item?.fieldnestedvaluerelation_set}
          groups={item?.groups}
        />
      ),
    }));

    setTabs(tabsData);
  }, [getSpecialExaminationValues.data]);

  return (
    <>
      {tabs?.length > 0 && (
        <>
          <ThirdNavTabs
            tabState={[tabId, setTabId]}
            tabs={tabs}
            encounterViewMode={encounterViewMode}
          />
          {tabs.map((tab, index) => (
            <div key={index} style={{ display: index !== tabId && "none" }}>
              {tab.component(encounterViewModeKey)}
            </div>
          ))}
        </>
      )}
    </>
  );
};

const CustomDetailView = ({
  encounterViewModeKey,
  singleFields,
  nestedFields,
  groups,
}) => {
  const [viewMode, setViewMode] = useState("list");
  const [encounterViewMode, setEncounterViewMode] = encounterViewModeKey;
  const [listData, setListData] = useState([]);

  const createListItem = (field, fieldIndex) => {
    const label = field.field?.label;
    const index = fieldIndex || field?.field?.fieldvalueorder_set?.value_index;
    switch (field.field?.type) {
      case "file":
        return {
          index,
          label,
          data: { file: field?.value },
          fieldType: "link",
        };
      case "date":
        return {
          index,
          label,
          data: moment(field.value).format("DD-MM-YYYY"),
        };
      case "select": {
        if (field.field?.options?.multiple) {
          const selectedItemsIds = field.value?.split(",");
          const selectedItems = field.field?.options?.items?.filter((item) =>
            selectedItemsIds.includes(`${item.id}`)
          );
          return {
            index,
            label,
            data: selectedItems.map((item) => item.label),
          };
        } else {
          return {
            index,
            label,
            data: field.field?.options?.items?.find(
              (item) => `${item.id}` === field.value
            )?.label,
          };
        }
      }
      default:
        return {
          index,
          label,
          data: field.value,
        };
    }
  };

  const createListData = (filteredSingleFields, groupedFields) => {
    const list = [];
    for (const field of filteredSingleFields) {
      list.push(createListItem(field));
    }
    for (const nestedField of nestedFields) {
      const nestedSets = nestedField?.nested_value_list;
      const nestedTitle = nestedSets?.[0]?.nested_field?.nestedfield_label;
      const nestedIndex =
        nestedSets?.[0]?.nested_field?.fieldvalueorder_set?.value_index;
      if (nestedTitle) {
        list.push({
          fieldType: "labelField",
          data: nestedTitle,
          index: nestedIndex,
        });
      }
      let fieldIndex = nestedIndex + 0.01;
      for (let i = 1; i < nestedSets.length; i++) {
        const sortedFields = nestedSets[i].field_value_list.sort(
          (a, b) =>
            a.field?.fieldvalueorder_set?.value_index -
            b.field?.fieldvalueorder_set?.value_index
        );
        for (const j in sortedFields) {
          list.push(createListItem(sortedFields[j], fieldIndex));
          fieldIndex += 0.01;
        }
        list.push({
          index: fieldIndex,
          fieldType: "element",
          element: (
            <hr
              style={{
                color: "rgba(0,0,0,0.1)",
                width: i === nestedSets.length - 1 ? "500px" : "200px",
                marginTop: "-10px",
              }}
            ></hr>
          ),
        });
        fieldIndex += 0.01;
      }
    }
    for (const group of groupedFields) {
      list.push(
        { fieldType: "labelField", data: group.title, index: group.index },
        {
          index: group.index + 0.01,
          fieldType: "element",
          element: <ImagesGallery images={group.images} />,
        }
      );
      let fieldIndex = group.index + 0.02;
      for (const field of group.fields) {
        list.push(createListItem(field, fieldIndex));
        fieldIndex += 0.01;
      }

      list.push({
        index: fieldIndex,
        fieldType: "element",
        element: (
          <hr
            style={{
              color: "rgba(0,0,0,0.1)",
              width: "500px",
              marginTop: "-10px",
            }}
          ></hr>
        ),
      });
    }
    setListData(list.sort((a, b) => a.index - b.index));
  };

  const createFieldsValues = () => {
    let filteredSingleFields = [...singleFields];
    const groupedFields = [];
    for (const group of groups) {
      filteredSingleFields = filteredSingleFields.filter(
        (item) => !group.field_set.includes(item?.field?.id)
      );
      const newGroupFields = [];
      for (const fieldId of group.field_set) {
        const field = singleFields.find((item) => item.field?.id === fieldId);
        if (field) newGroupFields.push(field);
      }
      groupedFields.push({
        index: group.fieldvalueorder_set?.value_index,
        id: group.id,
        title: group.group_label,
        fields: newGroupFields.sort(
          (a, b) =>
            a.field?.fieldvalueorder_set?.value_index -
            b.field?.fieldvalueorder_set?.value_index
        ),
        images: group?.imagepdf_set?.map((item) => item.image),
      });
    }
    createListData(filteredSingleFields, groupedFields);
  };

  useEffect(() => {
    createFieldsValues();
  }, [singleFields, nestedFields, groups]);

  return (
    <Stack gap={1}>
      <CebForm
        viewModeState={[viewMode, setViewMode]}
        listData={listData}
        disableAddNew={true}
        backHandler={() => setEncounterViewMode("encounter")}
        // loading={getGeneralExamination.loading}
        module="Encounter"
        // dataToPrint={dataToPrint}
      />
    </Stack>
  );
};
