import { Stack } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import SecondaryNav from "../components/Navigation/SecondaryNav";
import PatientCard, {
  PatientCardTitles,
} from "../components/patient/PatientCard";
import mainApi from "../api/main";
import useApi from "../hooks/useApi";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import CebForm from "../components/Form/CebForm";
import Filter from "../assets/icons/Filter";
import Modal from "../components/UI/Modal";
import getBase64 from "../helpers/getBase64";
import OtpForm from "../components/patient/OtpForm";
import { useToasts } from "react-toast-notifications";
import authService, { getCurrentUser } from "../auth/authService";
import { Context } from "../context/Context";

export default function Permissions() {
  const [viewMode, setViewMode] = useState("form");
  const [openModal, setOpenModal] = useState(false);
  const [otpView, setOtpView] = useState(false);
  const [newPatientId, setNewPatientId] = useState();
  const [watchedFields, setWatchedFields] = useState([]);
  const [resetForm, setResetForm] = useState();
  const [addNewPatient, setAddNewPatient] = useState(false);
  const { toastClickKey } = useContext(Context);
  const [toastClick, setToastClick] = toastClickKey;
  let navigate = useNavigate();

  const { addToast } = useToasts();

  //Api
  const getPatientsListSecureSearch = useApi(
    mainApi.getPatientsListSecureSearch
  );
  const createPatientApproval = useApi(mainApi.createPatientApproval);

  const fields = [
    {
      name: "nid",
      label: "NID",
      type: "number",
      validation: { required: true, length: 14 },
    },
  ];

  const handleOpenModal = () => {
    if (openModal && otpView) {
      setOtpView(false);
    }
    setOpenModal((state) => !state);
  };

  //helpers
  const getWatchedFields = (f) => {
    setWatchedFields(f);
  };

  //submit
  const onSubmit = async (data) => {
    const res = await getPatientsListSecureSearch.request(
      `?username=P${data.nid}`
    );
    if (res?.ok) {
      if (res?.data?.data?.hasOwnProperty("id")) {
        createPatientApproval.request(res?.data?.data?.id);
        setNewPatientId(res?.data?.data?.id);
        setOtpView(true);
        handleOpenModal();
        setResetForm(true);
      } else {
        addToast("Patient is not registered", {
          appearance: "error",
          autoDismiss: true,
        });
        setToastClick({
          onToastClick: () => console.log("pressed"),
          notification: true,
        });
        setAddNewPatient(true);
      }
    }
  };

  return (
    <>
      <Stack
        sx={{
          px: 9,
          pt: 6,
          width: "100%",
        }}
        gap={5}
      >
        <Stack direction={"row"} justifyContent="space-between">
          <SecondaryNav />
          {addNewPatient && (
            <Stack direction="row" gap={2}>
              <Button
                variant="outlined"
                onClick={() => {
                  navigate("/patients/newPatient");
                }}
                style={{
                  padding: "5px 20px",
                  borderRadius: 10,
                  textTransform: "none",
                  fontWeight: 500,
                }}
              >
                Add New
              </Button>
            </Stack>
          )}
        </Stack>

        <CebForm
          fields={fields}
          viewModeState={[viewMode, setViewMode]}
          onSubmit={onSubmit}
          // fieldsToWatch={{ name: ["state"] }}
          // getWatchedFields={getWatchedFields}
          resetState={[resetForm, setResetForm]}
          // requestErrors={addPatient.error}
          disableCancelButton
          saveButtonLabel="Ask for permission"
        />
      </Stack>

      <Modal open={openModal} handleOpen={handleOpenModal}>
        <OtpForm
          enrollExistingPatient
          userId={newPatientId}
          handleOpenModal={handleOpenModal}
        />
      </Modal>
    </>
  );
}
