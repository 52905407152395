import React, { useContext, useEffect } from "react";

import ThirdNav from "../../../components/Navigation/ThirdNav";
import { Routes, Route, useNavigate } from "react-router-dom";
import ProtectedRoute from "../../../auth/protectedRoute";
import ProcedureSummary from "./Summary";
import PreAnaesthesia from "./PreAnaesthesia";
import ProcedureSurgicalData from "./SurgicalData";
import ProcedureAnaethesia from "./Anaethesia";
import usePermissions from "../../../hooks/usePermissions";
import { Context } from "../../../context/Context";
import { modules } from "../../../config/constants";

export default () => {
  const store = useContext(Context);
  const permissions = store?.permissions?.["Procedure"];
  const navigate = useNavigate();
  const navItems = [
    permissions?.[modules.PROCEDURE_SUMMARY] && {
      name: "Summary",
      link: "summary",
      element: <ProcedureSummary />,
    },
    permissions?.[modules.PROCEDURE_PREANAESTHESIA] && {
      name: "Preoperative Anaesthesia",
      link: "preoperative-anaesthesia",
      element: <PreAnaesthesia />,
      width: "300px",
    },
    permissions?.[modules.PROCEDURE_ANAESTHESIA] && {
      name: "Anaesthesia",
      link: "anaesthesia",
      element: <ProcedureAnaethesia />,
    },
    permissions?.[modules.PROCEDURE_SURGICAL_DATA] && {
      name: "Surgical Data",
      link: "surgical-data",
      element: <ProcedureSurgicalData />,
    },
  ].filter((item) => item);

  useEffect(() => {
    if (navItems.length > 0) {
      navigate(navItems[0].link);
    }
  }, []);

  return (
    <>
      <ThirdNav navItems={navItems} />
      <Routes>
        {navItems.map((item) => (
          <Route
            key={item.name}
            path={"/" + item.link}
            element={<ProtectedRoute />}
          >
            <Route path={"/" + item.link} element={item.element} />
          </Route>
        ))}
      </Routes>
    </>
  );
};
