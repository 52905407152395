import { useToasts } from "react-toast-notifications";
import { useState } from "react";
// import useAuth from "../auth/useAuth";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

const useApi = (apiFunc) => {
  const { addToast } = useToasts();
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState();
  //   const auth = useAuth();
  const navigate = useNavigate();

  const request = async (...args) => {
    setLoading(true);
    const response = await apiFunc(...args);
    setLoading(false);
    console.log("useApi", response);

    if (response === undefined) {
      addToast("Something went wrong", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }

    if (response.ok === false) {
      let message = "";
      // console.log('message', message)
      if (
        response?.status === 406 ||
        response?.status === 401 ||
        response?.status === 403
      )
        message = response?.data?.message;
      else if (response?.status === 400) {
        if (response?.data?.message) message = response?.data?.message;
        else message = t("Please enter the fields properly");
        setError(response?.data?.errors);
      } else if (response?.status === 500)
        message = t("Ops, something went wrong. Please try again");
      else message = JSON.stringify(response?.problem);

      // if (
      //   response.data?.code === "user_not_found" ||
      //   response.data?.detail ===
      //     "Authentication credentials were not provided."
      // ) {
      //   // auth.logOut(false);
      //   // navigation.reset({
      //   //   index: 0,
      //   //   routes: [
      //   //     { name: 'login'},
      //   //   ],
      //   // })
      // }

      if (message !== "")
        addToast(message, {
          appearance: "error",
          autoDismiss: true,
        });

      return response;
    }

    setError(!response.ok);
    if (response.status === 204) setData(response);
    else setData(response.data.data);
    setCount(response.data.count);
    return response;
  };

  const requestWithToast = async (successMssg, ...args) => {
    let res = await request(...args);
    if (res?.status === 200 || res?.status === 201) {
      addToast(successMssg, {
        appearance: "success",
        autoDismiss: true,
      });
    }

    return res;
  };

  const resetError = () => {
    setError(false);
  };

  return { data, error, loading, request, requestWithToast, resetError, count };
};

export default useApi;
