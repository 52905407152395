export const modules = {
  ADMISSIOM: "Admission",
  ADMISSIOM_INSTRUCTION: "Admission Instructions",
  ADMISSIOM_MEDICATION: "Admission Medication",
  ADMISSIOM_DISCHARGE: "Admission Discharge",
  ADMISSIOM_FOLLOWUP: "Admission Follow Ups",
  ADMISSIOM_ORDER: "Admission Order",
  ADMISSIOM_REFERRAL: "Admission Referral Request",
  ENCOUNTER: "Encounter",
  CONSENT: "Consent",
  ORDER: "Order",
  PAST_HISTORY: "Past History",
  PRESCRIPTION: "Prescription",
  PROCEDURE: "Procedure",
  PROCEDURE_SUMMARY: "Procedure Summary",
  PROCEDURE_PREANAESTHESIA: "Procedure Preoperative Anaesthesia",
  PROCEDURE_ANAESTHESIA: "Procedure Anaesthesia",
  PROCEDURE_SURGICAL_DATA: "Procedure Surgical Data",
  PATIENT: "Patient",
  LAB: "Lab",
  RADIO: "Radio",
  PATIENT: "Patient",
  PATIENT_FIELDS: "Patient Columns",
  EXTRA_PERMISSIONS: "Extra Permissions",
};

export const WEEK_DAYS = [
  {
    id: 0,
    label: "Monday",
  },
  { id: 1, label: "Tuesday" },
  { id: 2, label: "Wednesday" },
  { id: 3, label: "Thursday" },
  { id: 4, label: "Friday" },
  { id: 5, label: "Saturday" },
  { id: 6, label: "Sunday" },
];

export const defaultPageSize = 20;
