import { create } from "apisauce";
import auth from "../auth/authService";
import i18next from "i18next";
// import cache from "../utility/cache";
// import settings from "../config/settings";

export const socketBaseURL = process.env.REACT_APP_SOCKET_URL;

const apiClient = create({
  // eslint-disable-next-line
  baseURL: process.env.REACT_APP_BACKEND_URL + "/api",
  // baseURL: "https://01ea-41-41-220-25.eu.ngrok.io" + "/api",
});

// adding the token to the header of every request
apiClient.addAsyncRequestTransform(async (request) => {
  const departmentId = localStorage.getItem("dep_id");
  if (departmentId) {
    request.headers["Department-Id"] = departmentId;
  }
  if (i18next.language === "ar") request.headers["Accept-Language"] = "ar";
  const authToken = await auth.getJwt();
  if (!authToken) return;
  request.headers["Authorization"] = `JWT ${authToken}`;
});

/// caching all the data fetched from the backend(for 5 min), by editing the get request
// const get = apiClient.get;
// apiClient.get = async (url, params, axiosConfig) => {
//   const response = await get(url, params, axiosConfig);

//   if (response.ok) {
//     cache.store(url, response.data);
//     return response;
//   }

//   const data = await cache.get(url);
//   return data ? { ok: true, data } : response;
// };

export default apiClient;
