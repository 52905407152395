import React, { useState, useEffect } from "react";
import "./CebDateField.css";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import { useWatch } from "react-hook-form";

export default function CebTimeField({
  width = "100%",
  height = 41,
  control,
  name,
  validation,
  defaultValue,
  errors,
  type,
  ...props
}) {
  const {
    minTimeField,
    maxTimeField,
    disableFuture = false,
    disablePast = false,
  } = validation;

  const [minTime, setMinTime] = useState(validation.minTime);
  const [maxTime, setMaxTime] = useState(validation.maxTime);
  const [focused, setFocused] = useState(false);

  const watchedFields = useWatch({
    control,
    name: [minTimeField, maxTimeField],
  });

  useEffect(() => {
    if (watchedFields[0]) {
      setMinTime(new Date(watchedFields[0]));
    }
    if (watchedFields[1]) {
      setMaxTime(new Date(watchedFields[1]));
    }
  }, [watchedFields]);
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Controller
        name={name}
        control={control}
        defaultValue={
          defaultValue ? moment(defaultValue).format("HH:mm") : null
        }
        render={({ field: { onChange, value } }) => (
          <TimePicker
            value={value}
            onChange={(value) => {
              console.log("Time", value);
              return onChange(value);
            }}
            disablePast={disablePast}
            disableFuture={disableFuture}
            minTime={minTime}
            maxTime={maxTime}
            renderInput={(params) => (
              <TextField
                id="date"
                variant="outlined"
                sx={{
                  width,
                  height,
                  outline: "1.5px solid black",
                  borderRadius: "10px",
                  outlineColor: focused
                    ? "#2161d1"
                    : errors?.[name]
                    ? "red"
                    : "black",
                  outlineWidth: focused && "2px",
                }}
                size="small"
                autoComplete="bday"
                {...params}
                {...props}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
              />
            )}
          />
        )}
      />
    </LocalizationProvider>
  );
}
