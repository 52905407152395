import ImagesGallery from "../components/ImagesGallery";
import React from "react";

const getCebFormField = (field) => {
  const name = field.id ? `${field.id}` : field.label;
  const newField = {
    ...field,
    index: field.fieldcustomorder_set?.index,
    name,
    validation: field.type !== "date" ? field.options : {},
  };
  switch (field.type) {
    case "text":
      newField.multiline = field.options?.multiline ? true : false;
      break;
    case "date":
      if (field.options.required) newField.validation.required = true;
      if (field.options.minDate)
        newField.validation.minDate = new Date(field.options.minDate);

      if (field.options.maxDate)
        newField.validation.maxDate = new Date(field.options.maxDate);

      if (field.options.disableFuture) newField.validation.disableFuture = true;
      if (field.options.disablePast) newField.validation.disablePast = true;
      break;
    case "number":
      newField.type = "decimal";
      break;
    case "file":
      newField.acceptFiles = field.options?.acceptFiles;
      break;
    case "select":
      newField.items = field.options?.items;
      newField.multiple = field.options?.multiple;
    default:
      break;
  }
  return newField;
};

const fieldsMapper = (tabFields, showConditionalFieldsIds) => {
  let viewFields = [];
  for (const field of tabFields.field_set) {
    if (!field.options.condition || showConditionalFieldsIds.includes(field.id))
      viewFields.push(getCebFormField(field));
  }
  for (const group of tabFields.groups) {
    const groupFieldsArr = [];
    for (const field of group.field_set) {
      if (
        !field.options.condition ||
        showConditionalFieldsIds.includes(field.id)
      )
        groupFieldsArr.push(getCebFormField(field));
    }
    if (groupFieldsArr.length > 0) {
      const newGroupFields = {
        index: group.fieldcustomorder_set?.index,
        relatedFields: true,
        fieldsTitle: group.group_label,
        displayCondition: true,
        fields:
          group.imagepdf_set?.length > 0
            ? [
                {
                  type: "element",
                  element: (
                    <ImagesGallery
                      images={group.imagepdf_set.map((item) => item.image)}
                    />
                  ),
                },
                ...groupFieldsArr.sort((a, b) => a.index - b.index),
              ]
            : groupFieldsArr.sort((a, b) => a.index - b.index),
      };
      viewFields.push(newGroupFields);
    }
  }
  for (const nestedForm of tabFields.nested_fields) {
    const nestedFormFields = [];
    for (const index in nestedForm.field_set) {
      const field = nestedForm.field_set[index];
      if (
        !field.options.condition ||
        showConditionalFieldsIds.includes(field.id)
      )
        nestedFormFields.push(getCebFormField(field));
    }
    const sortedNestedFields = nestedFormFields.sort(
      (a, b) => a.index - b.index
    );
    const newNestedForm = {
      index: nestedForm.fieldcustomorder_set?.index,
      title: nestedForm.nestedfield_label,
      name: `${nestedForm.id}`,
      fieldType: "nested",
      nestedId: nestedForm.id,
      // primaryListText: {
      //   key: sortedNestedFields[0]?.label,
      //   value: `field[${sortedNestedFields[0]?.name}]`,
      // },
      fields: sortedNestedFields,
    };
    viewFields.push(newNestedForm);
  }

  return viewFields.sort((a, b) => a.index - b.index);
};

export default fieldsMapper;
