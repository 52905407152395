import { Stack, Box, useMediaQuery, Button, Drawer } from "@mui/material";
import React, { useState } from "react";
import PatientSideBar from "../../components/patient/PatientSideBar";
import PatientTabs from "../../components/patient/PatientTabs";
import SecondaryNav from "../../components/Navigation/SecondaryNav";
import theme from "../../styles/MUITheme";
import { useTranslation } from "react-i18next";

export default function SinglePatientPage(props) {
  const { i18n } = useTranslation();
  const [drawer, setDrawer] = useState(false);
  const tableView = useMediaQuery(theme.breakpoints.down("md"));

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawer(open);
  };

  return (
    <Stack
      sx={{
        pt: 6, // 6*8=48px
        pl: 2,
        pr: 2,
        pb: 2,
      }}
      width="100%"
      direction={"row"}
      gap={2}
    >
      {!tableView && (
        <Stack position={"absolute"}>
          <PatientSideBar />
        </Stack>
      )}

      <Drawer anchor={"left"} open={drawer} onClose={toggleDrawer(false)}>
        <PatientSideBar drawer toggleDrawer={toggleDrawer(false)} />
      </Drawer>

      <Stack
        gap={2}
        flex={1}
        sx={
          i18n.language === "en"
            ? {
                paddingLeft: "330px",
                "@media (max-width: 900px)": {
                  paddingLeft: "0px",
                },
              }
            : {
                paddingRight: "330px",
                "@media (max-width: 900px)": {
                  paddingRight: "0px",
                },
              }
        }
        width="100%"
        overflow={"auto"}
      >
        <Stack direction={"row"} gap={1} alignItems="center">
          {tableView && (
            <Button
              variant="contained"
              sx={{
                alignSelf: "flex-start",
                textTransform: "none",
                flexWrap: "nowrap",
                height: 30,
                minWidth: 110,
                fontSize: 14,
                borderRadius: "10px",
              }}
              onClick={toggleDrawer(true)}
            >
              Patient Info
            </Button>
          )}
          <SecondaryNav activePatientsTab />
        </Stack>

        <PatientTabs />
        {props.children}
      </Stack>
    </Stack>
  );
}
