import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.844.329 10.78 4.5H6.75V6h4.969l.937-1.922L14.79 9.75 17.625 6H21V4.5h-4.125l-1.664 2.156L12.844.328ZM4.875 8.25A1.873 1.873 0 0 0 3 10.125V21h3.75v-1.5h10.5V21H21v-8.25a2.261 2.261 0 0 0-2.25-2.25h-9v.422a2.945 2.945 0 0 0-1.5-.422c-.55 0-1.054.16-1.5.422v-.797A1.873 1.873 0 0 0 4.875 8.25Zm0 1.5c.161 0 .375.214.375.375V16.5H19.5v3h-.75V18H5.25v1.5H4.5v-9.375c0-.161.214-.375.375-.375ZM8.25 12c.838 0 1.5.662 1.5 1.5S9.088 15 8.25 15s-1.5-.662-1.5-1.5.662-1.5 1.5-1.5Zm3 0h7.5c.416 0 .75.334.75.75V15h-8.25v-3Z"
      fill="#003468"
    />
  </svg>
);

export default SvgComponent;
