import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.718 18C17.264 11.872 14.4 10.256 14.4 7.2V3.6h.6a.6.6 0 0 0 .6-.6V1.8a.6.6 0 0 0-.6-.6H9a.6.6 0 0 0-.6.6V3a.6.6 0 0 0 .6.6h.6v3.6c0 3.056-2.864 4.672-5.318 10.8C3.5 19.95 1.494 22.8 12 22.8s8.5-2.85 7.718-4.8Zm-1.94 2.101c-.842.252-2.519.539-5.778.539-3.26 0-4.936-.287-5.778-.539-.299-.089-.415-.435-.31-.753a18.26 18.26 0 0 1 1.694-3.746c2.275-3.79 4.635.597 6.6.597 1.964 0 1.425-1.873 2.188-.597a18.425 18.425 0 0 1 1.694 3.746c.105.318-.011.663-.31.753Z"
      fill="#003468"
    />
  </svg>
);

export default SvgComponent;
