import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Typography,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import FiberManualRecordSharpIcon from "@mui/icons-material/FiberManualRecordSharp";

import CebList from "../../../components/Form/CebList";
import useApi from "../../../hooks/useApi";
import mainApi from "../../../api/main";
import CebForm from "../../../components/Form/CebForm";
import { useParams } from "react-router-dom";
import DrugsForm from "../../../components/patient/DrugsForm";
import { Delete } from "@mui/icons-material";
import { LoadingBox } from "../../../components/UI/LoadingBox";
import usePermissions from "../../../hooks/usePermissions";
import { modules } from "../../../config/constants";
import moment from "moment";
import Print from "../../../components/Print";
import { t } from "i18next";

const module = modules.ADMISSIOM;
const subModule = modules.ADMISSIOM_DISCHARGE;

const DischargePage = () => {
  const { patientId, admissionId } = useParams();
  const [viewMode, setViewMode] = useState("form");
  const [preDiagnosis, setPreDiagnosis] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [prescriptionList, setPrescriptionList] = useState([]);
  const [discharge, setDischarge] = useState();

  //mobile/web view
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));

  //api
  const getAdmissionDischargeList = useApi(mainApi.getAdmissionDischargeList);
  const getAdmission = useApi(mainApi.getAdmission);
  const getFilteredDiseases = useApi(mainApi.getFilteredDiseases);
  const addAdmissionDischarge = useApi(mainApi.addAdmissionDischarge);

  //permissions
  const permissions = usePermissions(module, subModule);

  //helpers
  const handleOpenModal = () => {
    setOpenModal((state) => !state);
  };

  const deletePrescHandler = (index) => {
    const newList = prescriptionList.filter((f, i) => i !== index);
    setPrescriptionList(newList);
  };

  const prescriptionListElement = (list) => {
    if (list?.length > 0) {
      return list.map((item, index) => (
        <Stack key={index} direction="row" alignItems="center">
          <Typography>{`${item?.generic_name}, ${item?.route_administration} 
                  for ${item?.rate} ${item?.rate_type} - ${item?.frequency_rate} doses /${item?.frequency_type}`}</Typography>
          <IconButton onClick={() => deletePrescHandler(index)}>
            <Delete />
          </IconButton>
        </Stack>
      ));
    } else {
      return <div>{t("No Prescription")}</div>;
    }
  };

  const diseasesChangeHandler = (value) => {
    getFilteredDiseases.request(value);
  };

  //useEffect
  useEffect(() => {
    console.log("permissions", permissions);
    if (permissions?.view) {
      getAdmissionDischargeList.request(patientId, admissionId);
    }
    getAdmission.request(patientId, admissionId);
  }, [permissions]);

  useEffect(() => {
    if (getAdmission.data?.discharge_date) {
      setDischarge(getAdmissionDischargeList.data[0]);
      setViewMode("list");
    }
  }, [getAdmissionDischargeList.data, getAdmission.data]);

  useEffect(() => {
    if (viewMode === "form") {
      getFilteredDiseases.request("*");
    }
  }, [viewMode]);

  useEffect(() => {
    if (getAdmission.data?.diagnosis) {
      setPreDiagnosis(getAdmission.data.diagnosis.map((item) => item.label));
    }
  }, [getAdmission.data]);

  //fields and data

  const fields = [
    {
      label: "Pre-admission Diagnosis",
      name: "pre_diagnosis",
      type: "readOnly",
      value: preDiagnosis ? preDiagnosis.join("\r\n") : "",
    },
    {
      label: "Diagnosis",
      name: "diagnosis",
      type: "select",
      onInputChange: diseasesChangeHandler,
      items: getFilteredDiseases.data,
      multiple: true,
      autoComplete: true,
      disableFilter: true,
      validation: { required: true },
    },
    { label: "Discharge Data", name: "notes", multiline: true },
    { label: "Instructions", name: "instructions", multiline: true },
    {
      twoFieldsInRow: true,
      fields: [
        {
          label: "Prescription",
          type: "element",
          value: "Pres List",
          element: prescriptionListElement(prescriptionList),
          xs: 10,
        },
        {
          label: "",
          type: "element",
          xs: 2,
          element: (
            <IconButton
              sx={{
                width: 30,
                height: 30,
                backgroundColor: "primary.main",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#fff",
                "&:hover": {
                  color: "#000",
                },
              }}
              onClick={handleOpenModal}
            >
              +
            </IconButton>
          ),
        },
      ],
    },
  ];

  const data = [
    { label: "Pre-admission Diagnosis", data: preDiagnosis },
    {
      label: "Diagnosis",
      data: discharge?.diagnosis?.map((item) => item.label),
    },
    { label: "Discharge Data", data: discharge?.notes, multiline: true },
    { label: "Instructions", data: discharge?.instructions, multiline: true },
    {
      label: "Prescription",
      fieldType: "element",
      element: (
        <>
          {discharge?.prescription?.drugs?.length > 0 ? (
            <Stack>
              {discharge.prescription.drugs.map((item) => (
                <Stack direction="column" key={item.id} marginBottom={2}>
                  <Stack direction="row" alignItems={"center"} gap={2}>
                    <FiberManualRecordSharpIcon
                      style={{ fontSize: 15, color: "black" }}
                    />
                    <Typography fontSize={"1.14rem"} fontWeight={400}>
                      {`${item.generic_name}, ${item.route_administration}`}
                    </Typography>
                  </Stack>
                  <Typography
                    fontSize={"1.14rem"}
                    fontWeight={400}
                    marginLeft={4}
                  >
                    {`${item.meal_relation}, ${item.frequency_rate} doses/${item.frequency_type}, for ${item.rate} ${item.rate_type}`}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          ) : (
            <Typography>{t("No Prescription")}</Typography>
          )}
        </>
      ),
    },
  ];

  //onSubmit
  const onSubmitPrescription = (data) => {
    setOpenModal(false);
    setPrescriptionList((state) => [...state, ...data]);
  };

  const onSubmit = async (data) => {
    let obj = {
      ...data,
      // entity_department: 1,
      diagnosis: [...data.diagnosis.map((item) => item.id)],
      ...(prescriptionList.length > 0 && {
        prescription: { drugs: prescriptionList },
      }),
    };
    const res = await addAdmissionDischarge.requestWithToast(
      t("Created successfully"),
      patientId,
      admissionId,
      obj
    );
    if (res.ok) {
      if (permissions?.view)
        await getAdmissionDischargeList.request(patientId, admissionId);
      setViewMode("list");
    }
  };

  //printing
  const dataToPrint = {
    header: {
      left: [moment(discharge?.created_at).format("DD-MM-YYYY")],
      title: "Admission Discharge",
    },
    content: [
      ...data?.filter((item) => item.label !== "Prescription"),
      {
        label: "Prescription",
        data: discharge?.prescription?.drugs?.map(
          (item) =>
            `${item.generic_name}, ${item.route_administration} - ${item.meal_relation}, ${item.frequency_rate} doses/${item.frequency_type}, for ${item.rate} ${item.rate_type}`
        ),
      },
    ],
  };

  //render
  if (getAdmissionDischargeList.loading || getAdmission.loading) {
    return <LoadingBox loading={true} />;
  }

  return (
    <>
      {viewMode === "list" && permissions?.view ? (
        <>
          <Box
            width={970}
            sx={{
              border: "1px solid black",
              borderRadius: "10px",
              borderColor: "#00346880",
              p: 2,
              position: "relative",
              paddingBottom: 6,
            }}
          >
            <CebList data={data} />
            {dataToPrint && (
              <Print
                dataToPrint={dataToPrint}
                style={{
                  position: "absolute",
                  bottom: "15px",
                  right: "15px",
                }}
              />
            )}
          </Box>
        </>
      ) : (
        !getAdmission.data?.discharge_date &&
        permissions?.add &&
        !mobileView && (
          <>
            <DrugsForm
              openModal={openModal}
              handleOpenModal={handleOpenModal}
              onSubmitHandler={onSubmitPrescription}
            />
            <CebForm
              onSubmit={onSubmit}
              fields={fields}
              viewModeState={[viewMode, setViewMode]}
              loading={
                getAdmissionDischargeList.loading ||
                getAdmission.loading ||
                addAdmissionDischarge.loading
              }
              cancelHandler={() => setViewMode("create")}
              requestErrors={addAdmissionDischarge.error}
              module={module}
              subModule={subModule}
            />
          </>
        )
      )}
    </>
  );
};

export default DischargePage;
