import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={50}
    height={50}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <rect width={50} height={50} rx={25} fill='#8E0201' />
    <path
      d='M16.54 36.305a1.334 1.334 0 0 1-2.207-.981v-2.99a2.667 2.667 0 0 1-2.667-2.667v-9.334a2.667 2.667 0 0 1 2.667-2.666h14.666a2.667 2.667 0 0 1 2.667 2.666v9.334a2.666 2.666 0 0 1-2.667 2.666h-6.662l-5.796 3.972Zm1.126-4.124c.122 0 .24-.032.347-.092l3.14-2.184c.223-.155.489-.238.761-.238h5.752a1.333 1.333 0 0 0 1.333-1.334v-6.666a1.333 1.333 0 0 0-1.333-1.334h-12a1.334 1.334 0 0 0-1.333 1.334v6.666c0 .72.572 1.31 1.333 1.334.547.021.943.11 1.125.301.171.179.18.48.208 1.053.004.084.004.236 0 .494a.667.667 0 0 0 .667.666Zm15.333-6.493a9.662 9.662 0 0 0 0 .493v-.493c.027-.573.038-.875.208-1.053.183-.191.579-.28 1.126-.302.761-.024 1.333-.613 1.333-1.333v-6.667A1.333 1.333 0 0 0 34.333 15h-12a1.333 1.333 0 0 0-1.334 1.333h-2.666V15a2.667 2.667 0 0 1 2.666-2.667h14.667A2.666 2.666 0 0 1 38.333 15v9.333A2.667 2.667 0 0 1 35.666 27v2.99a1.333 1.333 0 0 1-2.208.982l-.459-.315v-4.969Z'
      fill='#fff'
    />
  </svg>
);

export default SvgComponent;
