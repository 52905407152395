import React from "react";

const SvgComponent = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.4 2.3999C5.16131 2.3999 4.93239 2.49472 4.7636 2.66351C4.59482 2.83229 4.5 3.06121 4.5 3.2999C4.5 3.5386 4.59482 3.76752 4.7636 3.9363C4.93239 4.10508 5.16131 4.1999 5.4 4.1999H18C18.2387 4.1999 18.4676 4.10508 18.6364 3.9363C18.8052 3.76752 18.9 3.5386 18.9 3.2999C18.9 3.06121 18.8052 2.83229 18.6364 2.66351C18.4676 2.49472 18.2387 2.3999 18 2.3999H5.4ZM12.5952 20.8199C12.5641 21.0458 12.4484 21.2515 12.2716 21.3954C12.0947 21.5393 11.8698 21.6107 11.6423 21.5951C11.4148 21.5796 11.2016 21.4783 11.046 21.3117C10.8903 21.145 10.8037 20.9255 10.8036 20.6975V8.4695L7.236 12.0323L7.1352 12.1199C6.95256 12.2557 6.72527 12.3172 6.49908 12.2921C6.2729 12.267 6.06461 12.1572 5.91616 11.9847C5.76771 11.8122 5.69011 11.5899 5.69898 11.3625C5.70786 11.1351 5.80255 10.9195 5.964 10.7591L11.112 5.6159C11.2019 5.53719 11.3068 5.47759 11.4204 5.4407L11.5164 5.4131C11.576 5.40042 11.6367 5.39399 11.6976 5.3939L11.7696 5.3963L11.8536 5.4059L11.9976 5.4419L12.1068 5.4875L12.1512 5.5115L12.2592 5.5835L12.3456 5.6579L17.436 10.7579L17.5236 10.8599C17.6515 11.0334 17.7129 11.2471 17.6966 11.462C17.6803 11.6769 17.5874 11.8789 17.4348 12.0311L17.334 12.1187C17.1605 12.2466 16.9469 12.308 16.7319 12.2917C16.517 12.2754 16.315 12.1825 16.1628 12.0299L12.6036 8.4659V20.6975L12.5952 20.8187V20.8199Z"
      fill="#003468"
    />
  </svg>
);

export default SvgComponent;
