//Patient Tabs Values
export const PAST_HISTORY = 1;
export const SUMMARY = 2;
export const ENCOUNTERS = 3;
export const ORDERS = 4;
export const RADIOLOGY = 5;
export const LABORATORY = 6;
export const ADMISSION = 7;
export const PRESCRIPTION = 8;
export const MEDIA = 9;
export const CONSENTS = 10;
export const PROCEDURES = 11;
export const REPORT_LETTERS = 12;
