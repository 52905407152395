import React from "react";
import { Box, CircularProgress } from "@mui/material";

export const LoadingBox = ({ addMode=false, loading }) => {
  return (
    <Box
      sx={{
        width: "100%",
        position: addMode && "fixed",
        top: addMode && "260px",
        left: addMode && "175px",
        display: "flex ",
        alignItems: "center",
        justifyContent: "center",
        display: loading ? "flex" : "none",
        // display: "flex",
        zIndex: 10000000
      }}
    >
      <CircularProgress style={{marginTop: '160px'}} />
    </Box>
  );
};
