import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { InputBase, TextareaAutosize, TextField } from "@mui/material";

const StyledTextField = ({
  register,
  name,
  multiline,
  onKeyDown,
  type,
  errors,
  setValue,
  trigger,
  ...props
}) => {
  return (
    <InputBase
      sx={{
        outline: "1.5px solid black",
        borderRadius: "10px",
        paddingX: 1.5,
        paddingY: 0.3,
        outlineColor: errors ? "red" : "black",
        width: props.width ? props.width : "100%",
        backgroundColor: "#fff",
      }}
      {...register(name)}
      {...(setValue &&
        trigger &&
        type === "number" && {
          onChange: (e) => {
            setValue(name, e.target.value.replace(/\D/g, ""));
            if (errors) trigger(name);
          },
        })}
      {...props}
      type={type === "decimal" ? "number" : "text"}
      inputProps={{
        step: type === "decimal" && "any",
      }}
      {...(type === "decimal" && {
        onWheel: (e) => e.target.blur(),
      })}
      multiline={multiline}
      minRows={2}
      onKeyDown={onKeyDown}
    />
  );
};

export default function CebTextField({
  width = "100%",
  arrName,
  onKeyDown,
  errors = {},
  ...props
}) {
  const styles = {
    textArea: {
      borderRadius: 10,
      // textAlign: 'start!important',
      border: `1px solid ${errors?.[props.name] ? "red" : "black"}`,
      padding: 10,
      fontFamily: `'Ubuntu', sans-serif`,
      fontSize: 16,
      // "&:hover": {
      //   border: `1.5px solid ${errors ? "red" : "#003468"}`,
      // },
      // "&.Mui-focused": {
      //   border: `1.5px solid ${errors ? "red" : "#003468"}`,
      // },
    },
  };

  if (props.multiline)
    return (
      <TextareaAutosize
        minRows={4}
        style={{ ...styles.textArea, width }}
        aria-label="maximum height"
        // onKeyDown={onKeyDown}
        {...props.register(props.name)}
        {...props}
      />
    );
  return (
    <>
      {arrName && <input hidden disabled {...props.register(arrName)} />}
      <StyledTextField
        variant="standard"
        onKeyDown={onKeyDown}
        // style={{ width, height: props.multiline ? "fit-content" : height }}
        width={width}
        {...props}
        errors={errors[props.name]}
      />
      {/* <TextField
        {...props.register(props.name)}
        {...props}
        style={{
          // border: "1px solid black",
          borderColor: props.errors?.[props.name] ? "red" : "black",
        }}
      /> */}
    </>
  );
}
