import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import mainApi from "../../../api/main";
import CebForm from "../../../components/Form/CebForm";
import useApi from "../../../hooks/useApi";
import { t } from "i18next";

const Mentality = ({
  generalExaminationId,
  encounterViewModeKey,
  detailView,
  tabState,
}) => {
  const { patientId } = useParams();
  const [viewMode, setViewMode] = useState(detailView ? "list" : "form");
  const [encounterViewMode, setEncounterViewMode] = encounterViewModeKey;
  const [tabId, setTabId] = tabState;
  const [success, setSuccess] = useState();
  const [submitType, setSubmitType] = useState();

  //api
  const addGeneralExaminationMentality = useApi(
    mainApi.addGeneralExaminationMentality
  );
  const getMentalityPerceptionList = useApi(mainApi.getMentalityPerceptionList);
  const getMentalityMoodList = useApi(mainApi.getMentalityMoodList);
  const getMentalityMemoryList = useApi(mainApi.getMentalityMemoryList);
  const getGeneralExaminationMentality = useApi(
    mainApi.getGeneralExaminationMentality
  );

  useEffect(() => {}, []);

  useEffect(() => {
    if (viewMode === "form") {
      getMentalityPerceptionList.request();
      getMentalityMoodList.request();
      getMentalityMemoryList.request();
    } else if (viewMode === "list") {
      getGeneralExaminationMentality.request(patientId, generalExaminationId);
    }
  }, [viewMode]);

  const fields = [
    {
      name: "gcs",
      label: "GCS",
      type: "number",
      validation: {
        minValue: 3,
        maxValue: 15,
        message: "Must be number between 3 and 15",
      },
    },
    {
      name: "orientation",
      label: "Orientation",
      type: "select",
      isEnum: true,
      items: ["Oriented", "Disoriented"],
    },
    {
      name: "memories",
      label: "Memory",
      type: "select",
      multiple: true,
      items: getMentalityMemoryList.data,
    },
    {
      name: "mood",
      label: "Mood",
      type: "select",
      items: getMentalityMoodList.data,
    },
    {
      name: "behavior",
      label: "Behavior",
      type: "select",
      isEnum: true,
      items: ["Normal", "Abnormal"],
    },
    {
      name: "perception",
      label: "Perception",
      type: "select",
      items: getMentalityPerceptionList.data,
    },
  ];

  const data = [
    {
      label: "GCS",
      data: getGeneralExaminationMentality.data?.gcs,
    },
    {
      label: "Orientation",
      data: getGeneralExaminationMentality.data?.orientation,
    },
    {
      label: "Memories",
      data: getGeneralExaminationMentality.data?.memories?.map(
        (item) => item.label
      ),
    },
    {
      label: "Mood",
      data: getGeneralExaminationMentality.data?.mood?.label,
    },
    {
      label: "Mood",
      data: getGeneralExaminationMentality.data?.behavior,
    },
    {
      label: "Perception",
      data: getGeneralExaminationMentality.data?.perception?.label,
    },
  ];

  const onSubmit = async (data) => {
    const res = await addGeneralExaminationMentality.requestWithToast(
      t("Added successfully"),
      patientId,
      generalExaminationId,
      data
    );
    setSuccess(res.ok);
  };

  useEffect(() => {
    console.log("submit", success, submitType);
    if (success && submitType) {
      if (submitType === "next") {
        setTabId((prev) => prev + 1);
        window.scrollTo(0, 0);
      } else {
        setEncounterViewMode("encounter");
      }
      setSuccess(false);
      setSubmitType(null);
    }
  }, [success, submitType]);

  return (
    <Stack gap={1}>
      <CebForm
        viewModeState={[viewMode, setViewMode]}
        fields={fields}
        listData={data}
        onSubmit={onSubmit}
        disableAddNew={true}
        nextButtonHandler={() => {
          setSubmitType("next");
        }}
        cancelButtonLabel="Back"
        saveHandler={() => setSubmitType("save")}
        cancelHandler={() => setEncounterViewMode("encounter")}
        requestErrors={addGeneralExaminationMentality.error}
      />
    </Stack>
  );
};

export default Mentality;
