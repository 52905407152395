import { modules } from "../config/constants";

const getModuleQuery = (module, id) => {
  let moduleLinkName = "summary";
  switch (module?.replace(/\s/g, "")?.toLowerCase()) {
    case modules.ADMISSIOM_INSTRUCTION.replace(/\s/g, "").toLowerCase():
      moduleLinkName = "instructions";
      break;
    case modules.ORDER.replace(/\s/g, "").toLowerCase():
      moduleLinkName = "orders";
      break;
    case "medication":
      moduleLinkName = "medications";
      break;
    case "drug":
      moduleLinkName = "medications";
      break;
    case modules.ADMISSIOM_FOLLOWUP.replace(/\s/g, "").toLowerCase():
      moduleLinkName = "followup";
      break;
    case modules.ADMISSIOM_DISCHARGE.replace(/\s/g, "").toLowerCase():
      moduleLinkName = "discharge";
      break;
    default:
      break;
  }
  return `${moduleLinkName}?view=true&id=${id}`;
};

export default (data) =>
  data.sub_module === modules.ADMISSIOM_REFERRAL
    ? `patients/referrals?id=${data.sub_module_id}`
    : `patients/${data.patient_id}/${data.patient_age}/${
        data.patient_gender
      }/admission/${data.admission_id}/${getModuleQuery(
        data.sub_module,
        data.sub_module_id
      )}`;
