import React, { useEffect, useState } from "react";
import CebForm from "../../../components/Form/CebForm";
import useApi from "../../../hooks/useApi";
import mainApi from "../../../api/main";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import CebList from "../../../components/Form/CebList";
import { LoadingBox } from "../../../components/UI/LoadingBox";
import Print from "../../../components/Print";
import { t } from "i18next";

const module = "Admission";
const subModule = "Admission Summary";

export default function ProcedureSummary() {
  const { patientId, procedureId, age, gender } = useParams();
  const [viewMode, setViewMode] = useState();
  const [summary, setSummary] = useState();
  const [preDiagnosis, setPreDiagnosis] = useState([]);
  const [postDiagnosis, setPostDiagnosis] = useState([]);
  const [diagnosisType, setDiagnosisType] = useState("both");
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  //mobile/web view
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));

  //api
  const getFilteredDiseases = useApi(mainApi.getFilteredDiseases);
  const addProcedureSummary = useApi(mainApi.addProcedureSummary);

  const handleProcedureView = async () => {
    const res = await mainApi.getProcedureSummary(patientId, procedureId);
    if (res.ok) {
      if (res.data?.data) {
        setSummary(res.data.data);
        setViewMode("list");
      } else {
        setViewMode("form");
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    handleProcedureView();
    getFilteredDiseases.request("*");
  }, [addProcedureSummary.data]);

  useEffect(() => {
    if (diagnosisType === "both") {
      setPostDiagnosis(getFilteredDiseases.data);
      setPreDiagnosis(getFilteredDiseases.data);
    } else if (diagnosisType === "pre") {
      setPreDiagnosis(getFilteredDiseases.data);
    } else {
      setPostDiagnosis(getFilteredDiseases.data);
    }
  }, [getFilteredDiseases.data, diagnosisType]);

  const filterDiseaseHandler = async (value, type) => {
    await getFilteredDiseases.request(value);
    setDiagnosisType(type);
  };

  const fields = [
    {
      type: "readOnly",
      label: "Date",
      name: "date",
      value: new Date().toLocaleString(),
    },
    {
      type: "select",
      label: "Preoperative Diagnosis",
      name: "preoperative_diagnosis",
      items: preDiagnosis,
      autoComplete: true,
      disableFilter: true,
      onInputChange: (value) => filterDiseaseHandler(value, "pre"),
    },
    {
      type: "select",
      label: "Postoperative Diagnosis",
      name: "postoperative_diagnosis",
      items: postDiagnosis,
      autoComplete: true,
      disableFilter: true,
      onInputChange: (value) => filterDiseaseHandler(value, "post"),
    },
    {
      label: "Prothesis",
      name: "prothesis",
      multiline: true,
    },
    {
      label: "Intraoperative Incidents",
      name: "intraoperative_incidents",
      multiline: true,
    },
    {
      label: "Recovery",
      name: "recovery",
      multiline: true,
    },
    {
      label: "Immediate Postop Followup",
      name: "immediate_postop_followup",
      multiline: true,
    },
  ];

  const data = [
    {
      label: "Procedure",
      data: `(${summary?.procedure?.procedure_code}) ${summary?.procedure?.procedure_name}`,
    },
    {
      label: "Date",
      data: moment(summary?.procedure.created_at).format("DD-MM-YYYY"),
    },
    {
      label: "Surgeons",
      data: summary?.procedure.surgeons.map((item) => item.full_name),
    },
    {
      label: "Assistants",
      data: summary?.procedure.assistant_doctors.map((item) => item.full_name),
    },

    {
      label: "Anaesthesia consultant",
      data: summary?.procedure.anaesthesia_consultants.map(
        (item) => item.full_name
      ),
    },

    {
      label: "Anaesthesia Specialist",
      data: summary?.procedure.anaesthesia_specialists.map(
        (item) => item.full_name
      ),
    },
    {
      label: "Scrub Nurses",
      data: summary?.procedure.scrub_nurses.map((item) => item.full_name),
    },
    {
      label: "Circulating Nurses",
      data: summary?.procedure.circulating_nurses.map((item) => item.full_name),
    },
    {
      label: "Anaesthsiology Nurses",
      data: summary?.procedure.anaesthesia_nurses.map((item) => item.full_name),
    },
    {
      label: "Preoperative Diagnosis",
      data: summary?.preoperative_diagnosis?.label,
    },

    {
      label: "Postoperative Diagnosis",
      data: summary?.postoperative_diagnosis?.label,
    },

    {
      label: "Prothesis",
      data: summary?.prothesis,
    },
    {
      label: "Intraoperative Incidents",
      data: summary?.intraoperative_incidents,
    },

    { label: "Recovery", data: summary?.recovery },

    {
      label: "Immediate postop Followup",
      data: summary?.immediate_postop_followup,
    },
  ];

  const onSubmit = async (data) => {
    const obj = {
      ...data,
      preoperative_diagnosis: data.preoperative_diagnosis.id,
      postoperative_diagnosis: data.postoperative_diagnosis.id,
    };

    const res = await addProcedureSummary.requestWithToast(
      t("Added successfully"),
      patientId,
      procedureId,
      obj
    );

    if (res.ok) {
      setViewMode("list");
    }
  };

  const dataToPrint = {
    header: {
      title: "Procedure Summary",
      left: [moment(summary?.procedure.created_at).format("DD MMM YYYY")],
    },
    content: data.filter((item) => item.label !== "Date"),
  };

  return (
    <>
      {loading && <LoadingBox loading={true} />}
      {viewMode === "form" && !mobileView && (
        <CebForm
          onSubmit={onSubmit}
          fields={fields}
          viewModeState={[viewMode, setViewMode]}
          formWidth="90%"
          cancelHandler={() =>
            navigate(`/patients/${patientId}/${age}/${gender}/procedures`)
          }
          requestErrors={addProcedureSummary.error}
        />
      )}
      {viewMode === "list" && (
        <Box
          width={970}
          sx={{
            border: "1px solid black",
            borderRadius: "10px",
            borderColor: "#00346880",
            p: 2,
            position: "relative",
            paddingBottom: 6,
          }}
        >
          <CebList data={data} />
          {dataToPrint && (
            <Print
              dataToPrint={dataToPrint}
              style={{
                position: "absolute",
                bottom: "15px",
                right: "15px",
              }}
            />
          )}
        </Box>
      )}
    </>
  );
}
