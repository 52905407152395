import React, { useContext, useEffect, useState } from "react";
import CebField from "./CebField";
import {
  Stack,
  Box,
  Button,
  Typography,
  Grid,
  Pagination,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import NestedForm from "./NestedForm";
import CebList from "./CebList";
import PatientCard, { PatientCardTitles } from "../patient/PatientCard";
import Filter from "../../assets/icons/Filter";
import { useForm, useWatch } from "react-hook-form";
import useYupResolver from "../../hooks/useYupResolver";
import { LoadingBox } from "../UI/LoadingBox";
import { Context } from "../../context/Context";
import { useNavigate, useParams } from "react-router-dom";
import usePermissions from "../../hooks/usePermissions";
import useApi from "../../hooks/useApi";
import main from "../../api/main";
import Print from "../Print";
import { useToasts } from "react-toast-notifications";
import { defaultPageSize } from "../../config/constants";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const getFieldsArr = (fields, conditionalFields) => {
  let arr = [];
  for (const field of fields) {
    if (field.relatedFields) {
      arr.push(...field.fields);
    } else {
      arr.push(field);
    }
  }
  // console.log("arr", arr);
  // arr = arr.filter(
  //   (field, index) =>
  //     (field?.hasOwnProperty("condition") === false ||
  //       field?.condition === true ||
  //       field?.condition?.[0] === true) &&
  //     (conditionalFields[index] || field.fieldType !== "nested")
  // );
  // console.log("arr2", arr);
  return arr;
};

export default function CebForm({
  onSubmit,
  viewModeState,
  fields = [],
  fieldsToWatch,
  getWatchedFields,
  initialValues,
  listData,
  cardsData,
  addInList = false,
  handleViewButton,
  handleClickCardButton,
  cardItems,
  modalForm = false,
  modalList = false,
  modalTitle,
  handleOpenModal,
  addNewHandler,
  formWidth = "97%",
  cardsWidth = "100%",
  resetState = [],
  disableAddNew,
  children,
  loading = false,
  chosenCardId = 0,
  saveHandler = null,
  cancelHandler,
  backHandler,
  nextButtonHandler,
  cancelButtonLabel,
  saveButtonLabel = "Save",
  requestErrors = {},
  disableFormButtons = false,
  disableCancelButton = false,
  module,
  subModule,
  dataToPrint,
  profile,
  profilePic,
  updateProfilePicHandler,
  totalCount,
  // onChangePage,
  // resetPagination,
  paginationState = [],
}) {
  const { t } = useTranslation();
  const [viewMode, setViewMode] = viewModeState;
  const [resetForm, setResetForm] = resetState;
  const [pageNumber, setPageNumber] = paginationState;
  const [resetNested, setResetNested] = useState({ nestedId: 0, state: false });
  // const [permissions, setPermissions] = useState();
  const [conditionalFields, setConditionalFields] = useState({});
  // const [submittedData, setSubmittedData] = useState({});
  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    getValues,
    setError,
    formState: { errors },
    trigger,
  } = useForm({
    mode: "onTouched",
    resolver: useYupResolver(
      getFieldsArr(fields).filter(
        (field, index) =>
          (field?.hasOwnProperty("condition") === false ||
            field?.condition === true ||
            field?.condition?.[0] === true) &&
          (conditionalFields[field.nestedId] || field.fieldType !== "nested")
      )
    ),
    shouldUnregister: false,
  });

  const navigate = useNavigate();
  const { addToast } = useToasts();

  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));

  //permissions

  const permissions = usePermissions(module, subModule);

  //check active admission
  const { patientId } = useParams();
  const [enableAddNew, setEnableAddNew] = useState(false);

  const hasActiveAdmissionApi = useApi(main.hasActiveAdmission);

  useEffect(() => {
    if (module) hasActiveAdmissionApi.request(patientId);
  }, [module]);

  useEffect(() => {
    if (
      localStorage.getItem("dep_id") ||
      hasActiveAdmissionApi.data === true ||
      module === "Admission"
    )
      setEnableAddNew(true);
  }, [hasActiveAdmissionApi.data]);

  console.log("ceb module", module, subModule);

  useEffect(() => {
    console.log("ceb perm", permissions);
  }, [permissions]);

  useEffect(() => {
    if (!loading && Object.keys(permissions)?.length > 0 && !profile) {
      if ((viewMode === "cards" || viewMode === "list") && !permissions?.view) {
        if (permissions?.add && !disableAddNew) {
          setViewMode("form");
        } else {
          setViewMode("forbidden");
        }
      }
    }
    console.log("view mode", viewMode);
  }, [viewMode, permissions, disableAddNew, loading]);

  const watchedFields = useWatch({
    control: control,
    name: fieldsToWatch?.name,
    defaultValue: fieldsToWatch?.defaultValue,
  });

  useEffect(() => {
    Object.keys(requestErrors).forEach((key) => {
      setError(
        key,
        { type: "focus", message: requestErrors[key][0] },
        { shouldFocus: true }
      );
    });
  }, [requestErrors]);

  useEffect(() => {
    if (watchedFields.length > 0) getWatchedFields(watchedFields);
  }, [watchedFields]);

  useEffect(() => {
    if (resetForm) {
      reset();
      setResetForm(false);
    }
  }, [resetForm]);

  const onChangeCondition = (value, index, parentIndex = 0, nestedId) => {
    console.log("nestedId", nestedId);
    setConditionalFields((state) => ({
      ...state,
      [nestedId]: value,
      // [index + parentIndex]: value,
    }));
  };

  const cebField = (f, nestedId, i) => (
    <CebField
      key={i}
      type={f.type ? f.type : "text"}
      childField={f.childField ? f.childField : false}
      multipleRecords={f.multipleRecords ? f.multipleRecords : false}
      multiple={f.multiple ? f.multiple : false}
      autoComplete={f.autoComplete ? f.autoComplete : false}
      isEnum={f.isEnum ? f.isEnum : false}
      width={f.width ? f.width : "100%"}
      multiline={f.multiline ? f.multiline : false}
      // rows={f.rows? f.rows : 1}
      height={f.height ? f.height : 41}
      items={f.items}
      idName={f.idName ? f.idName : "id"}
      control={control}
      register={register}
      setValue={setValue}
      reset={reset}
      name={f.name}
      label={t(f.label)}
      readOnlyValue={f.value}
      onInputChange={f.onInputChange ? f.onInputChange : () => void 0}
      errors={errors ? errors : {}}
      acceptFiles={f.acceptFiles}
      buttonTitle={f.buttonTitle}
      onClick={f.onClick}
      objectId={f.objectId}
      watch={watch}
      element={f.element}
      disabled={f.disabled ? f.disabled : false}
      sendOnUpload={f.sendOnUpload}
      url={f.url}
      addNewValue={f.addNewValue}
      validation={f.validation ? f.validation : {}}
      trigger={trigger}
      nestedId={nestedId}
      resetNestedState={[resetNested, setResetNested]}
      defaultValue={f.defaultValue}
      selectAll={f.selectAll ? f.selectAll : false}
      disableFilter={f.disableFilter}
    />
  );

  useEffect(() => {
    if (initialValues) {
      for (var key of Object.keys(initialValues)) {
        setValue(key, initialValues[key]);
      }
    }
  }, [initialValues]);

  const singleCebField = (f, nestedId, i) => {
    if (f.hasOwnProperty("condition")) {
      if (Array.isArray(f.condition))
        return f.condition[0] && cebField(f, nestedId, i);
      else return f.condition && cebField(f, nestedId, i);
    } else return cebField(f, nestedId, i);
  };

  const doubleCebFields = (f, nestedId, i) => (
    <Grid
      container
      key={i}
      style={{
        width: "100%",
        alignItems:
          errors[f.fields[0].name] || errors[f.fields[0].name]
            ? "flex-start"
            : "center",
      }}
    >
      <Grid
        item
        xs={f.fields[0].xs ? f.fields[0].xs : 6}
        style={{ display: "flex", alignItems: "center" }}
      >
        {singleCebField(f.fields[0], nestedId)}
      </Grid>
      <Grid
        item
        xs={f.fields[1].xs ? f.fields[1].xs : 6}
        style={{ display: "flex", alignItems: "center" }}
      >
        {singleCebField(f.fields[1], nestedId)}
      </Grid>
    </Grid>
  );

  const cebFieldFunc = (f, nestedId = 0, i) => {
    if (f.twoFieldsInRow === true) return doubleCebFields(f, nestedId, i);
    else
      return (
        <Grid container key={i} style={{ width: "100%" }}>
          <Grid item xs={f.xs ? f.xs : 12}>
            {singleCebField(f, nestedId, i)}
          </Grid>
        </Grid>
      );
  };

  const NestedFormFunc = (f, index, parentIndex) => {
    return (
      <NestedForm
        key={index}
        index={index}
        nestedId={f.nestedId}
        parentIndex={parentIndex}
        condition={f.condition}
        label={t(f.label)}
        title={t(f.title)}
        data={f.data}
        onAdd={f.onAdd}
        onDelete={f.onDelete}
        primaryListText={f.primaryListText}
        secondaryListText={f.secondaryListText}
        width={f.width}
        fields={f.fields.map((field) => ({
          ...field,
          name: `${f.name}_${field.name}`,
        }))}
        errors={errors}
        cebFieldFunc={cebFieldFunc}
        onChangeCondition={onChangeCondition}
        watch={watch}
        setValue={setValue}
        required={f.required}
        register={register}
        control={control}
        name={f.name}
        uniqueItems={f.uniqueItems}
        resetNested={(nestedId) => {
          setResetNested({ nestedId, state: true });
        }}
        disableNested={f.disableNested}
        onReset={f.onReset}
        // submittedData={submittedData}
      />
    );
  };

  const onSubmitForm = (data) => {
    // for (const index in conditionalFields) {
    //   if (conditionalFields[index]) {
    //     const additionalFields = {};
    //     const fieldsArr = getFieldsArr(fields);
    //     let adding = false;
    //     const filteredFieldsArr = fieldsArr.find(
    //       (item) => item.nestedId == index
    //     );
    //     if (filteredFieldsArr) {
    //       for (const field of filteredFieldsArr.fields) {
    //         const nestedFieldName = `${filteredFieldsArr.name}_${field.name}`;
    //         if (
    //           (Array.isArray(watch(nestedFieldName)) &&
    //             watch(nestedFieldName)?.length > 0) ||
    //           (!Array.isArray(watch(nestedFieldName)) && watch(nestedFieldName))
    //         )
    //           adding = true;
    //         if (
    //           field.type === "boolean" &&
    //           Array.isArray(watch(nestedFieldName))
    //         )
    //           additionalFields[field.name] = watch(nestedFieldName)[0];
    //         else additionalFields[field.name] = watch(nestedFieldName);
    //       }
    //     }
    //     if (adding)
    //       data[filteredFieldsArr.name] = [
    //         ...data[filteredFieldsArr.name],
    //         additionalFields,
    //       ];
    //   }
    // }
    const OpenNestedForm = Object.keys(conditionalFields).find(
      (key) => conditionalFields[key] === true
    );
    if (OpenNestedForm) {
      addToast("You have unsaved changes, please save or discard them", {
        autoDismiss: true,
      });
      return;
    }
    onSubmit(data);
    // setSubmittedData(data);
  };

  const addField = (field, index, parentIndex) => {
    if (field.fieldType === "nested")
      return NestedFormFunc(field, index, parentIndex);
    else if (field.fieldType === "buttons") return buttonFunc(field, index);
    else if (field.fieldType === "labelField")
      return (
        <div style={{ marginTop: "35px", marginBottom: "20px" }}>
          <Typography
            variant="h5"
            color="primary"
            sx={{
              fontWeight: "bold",
            }}
          >
            {t(field.label)}
          </Typography>
        </div>
      );
    else return cebFieldFunc(field, 0, index);
  };

  const buttonFunc = (field, i) => {
    return (
      <Stack key={i} display="flex" flexDirection="row">
        {field.fields.map((button, index) => (
          <Button
            key={index}
            onClick={button.onClick}
            variant="outlined"
            color="primary"
            style={{
              width: 197,
              height: 46,
              borderRadius: 10,
              textTransform: "none",
              fontWeight: 500,
              marginRight: 16,
            }}
          >
            {t(button.title)}
          </Button>
        ))}
      </Stack>
    );
  };

  const relatedFieldsStyle = (field) => {
    return field.addBorder === false
      ? {}
      : {
          border: "1px solid black",
          borderRadius: "10px",
          p: 2,
        };
  };

  const addRelatedFields = (fieldsObj, index) =>
    fieldsObj.displayCondition ? (
      <Box key={index} /*width="97%"*/ sx={relatedFieldsStyle(fieldsObj)}>
        <Stack gap={3}>
          {fieldsObj.fieldsTitle && (
            <Typography variant="h6" mb={2}>
              {t(fieldsObj.fieldsTitle)}
            </Typography>
          )}
          {fieldsObj.fields.map((field, i) => {
            return (
              // to use nested related fields, e.g.: adult female
              field.relatedFields === true
                ? addRelatedFields(field)
                : addField(field, i, index)
            );
          })}
        </Stack>
      </Box>
    ) : null;

  const addFormButtons = (cancelAction, type) => (
    <Stack direction={"row"} gap={2}>
      {!disableCancelButton && (
        <Button
          onClick={
            cancelHandler
              ? cancelHandler
              : permissions?.view || type === "modal"
              ? cancelAction
              : () => navigate("/patients/list")
          }
          variant="outlined"
          color="primary"
          style={{
            width: 135,
            height: 42,
            borderRadius: 10,
            textTransform: "none",
            fontWeight: 500,
          }}
        >
          {cancelButtonLabel
            ? t(cancelButtonLabel)
            : modalList
            ? t("Close")
            : t("Cancel")}
        </Button>
      )}
      {!modalList && (
        <Button
          type="submit"
          variant="contained"
          disabled={loading}
          color="primary"
          style={{
            // width: 145,
            minWidth: 135,
            height: 42,
            borderRadius: 10,
            textTransform: "none",
            fontWeight: 500,
          }}
          onClick={saveHandler}
        >
          {t(saveButtonLabel)}
        </Button>
      )}
    </Stack>
  );

  const checkKeyDown = (e) => {
    if (e.target.type === "textarea") return;
    if (e.code === "Enter") e.preventDefault();
  };

  if (viewMode === "forbidden")
    return (
      <Typography variant="h5" color="fieldBorder" textAlign="center">
        You don't have access to this page
      </Typography>
    );

  return (
    <Stack pb={3} gap={2}>
      {viewMode === "list" && listData && (permissions?.view || profile) && (
        <>
          {loading === true ? (
            <LoadingBox loading={loading} />
          ) : (
            <>
              {modalList && (
                <>
                  <Stack
                    display="flex"
                    flexDirection="row"
                    mb={3}
                    py={1}
                    borderBottom="2px solid lightgrey"
                    position="relative"
                  >
                    <Typography
                      justifySelf="start"
                      textAlign="start"
                      flexGrow={1}
                      style={{
                        fontSize: 20,
                        fontWeight: 600,
                        marginRight: "20px",
                      }}
                    >
                      {t(modalTitle)}
                    </Typography>
                    {addFormButtons(handleOpenModal)}
                  </Stack>
                  <Box
                    width={"100%"}
                    sx={{
                      /*border: "1px solid black", borderRadius: "10px",*/ p: 2,
                      paddingBottom: 6,
                    }}
                  >
                    {dataToPrint && (
                      <Print
                        dataToPrint={dataToPrint}
                        style={{
                          position: "absolute",
                          bottom: "30px",
                          right: "30px",
                        }}
                      />
                    )}
                    <CebList data={listData} profile={profile} />
                  </Box>
                </>
              )}
              {!modalList && (
                <>
                  <Stack direction="row" gap={2}>
                    {!addInList && (
                      <Button
                        variant="outlined"
                        onClick={
                          backHandler ? backHandler : () => setViewMode("cards")
                        }
                        style={{
                          padding: "5px 20px",
                          borderRadius: 10,
                          textTransform: "none",
                          fontWeight: 500,
                        }}
                      >
                        {t("Back")}
                      </Button>
                    )}
                    {/* disable buttons when field array is empty */}
                    {!disableAddNew &&
                      permissions?.add &&
                      fields.length > 0 &&
                      !mobileView &&
                      enableAddNew && (
                        <Button
                          variant="contained"
                          onClick={() => setViewMode("form")}
                          style={{
                            padding: "5px 20px",
                            borderRadius: 10,
                            textTransform: "none",
                            fontWeight: 500,
                          }}
                        >
                          {t("Add New")}
                        </Button>
                      )}
                  </Stack>
                  {listData.length > 0 ? (
                    <Box
                      width={"100%"}
                      sx={{
                        border: !profile && "1px solid black",
                        borderRadius: "10px",
                        p: 2,
                        position: "relative",
                        backgroundColor: profile && "card.main",
                        paddingBottom: 6,
                      }}
                    >
                      {dataToPrint && (
                        <Print
                          dataToPrint={dataToPrint}
                          style={{
                            position: "absolute",
                            bottom: "15px",
                            right: "15px",
                          }}
                        />
                      )}
                      <CebList
                        data={listData}
                        cardsData={cardsData}
                        chosenCardId={chosenCardId}
                        cardItems={cardItems}
                        onClickHandler={(card) => handleClickCardButton(card)}
                        profile={profile}
                        profilePic={profilePic}
                        updateProfilePicHandler={updateProfilePicHandler}
                      />
                    </Box>
                  ) : (
                    <Typography
                      variant="h5"
                      color="fieldBorder"
                      textAlign="center"
                    >
                      {t("There is no data available")}
                    </Typography>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}

      {viewMode === "form" && (
        <>
          <LoadingBox addMode={true} loading={loading} />
          <div dir={i18next.language === "en" ? "ltr" : "rtl"}>
            <form
              onSubmit={handleSubmit(onSubmitForm)}
              onKeyDown={(e) => checkKeyDown(e)}
            >
              {modalForm && (
                <>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    mb={3}
                    mx={6}
                    gap="20px"
                  >
                    <Typography
                      style={{
                        fontSize: 20,
                        fontWeight: 600,
                      }}
                    >
                      {t(modalTitle)}
                    </Typography>
                    {addFormButtons(handleOpenModal, "modal")}
                  </Stack>
                  <Box
                    sx={{
                      height: "2px",
                      width: "90%",
                      backgroundColor: "#D9D9D9",
                      ml: 4,
                      mr: 8,
                      mb: 6,
                    }}
                  />
                </>
              )}
              <Stack gap={2} px={modalForm && 4} width={formWidth}>
                {fields.map((field, index) => {
                  return field.relatedFields === true
                    ? addRelatedFields(field, index)
                    : addField(field, index);
                })}
              </Stack>

              {children}

              {!modalForm && (
                <>
                  {nextButtonHandler && (
                    <Stack
                      display="flex"
                      flexDirection="row"
                      justifyContent="end"
                      my={3}
                      style={{ width: formWidth }}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={loading}
                        color="primary"
                        style={{
                          width: 145,
                          height: 46,
                          borderRadius: 10,
                          textTransform: "none",
                          fontWeight: 500,
                        }}
                        onClick={nextButtonHandler}
                      >
                        {t("Next")}
                      </Button>
                    </Stack>
                  )}

                  {!disableFormButtons && (
                    <Stack
                      display="flex"
                      flexDirection="row"
                      justifyContent="end"
                      my={3}
                      style={{ width: formWidth }}
                    >
                      {addFormButtons(() =>
                        setViewMode(addInList ? "list" : "cards")
                      )}
                    </Stack>
                  )}
                </>
              )}
            </form>
          </div>
        </>
      )}

      {viewMode === "cards" && cardsData && (
        <>
          {loading === true ? (
            <LoadingBox loading={loading} />
          ) : (
            <>
              {/* disable buttons when fields array is empty */}

              <Stack direction="row" gap={2}>
                {!disableAddNew &&
                  fields.length > 0 &&
                  permissions?.add &&
                  !mobileView &&
                  enableAddNew && (
                    <Button
                      variant="outlined"
                      onClick={() =>
                        addNewHandler ? addNewHandler() : setViewMode("form")
                      }
                      style={{
                        padding: "5px 20px",
                        borderRadius: 10,
                        textTransform: "none",
                        fontWeight: 500,
                      }}
                    >
                      {t("Add New")}
                    </Button>
                  )}
              </Stack>

              <Stack gap={2}>
                {cardsData.map((card, index) => (
                  <>
                    {index === 0 && !mobileView && (
                      <PatientCardTitles
                        items={cardItems}
                        width={cardsWidth}
                        // handleView={true}
                        data={card}
                      />
                    )}
                    <PatientCard
                      key={index}
                      items={cardItems}
                      data={card}
                      index={index}
                      width={cardsWidth}
                      onClickHandler={() => handleClickCardButton(card)}
                      handleView={() => handleViewButton(card)}
                    />
                  </>
                ))}
                {(cardsData.length === 0 ||
                  cardsData === undefined ||
                  cardsData === null ||
                  cardsData == {}) && (
                  <Typography
                    variant="h5"
                    color="fieldBorder"
                    textAlign="center"
                  >
                    {t("There is no data available")}
                  </Typography>
                )}
              </Stack>
            </>
          )}

          {totalCount > 0 && paginationState?.length > 0 && (
            <Stack alignItems={"center"} pt={4}>
              <Pagination
                dir="ltr"
                color="primary"
                count={Math.ceil(totalCount / defaultPageSize)}
                onChange={(event, value) => {
                  // onChangePage(value);
                  setPageNumber(value);
                }}
                page={pageNumber}
              />
            </Stack>
          )}
        </>
      )}
    </Stack>
  );
}
