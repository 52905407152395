import { Pagination, Stack, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import SecondaryNav from "../components/Navigation/SecondaryNav";
import PatientCard, {
  PatientCardTitles,
} from "../components/patient/PatientCard";
import mainApi from "../api/main";
import useApi from "../hooks/useApi";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import CebForm from "../components/Form/CebForm";
import Filter from "../assets/icons/Filter";
import Modal from "../components/UI/Modal";
import getBase64 from "../helpers/getBase64";
import OtpForm from "../components/patient/OtpForm";
import { useToasts } from "react-toast-notifications";
import usePermissions from "../hooks/usePermissions";
import { defaultPageSize, modules } from "../config/constants";
import Referral from "./Referral";
import AdvancedFilter from "../components/AdvancedFilter";
import { useTranslation } from "react-i18next";

export default function PatientsPage({ createPatient = false }) {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const [viewMode, setViewMode] = useState("form");
  const [openModal, setOpenModal] = useState(false);
  const [otpView, setOtpView] = useState(false);
  const [newPatientId, setNewPatientId] = useState();
  const [watchedFields, setWatchedFields] = useState([]);
  const [resetForm, setResetForm] = useState();
  const [dischargedFilter, setDishcargedFilter] = useState(false);
  const [myReferralToggle, setMyReferralToggle] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const { patientsViewMode } = useParams();

  const { addToast } = useToasts();

  //mobile/web view
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const tableView = useMediaQuery(theme.breakpoints.down("md"));

  //Api
  const getPatients = useApi(mainApi.getPatients);
  const getStates = useApi(mainApi.getStates);
  const getAreas = useApi(mainApi.getAreas);
  const addPatient = useApi(mainApi.addPatient);
  const getMyTeam = useApi(mainApi.getMyTeam);

  useEffect(() => {
    getMyTeam.request();
  }, []);

  // get patients cards displayed columns
  // get permissions to the following types of patients: admitted, discharged, all
  // get destination to navigate to

  //permissions
  const permissions = {
    ...usePermissions(modules.PATIENT, modules.PATIENT_FIELDS),
    ...usePermissions(modules.PATIENT),
  };

  const referralPermissions = usePermissions(
    modules.ADMISSIOM,
    modules.ADMISSIOM_REFERRAL
  );

  const entityItems = [
    permissions["Name"] && { header: t("Name"), keyName: "data.full_name" },
    permissions["Age"] && { header: t("Age"), keyName: "data.age" },
    permissions["MRN"] && { header: t("MRN"), keyName: "data.username" },
    permissions["Responsible Physicians"] && {
      header: t("Responsible Physicians"),
      keyName: "data.admission?.doctors",
      type: "accordion",
    },
    permissions["Date of Admission"] &&
      !dischargedFilter && {
        header: t("Date of Admission"),
        keyName: "data.admission?.created_at",
        type: "formattedDate",
        format: "DD-MM-YYYY hh:mm A",
      },
    permissions["Date of Discharge"] &&
      dischargedFilter && {
        header: t("Date of Discharge"),
        keyName: "data.admission?.discharge_date",

        type: "formattedDate",
        format: "DD-MM-YYYY hh:mm A",
      },
    permissions["Diagnosis"] && {
      header: t("Diagnosis"),
      keyName: "data.admission?.diagnosis",
      type: "accordion",
    },
    permissions["Location"] && {
      header: t("Location in hospital"),
      keyName: "data.admission?.admitted_to_layer?.name",
    },
  ].filter((item) => item);

  const fields = [
    {
      name: "full_name",
      label: "Full name",
      validation: { required: true },
    },
    {
      name: "birth_date",
      label: "Birthday",
      type: "date",
      validation: { required: true, disableFuture: true },
    },
    {
      name: "gender",
      label: "Gender",
      type: "select",
      isEnum: true,
      items: ["male", "female"],
      validation: { required: true },
    },
    {
      name: "phone_number",
      label: "Mobile number",
      type: "number",
      validation: {
        required: true,
        length: 11,
        message: t("Enter valid mobile number"),
      },
    },
    {
      twoFieldsInRow: true,
      fields: [
        {
          name: "nid",
          label: "NID",
          type: "number",
          validation: { required: true, length: 14 },
          xs: 9.5,
        },
        {
          name: "username_pic",
          label: "",
          type: "file",
          acceptFiles: ["image/jpg", "image/jpeg", "image/png"],
          validation: { required: true },
          xs: 2.5,
        },
      ],
    },
    { name: "email", label: "Email", type: "email" },
    {
      name: "marital_status",
      label: "Marital status",
      type: "select",
      isEnum: true,
      items: ["single", "married"],
    },
    {
      name: "blood_type",
      label: "Blood type",
      type: "select",
      isEnum: true,
      items: ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"],
    },
    {
      name: "occupation",
      label: "Occupation",
      validation: { required: true },
    },
    {
      name: "state",
      label: "City",
      type: "select",
      items: getStates.data,
      validation: { required: true },
    },
    {
      name: "area",
      label: "Area",
      type: "select",
      items: getAreas.data,
      validation: { required: true },
    },
    {
      name: "description",
      label: "Address",
      multiline: true,
      validation: { required: true },
    },
  ];

  //useEffect
  useEffect(() => {
    if (patientsViewMode === "newPatient") handleOpenModal();
  }, []);

  useEffect(() => {
    if (permissions["Date of Discharge"] && !permissions["Date of Admission"]) {
      setDishcargedFilter(true);
    }
  }, [permissions]);

  useEffect(() => {
    if (dischargedFilter) {
      getPatients.request(
        `?is_discharged=true&page=${pageNumber}&page_size=${defaultPageSize}`
      );
    } else {
      getPatients.request(
        `?is_discharged=false&page=${pageNumber}&page_size=${defaultPageSize}`
      );
    }
  }, [dischargedFilter, pageNumber]);

  const handleOpenModal = () => {
    if (openModal && otpView) {
      setOtpView(false);
      getPatients.request("?is_discharged=false&page=1");
      setPageNumber(1);
    }
    setOpenModal((state) => !state);
  };

  useEffect(() => {
    if (watchedFields[0]) {
      getAreas.request(watchedFields[0]);
    }
  }, [watchedFields[0]]);

  useEffect(() => {
    if (openModal) {
      getStates.request();
    }
  }, [openModal]);

  //helpers
  const getWatchedFields = (f) => {
    setWatchedFields(f);
  };

  //submit
  const onSubmit = async (data) => {
    addPatient.resetError();
    const obj = {
      ...data,
      address: {
        area: data.area,
        description: data.description,
      },
      username_pic: await getBase64(data.username_pic[0]),
      username: "P" + data.nid,
    };
    const res = await addPatient.requestWithToast(
      t("Patient added successfully"),
      obj
    );
    if (res?.ok) {
      setNewPatientId(res?.data?.data?.id);
      setOtpView(true);
      setResetForm(true);
    }
  };

  return (
    <Stack
      sx={{
        paddingX: tableView ? 2 : 9,
        mt: 6,
        width: "100%",
      }}
    >
      <Stack
        direction={tableView ? "column" : "row"}
        justifyContent="space-between"
        overflow="auto"
      >
        <SecondaryNav />
        <Stack direction="row" gap={2} mb={tableView ? "10px" : "0px"}>
          {referralPermissions.add && patientsViewMode === "referrals" && (
            <Button
              variant="outlined"
              onClick={() => {
                // navigate("/patients/my-referrals");
                setMyReferralToggle((s) => !s);
              }}
              style={{
                width: 230,
                height: tableView ? 30 : 48,
                borderRadius: 10,
                textTransform: "none",
                fontWeight: 500,
              }}
            >
              <Stack direction={"row"} gap={1} alignItems={"center"}>
                {myReferralToggle
                  ? t("Incoming Referrals")
                  : t("My Referral Requests")}
                <Filter />
              </Stack>
            </Button>
          )}
          {permissions["add"] && patientsViewMode === "list" && !mobileView && (
            <Button
              variant="outlined"
              onClick={() => {
                setOpenModal(true);
              }}
              style={{
                width: 130,
                height: tableView ? 30 : 48,
                borderRadius: 10,
                textTransform: "none",
                fontWeight: 500,
              }}
            >
              {t("Add New")}
            </Button>
          )}
          {permissions["Date of Admission"] &&
            permissions["Date of Discharge"] &&
            patientsViewMode === "list" && (
              <Button
                variant="outlined"
                onClick={() => setDishcargedFilter((state) => !state)}
                style={{
                  width: 220,
                  height: tableView ? 30 : 48,
                  borderRadius: 10,
                  textTransform: "none",
                  fontWeight: 500,
                }}
                disabled={getPatients.loading}
              >
                <Stack direction={"row"} gap={1} alignItems={"center"}>
                  {dischargedFilter
                    ? t("Admitted Patients")
                    : t("Discharged Patients")}
                  <Filter />
                </Stack>
              </Button>
            )}
        </Stack>
      </Stack>

      <Modal open={openModal} handleOpen={handleOpenModal}>
        {otpView ? (
          <OtpForm userId={newPatientId} handleOpenModal={handleOpenModal} />
        ) : (
          <CebForm
            modalTitle={"Add new patient"}
            fields={fields}
            viewModeState={[viewMode, setViewMode]}
            modalForm
            handleOpenModal={handleOpenModal}
            onSubmit={onSubmit}
            fieldsToWatch={{ name: ["state"] }}
            getWatchedFields={getWatchedFields}
            resetState={[resetForm, setResetForm]}
            requestErrors={
              addPatient.error?.username
                ? {
                    ...addPatient.error,
                    nid: [t("NID is not valid or already exists")],
                  }
                : addPatient.error
            }
            formWidth={"80vw"}
          />
        )}
      </Modal>

      {patientsViewMode === "list" && entityItems.length > 0 && (
        <Stack gap={2} pb={3}>
          <AdvancedFilter
            onChangeQuery={(query) => getPatients.request(query)}
            loading={getPatients.loading}
          />
          {getPatients.data.map((card, index) => (
            <>
              {index === 0 && !mobileView && (
                <PatientCardTitles items={entityItems} data={card} />
              )}
              <PatientCard
                key={index}
                items={entityItems}
                data={{ ...card, username: card.username.substr(1) }}
                index={index}
                handleView={() => {
                  if (permissions?.active_medications) {
                    if (card?.admission?.id)
                      navigate(
                        `/patients/${card.id}/${card.age}/${card.gender}/admission`
                      );
                    else
                      addToast(t("This patient has no active admission"), {
                        appearance: "error",
                        autoDismiss: true,
                      });
                  } else
                    navigate(
                      `/patients/${card.id}/${card.age}/${card.gender}/`
                    );
                }}
              />
            </>
          ))}
          {getPatients.count > 0 && (
            <Stack alignItems={"center"}>
              <Pagination
                dir="ltr"
                color="primary"
                count={Math.ceil(getPatients.count / defaultPageSize)}
                onChange={(event, value) => setPageNumber(value)}
                page={pageNumber}
              />
            </Stack>
          )}
        </Stack>
      )}
      {patientsViewMode === "referrals" && (
        <Referral myReferrals={myReferralToggle} />
      )}
    </Stack>
  );
}
