import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7 17c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 5 15V4c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 7 2h4.175a1.978 1.978 0 0 1 1.4.575L14 4h7c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v9c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 21 17H7Zm0-2h14V6h-7.825l-2-2H7v11Zm-4 6c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 1 19V7c0-.283.096-.521.288-.713A.967.967 0 0 1 2 6a.97.97 0 0 1 .713.287A.97.97 0 0 1 3 7v12h16c.283 0 .52.096.712.288A.965.965 0 0 1 20 20c0 .283-.096.52-.288.712A.965.965 0 0 1 19 21H3Zm7-8h8c.2 0 .35-.092.45-.275a.44.44 0 0 0-.05-.525l-2.375-3.175a.475.475 0 0 0-.4-.2c-.167 0-.3.067-.4.2L13 12l-1.225-1.65a.475.475 0 0 0-.4-.2c-.167 0-.3.067-.4.2L9.6 12.2a.44.44 0 0 0-.05.525c.1.183.25.275.45.275Zm-3 2V4v11Z"
      fill="#003468"
    />
  </svg>
);

export default SvgComponent;
