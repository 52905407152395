import { getCurrentUser } from "../auth/authService";

const getAdmissionPermissions = (...tabs) => {
  if (!tabs.length)
    return {
      summary: true,
      instructions: true,
      orders: true,
      medications: true,
      followup: true,
      sheets: true,
      discharge: true,
    };
  else {
    const obj = {};
    for (const tab of tabs) {
      obj[tab] = true;
    }
    return obj;
  }
};

const doctorPermissions = {
  patientsList: true,
  pastHistory: true,
  summary: true,
  encounters: true,
  orders: true,
  lab: true,
  radio: true,
  admissionList: true,
  admission: getAdmissionPermissions(),
  prescriptions: true,
  media: true,
  consents: true,
  procedures: true,
  reportsAndLetters: true,
};

const receptionistPermissions = {
  patientsList: true,
  admission: true,
};

const labPermissions = {
  patientsList: true,
  orders: true,
  lab: true,
  admission: getAdmissionPermissions("orders"),
};

const radioPermissions = {
  patientsList: true,
  orders: true,
  radio: true,
  admission: getAdmissionPermissions("orders"),
};

const pharmacistPermissions = {
  patientsList: true,
  admission: getAdmissionPermissions("medications"),
};

const roleMapper = () => {
  console.log("type", getCurrentUser()?.type);
  switch (getCurrentUser()?.type) {
    case "Doctor":
      return doctorPermissions;
    case "Receptionist":
      return receptionistPermissions;
    case "Lab Technician":
      return labPermissions;
    case "Radiologist":
      return radioPermissions;
    case "Pharmacist":
      return pharmacistPermissions;
    default:
      return {};
  }
};

export default roleMapper;
