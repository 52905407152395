import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import mainApi from "../../../api/main";
import CebForm from "../../../components/Form/CebForm";
import useApi from "../../../hooks/useApi";
import { t } from "i18next";

const Decubitus = ({
  generalExaminationId,
  encounterViewModeKey,
  tabState,
}) => {
  const { patientId } = useParams();
  const [viewMode, setViewMode] = useState("form");
  const [encounterViewMode, setEncounterViewMode] = encounterViewModeKey;
  const [tabId, setTabId] = tabState;
  const [success, setSuccess] = useState();
  const [submitType, setSubmitType] = useState();

  //api
  const addGeneralExaminationDecubitus = useApi(
    mainApi.addGeneralExaminationDecubitus
  );
  const getGeneralExaminationDecubitusList = useApi(
    mainApi.getGeneralExaminationDecubitusList
  );

  useEffect(() => {
    getGeneralExaminationDecubitusList.request();
  }, []);

  useEffect(() => {
    if (viewMode === "cards") {
      setEncounterViewMode("encounter");
      setViewMode("form");
    }
  }, [viewMode]);

  const fields = [
    {
      name: "decubitus",
      label: "Decubitus",
      type: "select",
      items: getGeneralExaminationDecubitusList.data,
    },
  ];

  const onSubmit = async (data) => {
    const res = await addGeneralExaminationDecubitus.requestWithToast(
      t("Added Successfully"),
      patientId,
      generalExaminationId,
      data
    );
    setSuccess(res.ok);
  };

  useEffect(() => {
    console.log("submit", success, submitType);
    if (success && submitType) {
      if (submitType === "next") {
        setTabId((prev) => prev + 1);
        window.scrollTo(0, 0);
      } else {
        setEncounterViewMode("encounter");
      }
      setSuccess(false);
      setSubmitType(null);
    }
  }, [success, submitType]);

  return (
    <CebForm
      viewModeState={[viewMode, setViewMode]}
      fields={fields}
      onSubmit={onSubmit}
      nextButtonHandler={() => {
        setSubmitType("next");
      }}
      cancelButtonLabel="Back"
      saveHandler={() => setSubmitType("save")}
      cancelHandler={() => setEncounterViewMode("encounter")}
      requestErrors={addGeneralExaminationDecubitus.error}
    />
  );
};

export default Decubitus;
