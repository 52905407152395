import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5 22c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 0 1 3 20V6c0-.55.196-1.02.587-1.412A1.927 1.927 0 0 1 5 4h1V2h2v2h8V2h2v2h1c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v14c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 19 22H5Zm0-2h14V6H5v14Zm4 0v-1.8l-1.8-2.1a2.478 2.478 0 0 1-.487-.75 2.665 2.665 0 0 1-.213-1.1c0-.217.02-.43.062-.638.042-.208.113-.412.213-.612a3.243 3.243 0 0 1-.2-.588 2.825 2.825 0 0 1-.075-.662c0-.417.071-.783.213-1.1.141-.317.304-.567.487-.75L9 7.8V6h1.5v2.075c0 .083-.058.242-.175.475l-2 2.35c-.117.133-.2.27-.25.412A1.31 1.31 0 0 0 8 11.75c0 .333.108.621.325.863.217.241.492.362.825.362a1.2 1.2 0 0 0 .425-.075.85.85 0 0 0 .35-.25c.283-.283.604-.5.963-.65a2.85 2.85 0 0 1 2.225 0c.358.15.679.367.962.65a1.057 1.057 0 0 0 .775.325c.333 0 .608-.12.825-.362a1.219 1.219 0 0 0 .238-1.276 1.904 1.904 0 0 0-.238-.412l-2-2.375a.527.527 0 0 1-.137-.225.857.857 0 0 1-.038-.25V6H15v1.8l1.825 2.15c.233.267.404.554.512.862.109.309.163.63.163.963 0 .217-.029.429-.087.637-.059.209-.13.405-.213.588a2.728 2.728 0 0 1 .3 1.25c0 .417-.07.783-.212 1.1a2.492 2.492 0 0 1-.488.75L15 18.2V20h-1.5v-2.075c0-.1.058-.258.175-.475l2-2.35c.117-.133.2-.275.25-.425.05-.15.075-.3.075-.45a2.835 2.835 0 0 1-.55.187 2.567 2.567 0 0 1-.575.063c-.333 0-.667-.067-1-.2a2.417 2.417 0 0 1-.875-.6 1.083 1.083 0 0 0-.438-.3 1.562 1.562 0 0 0-1.099 0c-.175.067-.33.167-.463.3-.25.267-.538.467-.863.6-.325.133-.654.2-.987.2a2.743 2.743 0 0 1-1.15-.25c0 .15.025.3.075.45.05.15.133.292.25.425l2 2.35c.05.083.092.163.125.238.033.075.05.154.05.237V20H9Zm-4 0V6v14Z"
      fill="#003468"
    />
  </svg>
);

export default SvgComponent;
