import React, { useContext, useEffect, useState } from "react";
import CebForm from "../../../components/Form/CebForm";
import useApi from "../../../hooks/useApi";
import mainApi from "../../../api/main";
import { useNavigate, useParams } from "react-router-dom";
import usePermissions from "../../../hooks/usePermissions";
import { modules } from "../../../config/constants";
import { t } from "i18next";

const module = modules.PROCEDURE;

export default function ProcedureList() {
  const { patientId, age, gender } = useParams();
  const navigate = useNavigate();
  const [viewMode, setViewMode] = useState("cards");
  const [resetForm, setResetForm] = useState();
  const [doctors, setDoctors] = useState([]);
  const [nurses, setNurses] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);

  //apis
  const getPractitionerList = useApi(mainApi.getPractitionerList);
  const createProcedure = useApi(mainApi.createProcedure);
  const getProcedureList = useApi(mainApi.getProcedureList);
  const hasActiveAdmission = useApi(mainApi.hasActiveAdmission);
  const getProcedureIchiListData = useApi(mainApi.getProcedureIchiListData);

  //permissions
  const permissions = usePermissions(module);

  useEffect(() => {
    hasActiveAdmission.request(patientId);
  }, []);

  useEffect(() => {
    if (permissions?.view) getProcedureList.request(patientId, pageNumber);
  }, [createProcedure.data, permissions, pageNumber]);

  useEffect(() => {
    if (viewMode === "form") {
      getPractitionerList.request();
      getProcedureIchiListData.request();
    }
  }, [viewMode]);

  useEffect(() => {
    if (getPractitionerList.data?.length > 0) {
      const docList = [];
      const nonDocList = [];
      for (const pr of getPractitionerList.data) {
        if (pr.role === "Doctor")
          docList.push({ id: pr.id, label: pr.full_name });
        else nonDocList.push({ id: pr.id, label: pr.full_name });
      }
      setDoctors(docList);
      setNurses(nonDocList);
    }
  }, [getPractitionerList.data]);

  const cardItems = [
    { header: "Procedure", keyName: "data.procedure_name" },
    {
      header: "Date",
      keyName: "data.created_at",
      type: "formattedDate",
      format: "DD-MM-YYYY",
    },
    { header: "Location", keyName: "data.entity?.full_name" },
    {
      header: "Preoperative Diagnosis",
      keyName: "data.summary?.preoperative_diagnosis",
    },
    {
      header: "Postoperative Diagnosis",
      keyName: "data.summary?.postoperative_diagnosis",
    },
  ];

  const fields = [
    {
      label: "Name",
      name: "procedure",
      type: "select",
      onInputChange: (val) =>
        getProcedureIchiListData.request(`?search=${val}`),
      items: getProcedureIchiListData.data.map((item) => ({
        ...item,
        name: item.label,
        label: `(${item.code}) ${item.label}`,
      })),
      autoComplete: true,
      disableFilter: true,
      validation: { required: true },
    },
    {
      type: "select",
      label: "Surgeons",
      name: "surgeons",
      items: doctors,
      multiple: true,
      autoComplete: true,
      validation: { required: true },
    },
    {
      type: "select",
      label: "Assistant Doctors",
      name: "assistant_doctors",
      items: doctors,
      multiple: true,
      autoComplete: true,
      validation: { required: true },
    },
    {
      type: "select",
      label: "Anaesthesia Consultants",
      name: "anaesthesia_consultants",
      items: doctors,
      multiple: true,
      autoComplete: true,
      validation: { required: true },
    },
    {
      type: "select",
      label: "Anaesthesia Specialists",
      name: "anaesthesia_specialists",
      items: doctors,
      multiple: true,
      autoComplete: true,
      validation: { required: true },
    },
    {
      type: "select",
      label: "Circulating Nurses",
      name: "circulating_nurses",
      items: nurses,
      multiple: true,
      autoComplete: true,
    },
    {
      type: "select",
      label: "Scrub Nurses",
      name: "scrub_nurses",
      items: nurses,
      multiple: true,
      autoComplete: true,
    },
    {
      type: "select",
      label: "Anaesthesia Nurses",
      name: "anaesthesia_nurses",
      items: nurses,
      multiple: true,
      autoComplete: true,
    },
  ];
  const onSubmit = async (data) => {
    const obj = {
      procedure_name: data.procedure?.name,
      procedure_code: data.procedure?.code,
      surgeons: data.surgeons.map((item) => item.id),
      assistant_doctors: data.assistant_doctors.map((item) => item.id),
      anaesthesia_consultants: data.anaesthesia_consultants.map(
        (item) => item.id
      ),
      anaesthesia_specialists: data.anaesthesia_specialists.map(
        (item) => item.id
      ),
      circulating_nurses: data.circulating_nurses.map((item) => item.id),
      scrub_nurses: data.scrub_nurses.map((item) => item.id),
      anaesthesia_nurses: data.anaesthesia_nurses.map((item) => item.id),
    };

    const res = await createProcedure.requestWithToast(
      t("Added successfully"),
      patientId,
      obj
    );

    if (res.ok) {
      setPageNumber(1);
      setViewMode("cards");
      setResetForm(true);
    }
  };

  const handleViewCard = (card) => {
    navigate(`${card.id}`);
  };

  return (
    <CebForm
      onSubmit={onSubmit}
      fields={fields}
      cardsData={getProcedureList.data}
      cardItems={cardItems}
      viewModeState={[viewMode, setViewMode]}
      handleViewButton={handleViewCard}
      formWidth="90%"
      cardsWidth="100%"
      resetState={[resetForm, setResetForm]}
      loading={
        getProcedureList.loading ||
        createProcedure.loading ||
        hasActiveAdmission.loading
      }
      disableAddNew={hasActiveAdmission.data === false}
      requestErrors={createProcedure.error}
      module={module}
      totalCount={getProcedureList.count}
      paginationState={[pageNumber, setPageNumber]}
    />
  );
}
