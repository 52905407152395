import { Delete } from "@mui/icons-material";
import { Button, Stack, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import main from "../api/main";
import useApi from "../hooks/useApi";
import useDiseasesField from "../hooks/useDiseasesFields";
import theme from "../styles/MUITheme";
import CebSelectField from "./Form/CebSelectField";
import CebTextField from "./Form/CebTextField";
import LayersTree from "./LayersTree";
import CustomAddButton from "./UI/CustomAddButton";
import Modal from "./UI/Modal";
import { useTranslation } from "react-i18next";

const AdvancedFilter = ({ onChangeQuery, loading }) => {
  const { t } = useTranslation();
  const filterTypes = [
    {
      id: "full_name__icontains",
      label: t("Name"),
      fieldType: "text",
    },
    {
      id: "username",
      label: t("MRN"),
      fieldType: "text",
    },
    {
      id: "age",
      label: t("Age"),
      fieldType: "number",
    },
    !localStorage.getItem("dep_id") && {
      id: "diagnosis",
      label: t("Diagnosis"),
      fieldType: "select",
    },
    {
      id: "diseases",
      label: t("Disease"),
      fieldType: "select",
    },
    !localStorage.getItem("dep_id") && {
      id: "location_in_hospital",
      label: t("Location in hospital"),
      fieldType: "select",
    },
  ].filter((item) => item);

  const [filterCount, setFilterCount] = useState(0);
  const [filterFields, setFilterFields] = useState([]);
  const { register, control, handleSubmit, setValue, trigger, watch } =
    useForm();
  const [filterTypesList, setFilterTypesList] = useState(filterTypes);
  //select location in hospital
  const [openHeirarchy, setOpenHeirarchy] = useState(false);
  const [selectedLayer, setSelectedLayer] = useState();

  const getDiseases = useDiseasesField();
  const getLayerList = useApi(main.getLayerList);

  //mobile view
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const tabView = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    getLayerList.request();
  }, []);

  const handleChangeFilterType = (filterTypeId, fieldId) => {
    setFilterTypesList((old) =>
      old
        .map((item) =>
          item.fieldId === fieldId ? { id: item.id, label: item.label } : item
        )
        .map((item) => (item.id === filterTypeId ? { ...item, fieldId } : item))
    );
    const fieldType = filterTypes.find(
      (item) => item.id === filterTypeId
    )?.fieldType;
    setFilterFields((old) =>
      old.map((item) =>
        item.id === fieldId ? { ...item, type: fieldType, filterTypeId } : item
      )
    );
  };

  const getFilterValueField = (field) => {
    if (field.type === "text" || field.type === "number") {
      return (
        <CebTextField
          name={`value_${field.id}`}
          register={register}
          control={control}
          trigger={trigger}
          setValue={setValue}
          watch={watch}
          type={field.type}
        />
      );
    } else if (
      field.filterTypeId === "diagnosis" ||
      field.filterTypeId === "diseases"
    ) {
      return (
        <CebSelectField
          name={`value_${field.id}`}
          register={register}
          control={control}
          autoComplete
          onInputChange={getDiseases.handleInputChange}
          items={getDiseases.diseases}
          disableFilter
        />
      );
    } else if (field.filterTypeId === "location_in_hospital") {
      return (
        <Stack direction={"row"} gap={2}>
          <Stack flex={1}>
            <CebSelectField
              name={`value_${field.id}`}
              register={register}
              control={control}
              autoComplete
              onInputChange={() => void 0}
              items={getLayerList.data.map((item) => ({
                id: item.id,
                label: item.name,
              }))}
            />
          </Stack>

          <Button
            variant="contained"
            color="inherit"
            sx={{
              color: "primary.main",
              backgroundColor: "#fff",
              textTransform: "none",
            }}
            onClick={handleOpenHeirarchy}
          >
            {t("View Hierarchy")}
          </Button>
        </Stack>
      );
    }
  };

  const addFilterHandler = () => {
    setFilterFields((old) => [...old, { id: filterCount, type: null }]);
    setFilterCount((old) => old + 1);
  };

  const handleRemoveFilter = (fieldId) => {
    setFilterFields((old) => old.filter((item) => item.id !== fieldId));
    setFilterTypesList((old) =>
      old.map((item) =>
        item.fieldId === fieldId ? { id: item.id, label: item.label } : item
      )
    );
  };

  const onSubmitHandler = (data) => {
    let query = "";
    for (const key in data) {
      const fieldType = key.split("_")[0];
      const index = key.split("_")[1];
      if (
        fieldType === "type" &&
        filterFields.findIndex((item) => item.id == index) !== -1
      ) {
        const value =
          data[key] === "username"
            ? "P" + data[`value_${index}`]
            : data[key] === "diseases" ||
              data[key] === "diagnosis" ||
              data[key] === "location_in_hospital"
            ? data[`value_${index}`]?.id
            : data[`value_${index}`];
        query += `${!query ? "?" : "&"}${data[key]}=${value}`;
      }
    }
    console.log("query", query);

    onChangeQuery(query);
  };

  const handleClearFilter = () => {
    setFilterFields([]);
    setFilterTypesList((old) =>
      old.map((item) => ({ id: item.id, label: item.label }))
    );
    onChangeQuery("");
  };

  const handleOpenHeirarchy = () => {
    setOpenHeirarchy((old) => !old);
  };

  const handleLayerClick = (id) => {
    if (id !== "root") {
      const layer = getLayerList.data.find((item) => item.id === id);
      setSelectedLayer({ id: layer.id, label: layer.name });
      handleOpenHeirarchy();
    }
  };

  useEffect(() => {
    if (selectedLayer) {
      const fieldId = filterFields.find(
        (item) => item.filterTypeId === "location_in_hospital"
      )?.id;
      setValue(`value_${fieldId}`, selectedLayer);
    }
  }, [selectedLayer]);

  return (
    <>
      <Modal open={openHeirarchy} handleOpen={handleOpenHeirarchy}>
        <LayersTree onLayerClick={handleLayerClick} />
      </Modal>
      <Stack
        width="100%"
        sx={{
          backgroundColor: "primary.main",
          borderRadius: "20px",
          p: "25px",
        }}
        gap={2}
      >
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography color="white">{t("Add Filter")}</Typography>
          <CustomAddButton light onClick={addFilterHandler} />
        </Stack>
        {filterFields.length > 0 && (
          <>
            {filterFields.map((item) => (
              <Stack key={item.id} direction="row" gap={2} alignItems="center">
                <CebSelectField
                  name={`type_${item.id}`}
                  register={register}
                  control={control}
                  items={filterTypesList.filter(
                    (el) =>
                      !el.hasOwnProperty("fieldId") || el.fieldId === item.id
                  )}
                  width={mobileView ? 120 : tabView ? 150 : 250}
                  idName="id"
                  onInputChange={(filterTypeId) =>
                    handleChangeFilterType(filterTypeId, item.id, item.type)
                  }
                />
                <Stack flex={1}>{getFilterValueField(item)}</Stack>
                <Delete
                  style={{ color: "#fff" }}
                  onClick={() => handleRemoveFilter(item.id)}
                />
              </Stack>
            ))}
            <Stack
              width="100%"
              direction="row"
              justifyContent={mobileView ? "space-between" : "flex-end"}
              pt={1}
              gap={2}
            >
              <Button
                variant="contained"
                color="inherit"
                sx={{
                  color: "primary.main",
                  backgroundColor: "#fff",
                  textTransform: "none",
                  width: 200,
                }}
                onClick={handleClearFilter}
                disabled={loading}
              >
                {t("Clear Filter")}
              </Button>
              <Button
                variant="contained"
                color="inherit"
                sx={{
                  color: "primary.main",
                  backgroundColor: "#fff",
                  textTransform: "none",
                  width: 200,
                }}
                onClick={handleSubmit(onSubmitHandler)}
                disabled={loading}
              >
                {t("Filter Patients")}
              </Button>
            </Stack>
          </>
        )}
      </Stack>
    </>
  );
};

export default AdvancedFilter;
