import React, { useContext, useEffect, useState } from "react";
import CebForm from "../../components/Form/CebForm";
import { Context } from "../../context/Context";
import useApi from "../../hooks/useApi";
import mainApi from "../../api/main";
import { useLocation, useParams } from "react-router-dom";
import Modal from "../../components/UI/Modal";
import moment from "moment";
import useDiseasesField from "../../hooks/useDiseasesFields";
import selectFieldOrOther from "../../helpers/getSelectFieldOrOther";
import usePermissions from "../../hooks/usePermissions";
import { modules } from "../../config/constants";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import Print from "../../components/Print";
import { t } from "i18next";

export const getFields = (
  orderTypes = [],
  orderSubtypes = [],
  admissionCondition,
  getDiseasesField,
  selectedOrder,
  handleSubtypeSearch
) => {
  return [
    {
      type: admissionCondition ? "dateTime" : "date",
      label: admissionCondition ? "Date & Time" : "Date",
      name: "date",
      validation: { disablePast: true, required: true },
    },
    {
      type: "select",
      items: orderTypes,
      label: "Type of Order",
      name: "orderType",
      objectId: true,
    },
    selectFieldOrOther(selectedOrder.name, {
      multiple: true,
      autoComplete: true,
      // objectId: true,
      onInputChange: handleSubtypeSearch,
      items: orderSubtypes.map((item) => ({ ...item, label: item.name })),
      type: "select",
      label: "Details",
      name: "sub_types",
      otherName: "order_subtype_string",
      validation: { required: true },
    }),
    // conditionalObj(selectedOrder.name === "Other", {
    //   label: "Details",
    //   name: "order_subtype_string",
    // }),
    // {
    //   label: "Reason of Order",
    //   name: "reason",
    //   validation: { required: true },
    // },

    {
      multiple: true,
      autoComplete: true,
      onInputChange: getDiseasesField.handleInputChange,
      items: getDiseasesField.diseases,
      type: "select",
      label: "Reason",
      name: "reasons",
      validation: { required: true },
    },

    { label: "Notes", name: "notes", multiline: true },
  ];
};

export const getDataArr = (
  dataArr,
  admissionCondition = false,
  ordersPageCondition = false
) => {
  const fulfillListDisplayConditon =
    admissionCondition && dataArr.data.status === "Done";
  return [
    { label: "Status", data: dataArr.data.status },
    {
      label: admissionCondition ? "Date & Time" : "Date",
      data: admissionCondition
        ? moment(dataArr.data.date).format("DD-MM-YYYY, hh:mm a")
        : moment(dataArr.data.date).format("DD-MM-YYYY"),
      cardItem: true,
    },
    {
      label: "Type of order",
      data: dataArr.data.order_type_string,
      cardItem: ordersPageCondition ? true : false,
    },
    { label: "Details", data: dataArr.data.order_subtype_string },
    {
      label: "Doctor",
      data: dataArr.data.practitioner?.full_name,
      cardItem: true,
    },
    {
      label: "Reason of order",
      data: dataArr.data.reasons?.map((reas) => reas.label),
    },
    { label: "Notes", data: dataArr.data.notes, multiline: true },

    //fulfillment data in case of admissions
    {
      condition: fulfillListDisplayConditon,
      data: "Fulfillment Data",
      fieldType: "labelField",
    },
    {
      condition: fulfillListDisplayConditon,
      label: "Fulfill Date",
      data: moment(dataArr.data.fulfillment?.updated_at).format(
        "YY-MM-DD, h:mm a"
      ),
    },
    {
      condition: fulfillListDisplayConditon,
      label: "Ordering doctor",
      data: dataArr.data.fulfillment?.fulfilled_by?.full_name,
    },
    {
      condition:
        fulfillListDisplayConditon &&
        dataArr.data.order_type_string === "Radiology",
      label: "Images",
      fieldType: "link",
      data: dataArr.data.fulfillment?.images,
    },
    {
      condition: fulfillListDisplayConditon,
      label: "Report",
      fieldType: "link",
      data: dataArr.data.fulfillment?.documents,
    },
    {
      condition: fulfillListDisplayConditon,
      label: "Notes",
      data: dataArr.data.fulfillment?.comment,
      multiline: true,
    },
  ];
};

export default function OrdersPage() {
  const location = useLocation();
  const { patientId, admissionId } = useParams();
  const [viewMode, setViewMode] = useState("cards");
  const [fulfillViewMode, setFulfillViewMode] = useState("form");
  const [listData, setListData] = useState([]);
  const [watchedFields, setWatchedFields] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [selectedOrderType, setSelectedOrderType] = useState({});
  const [resetForm, setResetForm] = useState();
  const [chosenCardId, setChosenCardId] = useState();
  const [cardsData, setCardsData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);

  const getDiseasesField = useDiseasesField();

  const admissionCondition = location.pathname.includes("admission");
  const ordersPageCondition = location.pathname.includes("orders");

  //mobile view
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));

  //api
  const getOrdersList = useApi(
    admissionCondition ? mainApi.getAdmissionOrdersList : mainApi.getOrdersList
  );
  const getOrder = useApi(
    admissionCondition ? mainApi.getAdmissionOrder : mainApi.getOrder
  );
  const addOrder = useApi(
    admissionCondition ? mainApi.addAdmissionOrder : mainApi.addOrder
  );
  const getOrderTypesList = useApi(mainApi.getOrderTypesList);
  const getOrderSubTypesList = useApi(mainApi.getOrderSubTypesList);

  //api order fulfillment
  const sendFile = useApi(mainApi.sendFile);
  const completeOrderFullfillment = useApi(mainApi.completeOrderFullfillment);

  //Admission
  const getAdmission = useApi(mainApi.getAdmission);

  //permissions
  const module = admissionId ? modules.ADMISSIOM : modules.ORDER;
  const subModule = admissionId ? modules.ADMISSIOM_ORDER : modules.ORDER;
  const permissions = usePermissions(module, subModule);
  const store = useContext(Context);
  const storePermissions = store?.permissions?.[module]?.[subModule];

  useEffect(() => {
    getOrderTypesList.request();
    if (admissionId) {
      getAdmission.request(patientId, admissionId);
    }
  }, []);

  //get cards data
  useEffect(() => {
    if (permissions?.view) {
      getOrdersList.request(patientId, admissionId, pageNumber);
    }
  }, [addOrder.data, completeOrderFullfillment.data, permissions, pageNumber]);

  //nav of notification
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  useEffect(() => {
    if (urlParams.get("view")) handleViewCard({ id: urlParams.get("id") });
  }, [urlParams.get("view")]);

  const handleOpenModal = () => {
    setOpenModal((state) => !state);
  };

  useEffect(() => {
    if (watchedFields[0] !== undefined && watchedFields[0] !== "") {
      getOrderSubTypesList.request(JSON.parse(watchedFields[0]).id); /// because of useFieldArray, at some point the watched field value equals []
      setSelectedOrderType(JSON.parse(watchedFields[0]));
    }
  }, [watchedFields]);

  const handleFulfillButton = (card) => {
    console.log("card", card);
    setSelectedOrder(card);
    handleOpenModal();
  };

  const orderCardItems = [
    { header: "Type", keyName: "data.order_type_string" },
    {
      header: "Order",
      keyName:
        "data?.name?.length > 15 ? (data.name?.substring(0, 15) + '...') : data.name",
    },
    { header: "Ordering Physician", keyName: "data.practitioner?.full_name" },
    {
      header: admissionCondition ? "Date & Time" : "Date",
      keyName: "data.date",
    },
    {
      header: "Status",
      keyName: "data.status",
      condition: "data.status!=='Pending'",
      statusColor: true,
    },
    !mobileView && {
      header: "Fulfill order",
      keyName: "data.report",
      condition: "data.status==='Pending'",
      type: "button",
    },
  ].filter((item) => item);

  //filter cards data according to type of order
  useEffect(() => {
    // const labPermission = storePermissions?.find((item) =>
    //   item.codename.includes("view_lab")
    // );
    // const radioPermission = storePermissions?.find((item) =>
    //   item.codename.includes("view_radiology")
    // );
    // const filteredOrders = getOrdersList.data.filter(
    //   (item) =>
    //     (labPermission && item.order_type_string === "Laboratory") ||
    //     (radioPermission && item.order_type_string === "Radiology")
    // );
    // const filteredOrders = getOrdersList.data;

    setCardsData(
      admissionCondition
        ? getOrdersList.data?.map((item) => ({
            ...item,
            date: moment(item.date).format("DD-MM-YYYY, hh:mm a"),
          }))
        : getOrdersList.data?.map((item) => ({
            ...item,
            date: moment(item.date).format("DD-MM-YYYY"),
          }))
    );
  }, [getOrdersList.data, storePermissions]);

  const data = getDataArr(getOrder, admissionCondition, ordersPageCondition);

  const onSubmit = async (data) => {
    const obj = {
      // entity_department: 1,
      ...data,
      reasons: data.reasons.map((reas) => reas.id), // written after ...data, so overwritten it
      sub_types: data.sub_types.map((sub_type) => sub_type.id),
    };
    delete obj[
      selectedOrderType.name === "Other" ? "sub_types" : "order_subtype_string"
    ];

    const res = await addOrder.requestWithToast(
      t("Added successfully"),
      obj,
      patientId,
      admissionId
    );
    if (res.ok) {
      if (permissions?.view) setViewMode("cards");
      setPageNumber(1);
      setResetForm(true);
    }
  };

  const handleViewCard = (order) => {
    console.log("entered view");
    setChosenCardId(order.id);
    setViewMode("list");
    getOrder.request(patientId, admissionId, order.id);
  };

  useEffect(() => {
    setListData(data);
  }, [getOrder.data]);

  let mediaBaseUrl = `/order/v1/patient/${patientId}/fulfillment/${selectedOrder.id}/`;
  const fulFillFields = [
    {
      type: "readOnly",
      label: "Name of investigation",
      value: selectedOrder.name,
    },
    {
      type: "readOnly",
      label: "Date",
      value: moment(new Date()).format("YYYY-MM-DD"),
    },
    { label: "Comment", name: "comment", multiline: true },
    {
      condition: selectedOrder.order_type_string === "Radiology",
      type: "file",
      label: "Images",
      multiple: true,
      name: "images",
      acceptFiles: ["image/jpg", "image/png", "image/jpeg"],
      sendOnUpload: true,
      url: `${mediaBaseUrl}media/`,
      // xs: 10,
      // validation: { required: true, message: "Invalid Image" },
    },
    {
      type: "file",
      label: "Report",
      multiple: true,
      name: "report",
      sendOnUpload: true,
      url: `/order/v1/patient/${patientId}/fulfillment/${selectedOrder.id}/reports/`,
      acceptFiles: [
        "image/jpg",
        "image/png",
        "image/jpeg",
        ".pdf",
        "application/pdf",
        ".doc",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ],
      sendOnUpload: true,
      url: `${mediaBaseUrl}reports/`,
      // xs: 10,
      // validation: { required: true, message: "Invalid Image" },
    },
  ];

  const onFulfillOrder = async (data) => {
    const res = await completeOrderFullfillment.requestWithToast(
      t("Order fullfilled successfully"),
      { comment: data.comment },
      patientId,
      selectedOrder.id
    );
    if (res.ok) {
      handleOpenModal();
      setResetForm(true);
    }
  };

  const handleSubtypeSearch = (query) => {
    if (watchedFields[0] !== "")
      getOrderSubTypesList.request(
        JSON.parse(watchedFields[0]).id,
        `?search=${query}`
      );
  };

  //printing

  const dataToPrint = {
    header: {
      title: "Order",
      left: [
        moment(getOrder.data.date).format("DD-MM-YYYY"),
        `Doctor: ${getOrder.data.practitioner?.full_name}`,
      ],
      right: [
        `Type: ${getOrder.data.order_type_string}`,
        `Status: ${getOrder.data.status}`,
      ],
    },
    content: [
      { label: "Details", data: getOrder.data.order_subtype_string },
      {
        label: "Reasons of order",
        data: getOrder.data.reasons?.map((reas) => reas.label),
      },
      { label: "Notes", data: getOrder.data.notes, multiline: true },
      ...(admissionCondition && getOrder.data.status === "Done"
        ? [
            {
              label: "Fulfillment Data",
              type: "label",
            },
            {
              label: "Fulfill Date",
              data: moment(getOrder.data.fulfillment?.updated_at).format(
                "YY-MM-DD, h:mm a"
              ),
            },
            {
              label: "Fullfilled by",
              data: getOrder.data.fulfillment?.fulfilled_by?.full_name,
            },
            {
              label: "Notes",
              data: getOrder.data.fulfillment?.comment,
              multiline: true,
            },
            getOrder.data.order_type_string === "Laboratory" && {
              label: "Images",
              type: "image",
              data: getOrder.data.fulfillment?.documents,
            },
            getOrder.data.order_type_string === "Radiology" && {
              label: "Reports",
              type: "link",
              data: getOrder.data.fulfillment?.documents,
            },
            getOrder.data.order_type_string === "Radiology" && {
              label: "Images",
              type: "image",
              data: getOrder.data.fulfillment?.images,
            },
          ].filter((item) => item)
        : [null]),
    ].filter((item) => item),
  };

  return (
    <>
      <CebForm
        onSubmit={onSubmit}
        fields={getFields(
          getOrderTypesList.data,
          getOrderSubTypesList.data,
          admissionCondition,
          getDiseasesField,
          selectedOrderType,
          handleSubtypeSearch
        ).filter((f) => f)}
        listData={listData}
        cardsData={
          viewMode === "list"
            ? [
                {
                  ...getOrder.data,
                  date: moment(getOrder.data?.date).format(
                    admissionCondition ? "DD-MM-YYYY, hh:mm a" : "DD-MM-YYYY"
                  ),
                },
              ]
            : cardsData
        }
        cardItems={orderCardItems}
        fieldsToWatch={{ name: ["orderType"], defaultValue: [""] }}
        getWatchedFields={(fields) => setWatchedFields(fields)}
        viewModeState={[viewMode, setViewMode]}
        handleViewButton={handleViewCard}
        handleClickCardButton={handleFulfillButton}
        resetState={[resetForm, setResetForm]}
        disableAddNew={admissionCondition && getAdmission.data?.discharge_date}
        loading={
          getOrdersList.loading ||
          getOrder.loading ||
          addOrder.loading ||
          getAdmission.loading
        }
        chosenCardId={chosenCardId}
        requestErrors={addOrder.error}
        module={module}
        subModule={subModule}
        dataToPrint={dataToPrint}
        totalCount={getOrdersList.count}
        paginationState={[pageNumber, setPageNumber]}
        // loading={true}
      />

      <Modal open={openModal} handleOpen={handleOpenModal}>
        <CebForm
          modalForm
          modalTitle={"Fulfill order"}
          handleOpenModal={handleOpenModal}
          onSubmit={onFulfillOrder}
          viewModeState={[fulfillViewMode, setFulfillViewMode]}
          fields={fulFillFields}
          // fieldsToWatch={{ name: ['orderType'], defaultValue: [''] }}
          // getWatchedFields={(fields) => setOrdersWatchedFields(fields)}
          listData={[]}
          addInList={true}
          resetState={[resetForm, setResetForm]}
          loading={completeOrderFullfillment.loading}
          requestErrors={completeOrderFullfillment.error}
          module={module}
          subModule={subModule}
        />
      </Modal>
    </>
  );
}
