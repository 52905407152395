import React, { useEffect, useState } from "react";
import "./CebDateField.css";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { Controller } from "react-hook-form";
import { InputBase, TextField } from "@mui/material";
import { useWatch } from "react-hook-form";

export default function CebDateField({
  width = "100%",
  height = 41,
  control,
  name,
  validation,
  defaultValue,
  errors,
  ...props
}) {
  const {
    minDateField,
    maxDateField,
    disableFuture = false,
    disablePast = false,
  } = validation;

  const [minDate, setMinDate] = useState(validation.minDate);
  const [maxDate, setMaxDate] = useState(validation.maxDate);
  const [focused, setFocused] = useState(false);

  const watchedFields = useWatch({
    control,
    name: [minDateField, maxDateField],
  });

  useEffect(() => {
    if (watchedFields[0]) {
      setMinDate(new Date(watchedFields[0]));
    }
    if (watchedFields[1]) {
      setMaxDate(new Date(watchedFields[1]));
    }
  }, [watchedFields]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Controller
        name={name}
        control={control}
        defaultValue={
          defaultValue ? moment(defaultValue).format("YYYY-MM-DD") : null
        }
        render={({
          field: { onChange, value },
          fieldState: { error, invalid },
        }) => (
          <DatePicker
            value={value}
            onChange={(value) => {
              return onChange(moment(value).format("YYYY-MM-DD"));
            }}
            disablePast={disablePast}
            disableFuture={disableFuture}
            minDate={minDate}
            maxDate={maxDate}
            openTo="day"
            views={["year", "month", "day"]}
            renderInput={(params) => (
              <TextField
                id="date"
                variant="outlined"
                sx={{
                  width,
                  height,
                  outline: "1.5px solid black",
                  borderRadius: "10px",
                  outlineColor: focused
                    ? "#2161d1"
                    : errors?.[name]
                    ? "red"
                    : "black",
                  outlineWidth: focused && "2px",
                }}
                size="small"
                autoComplete="bday"
                {...params}
                {...props}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
              />
            )}
          />
        )}
      />
    </LocalizationProvider>
  );
}
