import React, { useEffect } from "react";

import ThirdNav from "../../../components/Navigation/ThirdNav";
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import ProtectedRoute from "../../../auth/protectedRoute";
import PastHistoryPage from "./PastHistoryPage";
import conditionalObj from "../../../helpers/getConditionalObj";

export default () => {
  const navigate = useNavigate();
  const { patientId, age, gender } = useParams();
  const youngPatientDisplayCondition = age < 12;
  const adultFemaleDisplayCondition =
    age >= 12 && age <= 50 && (gender === "female" || gender === "أنثى");
  const thirdNavDisplayCondition =
    youngPatientDisplayCondition || adultFemaleDisplayCondition;

  const navItems = [
    {
      name: "General History",
      link: "general",
      element: <PastHistoryPage mode="general" />,
    },
    adultFemaleDisplayCondition && {
      name: "Female History",
      link: "female",
      element: <PastHistoryPage mode="female" />,
    },
    youngPatientDisplayCondition && {
      name: "Pediatric History",
      link: "pediatric",
      element: <PastHistoryPage mode="young" />,
    },
  ].filter((item) => item);

  useEffect(() => {
    navigate("general");
  }, []);

  return (
    <>
      {thirdNavDisplayCondition && <ThirdNav navItems={navItems} />}
      <Routes>
        {navItems
          .filter((item) => item)
          .map((item) => (
            <Route
              key={item.name}
              path={"/" + item.link}
              element={<ProtectedRoute />}
            >
              <Route path={"/" + item.link} element={item.element} />
            </Route>
          ))}
      </Routes>
    </>
  );
};
