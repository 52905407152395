import { defaultPageSize } from "../config/constants";
import client from "./client";

const getPatients = (query = "") => client.get(`/profile/v1/patients/${query}`);
const addPatient = (obj) => client.post("/profile/v1/patients/", obj);
const getDiseases = () => client.get(`/icd-11/`);
const getFilteredDiseases = (query) => client.get(`/icd-11/?search=${query}`);

const addPastHistory = (obj, patientId) =>
  client.post(`/past-history/v1/patient/${patientId}/past-history-form`, obj);
const getPastHistory = (patientId) =>
  client.get(`/past-history/v1/patient/${patientId}/past-history-form`);

//encounters
const getEncountersList = (patientId, pageNumber = 1) =>
  client.get(
    `/encounter/v1/patient/${patientId}/encounters/?page=${pageNumber}&page_size=${defaultPageSize}`
  );
const getEncounterPhoneCall = (patientId, encounterId) =>
  client.get(`/encounter/v1/patient/${patientId}/phone-call/${encounterId}/`);
const getEncounterProgressNote = (patientId, encounterId) =>
  client.get(
    `/encounter/v1/patient/${patientId}/progress-note/${encounterId}/`
  );
const getEncounterClinicVisit = (patientId, encounterId) =>
  client.get(`/encounter/v1/patient/${patientId}/clinic-visit/${encounterId}/`);
const addEncounterPhoneCall = (obj, patientId) =>
  client.post(`/encounter/v1/patient/${patientId}/phone-call/`, obj);
const addEncounterProgressNote = (obj, patientId) =>
  client.post(`/encounter/v1/patient/${patientId}/progress-note/`, obj);
const addEncounterClinicVisit = (obj, patientId) =>
  client.post(`/encounter/v1/patient/${patientId}/clinic-visit/`, obj);

//orders  //admissionId added to prevent if conditions in orders page
const getOrdersList = (patientId, admissionId, pageNumber) =>
  client.get(
    `/order/v1/patient/${patientId}/encounter-orders/?page=${pageNumber}&page_size=${defaultPageSize}`
  );
const getOrder = (patientId, admissionId, orderId) =>
  client.get(`/order/v1/patient/${patientId}/encounter-orders/${orderId}`);
const addOrder = (obj, patientId, admissionId) =>
  client.post(`/order/v1/patient/${patientId}/encounter-orders/`, obj);
const getOrderTypesList = () =>
  client.get(`/order/order_types/?pagination=false`);
const getOrderSubTypesList = (orderTypeId, query = "") =>
  client.get(`/order/order_types/${orderTypeId}/order_subtypes${query}`);

//Radio
const getRadioList = (patientId, pageNumber) =>
  client.get(
    `/order/v1/patient/${patientId}/radiology/?page=${pageNumber}&page_size=${defaultPageSize}`
  );
const getRadio = (patientId, radioId) =>
  client.get(`/order/v1/patient/${patientId}/radiology/${radioId}`);

//lab
const getLabList = (patientId, pageNumber) =>
  client.get(
    `/order/v1/patient/${patientId}/laboratory/?page=${pageNumber}&page_size=${defaultPageSize}`
  );
const getLab = (patientId, labId) =>
  client.get(`/order/v1/patient/${patientId}/laboratory/${labId}`);

//order fullfillment
const completeOrderFullfillment = (obj, patientId, orderId) =>
  client.put(`/order/v1/patient/${patientId}/fulfillment/${orderId}/`, obj);

//personal history
const getPatientPersonalHistory = (patientId) =>
  client.get(`/personal-history/v1/${patientId}/`);
const editPatientPersonalHistory = (patientId, obj) =>
  client.patch(`/personal-history/v1/${patientId}/`, obj);

//auth
const login = (obj) => client.post(`/auth/v1/login/`, obj);
const getEntitiesList = () =>
  client.get(`/entity/v1/entities/?pagination=false`);
const loginWithEntity = (obj) =>
  client.post(`/entity-enroll/v1/enrollment-login/`, obj);
const resetPassword = (obj) =>
  client.patch("/auth/v1/password-reset-complete", obj);
const requestResetEmail = (obj) =>
  client.post(`/auth/v1/request-reset-email/`, obj);
const changePassword = (oldPassword, newPassword, newPasswordConfirm) =>
  client.patch(`/auth/v1/change-password/`, {
    old_password: oldPassword,
    new_password: newPassword,
    new_password_confirmation: newPasswordConfirm,
  });

//Send Files
const sendFile = (url, file) => {
  const formData = new FormData();
  formData.append("file", file);
  return client.post(url, formData);
};
const deleteFile = (url) => client.delete(url);

//ADMISSION
const addAdmission = (obj, patientId) =>
  client.post(`/admission/v1/patient/${patientId}/admissions/`, obj);
const getAdmissionList = (patientId, pageNumber) =>
  client.get(
    `/admission/v1/patient/${patientId}/admissions/?page=${pageNumber}&page_size=${defaultPageSize}`
  );
const getAdmission = (patientId, admissionId) =>
  client.get(`/admission/v1/patient/${patientId}/admissions/${admissionId}/`);
const hasActiveAdmission = (patientId) =>
  client.get(
    `/admission/v1/patient/${patientId}/admissions/has_active_admission/`
  );

//Admission discharge
const getAdmissionDischargeList = (patientId, admissionId) =>
  client.get(`/admission/v1/patient/${patientId}/admissions/${admissionId}/discharge/
`);
const addAdmissionDischarge = (patientId, admissionId, obj) =>
  client.post(
    `/admission/v1/patient/${patientId}/admissions/${admissionId}/discharge/`,
    obj
  );

//admission orders
const getAdmissionOrdersList = (patientId, admissionId, pageNumber) =>
  client.get(
    `/order/v1/patient/${patientId}/admission/${admissionId}/admission-orders/?page=${pageNumber}&page_size=${defaultPageSize}`
  );
const getAdmissionOrder = (patientId, admissionId, orderId) =>
  client.get(
    `/order/v1/patient/${patientId}/admission/${admissionId}/admission-orders/${orderId}/`
  );
const addAdmissionOrder = (obj, patientId, admissionId) =>
  client.post(
    `/order/v1/patient/${patientId}/admission/${admissionId}/admission-orders/`,
    obj
  );

//Admission prescription
const getAdmissionPrescriptionList = (patientId, admissionId, pageNumber) =>
  client.get(
    `/prescription/v1/patient/${patientId}/admission/${admissionId}/admission-prescriptions/?page=${pageNumber}&page_size=${defaultPageSize}`
  );
const addAdmissionPrescription = (patientId, admissionId, obj) =>
  client.post(
    `/prescription/v1/patient/${patientId}/admission/${admissionId}/admission-prescriptions/`,
    obj
  );
const getAdmissionPrescription = (patientId, admissionId, prescriptionId) =>
  client.get(
    `/prescription/v1/patient/${patientId}/admission/${admissionId}/admission-prescriptions/${prescriptionId}`
  );
const toggleAdmissionPrescription = (
  patientId,
  admissionId,
  prescriptionId,
  obj
) =>
  client.patch(
    `/prescription/v1/patient/${patientId}/admission/${admissionId}/admission-prescriptions/${prescriptionId}/deactivate/`,
    obj
  );

//Admission instructions
const getInstructionsList = (patientId, admissionId, pageNumber = 1) =>
  client.get(
    `/admission/v1/patient/${patientId}/admissions/${admissionId}/instructions?page=${pageNumber}&page_size=${defaultPageSize}`
  );
const getInstruction = (patientId, admissionId, instructionId) =>
  client.get(
    `/admission/v1/patient/${patientId}/admissions/${admissionId}/instructions/${instructionId}`
  );
const addInstruction = (obj, patientId, admissionId) =>
  client.post(
    `/admission/v1/patient/${patientId}/admissions/${admissionId}/instructions/`,
    obj
  );
const updateInstructionsStatus = (
  obj,
  patientId,
  admissionId,
  instructionsId
) =>
  client.patch(
    `/admission/v1/patient/${patientId}/admissions/${admissionId}/instructions/${instructionsId}/update_status/`,
    obj
  );

//Admission followup
const getAdmissionFollowupList = (patientId, admissionId, pageNumber) =>
  client.get(
    `/admission/v1/patient/${patientId}/admissions/${admissionId}/follow-ups/?page=${pageNumber}&page_size=${defaultPageSize}`
  );
const addAdmissionFollowup = (patientId, admissionId, obj) =>
  client.post(
    `/admission/v1/patient/${patientId}/admissions/${admissionId}/follow-ups/`,
    obj
  );
const getAdmissionFollowup = (patientId, admissionId, followupId) =>
  client.get(
    `/admission/v1/patient/${patientId}/admissions/${admissionId}/follow-ups/${followupId}/`
  );
//doctors
const getDoctorList = () => client.get(`/profile/v1/doctors/?pagination=false`);
const getMyPractitionerProfile = () =>
  client.get(`/profile/v1/practitioners/me/`);
const getMyDoctorProfile = () => client.get(`/profile/v1/doctors/me/`);
const updateDoctorProfile = (obj) =>
  client.patch(`/profile/v1/doctors/me/`, obj);
const deleteDoctorDegrees = (doctor_pk, id) =>
  client.delete(`/profile/v1/doctors/${doctor_pk}/degrees/${id}/`);
//Nurse
const getNurseList = () =>
  client.get(`/profile/v1/practitioners/?role=3&pagination=false`);
//practitioners
const getPractitionerList = () =>
  client.get("/profile/v1/practitioners/?pagination=false");

//prescription
const getDrugList = (query = "") =>
  client.get(`/drugs/drugs_enhancement/${query}`);
const getRouteAdministrationList = () =>
  client.get("/drugs/route_administration/?pagination=false");
const getFilteredDrugList = (query) => client.get(`/drugs/drugs/${query}`);
const getMealRelationList = () => client.get("/prescription/meal-relation/");
const getDosesTime = (patientId, obj) =>
  client.post(
    `/prescription/v1/patient/${patientId}/prescription/get_doses_time_preview/
`,
    obj
  );
const addPrescription = (patientId, obj) =>
  client.post(`/prescription/v1/patient/${patientId}/prescription/`, obj);
const getPrescriptionList = (patientId, pageNumber) =>
  client.get(
    `/prescription/v1/patient/${patientId}/prescription/?page=${pageNumber}&page_size=${defaultPageSize}`
  );
const getPrescription = (patientId, prescriptionId) =>
  client.get(
    `/prescription/v1/patient/${patientId}/prescription/${prescriptionId}`
  );

const togglePrescription = (patientId, prescriptionId, obj) =>
  client.patch(
    `/prescription/v1/patient/${patientId}/prescription/${prescriptionId}/deactivate/`,
    obj
  );

//OTP
const sendOtpById = (obj) => client.post("/otp/v1/send-otp-by-id/", obj);
const validateOtpEnrollUser = (obj) =>
  client.post("/otp/v1/validate-otp-confirm-enroll-user/", obj);

//Address
const getStates = () => client.get(`/address/states/?pagination=false`);
const getAreas = (stateId) =>
  client.get(`/address/states/${stateId}/areas?pagination=false`);

//General Examination
const createGeneralExamination = (patientId) =>
  client.post(
    `/general_examination/v1/patient/${patientId}/general-examination/`,
    {}
  );
const getGeneralExamination = (patientId, generalExaminationId) =>
  client.get(
    `/general_examination/v1/patient/${patientId}/general-examination/${generalExaminationId}/`
  );
//Mentality
const getGeneralExaminationMentality = (patientId, generalExaminationId) =>
  client.get(
    `/general_examination/v1/patient/${patientId}/general-examination/${generalExaminationId}/mentality/`
  );
const addGeneralExaminationMentality = (patientId, generalExaminationId, obj) =>
  client.post(
    `/general_examination/v1/patient/${patientId}/general-examination/${generalExaminationId}/mentality/`,
    obj
  );
const getMentalityPerceptionList = () =>
  client.get(`/general_examination/v1/mentalityperception/`);
const getMentalityMoodList = () =>
  client.get(`/general_examination/v1/mentalitymood/`);
const getMentalityMemoryList = () =>
  client.get(`/general_examination/v1/mentalitymemory/`);
//Body built
const addGeneralExaminationBodyBuilt = (patientId, generalExaminationId, obj) =>
  client.post(
    `/general_examination/v1/patient/${patientId}/general-examination/${generalExaminationId}/body-built/`,
    obj
  );
const getGeneralExaminationBodyBuilt = (patientId, generalExaminationId) =>
  client.get(
    `/general_examination/v1/patient/${patientId}/general-examination/${generalExaminationId}/body-built/`
  );
//Skin
const getGeneralExaminationSkin = (patientId, generalExaminationId) =>
  client.get(
    `/general_examination/v1/patient/${patientId}/general-examination/${generalExaminationId}/skin/`
  );
const addGeneralExaminationSkin = (patientId, generalExaminationId, obj) =>
  client.post(
    `/general_examination/v1/patient/${patientId}/general-examination/${generalExaminationId}/skin/`,
    obj
  );
const getSkinTextureList = () =>
  client.get(`/general_examination/v1/skintexture/`);
const getSkinComplexionList = () =>
  client.get(`/general_examination/v1/skincomplexion/`);
//Head
const getInspectionList = () =>
  client.get(`/general_examination/v1/inspection/`);
const getPalpationList = () => client.get(`/general_examination/v1/palpation/`);
const getPercussionList = () =>
  client.get(`/general_examination/v1/percussion/`);
const getAuscultationList = () =>
  client.get(`/general_examination/v1/auscultation/`);

const addGeneralExaminationHead = (patientId, generalExaminationId, obj) =>
  client.post(
    `/general_examination/v1/patient/${patientId}/general-examination/${generalExaminationId}/head/`,
    obj
  );
const getGeneralExaminationHead = (patientId, generalExaminationId) =>
  client.get(
    `/general_examination/v1/patient/${patientId}/general-examination/${generalExaminationId}/head/`
  );

//Vital Signs
const addGeneralExaminationVitalSigns = (
  patientId,
  generalExaminationId,
  obj
) =>
  client.post(
    `/general_examination/v1/patient/${patientId}/general-examination/${generalExaminationId}/vital-signs/`,
    obj
  );

const getGeneralExaminationVitalSigns = (patientId, generalExaminationId) =>
  client.get(
    `/general_examination/v1/patient/${patientId}/general-examination/${generalExaminationId}/vital-signs/`
  );

const getGeneralExaminationFeltAtList = () =>
  client.get(`/general_examination/v1/pulsefeltat/?pagination=false/`);

const getGeneralExaminationPulseSpecialCharacter = () =>
  client.get(
    `/general_examination/v1/pulsespecialcharacter/?pagination=false/`
  );
const getGeneralExaminationPulsePeripheralPulsations = () =>
  client.get(
    `/general_examination/v1/pulseperipheralpulsations/?pagination=false/`
  );

const getGeneralExaminationRespiratoryRateRhythms = () =>
  client.get(
    `/general_examination/v1/respiratoryraterhythm/?pagination=false/`
  );

const getGeneralExaminationRespiratoryRatetype = () =>
  client.get(`/general_examination/v1/respiratoryratetype/?pagination=false/`);

const getGeneralExaminationTemperaturePattern = () =>
  client.get(`/general_examination/v1/temperaturepattern/?pagination=false/`);

//Abdominal
const getGeneralExaminationAbdominal = (patientId, generalExaminationId) =>
  client.get(
    `/general_examination/v1/patient/${patientId}/general-examination/${generalExaminationId}/abdominal/`
  );
const addGeneralExaminationAbdominal = (patientId, generalExaminationId, obj) =>
  client.post(
    `/general_examination/v1/patient/${patientId}/general-examination/${generalExaminationId}/abdominal/`,
    obj
  );
const getAbdominalSpleenList = () =>
  client.get(`/general_examination/v1/abdominalspleen/`);
const getAbdominalLiverList = () =>
  client.get(`/general_examination/v1/abdominalliver/`);
const getAbdominalAscitesList = () =>
  client.get(`/general_examination/v1/abdominalascites/`);

//Face
const addGeneralExaminationFace = (patientId, generalExaminationId, obj) =>
  client.post(
    `/general_examination/v1/patient/${patientId}/general-examination/${generalExaminationId}/face/`,
    obj
  );
const getGeneralExaminationFace = (patientId, generalExaminationId) =>
  client.get(
    `/general_examination/v1/patient/${patientId}/general-examination/${generalExaminationId}/face/`
  );
const getGeneralExaminationFaceList = () =>
  client.get(`/general_examination/v1/face/`);
//back
const getGeneralExaminationBack = (patientId, generalExaminationId) =>
  client.get(
    `/general_examination/v1/patient/${patientId}/general-examination/${generalExaminationId}/back/`
  );
const addGeneralExaminationBack = (patientId, generalExaminationId, obj) =>
  client.post(
    `/general_examination/v1/patient/${patientId}/general-examination/${generalExaminationId}/back/`,
    obj
  );
const getGeneralExaminationMurmursList = () =>
  client.get(`/general_examination/v1/backmurmurbruit/`);
const getGeneralExaminationCrepitation = () =>
  client.get(`/general_examination/v1/backcrepitation/`);
const getGeneralExaminationVertebraeList = () =>
  client.get(`/general_examination/v1/backvertebrae/`);
const getGeneralExaminationScapulaList = () =>
  client.get(`/general_examination/v1/backscapula/`);
const getGeneralExaminationBackSkinList = () =>
  client.get(`/general_examination/v1/backskin/`);
//Nose & Cheeks
const getGeneralExaminationNoseAndCheeksList = () =>
  client.get(`/general_examination/v1/noseandcheeks/`);
const getGeneralExaminationNoseAndCheeks = (patientId, generalExaminationId) =>
  client.get(
    `/general_examination/v1/patient/${patientId}/general-examination/${generalExaminationId}/nose_and_cheeks/`
  );
const addGeneralExaminationNoseAndCheeks = (
  patientId,
  generalExaminationId,
  obj
) =>
  client.post(
    `/general_examination/v1/patient/${patientId}/general-examination/${generalExaminationId}/nose_and_cheeks/`,
    obj
  );
//Ear
const getGeneralExaminationEarList = () =>
  client.get(`/general_examination/v1/ear/`);
const getGeneralExaminationEar = (patientId, generalExaminationId) =>
  client.get(
    `/general_examination/v1/patient/${patientId}/general-examination/${generalExaminationId}/ear/`
  );
const addGeneralExaminationEar = (patientId, generalExaminationId, obj) =>
  client.post(
    `/general_examination/v1/patient/${patientId}/general-examination/${generalExaminationId}/ear/`,
    obj
  );
//Mouth & Throat
const getGeneralExaminationMouthAndThroatList = () =>
  client.get(`/general_examination/v1/mouthandthroat/`);
const addGeneralExaminationMouthAndThroat = (
  patientId,
  generalExaminationId,
  obj
) =>
  client.post(
    `/general_examination/v1/patient/${patientId}/general-examination/${generalExaminationId}/mouth_and_throat/`,
    obj
  );
const getGeneralExaminationMouthAndThroat = (patientId, generalExaminationId) =>
  client.get(
    `/general_examination/v1/patient/${patientId}/general-examination/${generalExaminationId}/mouth_and_throat/`
  );
//Parotid
const getGeneralExaminationParotidList = () =>
  client.get(`/general_examination/v1/parotid/`);
const addGeneralExaminationParotid = (patientId, generalExaminationId, obj) =>
  client.post(
    `/general_examination/v1/patient/${patientId}/general-examination/${generalExaminationId}/parotid/`,
    obj
  );
const getGeneralExaminationParotid = (patientId, generalExaminationId) =>
  client.get(
    `/general_examination/v1/patient/${patientId}/general-examination/${generalExaminationId}/parotid/`
  );

//Eyes
const addGeneralExaminationEye = (patientId, generalExaminationId, obj) =>
  client.post(
    `/general_examination/v1/patient/${patientId}/general-examination/${generalExaminationId}/eyes/`,
    obj
  );
const getGeneralExaminationEyeBrowsList = () =>
  client.get(`/general_examination/v1/eyebrows/`);
const getGeneralExaminationEyeLidsList = () =>
  client.get(`/general_examination/v1/eyelids/`);
const getGeneralExaminationEyeBallsList = () =>
  client.get(`/general_examination/v1/eyeballs/`);
const getGeneralExaminationEyeConjunctivaList = () =>
  client.get(`/general_examination/v1/eyeconjunctiva/`);
const getGeneralExaminationEyeCorneaList = () =>
  client.get(`/general_examination/v1/eyecornea/`);
const getGeneralExaminationEyeScleraList = () =>
  client.get(`/general_examination/v1/eyesclera/`);
const getGeneralExaminationEyePupilsList = () =>
  client.get(`/general_examination/v1/eyepupils/`);
const getGeneralExaminationEyeMovementList = () =>
  client.get(`/general_examination/v1/eyemovement/`);
const getGeneralExaminationEyeLacrimalGlandsList = () =>
  client.get(`/general_examination/v1/eyelacrimalglands/`);
const getGeneralExaminationEyeLensList = () =>
  client.get(`/general_examination/v1/eyelens/`);
const getGeneralExaminationEyeFundusList = () =>
  client.get(`/general_examination/v1/eyefundus/`);

//neck

const addGeneralExaminationNeck = (patientId, generalExaminationId, obj) =>
  client.post(
    `/general_examination/v1/patient/${patientId}/general-examination/${generalExaminationId}/neck/`,
    obj
  );
const getGeneralExaminationNeckGeneralExaminationList = () =>
  client.get(`/general_examination/v1/neckgeneralexamination/`);
const getGeneralExaminationNeckThyroidGlandList = () =>
  client.get(`/general_examination/v1/neckthyroidgland/`);
const getGeneralExaminationNeckVeinsList = () =>
  client.get(`/general_examination/v1/neckveins/`);
const getGeneralExaminationNeckCarotidArteryList = () =>
  client.get(`/general_examination/v1/neckcarotidartery/`);

// upper limb
const addGeneralExaminationUpperLimb = (patientId, generalExaminationId, obj) =>
  client.post(
    `/general_examination/v1/patient/${patientId}/general-examination/${generalExaminationId}/upper-limb/`,
    obj
  );
const getGeneralExaminationUpperLimbGripList = () =>
  client.get(`/general_examination/v1/upperlimbgrip/`);
const getGeneralExaminationUpperLimbShapeList = () =>
  client.get(`/general_examination/v1/upperlimbshape/`);
const getGeneralExaminationLimbSkinList = () =>
  client.get(`/general_examination/v1/limbskin/`);
const getGeneralExaminationUpperLimbUnitaryMovementList = () =>
  client.get(`/general_examination/v1/upperlimbunitarymovement/`);
const getGeneralExaminationLimbNailList = () =>
  client.get(`/general_examination/v1/limbnail/`);

//lower limb
const addGeneralExaminationLowerLimb = (patientId, generalExaminationId, obj) =>
  client.post(
    `/general_examination/v1/patient/${patientId}/general-examination/${generalExaminationId}/lower-limb/`,
    obj
  );
const getGeneralExaminationLowerLimbShapeList = () =>
  client.get(`/general_examination/v1/lowerlimbshape/`);
const getGeneralExaminationLowerLimbEdemaList = () =>
  client.get(`/general_examination/v1/lowerlimbedema/`);

//decubitus
const getGeneralExaminationDecubitus = (patientId, generalExaminationId) =>
  client.get(
    `/general_examination/v1/patient/${patientId}/general-examination/${generalExaminationId}/decubitus/`
  );
const addGeneralExaminationDecubitus = (patientId, generalExaminationId, obj) =>
  client.post(
    `/general_examination/v1/patient/${patientId}/general-examination/${generalExaminationId}/decubitus/`,
    obj
  );
const getGeneralExaminationDecubitusList = () =>
  client.get(`/general_examination/v1/decubitus/`);

//Procedure
const getProcedureList = (patientId, pageNumber) =>
  client.get(
    `/procedures/v1/patient/${patientId}/procedures/?page=${pageNumber}&page_size=${defaultPageSize}`
  );
const createProcedure = (patientId, obj) =>
  client.post(`/procedures/v1/patient/${patientId}/procedures/`, obj);
const getProcedureIchiListData = (query = "") =>
  client.get(`procedures/v1/data/procedureichilistdata/${query}`);

//summary
const addProcedureSummary = (patientId, procedureId, obj) =>
  client.post(
    `/procedures/v1/patient/${patientId}/procedures/${procedureId}/summary/`,
    obj
  );
const getProcedureSummary = (patientId, procedureId) =>
  client.get(
    `/procedures/v1/patient/${patientId}/procedures/${procedureId}/summary/`
  );

//surgical data
const addProcedureSurgicalData = (patientId, procedureId, obj) =>
  client.post(
    `/procedures/v1/patient/${patientId}/procedures/${procedureId}/surgical_data/`,
    obj
  );
const getProcedureSurgicalData = (patientId, procedureId) =>
  client.get(
    `/procedures/v1/patient/${patientId}/procedures/${procedureId}/surgical_data/`
  );

//PreAnaesthesia
const getProcedurePreAnaesthesia = (patientId, procedureId) =>
  client.get(
    `/procedures/v1/patient/${patientId}/procedures/${procedureId}/preoperative_anaesthesia/`
  );
const addProcedurePreAnaesthesia = (patientId, procedureId, obj) =>
  client.post(
    `/procedures/v1/patient/${patientId}/procedures/${procedureId}/preoperative_anaesthesia/`,
    obj
  );
const getProcedureSurgerySideList = () =>
  client.get(
    `/procedures/v1/data/procedurepreoperativeanaesthesiasurgerysidedata/`
  );
const getProcedureBloodGroupList = () =>
  client.get(`/procedures/v1/data/procedurebloodgroupdata/`);
const getProcedureVirtualMarkerList = () =>
  client.get(
    `/procedures/v1/data/procedurepreoperativeanaesthesiavirtualmarkerdata/`
  );
const getProcedureAnaesthesiaTypeList = () =>
  client.get(`/procedures/v1/data/procedureanaesthesiatypedata/`);
const getProcedureMallapatiList = () =>
  client.get(
    `/procedures/v1/data/procedurepreoperativeanaesthesiamallapatidata/`
  );
const getProcedureAsascoreList = () =>
  client.get(
    `/procedures/v1/data/procedurepreoperativeanaesthesiaasascoredata/`
  );

//anesthesia
const getProcedureAnaesthesiaMonitoringList = (patientId) =>
  client.get(`/procedures/v1/data/procedureanaesthesiamonitoringdata/`);

const getProcedureAnaesthesiaVenousList = (patientId) =>
  client.get(
    `/procedures/v1/data/procedureanaesthesiavenusaccesslinesitedata/`
  );

const getProcedureAnaesthesiaDeviceTypeList = (patientId) =>
  client.get(`/procedures/v1/data/procedureanaesthesiaairwaydevicetypedata/`);

const getProcedureAnaesthesiaDeviceIntubationList = (patientId) =>
  client.get(
    `/procedures/v1/data/procedureanaesthesiaairwaydeviceintubationdata/`
  );

const getProcedureAnaesthesiaPostOperativeTransferList = (patientId) =>
  client.get(
    `/procedures/v1/data/procedureanaesthesiapostoperativetransferdata/`
  );

const addProcedureAnaesthesia = (patientId, procedureId, obj) =>
  client.post(
    `/procedures/v1/patient/${patientId}/procedures/${procedureId}/anaesthesia/`,
    obj
  );

const getProcedureAnaesthesia = (patientId, procedureId) =>
  client.get(
    `/procedures/v1/patient/${patientId}/procedures/${procedureId}/anaesthesia/`
  );

///Reciptionist view
const entityEnrollPatient = (obj) =>
  client.post(`/entity-enroll/v1/patient-enroll/enroll/`, obj);
const getPatientsListSecureSearch = (query) =>
  client.get(`/profile/v1/patients/secure_search/${query}`);

const createPatientApproval = (patientId) =>
  client.post(`/patient-approval/v1/patient/${patientId}/approvals/`);

//Consents
const getConsentList = (patientId, pageNumber) =>
  client.get(
    `/consents/v1/patient/${patientId}/consents/?page=${pageNumber}&page_size=${defaultPageSize}`
  );
const addConsent = (patientId, obj) =>
  client.post(`/consents/v1/patient/${patientId}/consents/`, obj);
const getConsent = (patientId, consentId) =>
  client.get(`/consents/v1/patient/${patientId}/consents/${consentId}/`);

//permissions
const getMyPermissions = () =>
  client.get(`/profile/v1/practitioners/my_permissions/`);

//Layers
const getLayerList = () =>
  client.get(`/entity-setup/v1/layer/?pagination=false`);

//notifications
const getNotificationList = (pageNumber) =>
  client.get(`/notifications/v1/notification/?page=${pageNumber}&page_size=10`);
// const getNextNotificationList = (pageNumber) =>
//   client.get(`/notifications/v1/notification/?page=${pageNumber}&page_size=10`);
const getNotificationUnreadCount = () =>
  client.get(`/notifications/v1/notification/unread_count/`);

//referrals
const createReferral = (patientId, admissionId, obj) =>
  client.post(
    `/admission/v1/patient/${patientId}/admissions/${admissionId}/referrals/`,
    obj
  );
const getReferralList = (pageNumber) =>
  client.get(
    `/admission/v1/referrals/?page=${pageNumber}&page_size=${defaultPageSize}`
  );
const getReferral = (id) => client.get(`/admission/v1/referrals/${id}/`);
const getMyReferralList = (pageNumber) =>
  client.get(
    `/admission/v1/my-referrals/?page=${pageNumber}&page_size=${defaultPageSize}`
  );
const getMyReferral = (id) => client.get(`/admission/v1/my-referrals/${id}/`);
const acceptReferral = (id) =>
  client.patch(`/admission/v1/referrals/${id}/accept/`);
const rejectReferral = (id, body) =>
  client.patch(`/admission/v1/referrals/${id}/reject/`, body);

//Doctor Schedule
const getDoctorAvailabilityList = (clinicId, doctorId) =>
  client.get(
    `/clinic-scheduler-management/v1/doctor/${doctorId}/departments/${clinicId}/doctor_schedules/`
  );
const addDoctorAvailability = (clinicId, doctorId, obj) =>
  client.post(
    `/clinic-scheduler-management/v1/doctor/${doctorId}/departments/${clinicId}/doctor_schedules/`,
    obj
  );
const getAppointmentList = (clinicId, doctorId, startDate, endDate) =>
  client.get(
    `/clinic-scheduler-management/v1/doctor/${doctorId}/departments/${clinicId}/doctor_schedules/get_doctor_time_schedule/?start_date=${startDate}&end_date=${endDate}&pagination=false`
  );
const getClinicList = () =>
  client.get(`/clinic-scheduler-management/v1/departments/?pagination=false`);

const getClincDoctorList = (clinicId) =>
  client.get(
    `/clinic-scheduler-management/v1/departments/${clinicId}/?doctor_only=true&pagination=false`
  );

const cancelAppintment = (doctorId, shiftId, appointmentId) =>
  client.post(
    `/clinic-scheduler-management/v1/doctor/${doctorId}/shift/${shiftId}/appointments/${appointmentId}/cancel/`
  );

const confirmAppointment = (doctorId, shiftId, appointmentId) =>
  client.post(
    `/clinic-scheduler-management/v1/doctor/${doctorId}/shift/${shiftId}/appointments/${appointmentId}/confirm_appointment/`
  );

const deleteAvailability = (doctorId, clinicId, availabilityId) =>
  client.delete(
    `/clinic-scheduler-management/v1/doctor/${doctorId}/departments/${clinicId}/doctor_schedules/${availabilityId}/`
  );

const requestClinicPatientApproval = (patientId) =>
  client.post(`/patient-approval/v1/patient/${patientId}/approvals/`, {
    type: "Permission-Department",
  });
//special examination
const getSpecialExaminationForm = () =>
  client.get(`/custom_form/v2/doctor_forms/`);

const createEncounerSpecialExamination = () =>
  client.post("/custom_form/v2/special_examination/");

const addSpecialExaminationTabValues = (
  formId,
  tabId,
  specialExaminationId,
  obj
) =>
  client.post(
    `/custom_form/v2/forms/${formId}/tabs/${tabId}/special_examination/${specialExaminationId}/form_values/`,
    obj
  );

const getSpecialExaminationValues = (specialExaminationId) =>
  client.get(
    `/custom_form/v2/special_examination/${specialExaminationId}/form_values/`
  );
const getSpecialExaminationTabFields = (formId, tabId) =>
  client.get(`/custom_form/v2/forms/${formId}/tabs/${tabId}/?pagination=false`);

const getSpecialExaminationTabSingleFields = (formId, tabId) =>
  client.get(
    `/custom_form/v2/forms/${formId}/tabs/${tabId}/single_fields/?pagination=false`
  );

//Chat
const getPatientOldMessages = (patientId, page) =>
  client.get(`/chat-app/my-users/${patientId}/messages/?page=${page}`);

export const uploadFileToServer = ({ userId, file }) =>
  client.post(`/chat-app/my-users/${userId}/upload-file/`, file);

//my team
const getMyTeam = () => client.get(`/entity-setup/v1/teams/my_team/`);

export default {
  getPatients,
  addPatient,
  getDiseases,
  getFilteredDiseases,
  addPastHistory,
  getPastHistory,
  // addPastHistoryGeneralForm,
  // getPastHistoryGeneralForm,
  // addYoungerThanTwelveForm,
  // getYoungerThanTwelveForm,
  // addAdultFemaleForm,
  // getAdultFemaleForm,
  getEncountersList,
  getEncounterPhoneCall,
  getEncounterProgressNote,
  getEncounterClinicVisit,
  addEncounterPhoneCall,
  addEncounterProgressNote,
  addEncounterClinicVisit,
  getOrdersList,
  getOrder,
  addOrder,
  getOrderTypesList,
  getOrderSubTypesList,
  getRadioList,
  getRadio,
  getLabList,
  getLab,
  completeOrderFullfillment,
  getPatientPersonalHistory,
  login,
  getEntitiesList,
  loginWithEntity,
  editPatientPersonalHistory,
  sendFile,
  deleteFile,
  getInstructionsList,
  getInstruction,
  addInstruction,
  updateInstructionsStatus,
  addAdmission,
  getAdmissionList,
  getAdmission,
  hasActiveAdmission,
  getAdmissionDischargeList,
  addAdmissionDischarge,
  getAdmissionPrescriptionList,
  addAdmissionPrescription,
  getAdmissionFollowupList,
  addAdmissionFollowup,
  getAdmissionFollowup,
  getDrugList,
  getRouteAdministrationList,
  getFilteredDrugList,
  getMealRelationList,
  getDosesTime,
  addPrescription,
  getPrescriptionList,
  getDoctorList,
  getMyPractitionerProfile,
  getMyDoctorProfile,
  updateDoctorProfile,
  deleteDoctorDegrees,
  getNurseList,
  getPractitionerList,
  getAdmissionOrdersList,
  getAdmissionOrder,
  addAdmissionOrder,
  getPrescription,
  getAdmissionPrescription,
  togglePrescription,
  toggleAdmissionPrescription,
  sendOtpById,
  validateOtpEnrollUser,
  getStates,
  getAreas,
  resetPassword,
  changePassword,
  requestResetEmail,
  createGeneralExamination,
  getGeneralExamination,
  addGeneralExaminationMentality,
  getGeneralExaminationMentality,
  getMentalityPerceptionList,
  getMentalityMoodList,
  getMentalityMemoryList,
  addGeneralExaminationBodyBuilt,
  getGeneralExaminationBodyBuilt,
  addGeneralExaminationSkin,
  getGeneralExaminationSkin,
  getSkinTextureList,
  getSkinComplexionList,
  getInspectionList,
  getPalpationList,
  getPercussionList,
  getAuscultationList,
  addGeneralExaminationHead,
  getGeneralExaminationHead,
  addGeneralExaminationVitalSigns,
  getGeneralExaminationVitalSigns,
  getGeneralExaminationFeltAtList,
  getGeneralExaminationPulseSpecialCharacter,
  getGeneralExaminationPulsePeripheralPulsations,
  getGeneralExaminationRespiratoryRateRhythms,
  getGeneralExaminationRespiratoryRatetype,
  getGeneralExaminationTemperaturePattern,
  getGeneralExaminationAbdominal,
  addGeneralExaminationAbdominal,
  addGeneralExaminationFace,
  getGeneralExaminationFaceList,
  getGeneralExaminationFace,
  addGeneralExaminationNoseAndCheeks,
  getGeneralExaminationNoseAndCheeks,
  getGeneralExaminationNoseAndCheeksList,
  getGeneralExaminationEarList,
  addGeneralExaminationEar,
  getGeneralExaminationEar,
  getGeneralExaminationMouthAndThroatList,
  addGeneralExaminationMouthAndThroat,
  getGeneralExaminationMouthAndThroat,
  addGeneralExaminationParotid,
  getGeneralExaminationParotid,
  getGeneralExaminationParotidList,
  getAbdominalAscitesList,
  getAbdominalLiverList,
  getAbdominalSpleenList,
  addGeneralExaminationBack,
  getGeneralExaminationBack,
  getGeneralExaminationBackSkinList,
  getGeneralExaminationCrepitation,
  getGeneralExaminationMurmursList,
  getGeneralExaminationScapulaList,
  getGeneralExaminationVertebraeList,
  addGeneralExaminationEye,
  getGeneralExaminationEyeBrowsList,
  getGeneralExaminationEyeLidsList,
  getGeneralExaminationEyeBallsList,
  getGeneralExaminationEyeConjunctivaList,
  getGeneralExaminationEyeCorneaList,
  getGeneralExaminationEyeScleraList,
  getGeneralExaminationEyePupilsList,
  getGeneralExaminationEyeMovementList,
  getGeneralExaminationEyeLacrimalGlandsList,
  getGeneralExaminationEyeLensList,
  getGeneralExaminationEyeFundusList,
  addGeneralExaminationNeck,
  getGeneralExaminationNeckGeneralExaminationList,
  getGeneralExaminationNeckThyroidGlandList,
  getGeneralExaminationNeckVeinsList,
  getGeneralExaminationNeckCarotidArteryList,
  addGeneralExaminationUpperLimb,
  getGeneralExaminationUpperLimbGripList,
  getGeneralExaminationUpperLimbShapeList,
  getGeneralExaminationLimbSkinList,
  getGeneralExaminationUpperLimbUnitaryMovementList,
  getGeneralExaminationLimbNailList,
  addGeneralExaminationLowerLimb,
  getGeneralExaminationLowerLimbShapeList,
  getGeneralExaminationLowerLimbEdemaList,
  getGeneralExaminationDecubitus,
  addGeneralExaminationDecubitus,
  getGeneralExaminationDecubitusList,
  getProcedureList,
  createProcedure,
  addProcedureSummary,
  getProcedureSummary,
  addProcedureSurgicalData,
  getProcedureSurgicalData,
  getProcedurePreAnaesthesia,
  addProcedurePreAnaesthesia,
  addProcedureAnaesthesia,
  getProcedureAnaesthesia,
  getProcedureSurgerySideList,
  getProcedureBloodGroupList,
  getProcedureVirtualMarkerList,
  getProcedureAnaesthesiaTypeList,
  getProcedureMallapatiList,
  getProcedureAsascoreList,
  getProcedureAnaesthesiaMonitoringList,
  getProcedureAnaesthesiaVenousList,
  getProcedureAnaesthesiaDeviceTypeList,
  getProcedureAnaesthesiaDeviceIntubationList,
  getProcedureAnaesthesiaPostOperativeTransferList,
  getProcedureIchiListData,
  entityEnrollPatient,
  getPatientsListSecureSearch,
  getConsentList,
  addConsent,
  getConsent,
  getMyPermissions,
  getLayerList,
  getNotificationList,
  getNotificationUnreadCount,
  createPatientApproval,
  createReferral,
  getReferralList,
  getReferral,
  getMyReferral,
  getMyReferralList,
  acceptReferral,
  rejectReferral,
  getDoctorAvailabilityList,
  addDoctorAvailability,
  getAppointmentList,
  getClinicList,
  getClincDoctorList,
  cancelAppintment,
  deleteAvailability,
  requestClinicPatientApproval,
  confirmAppointment,
  getPatientOldMessages,
  getSpecialExaminationForm,
  createEncounerSpecialExamination,
  addSpecialExaminationTabValues,
  getSpecialExaminationValues,
  getSpecialExaminationTabFields,
  getSpecialExaminationTabSingleFields,
  uploadFileToServer,
  getMyTeam,
};
