import React, { useState } from "react";
import { ImageList, ImageListItem, Stack } from "@mui/material";
import Modal from "./UI/Modal";
import { Delete } from "@mui/icons-material";

function ImagesGallery({ images = [], handleRemoveImage }) {
  const [openModal, setOpenModal] = useState(false);
  const [selectedImageSrc, setSelectedImageSrc] = useState();
  const [isHover, setIsHover] = useState({});

  const handleOpenModal = () => {
    setOpenModal((s) => !s);
  };

  const handleImageClick = (e) => {
    console.log(e.target.src);
    setSelectedImageSrc(e.target.src);
    setOpenModal(true);
  };

  return (
    <Stack maxWidth={1000} overflow={"hidden"}>
      <Modal open={openModal} handleOpen={handleOpenModal}>
        <Stack maxWidth={1000}>
          <img
            src={selectedImageSrc}
            // maxWidth={"100%"}
            // maxHeight={"100%"}
            style={{ objectFit: "contain" }}
          />
        </Stack>
      </Modal>
      <ImageList
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
        }}
      >
        {images.map((image, index) => (
          <ImageListItem key={index}>
            <Stack
              onMouseEnter={() => setIsHover({ [index]: true })}
              onMouseLeave={() => setIsHover({})}
            >
              {handleRemoveImage && isHover?.[index] && (
                <Delete
                  style={{
                    color: "grey",
                    position: "absolute",
                    top: 0,
                    right: 0,
                    cursor: "pointer",
                  }}
                  onClick={() => handleRemoveImage(index)}
                />
              )}
              <img
                src={handleRemoveImage ? URL.createObjectURL(image) : image}
                alt={"Reference image"}
                onClick={handleImageClick}
                // onMouseOver={handleImageHover}
                style={{
                  width: "300px",
                  maxHeight: "300px",
                  cursor: "pointer",
                  objectFit: "contain",
                  border: "1px solid black",
                }}
              />
            </Stack>
          </ImageListItem>
        ))}
      </ImageList>
    </Stack>
  );
}

export default ImagesGallery;
