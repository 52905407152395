import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m17.435 11.98-.001.001-4.772 2.955a.086.086 0 0 1-.047.014.098.098 0 0 1-.049-.014c-.033-.02-.066-.062-.066-.133v-1.598l-.442-.052-.058.497.057-.497h-.006l-.013-.002a5.5 5.5 0 0 0-.7-.008 6.761 6.761 0 0 0-1.686.312 6.68 6.68 0 0 0-2.762 1.712c.545-2.087 1.41-3.204 2.244-3.813 1.093-.798 2.234-.804 2.866-.804h.5V8.896c0-.07.033-.111.066-.131a.096.096 0 0 1 .048-.015c.011 0 .027.003.047.015l4.773 2.954h.001c.038.024.066.068.066.131s-.028.107-.066.13Z"
      stroke="#003468"
    />
    <path
      d="m.75 4.5.06 1.305a2.985 2.985 0 0 0-.513 1.966l.955 10.5A3 3 0 0 0 4.24 21H19.76a3 3 0 0 0 2.986-2.729l.956-10.5A3 3 0 0 0 20.715 4.5h-5.973a3 3 0 0 1-2.121-.879l-1.242-1.242A3 3 0 0 0 9.258 1.5H3.75a3 3 0 0 0-3 3Zm1.04 3.135A1.5 1.5 0 0 1 3.286 6h17.43a1.5 1.5 0 0 1 1.494 1.635l-.954 10.5A1.5 1.5 0 0 1 19.76 19.5H4.239a1.5 1.5 0 0 1-1.493-1.365l-.955-10.5ZM9.259 3a1.5 1.5 0 0 1 1.06.44l1.06 1.06H3.286c-.36 0-.705.063-1.025.18l-.01-.21A1.5 1.5 0 0 1 3.75 3h5.508Z"
      fill="#003468"
    />
  </svg>
);

export default SvgComponent;
