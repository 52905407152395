import React, { useState } from "react";
import { Stack, Button, TabScrollButton, Typography } from "@mui/material";

import CebForm from "../../../components/Form/CebForm";
import { useEffect, useRef, useLayoutEffect } from "react";
import Mentality from "./Mentality";
import BodyBuilt from "./BodyBuilt";
import Skin from "./Skin";
import Head from "./Head";
import VitalSigns from "./VitalSigns";
import UpperLimb from "./UpperLimb";
import LowerLimb from "./LowerLimb";
import Neck from "./Neck";
import Eyes from "./Eyes";
import Abdominal from "./Abdominal";
import Back from "./Back";
import Face from "./Face";
import NoseAndCheeks from "./NoseAndCheeks";
import Ear from "./Ear";
import MouthAndThroat from "./MouthAndThroat";
import Parotid from "./Parotid";
import Decubitus from "./Decubitus";
import ThirdNavTabs from "../../../components/Navigation/ThirdNavTabs";

const tabs = [
  {
    name: "Vital Signs",
    component: (tabState, id, encounterViewModeKey, detailView) => (
      <VitalSigns
        tabState={tabState}
        generalExaminationId={id}
        encounterViewModeKey={encounterViewModeKey}
        detailView={detailView}
      />
    ),
  },
  {
    name: "Mentality",
    component: (tabState, id, encounterViewModeKey, detailView) => (
      <Mentality
        tabState={tabState}
        generalExaminationId={id}
        encounterViewModeKey={encounterViewModeKey}
        detailView={detailView}
      />
    ),
  },
  {
    name: "Body Build",
    component: (tabState, id, encounterViewModeKey, detailView) => (
      <BodyBuilt
        tabState={tabState}
        generalExaminationId={id}
        encounterViewModeKey={encounterViewModeKey}
        detailView={detailView}
      />
    ),
  },
  {
    name: "Decubitus",
    component: (tabState, id, encounterViewModeKey, detailView) => (
      <Decubitus
        tabState={tabState}
        generalExaminationId={id}
        encounterViewModeKey={encounterViewModeKey}
        detailView={detailView}
      />
    ),
  },
  {
    name: "Skin",
    component: (tabState, id, encounterViewModeKey, detailView) => (
      <Skin
        tabState={tabState}
        generalExaminationId={id}
        encounterViewModeKey={encounterViewModeKey}
        detailView={detailView}
      />
    ),
  },
  {
    name: "Head",
    component: (tabState, id, encounterViewModeKey, detailView) => (
      <Head
        tabState={tabState}
        generalExaminationId={id}
        encounterViewModeKey={encounterViewModeKey}
        detailView={detailView}
      />
    ),
  },
  {
    name: "Eyes",
    component: (tabState, id, encounterViewModeKey, detailView) => (
      <Eyes
        tabState={tabState}
        generalExaminationId={id}
        encounterViewModeKey={encounterViewModeKey}
        detailView={detailView}
      />
    ),
  },
  {
    name: "Neck",
    component: (tabState, id, encounterViewModeKey, detailView) => (
      <Neck
        tabState={tabState}
        generalExaminationId={id}
        encounterViewModeKey={encounterViewModeKey}
        detailView={detailView}
      />
    ),
  },
  {
    name: "Upper Limb",
    component: (tabState, id, encounterViewModeKey, detailView) => (
      <UpperLimb
        tabState={tabState}
        generalExaminationId={id}
        encounterViewModeKey={encounterViewModeKey}
        detailView={detailView}
      />
    ),
  },
  {
    name: "Lower Limb",
    component: (tabState, id, encounterViewModeKey, detailView) => (
      <LowerLimb
        tabState={tabState}
        generalExaminationId={id}
        encounterViewModeKey={encounterViewModeKey}
        detailView={detailView}
      />
    ),
  },

  {
    name: "Abdominal",
    component: (tabState, id, encounterViewModeKey, detailView) => (
      <Abdominal
        tabState={tabState}
        generalExaminationId={id}
        encounterViewModeKey={encounterViewModeKey}
        detailView={detailView}
      />
    ),
  },
  {
    name: "Back ",
    component: (tabState, id, encounterViewModeKey, detailView) => (
      <Back
        tabState={tabState}
        generalExaminationId={id}
        encounterViewModeKey={encounterViewModeKey}
        detailView={detailView}
      />
    ),
  },
  {
    name: "Face",
    component: (tabState, id, encounterViewModeKey, detailView) => (
      <Face
        tabState={tabState}
        generalExaminationId={id}
        encounterViewModeKey={encounterViewModeKey}
        detailView={detailView}
      />
    ),
  },
  {
    name: "Mouth & Throat",
    component: (tabState, id, encounterViewModeKey, detailView) => (
      <MouthAndThroat
        tabState={tabState}
        generalExaminationId={id}
        encounterViewModeKey={encounterViewModeKey}
        detailView={detailView}
      />
    ),
  },
  {
    name: "Ear",
    component: (tabState, id, encounterViewModeKey, detailView) => (
      <Ear
        tabState={tabState}
        generalExaminationId={id}
        encounterViewModeKey={encounterViewModeKey}
        detailView={detailView}
      />
    ),
  },
  {
    name: "Nose & Cheeks",
    component: (tabState, id, encounterViewModeKey, detailView) => (
      <NoseAndCheeks
        tabState={tabState}
        generalExaminationId={id}
        encounterViewModeKey={encounterViewModeKey}
        detailView={detailView}
      />
    ),
  },
  {
    name: "Parotid",
    component: (tabState, id, encounterViewModeKey, detailView) => (
      <Parotid
        tabState={tabState}
        generalExaminationId={id}
        encounterViewModeKey={encounterViewModeKey}
        detailView={detailView}
      />
    ),
  },
];

const GeneralExamination = ({
  encounterViewModeKey,
  generalExaminationId,
  detailView,
  generalExamination,
}) => {
  const [tabId, setTabId] = useState(0);
  const [encounterViewMode, setEncounterViewMode] = encounterViewModeKey;

  useEffect(() => {
    console.log("tab", tabs.filter((_, index) => index === tabId)[0].component);
  }, [tabId]);

  return (
    <>
      <ThirdNavTabs
        tabState={[tabId, setTabId]}
        tabs={tabs}
        encounterViewMode={encounterViewMode}
      />
      {tabs.map((tab, index) => (
        <div key={index} style={{ display: index !== tabId && "none" }}>
          {tab.component(
            [tabId, setTabId],
            generalExaminationId,
            encounterViewModeKey,
            detailView,
            generalExamination
          )}
        </div>
      ))}
    </>
  );
};

export default GeneralExamination;
