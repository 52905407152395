import { Stack, Button, Box, TabScrollButton, Typography } from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const ThirdNav = ({ navItems }) => {
  const { t } = useTranslation();
  const stackRef = useRef();
  const [isOverflow, setOverflow] = useState(false);

  useLayoutEffect(() => {
    const { clientWidth, scrollWidth } = stackRef.current;
    setOverflow(scrollWidth > clientWidth);
  }, [stackRef, navItems]);

  return (
    <Stack
      direction="row"
      width="100%"
      gap={1}
      position="relative"
      paddingLeft={isOverflow ? 5 : 0}
      paddingRight={isOverflow ? 5 : 0}
    >
      {isOverflow && (
        <TabScrollButton
          direction="left"
          color="secondary"
          style={{ position: "absolute", left: 0, top: "20%" }}
          onClick={() => (stackRef.current.scrollLeft -= 900)}
        />
      )}
      <Stack
        gap={1}
        direction="row"
        sx={{
          borderBottom: "2px solid #8E0201",
          overflow: "auto",
          WebkitOverflowScrolling: "touch",
          scrollBehavior: "smooth",
          pt: 1,
        }}
        className="scroll-container"
        width="fit-content"
        ref={stackRef}
      >
        {navItems.map((item, index) => (
          <NavLink
            key={index}
            value={item.name}
            to={item.link}
            style={{ textDecorationLine: "none" }}
          >
            {({ isActive }) => (
              <Button
                variant={isActive ? "contained" : "outlined"}
                color="secondary"
                style={{
                  borderWidth: "2px",
                  borderTopLeftRadius: 20,
                  borderTopRightRadius: 20,
                  borderBottomRightRadius: 0,
                  borderBottomLeftRadius: 0,
                  textTransform: "none",
                  fontWeight: 500,
                  marginBottom: -2,
                }}
              >
                <Typography noWrap>{t(item.name)}</Typography>
              </Button>
            )}
          </NavLink>
        ))}
      </Stack>
      {isOverflow && (
        <TabScrollButton
          direction="right"
          style={{ position: "absolute", right: 0, top: "20%" }}
          onClick={() => (stackRef.current.scrollLeft += 900)}
        />
      )}
    </Stack>
  );
};

export default ThirdNav;
