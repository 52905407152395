import React, { useEffect, useState } from "react";
import mainApi from "../api/main";
import useApi from "../hooks/useApi";
import {
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CebForm from "../components/Form/CebForm";
import { Key } from "@mui/icons-material";
import Modal from "../components/UI/Modal";
import { useForm } from "react-hook-form";
import { useYupResolver } from "./ResetPassword";
import { LoginPasswordField } from "./UserLoginPage";
import { useTranslation } from "react-i18next";

const ViewButton = ({ url }) => {
  const { t } = useTranslation();
  return (
    <a
      style={{
        background: "#A5D2FF",
        color: "#003468",
        padding: "1px 6px 1px 6px",
        borderRadius: 5,
        fontSize: 14,
        fontWeight: 300,
        cursor: "pointer",
      }}
      onClick={() => window.open(url, "_blank").focus()}
    >
      {t("view")}
    </a>
  );
};

export default function ProfilePage() {
  // States
  const [viewMode, setViewMode] = useState("list");
  const [openPasswordModel, setOpenPasswordModel] = useState(false);
  const { t } = useTranslation();

  const handleOpenPasswordModel = () => {
    setOpenPasswordModel((state) => !state);
  };

  const theme = useTheme();
  const webView = useMediaQuery(theme.breakpoints.up("md"));
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));

  // api
  const getProfile = useApi(mainApi.getMyPractitionerProfile);
  const updateDoctorProfile = useApi(mainApi.updateDoctorProfile);

  useEffect(() => {
    getProfile.request();
  }, [updateDoctorProfile.data]);

  const profileData = [
    {
      fieldType: "element",
      label: "NID",
      element: (
        <Stack
          direction={mobileView ? "column" : "row"}
          gap={2}
          alignItems={mobileView ? "flex-start" : "center"}
        >
          <Typography fontSize="16px" fontWeight={300}>
            {getProfile.data.username?.substr(1)}
          </Typography>
          <ViewButton url={getProfile.data.username_pic} />
        </Stack>
      ),
    },
    (getProfile.data.syndicate_id || getProfile.data.syndicate_pic) && {
      fieldType: "element",
      label: "Syndicate",
      element: (
        <Stack
          direction={mobileView ? "column" : "row"}
          gap={2}
          alignItems={mobileView ? "flex-start" : "center"}
        >
          <Typography fontSize="16px" fontWeight={300}>
            {getProfile.data.syndicate_id}
          </Typography>
          <ViewButton url={getProfile.data.syndicate_pic} />
        </Stack>
      ),
    },
    {
      fieldType: "label",
      label: "Date Of Birth",
      data: getProfile.data.birth_date,
    },

    {
      fieldType: "label",
      label: "Egyptian Fellowship Year",
      data: getProfile.data.egyptian_fellowship_date,
    },
    getProfile.data.professional_permit && {
      fieldType: "link",
      label: "Profession Permit",
      data: {
        file: getProfile.data.professional_permit,
        file_name: t("view"),
      },
    },
    getProfile.data.degrees?.length > 0 && {
      fieldType: "element",
      label: "Education",
      element: getProfile.data.degrees?.map((item, index) => (
        <Stack
          direction={mobileView ? "column" : "row"}
          alignItems={mobileView ? "flex-start" : "center"}
          gap={2}
          key={index}
        >
          <Typography fontSize="16px" fontWeight={300}>
            {`${item.type},${item.name ? ` ${item.name},` : ""} ${
              item.university
            }, ${item.graduation_year}`}
          </Typography>
          <ViewButton url={item.certificate} />
        </Stack>
      )),
    },
    {
      fieldType: "element",
      width: "100%",
      element: (
        <Stack direction="row" justifyContent="flex-end" pr={2}>
          <Button
            variant="outlined"
            sx={{ textTransform: "none" }}
            onClick={handleOpenPasswordModel}
          >
            <Stack direction={"row"} alignItems={"center"} gap={1}>
              {t("Change Password")}
              <Key />
            </Stack>
          </Button>
        </Stack>
      ),
    },
  ].filter((item) => item);

  const cardItems = [
    getProfile.data.profile_pic && {
      header: "",
      keyName: "",
    },
    {
      header: "Name",
      keyName: "data.name",
    },
    {
      header: "Specialty",
      keyName: "data.specialty",
    },
    {
      header: "Email",
      keyName: "data.email",
    },
  ].filter((item) => item);

  const cardsData = [
    {
      id: 1,
      name: getProfile.data.full_name,
      nid: getProfile.data.username?.substr(1),
      email: getProfile.data.email,
      specialty: getProfile.data.type?.name || getProfile.data.specialty,
    },
  ];

  return (
    <Stack width="100%" p={webView ? "50px 80px 30px 80px" : "20px"}>
      <Modal
        open={openPasswordModel}
        handleOpen={handleOpenPasswordModel}
        width={700}
      >
        <PasswordModal closeHandler={handleOpenPasswordModel} />
      </Modal>
      <Stack>
        {Object.keys(getProfile.data)?.length > 0 && (
          <CebForm
            viewModeState={[viewMode, setViewMode]}
            listData={profileData}
            cardsData={cardsData}
            cardItems={cardItems}
            chosenCardId={1}
            profile
            profilePic={getProfile.data.profile_pic}
            updateProfilePicHandler={async (img64) => {
              const res = await updateDoctorProfile.requestWithToast(
                t("Updated successfully"),
                {
                  profile_pic: img64,
                }
              );
              return res;
            }}
            addInList={true}
          />
        )}
      </Stack>
    </Stack>
  );
}

const PasswordModal = ({ closeHandler }) => {
  const [viewMode, setViewMode] = useState("form");
  const { t, i18n } = useTranslation();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ resolver: useYupResolver("change"), mode: "onTouched" });

  //api
  const changePassword = useApi(mainApi.changePassword);

  const onSubmit = async (data) => {
    const res = await changePassword.requestWithToast(
      t("Changed successfully"),
      data.old_password,
      data.password,
      data.confirm_password
    );
    if (res.ok) {
      closeHandler();
    }
  };

  return (
    <Stack mb={2} sx={{ direction: i18n.language === "en" ? "ltr" : "rtl" }}>
      <form onSubmit={handleSubmit(onSubmit)} style={{ display: "contents" }}>
        <>
          <LoginPasswordField
            title="Current Password"
            name="old_password"
            register={register}
            errors={errors}
          />
          <LoginPasswordField
            title="New Password"
            name="password"
            register={register}
            errors={errors}
          />

          <LoginPasswordField
            title="Re-type Password"
            name="confirm_password"
            register={register}
            errors={errors}
          />

          <Button
            variant="contained"
            type="submit"
            sx={{
              backgroundColor: "#003468",
              px: "50px",
              fontWeight: "600",
              fontSize: "20px",
              borderRadius: "10px",
              mt: "60px",
              textTransform: "none",
            }}
            disabled={changePassword.loading}
          >
            {t("Change")}
          </Button>
        </>
      </form>
    </Stack>
  );
};
