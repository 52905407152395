import React, { useContext, useEffect, useState } from "react";
import CebForm from "../../components/Form/CebForm";
import useApi from "../../hooks/useApi";
import mainApi from "../../api/main";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import getBase64 from "../../helpers/getBase64";
import usePermissions from "../../hooks/usePermissions";
import { Stack } from "@mui/material";
import Print from "../../components/Print";
import { t } from "i18next";

const module = "Consent";

export default function ConsentsPage() {
  const { patientId, age, gender } = useParams();
  const navigate = useNavigate();
  const [viewMode, setViewMode] = useState("cards");
  const [resetForm, setResetForm] = useState();
  const [doctors, setDoctors] = useState([]);
  const [chosenCardId, setChosenCardId] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  //apis
  const getConsentList = useApi(mainApi.getConsentList);
  const getConsent = useApi(mainApi.getConsent);
  const addConsent = useApi(mainApi.addConsent);

  //permissions
  const permissions = usePermissions(module);

  useEffect(() => {
    if (permissions?.view) getConsentList.request(patientId, pageNumber);
  }, [addConsent.data, permissions, pageNumber]);

  const cardItems = [
    { header: "Consent", keyName: "data.name" },
    {
      header: "Date",
      keyName: "data.created_at",
      type: "formattedDate",
      format: "YYYY-MM-DD",
    },
    {
      header: "View",
      type: "link",
      keyName: "data.file",
    },
  ];

  const fields = [
    {
      label: "Date",
      type: "readOnly",
      value: moment(new Date()).format("DD-MM-YYYY"),
    },
    {
      label: "Type/Name",
      name: "name",
      validation: { required: true },
    },
    {
      label: "Details",
      name: "details",
      multiline: true,
    },
    {
      name: "file",
      label: "Upload Data",
      type: "file",
      acceptFiles: [
        "image/jpg",
        "image/png",
        "image/jpeg",
        ".pdf",
        "application/pdf",
      ],
    },
  ];

  const data = [
    {
      label: "Details",
      data: getConsent.data?.details,
    },
  ];

  const onSubmit = async (data) => {
    console.log(data);
    const obj = {
      ...data,
      file: data.file && (await getBase64(data.file[0])),
    };
    const res = await addConsent.requestWithToast(
      t("Added successfully"),
      patientId,
      obj
    );
    if (res.ok) {
      setViewMode("cards");
      setPageNumber(1);
      setResetForm(true);
    }
  };

  const handleViewCard = (card) => {
    getConsent.request(patientId, card.id);
    setChosenCardId(card.id);
    setViewMode("list");
  };

  //printing
  // const selectedCard = getConsentList.data.find(
  //   (item) => item.id === chosenCardId
  // );
  // const dataToPrint = {
  //   header: {
  //     left: [moment(selectedCard?.created_at).format("DD MMM YYYY")],
  //     title: `Consent (${selectedCard?.name})`,
  //   },
  //   content: [
  //     ...data,
  //     {
  //       label: "Attachment",
  //       type: "link",
  //       data: { file: selectedCard?.file, file_name: "Open Document" },
  //     },
  //   ],
  // };

  return (
    <>
      <CebForm
        onSubmit={onSubmit}
        fields={fields}
        cardsData={getConsentList.data}
        cardItems={cardItems}
        listData={data}
        viewModeState={[viewMode, setViewMode]}
        handleViewButton={handleViewCard}
        resetState={[resetForm, setResetForm]}
        loading={
          getConsent.loading || getConsentList.loading || addConsent.loading
        }
        requestErrors={addConsent.error}
        chosenCardId={chosenCardId}
        module={module}
        totalCount={getConsentList.count}
        paginationState={[pageNumber, setPageNumber]}
      />
    </>
  );
}
