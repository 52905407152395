import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import mainApi from "../../../api/main";
import CebForm from "../../../components/Form/CebForm";
import useApi from "../../../hooks/useApi";
import { t } from "i18next";

const Skin = ({
  generalExaminationId,
  encounterViewModeKey,
  detailView,
  tabState,
}) => {
  const { patientId } = useParams();
  const [viewMode, setViewMode] = useState(detailView ? "list" : "form");
  const [encounterViewMode, setEncounterViewMode] = encounterViewModeKey;
  const [tabId, setTabId] = tabState;
  const [success, setSuccess] = useState();
  const [submitType, setSubmitType] = useState();

  //api
  const addGeneralExaminationSkin = useApi(mainApi.addGeneralExaminationSkin);
  const getSkinTextureList = useApi(mainApi.getSkinTextureList);
  const getSkinComplexionList = useApi(mainApi.getSkinComplexionList);
  const getGeneralExaminationSkin = useApi(mainApi.getGeneralExaminationSkin);

  useEffect(() => {
    if (viewMode === "form") {
      getSkinTextureList.request();
      getSkinComplexionList.request();
    } else if (viewMode === "list") {
      getGeneralExaminationSkin.request(patientId, generalExaminationId);
    }
  }, [viewMode]);

  const fields = [
    {
      name: "textures",
      label: "Textures",
      type: "select",
      multiple: true,
      items: getSkinTextureList.data,
    },
    {
      name: "complexions",
      label: "Complexions",
      type: "select",
      multiple: true,
      items: getSkinComplexionList.data,
    },
    {
      name: "hairsutism",
      label: "Hairsutism",
      type: "select",
      isEnum: true,
      items: ["Absent", "Present"],
    },
  ];

  const data = [
    {
      label: "Textures",
      data: getGeneralExaminationSkin.data?.textures?.map((item) => item.label),
    },
    {
      label: "Complexions",
      data: getGeneralExaminationSkin.data?.complexions?.map(
        (item) => item.label
      ),
    },
    {
      label: "Hairsutism",
      data: getGeneralExaminationSkin.data?.hairsutism,
    },
  ];

  const onSubmit = async (data) => {
    const res = await addGeneralExaminationSkin.requestWithToast(
      t("Added successfully"),
      patientId,
      generalExaminationId,
      data
    );
    setSuccess(res.ok);
  };

  useEffect(() => {
    console.log("submit", success, submitType);
    if (success && submitType) {
      if (submitType === "next") {
        setTabId((prev) => prev + 1);
        window.scrollTo(0, 0);
      } else {
        setEncounterViewMode("encounter");
      }
      setSuccess(false);
      setSubmitType(null);
    }
  }, [success, submitType]);

  return (
    <Stack gap={1}>
      <CebForm
        viewModeState={[viewMode, setViewMode]}
        fields={fields}
        onSubmit={onSubmit}
        disableAddNew={true}
        listData={data}
        nextButtonHandler={() => {
          setSubmitType("next");
        }}
        cancelButtonLabel="Back"
        saveHandler={() => setSubmitType("save")}
        cancelHandler={() => setEncounterViewMode("encounter")}
        requestErrors={addGeneralExaminationSkin.error}
      />
    </Stack>
  );
};

export default Skin;
