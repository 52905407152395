import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import authService from "../../auth/authService";
import ProtectedRoute from "../../auth/protectedRoute";
import ChatPage from "../../pages/ChatPage";
import HomePage from "../../pages/HomePage";
import MyTeam from "../../pages/MyTeam";
import Patient from "../../pages/patient/index";
import PatientsPage from "../../pages/PatientsPage";
import Permissions from "../../pages/Permissions";
import ProfilePage from "../../pages/ProfilePage";
import ResetPassword from "../../pages/ResetPassword";
import SchedulePage from "../../pages/SchedulePage";
import LoginPage from "../../pages/UserLoginPage";

const HOME = "/";
const PATIENTS = "/patients/:patientsViewMode";
const PERMISSIONS = "/permissions";
const PATIENT = "/patients/:patientId/:age/:gender/*";
const PROFILE = "/profile";
const CHAT = "/chat";
const CLINIC_SCHEDULE = "/clinic-schedule";
const MY_TEAM = "/my-team";

export const RouterConfig = ({ webSocket }) => {
  const getAuthToken = async () => {
    const token = await authService.getJwt();
    webSocket.handleWebSocket(token);
  };

  useEffect(() => {
    getAuthToken();
  }, []);

  return (
    <Routes>
      <Route
        path="/reset-password/:token1/:token2"
        element={<ResetPassword />}
      />
      <Route path="/login" element={<LoginPage webSocket={webSocket} />} />

      <Route path={HOME} element={<ProtectedRoute />}>
        <Route path={HOME} element={<HomePage />} />
      </Route>

      <Route path={PATIENTS} element={<ProtectedRoute />}>
        <Route path={PATIENTS} element={<PatientsPage />} />
      </Route>

      <Route path={PERMISSIONS} element={<ProtectedRoute />}>
        <Route path={PERMISSIONS} element={<Permissions />} />
      </Route>

      <Route path={PROFILE} element={<ProtectedRoute />}>
        <Route path={PROFILE} element={<ProfilePage />} />
      </Route>

      <Route path={CHAT} element={<ProtectedRoute />}>
        <Route path={CHAT} element={<ChatPage />} />
      </Route>

      <Route path={CLINIC_SCHEDULE} element={<ProtectedRoute />}>
        <Route path={CLINIC_SCHEDULE} element={<SchedulePage />} />
      </Route>
      <Route path={MY_TEAM} element={<ProtectedRoute />}>
        <Route path={MY_TEAM} element={<MyTeam />} />
      </Route>

      <Route path={PATIENT} element={<Patient />} />
    </Routes>
  );
};
