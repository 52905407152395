import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
} from "@mui/material";
import React, { useState } from "react";
import { useFieldArray, useWatch } from "react-hook-form";
import CebTextField from "./CebTextField";
import FiberManualRecordSharpIcon from "@mui/icons-material/FiberManualRecordSharp";
import { Delete } from "@mui/icons-material";

export default function MultipleRecordsField({
  fieldArr,
  control,
  currentFieldValueKey,
  name,
  setValue,
  onDelete,
  onKeyDown,
  ...props
}) {
  //use watch to get field value to be used in multiple records.

  const [currentFieldValue, setCurrentFieldValue] = currentFieldValueKey;

  const fieldValue = useWatch({
    control,
    name: "text_" + name,
    defaultValue: "",
  });

  const { fields, replace } = useFieldArray({
    control,
    name: name,
  });

  React.useEffect(() => {
    replace(fieldArr);
    setValue("text_" + name, null);
  }, [fieldArr]);

  React.useEffect(() => {
    setCurrentFieldValue(fieldValue);
    console.log("fieldValueText", fieldValue);
  }, [fieldValue]);


  return (
    <>
      <CebTextField onKeyDown={onKeyDown} multiline name={"text_" + name} arrName={name} {...props} />
      <List dense>
        {fieldArr.map((field, index) => (
          <ListItem
            key={index}
            value={index}
            secondaryAction={
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => onDelete(index)}
              >
                <Delete />
              </IconButton>
            }
          >
            <ListItemIcon>
              <FiberManualRecordSharpIcon
                style={{ fontSize: 15, color: "black" }}
              />
            </ListItemIcon>
            <ListItemText primary={field} />
          </ListItem>
        ))}
      </List>
    </>
  );
}
