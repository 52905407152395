import {
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState, useCallback } from "react";
import Logo from "../assets/icons/Logo";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";

import { LoginPasswordField } from "./UserLoginPage";
import main from "../api/main";
import * as yup from "yup";
import Success from "../assets/images/Success.png";
import theme from "../styles/MUITheme";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { Language } from "@mui/icons-material";

//validation
export const useYupResolver = (type = "reset") =>
  useCallback(async (data) => {
    //Craete Schema
    const schema = {
      password: yup.string().required("Password is required"),
      confirm_password: yup
        .string()
        .oneOf([yup.ref("password"), null], "Passwords must match"),
    };
    if (type === "change") {
      schema["old_password"] = yup
        .string()
        .required("Current password is required");
    }

    //Validate Schema
    try {
      const values = await yup.object().shape(schema).validate(data, {
        abortEarly: false,
      });

      return {
        values,
        errors: {},
      };
    } catch (errors) {
      return {
        values: {},
        errors: errors.inner.reduce(
          (allErrors, currentError) => ({
            ...allErrors,
            [currentError.path]: {
              type: currentError.type ?? "validation",
              message: currentError.message,
            },
          }),
          {}
        ),
      };
    }
  }, []);

const ResetPassword = () => {
  const { t, i18n } = useTranslation();
  const { token1, token2 } = useParams();
  const { addToast } = useToasts();
  let navigate = useNavigate();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ resolver: useYupResolver(), mode: "onTouched" });
  const [success, setSuccess] = useState();

  //mobile view
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));

  //api

  const onSubmit = async (data) => {
    const res = await main.resetPassword({
      token: token1 + "/" + token2,
      password: data.password,
    });
    console.log("res", res.data);
    if (res.data.status === "success") {
      addToast(t("Password has been updated successfully"), {
        appearance: "success",
        autoDismiss: true,
      });
      // navigate("/login");
      setSuccess(true);
    } else {
      addToast("Something went wrong", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      sx={{
        background:
          "linear-gradient(46.59deg, #B80B14 -3.87%, #8E0201 24.23%, #003468 100%)",
        minHeight: "100vh",
        width: "100vw",
      }}
    >
      <Grid
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "white",
          borderRadius: "20px",
          p: mobileView ? "20px" : "50px",
          maxWidth: "700px",
          width: mobileView ? "90%" : "70%",
        }}
      >
        {!success && (
          <>
            <Logo />
            <Typography
              style={{
                fontSize: "20px",
                fontWeight: 700,
                lineHeight: "20px",
                textAlign: "center",
                marginTop: "30px",
                marginBottom: "30px",
              }}
              color="primary"
            >
              {t("Create new password")}
            </Typography>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{ display: "contents" }}
            >
              <>
                <LoginPasswordField
                  title="New Password"
                  // placeholder={"Enter new password"}
                  name="password"
                  register={register}
                  errors={errors}
                />

                <LoginPasswordField
                  title="Re-type Password"
                  // placeholder={"Re-type Password"}
                  name="confirm_password"
                  register={register}
                  errors={errors}
                />
                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    backgroundColor: "#003468",
                    px: "50px",
                    fontWeight: "600",
                    fontSize: "20px",
                    borderRadius: "10px",
                    mt: "60px",
                    textTransform: "none",
                  }}
                >
                  {t("Next")}
                </Button>
                <IconButton
                  onClick={() => {
                    localStorage.setItem(
                      "lng",
                      i18n.language === "en" ? "ar" : "en"
                    );
                    i18n.changeLanguage(i18n.language === "en" ? "ar" : "en");
                  }}
                >
                  <Language
                    style={{
                      color: "#003468",
                      fontSize: "36px",
                      marginTop: "16px",
                    }}
                  />
                </IconButton>
              </>
            </form>
          </>
        )}
        {success && <SuccessView />}
      </Grid>
    </Grid>
  );
};

export default ResetPassword;

const SuccessView = () => (
  <Stack gap={2}>
    <img src={Success} />
    <Typography fontSize={24} fontWeight={500}>
      {t("Your password has been updated successfully")}
    </Typography>
  </Stack>
);
