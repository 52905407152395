import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function HomePage() {
  let navigate = useNavigate();

  useEffect(() => {
    navigate("/patients/list");
  }, []);
  return <></>;
}
