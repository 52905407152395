import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Tree, TreeNode } from "react-organizational-chart";
import theme from "../styles/MUITheme";
import main from "../api/main";
import { getCurrentUser } from "../auth/authService";
import useApi from "../hooks/useApi";

export const StyledNode = ({
  name,
  type = "department",
  onClick = () => {},
  isService,
  id,
  layer,
}) => {
  return (
    <div
      style={{
        padding: "10px",
        opacity: 1,
        borderRadius: "10px",
        backgroundColor: isService
          ? "green"
          : layer === 0
          ? theme.palette.primary.main
          : // : layerColor[layer],
            `rgba(0, 52, 104, ${1 - layer / 10})`,
        color: "#fff",
        width: "fit-content",
        height: "70px",
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        cursor: id !== "root" && "pointer",
      }}
      onClick={() => onClick(id, name)}
    >
      <p style={{ margin: 0, fontSize: "16px" }}>
        <b>
          {/* {name.length > 14 ? `${name.substr(0, 12)}..` : name} */}
          {name}
        </b>
      </p>
      {id !== "root" && <p style={{ margin: 0, fontSize: "12px" }}>{type}</p>}
    </div>
  );
};

const LayersTree = ({ onLayerClick }) => {
  const [levels, setLevels] = useState([]);

  //api
  const getLayerList = useApi(main.getLayerList);
  const entityName = getCurrentUser().workplace_name;

  useEffect(() => {
    getLayerList.request();
  }, []);

  useEffect(() => {
    setLevels(
      [
        { id: "root", name: entityName, parent_id: "none" },
        ...getLayerList.data.map((item) => ({
          id: item.id,
          name: item.name,
          parent_id: item.parent ? item.parent : "root",
          isService: item.layer_type?.is_service,
          type: item.layer_type?.name,
          createdAt: new Date(item.created_at),
        })),
      ].sort((a, b) => a.createdAt - b.createdAt)
    );
  }, [getLayerList.data]);

  const createNode = (node, layer) => {
    const children = levels.filter((l) => l.parent_id === node.id);
    if (children.length < 1) {
      return (
        <TreeNode
          label={
            <StyledNode
              name={node.name}
              onClick={(id) => onLayerClick(id)}
              id={node.id}
              isService={node.isService}
              type={node.type}
              layer={layer}
            />
          }
        />
      );
    } else {
      return (
        <TreeNode
          label={
            <StyledNode
              name={node.name}
              onClick={(id) => onLayerClick(id)}
              id={node.id}
              isService={node.isService}
              type={node.type}
              layer={layer}
            />
          }
        >
          {children.map((child) => createNode(child, layer + 1))}
        </TreeNode>
      );
    }
  };
  return (
    <Box sx={{ width: "100%", overflow: "auto" }}>
      <Tree
        label={"Hierarchy"}
        lineWidth={"2px"}
        lineColor={"#B3B3B3"}
        lineBorderRadius={"10px"}
      >
        {createNode({ id: "root", name: entityName }, 0)}
      </Tree>
    </Box>
  );
};

export default LayersTree;
