import React, { useState } from "react";
import { Modal, Box, Typography } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "90%",
  maxHeight: "90%",
  // height: 697,
  overflow: "auto",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
  pb: 5,
};

const StyledModal = ({
  open,
  handleOpen,
  children,
  width,
  minHeight = 300,
  zIndex = 20,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ zIndex: zIndex }}
    >
      <Box sx={{ ...style, width: width ? width : style.width, minHeight }}>
        {children}
      </Box>
    </Modal>
  );
};

export default StyledModal;
