import AttachFileIcon from "@mui/icons-material/AttachFile";
import ImageIcon from "@mui/icons-material/Image";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { Avatar, Box } from "@mui/material";
import jwt from "jwt-decode";
import React, { useEffect, useRef, useState } from "react";
import { Dimensions } from "react-native";
import {
  Actions,
  Bubble,
  Composer,
  Day,
  GiftedChat,
  InputToolbar,
  Send,
} from "react-native-gifted-chat";
import { StyleSheet, Text, View } from "react-native-web";
import { socketBaseURL } from "../api/client";
import main from "../api/main";
import chatSoundAudio from "../assets/audio/chat.mp3";
import SendIcon from "../assets/icons/Send";
import useApi from "../hooks/useApi";
import { t } from "i18next";

const App = ({ user, token }) => {
  const [messages, setMessages] = useState();
  const [page, setPage] = useState(1);
  const [socket, setSocket] = useState();
  const [chatHistory, setChatHistory] = useState();
  const [historyCount, setHistoryCount] = useState(0);

  const inverted = false;

  const getHistoryMessages = useApi(main.getPatientOldMessages);
  const uploadFile = useApi(main.uploadFileToServer);
  const chatSound = new Audio(chatSoundAudio); // replace with path to your sound file
  const id = jwt(token).user_id;

  const inputFile = useRef(null);
  const inputImage = useRef(null);

  useEffect(() => {
    (async () => {
      const res = await getHistoryMessages.request(user.id, page);
      setChatHistory(res.data);
    })();
  }, [page]);

  useEffect(() => {
    let ws = new WebSocket(
      `${socketBaseURL}/ws/chat/${user.id}?token=${token}`
    );

    ws.onopen = () => {
      setSocket(ws);
    };

    ws.onmessage = (event) => {
      const newMessages = JSON.parse(event?.data).message;
      const isFile = newMessages.content.includes(";file;//");
      const isImage = newMessages.content.includes(";image;//");
      const image = isImage ? newMessages.content.slice(9) : null;
      const file = isFile ? newMessages.content.slice(8) : null;

      const chatMessage = {
        _id: newMessages.id,
        text: isImage ? (
          ""
        ) : isFile ? (
          <FileView href={file} senderId={newMessages.from_user} />
        ) : (
          newMessages.content
        ),
        createdAt: new Date(),
        user: {
          _id: newMessages.from_user,
          avatar: "https://html.com/wp-content/uploads/flamingo.jpg",
        },
        image: image,
      };
      setMessages((previousMessages) =>
        GiftedChat.append(chatMessage, previousMessages)
      );

      if (newMessages.from_user !== id) chatSound.play();
    };

    setInterval(function () {
      if (ws.readyState !== WebSocket.OPEN) {
        console.log("Socket is not open. Trying to reopen...");
        ws = new WebSocket(
          `${socketBaseURL}/ws/chat/${user.id}?token=${token}`
        );
      }
    }, 5000);

    return () => ws.close();
  }, []);

  useEffect(() => {
    if (chatHistory?.data) {
      const oldMessages = chatHistory.data.map((o) => {
        const isFile = o.content.includes(";file;//");
        const isImage = o.content.includes(";image;//");
        return {
          _id: o.id,
          text: isImage ? (
            ""
          ) : isFile ? (
            <FileView href={o.content.slice(8)} senderId={o.from_user} />
          ) : (
            o.content
          ),
          createdAt: new Date(o.timestamp),
          user: {
            _id: o.from_user,
            avatar: "https://html.com/wp-content/uploads/flamingo.jpg",
          },
          image: isImage ? o.content.slice(9) : null,
        };
      });
      setHistoryCount(chatHistory?.count);

      setMessages((previousMessages) =>
        GiftedChat.append(previousMessages, [...oldMessages.reverse()])
      );
    }
  }, [chatHistory]);

  const onSend = (newMessages = []) => {
    socket.send(newMessages[0].text);
  };

  const FileView = ({ href, senderId }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <InsertDriveFileIcon />
      <a
        style={{
          marginLeft: "5px",
          color: id === senderId ? "white" : "black",
        }}
        target="_blank"
        rel="noreferrer"
        href={href}
      >
        {href.split("/")[href.split("/").length - 1]}
      </a>
    </div>
  );
  const renderBubble = (props) => {
    return (
      <Bubble
        {...props}
        wrapperStyle={{
          left: {
            backgroundColor: "#FFFFFF",
          },
          right: {
            backgroundColor: "#003468",
          },
        }}
      />
    );
  };

  const renderDay = (props) => {
    return <Day {...props} textStyle={styles.dayContainer} />;
  };

  const renderComposer = (props) => {
    return (
      <Composer
        {...props}
        composerHeight="50px"
        textInputStyle={styles.textInputStyle}
        placeholderTextColor="#aaa"
        placeholder={t("Message")}
      />
    );
  };

  const renderSend = (props) => {
    return (
      <Send {...props}>
        <View style={styles.sendButton}>
          <SendIcon />
        </View>
      </Send>
    );
  };

  const renderInputToolbar = (props) => {
    return (
      <InputToolbar
        {...props}
        containerStyle={styles.inputContainer}
        primaryStyle={styles.primaryStyle}
        renderComposer={renderComposer}
        renderSend={renderSend}
      />
    );
  };

  const renderActions = (props) => {
    const onSelectImage = (e) => {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      formData.append("file_name", e.target.files[0].name);
      uploadFile
        .request({ userId: id, file: formData })
        .then((result) => socket.send(`;image;//${result?.data?.data.file}`));
    };
    const onSelectFile = (e) => {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      formData.append("file_name", e.target.files[0].name);
      uploadFile
        .request({
          userId: id,
          file: formData,
        })
        .then((result) => socket.send(`;file;//${result?.data?.data.file}`));
    };
    return (
      <Box
        alignItems="center"
        justifyContent="center"
        display="flex"
        flexDirection="row"
        mb="10px"
      >
        <input
          type="file"
          id="file"
          ref={inputImage}
          accept="image/*"
          onChange={(e) => onSelectImage(e)}
          style={{ display: "none" }}
        />
        <input
          type="file"
          id="file"
          ref={inputFile}
          accept="application/pdf"
          onChange={(e) => onSelectFile(e)}
          style={{ display: "none" }}
        />
        <Actions
          {...props}
          containerStyle={{
            alignItems: "center",
            justifyContent: "center",
            marginLeft: 4,

            marginBottom: 8,
          }}
          icon={() => <ImageIcon sx={{ color: "#fff" }} />}
          onPressActionButton={() => inputImage.current.click()}
        />
        <Actions
          {...props}
          containerStyle={{
            alignItems: "center",
            justifyContent: "center",
            marginLeft: 2,
            marginBottom: 8,
          }}
          icon={() => <AttachFileIcon sx={{ color: "#fff" }} />}
          onPressActionButton={() => inputFile.current.click()}
        />
      </Box>
    );
  };

  const { height } = Dimensions.get("window");
  return (
    <View
      style={{ width: "100%", height: height - 69, backgroundColor: "#AEC3E4" }}
    >
      <View style={styles.headerContainer}>
        <Avatar src={user.avatar} />
        <Text style={{ fontSize: "16px", fontWeight: 600, marginLeft: "16px" }}>
          {user.title}
        </Text>
      </View>
      <GiftedChat
        {...{ messages, onSend, inverted }}
        user={{
          _id: id,
        }}
        renderBubble={renderBubble}
        renderActions={renderActions}
        renderInputToolbar={renderInputToolbar}
        renderDay={renderDay}
        loadEarlier={page * 50 < historyCount}
        onLoadEarlier={() => {
          setPage((p) => p + 1);
        }}
        bottomOffset={10}
        listViewProps={{ style: styles.listView }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  inputContainer: {
    borderTopWidth: 0,
    backgroundColor: "#AEC3E4",
    display: "flex",
    justifyContent: "center",
  },
  primaryStyle: {
    borderRadius: 25,
  },
  textInputStyle: {
    borderRadius: 12,
    borderWidth: 1,
    borderColor: "#e6e6e6",
    paddingLeft: "20px",
    paddingRight: "10px",
    fontSize: 16,
    marginBottom: 10,
    backgroundColor: "#F6FBFF",
    paddingTop: "15px",
    paddingBottom: "15px",
  },
  sendButton: {
    marginRight: 10,
    marginLeft: 5,
    marginBottom: 20,
  },
  dayContainer: {
    backgroundColor: "#3D70B899",
    color: "white",
    paddingHorizontal: "12px",
    paddingVertical: "8px",
    borderRadius: "12px",
    fontSize: "12px",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    backgroundColor: "#F9F9F9",
    alignItems: "center",
    paddingVertical: "8px",
    paddingHorizontal: "16px",
  },
  listView: {
    marginBottom: 10,
    marginLeft: 20,
    marginRight: 10,
  },
});

export default App;
