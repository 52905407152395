import React, { useContext, useEffect, useState } from "react";

//Components
import DepartmentField from "./DepartmentField";
import NavSearchField from "./NavSearchField";

//MUI components
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import { Context } from "../../context/Context";
import usePermissions from "../../hooks/usePermissions";

//icons
import LogoutIcon from "@mui/icons-material/Logout";
import {
  Button,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import main from "../../api/main";
import Chat from "../../assets/icons/Chat";
import NavLogo from "../../assets/icons/NavLogo";
import Notifications from "../../assets/icons/Notifications";
import Profile from "../../assets/icons/Profile";
import authService, { getCurrentUser } from "../../auth/authService";
import useApi from "../../hooks/useApi";
import NotificationList from "../NotificationList";
import HomeIcon from "@mui/icons-material/Home";
import MessageIcon from "@mui/icons-material/Message";
import Person2Icon from "@mui/icons-material/Person2";
import MenuIcon from "@mui/icons-material/Menu";
import {
  HomeMaxOutlined,
  HomeMiniOutlined,
  HomeOutlined,
  Language,
} from "@mui/icons-material";
import useMediaQuery from "@mui/material/useMediaQuery";
import theme from "../../styles/MUITheme";
import { useTranslation } from "react-i18next";

export default function MainNav({ webSocket }) {
  const { i18n, t } = useTranslation();
  const { getNotificationUnreadCount, unreadNotificationCount } =
    useContext(Context);
  const navigate = useNavigate();
  const [viewNotifications, setViewNotifications] = useState(false);
  const location = useLocation();
  const [drawer, setDrawer] = useState(false);
  const [departmentList, setDepartmentList] = useState([]);

  const tableView = useMediaQuery(theme.breakpoints.down("md"));

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawer(open);
  };

  //api
  const getClinicList = useApi(main.getClinicList);

  useEffect(() => {
    getClinicList.request();
  }, []);

  const handleLogout = () => {
    authService.logout();
    navigate("/login");
  };

  const profileNavigate = () => {
    navigate("/profile");
  };
  const chatNavigate = () => {
    navigate("/chat");
  };

  const { getPermissions } = useContext(Context);
  const messageReceived = webSocket?.messageReceived;

  useEffect(() => {
    getNotificationUnreadCount();
  }, [viewNotifications, messageReceived]);

  const handleNotificationView = () => setViewNotifications((state) => !state);

  useEffect(() => {
    setDepartmentList(
      [
        getCurrentUser().workplace_type?.hospital_profile && {
          id: "entity",
          name: getCurrentUser().workplace_name,
        },
        ...getClinicList.data.map((item) => ({
          id: item.id,
          name: item.clinic_name || item.specialty,
        })),
      ].filter((item) => item)
    );
  }, [getClinicList.data]);

  return (
    <>
      {viewNotifications && (
        <NotificationList handleClose={handleNotificationView} />
      )}

      <Stack sx={{ flexGrow: 1, position: "relative" }}>
        <AppBar position="static">
          <Toolbar>
            {tableView && (
              <React.Fragment key={"left"}>
                <IconButton
                  sx={{ color: "white" }}
                  onClick={toggleDrawer(true)}
                >
                  <MenuIcon color="white" />
                </IconButton>
                <Drawer
                  anchor={"left"}
                  open={drawer}
                  onClose={toggleDrawer(false)}
                >
                  <Stack
                    width="100%"
                    alignItems="center"
                    justifyContent="center"
                    my="40px"
                  >
                    <NavLogo />
                  </Stack>
                  <List sx={{ width: "300px" }}>
                    <ListItem key={"Home"}>
                      <ListItemButton
                        onClick={() => {
                          navigate("/");
                          setDrawer(false);
                        }}
                      >
                        <ListItemIcon>
                          <HomeIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Home"} />
                      </ListItemButton>
                    </ListItem>
                    <ListItem key={"Profile"}>
                      <ListItemButton
                        onClick={() => {
                          profileNavigate();
                          setDrawer(false);
                        }}
                      >
                        <ListItemIcon>
                          <Person2Icon />
                        </ListItemIcon>
                        <ListItemText primary={"Profile"} />
                      </ListItemButton>
                    </ListItem>
                    <ListItem key={"Messages"}>
                      <ListItemButton
                        onClick={() => {
                          chatNavigate();
                          setDrawer(false);
                        }}
                      >
                        <ListItemIcon>
                          <MessageIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Messages"} />
                      </ListItemButton>
                    </ListItem>
                    <Divider
                      sx={{ borderColor: "#0000003e", marginX: "20px" }}
                    />
                    <ListItem key={"Logout"}>
                      <ListItemButton
                        onClick={() => {
                          handleLogout();
                          setDrawer(false);
                        }}
                      >
                        <ListItemIcon>
                          <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText primary={"logout"} />
                      </ListItemButton>
                    </ListItem>
                  </List>
                </Drawer>
              </React.Fragment>
            )}
            {!tableView && (
              <>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  sx={{
                    mr: i18n.language === "en" ? 2 : 6,
                    ml: i18n.language === "en" ? 6 : 2,
                    my: 1,
                    p: 0,
                    cursor: "default",
                  }}
                  // onClick={() => navigate("/")}
                >
                  <NavLogo />
                </IconButton>
                <Button
                  color="secondary"
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    mr: 2,
                    fontWeight: 800,
                  }}
                  onClick={() => navigate("/")}
                >
                  <Stack direction={"row"} gap={1} alignItems={"center"}>
                    {t("Home")}
                    <HomeOutlined />
                  </Stack>
                </Button>
              </>
            )}
            <Box sx={{ flexGrow: 1 }} />
            {/* <Box onClick={() => getPermissions()} mr={2}>
              refresh permissions
            </Box> */}
            {getClinicList.data.length > 0 &&
              location.pathname.includes("patients/list") && (
                <Stack
                  direction="row"
                  gap={tableView ? 1 : 2}
                  alignItems="center"
                  mx={tableView ? 1 : 2}
                >
                  <Typography sx={{ maxWidth: tableView ? "50px" : "100%" }}>
                    {t("Entity / Clinic")}
                  </Typography>
                  <DepartmentField departments={departmentList} />
                </Stack>
              )}
            {/* <NavSearchField /> */}

            <IconButton
              onClick={() => {
                localStorage.setItem(
                  "lng",
                  i18n.language === "en" ? "ar" : "en"
                );
                i18n.changeLanguage(i18n.language === "en" ? "ar" : "en");
              }}
            >
              <Language style={{ color: "white", fontSize: "36px" }} />
            </IconButton>
            <Stack position="relative">
              <IconButton onClick={handleNotificationView}>
                <Notifications />
              </IconButton>
              {unreadNotificationCount !== 0 && (
                <Stack
                  position="absolute"
                  top={3}
                  right={0}
                  width="25px"
                  heigth="25px"
                  borderRadius="45%"
                  sx={{ backgroundColor: "rgba(240, 247, 255, 1)" }}
                  alignItems="center"
                >
                  <Typography fontSize={14} fontWeight={600} color="primary">
                    {unreadNotificationCount}
                  </Typography>
                </Stack>
              )}
            </Stack>

            {!tableView && (
              <>
                <IconButton onClick={chatNavigate}>
                  <Chat />
                </IconButton>
                <IconButton onClick={profileNavigate}>
                  <Profile />
                </IconButton>

                <IconButton onClick={handleLogout}>
                  <LogoutIcon style={{ color: "white" }} />
                </IconButton>
              </>
            )}
          </Toolbar>
        </AppBar>
      </Stack>
    </>
  );
}
