import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import mainApi from "../../../api/main";
import CebForm from "../../../components/Form/CebForm";
import useApi from "../../../hooks/useApi";
import { t } from "i18next";

const Neck = ({ generalExaminationId, encounterViewModeKey, tabState }) => {
  const { patientId } = useParams();
  const [viewMode, setViewMode] = useState("form");
  const [encounterViewMode, setEncounterViewMode] = encounterViewModeKey;
  const [tabId, setTabId] = tabState;
  const [success, setSuccess] = useState();
  const [submitType, setSubmitType] = useState();

  //api

  const addGeneralExaminationNeck = useApi(mainApi.addGeneralExaminationNeck);
  const getGeneralExaminationNeckGeneralExaminationList = useApi(
    mainApi.getGeneralExaminationNeckGeneralExaminationList
  );
  const getGeneralExaminationNeckThyroidGlandList = useApi(
    mainApi.getGeneralExaminationNeckThyroidGlandList
  );
  const getGeneralExaminationNeckVeinsList = useApi(
    mainApi.getGeneralExaminationNeckVeinsList
  );
  const getGeneralExaminationNeckCarotidArteryList = useApi(
    mainApi.getGeneralExaminationNeckCarotidArteryList
  );

  useEffect(() => {
    getGeneralExaminationNeckGeneralExaminationList.request();
    getGeneralExaminationNeckThyroidGlandList.request();
    getGeneralExaminationNeckVeinsList.request();
    getGeneralExaminationNeckCarotidArteryList.request();
  }, []);

  useEffect(() => {
    if (viewMode === "cards") {
      setEncounterViewMode("encounter");
      setViewMode("form");
    }
  }, [viewMode]);

  const fields = [
    {
      name: "cervical_lymph_nodes",
      label: "Cervical Lymph Nodes",
      type: "select",
      isEnum: true,
      // autoComplete: true,
      items: ["Normal", "Enlarged"],
    },
    {
      name: "neck_general_examination",
      label: "GENERAL NECK EXAMINATION",
      type: "select",
      // autoComplete: true,
      items: getGeneralExaminationNeckGeneralExaminationList.data,
    },
    {
      name: "thyroid_gland",
      label: "THYROID GLAD",
      type: "select",
      // autoComplete: true,
      items: getGeneralExaminationNeckThyroidGlandList.data,
    },
    {
      name: "veins",
      label: "NECK VEINS",
      type: "select",
      // autoComplete: true,
      items: getGeneralExaminationNeckVeinsList.data,
    },
    {
      name: "carotid_artery",
      label: "CAROTID ARTERIES",
      type: "select",
      // autoComplete: true,
      items: getGeneralExaminationNeckCarotidArteryList.data,
    },
  ];

  const onSubmit = async (data) => {
    const res = await addGeneralExaminationNeck.requestWithToast(
      t("Added successfully"),
      patientId,
      generalExaminationId,
      data
    );
    setSuccess(res.ok);
  };

  useEffect(() => {
    console.log("submit", success, submitType);
    if (success && submitType) {
      if (submitType === "next") {
        setTabId((prev) => prev + 1);
        window.scrollTo(0, 0);
      } else {
        setEncounterViewMode("encounter");
      }
      setSuccess(false);
      setSubmitType(null);
    }
  }, [success, submitType]);

  return (
    <CebForm
      viewModeState={[viewMode, setViewMode]}
      fields={fields}
      onSubmit={onSubmit}
      nextButtonHandler={() => {
        setSubmitType("next");
      }}
      cancelButtonLabel="Back"
      saveHandler={() => setSubmitType("save")}
      cancelHandler={() => setEncounterViewMode("encounter")}
      requestErrors={addGeneralExaminationNeck.error}
    />
  );
};

export default Neck;
