import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Stack } from "@mui/material";

import mainApi from "../../../api/main";
import CebForm from "../../../components/Form/CebForm";
import useApi from "../../../hooks/useApi";
import { t } from "i18next";

const BodyBuilt = ({
  generalExaminationId,
  encounterViewModeKey,
  detailView,
  tabState,
}) => {
  const { patientId } = useParams();
  const [viewMode, setViewMode] = useState(detailView ? "list" : "form");

  const [encounterViewMode, setEncounterViewMode] = encounterViewModeKey;
  const [tabId, setTabId] = tabState;
  const [success, setSuccess] = useState();
  const [submitType, setSubmitType] = useState();
  const [bmi, setBmi] = useState("");
  const [watchedFields, setWatchedFields] = useState([]);

  //api
  const addGeneralExaminationBodyBuilt = useApi(
    mainApi.addGeneralExaminationBodyBuilt
  );
  const getGeneralExaminationBodyBuilt = useApi(
    mainApi.getGeneralExaminationBodyBuilt
  );

  useEffect(() => {
    if (viewMode === "list") {
      getGeneralExaminationBodyBuilt.request(patientId, generalExaminationId);
    }
  }, [viewMode]);

  const fields = [
    {
      name: "weight",
      label: "Weight (Kg)",
      type: "decimal",
      validation: { positive: true },
    },
    {
      name: "height",
      label: "Height (M)",
      type: "decimal",
      validation: { positive: true },
    },
    {
      name: "bmi",
      label: "BMI",
      type: "readOnly",
      value: bmi,
    },
  ];

  const data = [
    {
      label: "Weight (Kg)",
      data: getGeneralExaminationBodyBuilt.data?.weight,
    },
    {
      label: "Height (M)",
      data: getGeneralExaminationBodyBuilt.data?.height,
    },
    {
      label: "BMI",

      data: getGeneralExaminationBodyBuilt.data?.bmi,
    },
  ];

  const onSubmit = async (data) => {
    const res = await addGeneralExaminationBodyBuilt.requestWithToast(
      t("Added successfully"),
      patientId,
      generalExaminationId,
      data
    );
    setSuccess(res.ok);
  };

  useEffect(() => {
    console.log("submit", success, submitType);
    if (success && submitType) {
      if (submitType === "next") {
        setTabId((prev) => prev + 1);
        window.scrollTo(0, 0);
      } else {
        setEncounterViewMode("encounter");
      }
      setSuccess(false);
      setSubmitType(null);
    }
  }, [success, submitType]);

  useEffect(() => {
    const weight = Number(watchedFields?.[0]);
    const height = Number(watchedFields?.[1]);
    if (weight > 0 && height > 0) {
      setBmi((weight / Math.pow(height, 2)).toFixed(2));
    } else {
      setBmi(t("Enter valid weight and height"));
    }
  }, [watchedFields]);

  return (
    <Stack gap={1}>
      <CebForm
        viewModeState={[viewMode, setViewMode]}
        listData={data}
        disableAddNew={true}
        fields={fields}
        fieldsToWatch={{ name: ["weight", "height"] }}
        getWatchedFields={(f) => setWatchedFields(f)}
        onSubmit={onSubmit}
        nextButtonHandler={() => {
          setSubmitType("next");
        }}
        cancelButtonLabel="Back"
        saveHandler={() => setSubmitType("save")}
        cancelHandler={() => setEncounterViewMode("encounter")}
        requestErrors={addGeneralExaminationBodyBuilt.error}
      />
    </Stack>
  );
};

export default BodyBuilt;
