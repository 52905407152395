import { Button, Stack, useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import main from "../../api/main";
import { getCurrentUser } from "../../auth/authService";
import { modules } from "../../config/constants";
import useApi from "../../hooks/useApi";
import usePermissions from "../../hooks/usePermissions";
import theme from "../../styles/MUITheme";
import { useTranslation } from "react-i18next";

const TAB1 = "Dashboard";
const TAB2 = "Patient List";
const TAB3 = "Referrals";
const TAB4 = "Ask for permission";
const TAB5 = "Clinic Schedule";
const TAB6 = "My Team";

export default function SecondaryNav({ activePatientsTab }) {
  const { t } = useTranslation();
  const permissions = usePermissions(modules.EXTRA_PERMISSIONS);
  const tableView = useMediaQuery(theme.breakpoints.down("md"));
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));

  const referralPermissions = usePermissions(
    modules.ADMISSIOM,
    modules.ADMISSIOM_REFERRAL
  );

  const navItems = [
    // permissions["Access Dashboard"] && { name: TAB1, link: "/" },
    { name: TAB2, link: "/patients/list" },
    referralPermissions.view &&
      !localStorage.getItem("dep_id") && {
        name: TAB3,
        link: "/patients/referrals",
      },
    // { name: TAB3, link: "/" }, //until permssions is working
    permissions["Access Permission Tab"] &&
      !mobileView && {
        name: TAB4,
        link: "/permissions",
      },
    localStorage.getItem("dep_id") && {
      name: TAB5,
      link: "/clinic-schedule",
    },
    !localStorage.getItem("dep_id") && {
      name: TAB6,
      link: "/my-team",
    },
  ].filter((item) => item);

  return (
    <Stack direction="row" sx={{ mb: tableView ? 2 : 3, overflow: "auto" }}>
      {navItems.map((item, index) => (
        <NavLink
          key={index}
          value={item.name}
          to={item.link}
          style={{ textDecorationLine: "none" }}
        >
          {({ isActive }) => (
            <Button
              variant={
                isActive || (activePatientsTab && item.name === TAB2)
                  ? "contained"
                  : "outlined"
              }
              color="secondary"
              style={{
                width: item.name === TAB4 ? 180 : 145,
                height: tableView ? 30 : 48,
                borderRadius: 10,
                textTransform: "none",
                fontWeight: 500,
                marginRight: tableView ? 10 : 20,
              }}
            >
              {t(item.name)}
            </Button>
          )}
        </NavLink>
      ))}
    </Stack>
  );
}
