import {
  Box,
  Grid,
  Input,
  InputAdornment,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ChatList } from "react-chat-elements";
import "react-chat-elements/dist/main.css";

import { Search } from "@mui/icons-material";
import { socketBaseURL } from "../api/client";
import authService from "../auth/authService";
import ChatComponent from "../components/ChatComponent";
import sortMessagesByTimestamp from "../helpers/sortMessagesByTimestamp";
import { useTranslation } from "react-i18next";

const ChatPage = () => {
  const [openedChat, setOpenChat] = useState(null);
  const [socket, setSocket] = useState();
  const [token, setToken] = useState();
  const [patientsChatList, setPatientsChatList] = useState([]);
  const [search, setSearch] = useState("");
  const defaultImage = "https://html.com/wp-content/uploads/flamingo.jpg";
  const { t } = useTranslation();

  useEffect(() => {
    console.log(openedChat);
  }, [openedChat]);

  useEffect(() => {
    (async () => {
      const authToken = await authService.getJwt();
      setToken(authToken);

      const ws = new WebSocket(
        `${socketBaseURL}/ws/init_chat?token=${authToken}`
      );
      ws.onopen = () => {
        setSocket(ws);
      };

      ws.onmessage = (event) => {
        console.log(JSON.parse(event.data));
        if (JSON.parse(event.data).type === "initial_message")
          setPatientsChatList(
            sortMessagesByTimestamp(JSON.parse(event.data).message)
          );

        if (JSON.parse(event.data).type === "new_message") {
          const newPatient = JSON.parse(event.data);
          setPatientsChatList((patients) => {
            let updatedPatient = patients.map((p) =>
              p.user.id === newPatient.user.id
                ? {
                    ...p,
                    unread_messages: newPatient.unread_messages,
                    message: {
                      ...p.message,
                      content: newPatient.message.content,
                      timestamp: newPatient.message.timestamp,
                    },
                  }
                : p
            );

            if (!updatedPatient.find((p) => p.user.id === newPatient.user.id)) {
              updatedPatient.push(newPatient);
            }

            return sortMessagesByTimestamp(updatedPatient);
          });
        }
      };
    })();

    return socket?.close();
  }, []);
  return (
    <Grid direction="row" sx={{ direction: "ltr" }} container>
      <Grid item xs={3} sx={{ backgroundColor: "#F9F9F9" }}>
        <Box width="100%" padding="5px">
          <OutlinedInput
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            placeholder={t("Search")}
            startAdornment={
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            }
            sx={{
              width: "90%",
              borderRadius: "22px",
              backgroundColor: "#F5F5F5",
              height: "40px",
              margin: "10px",
              border: "1px solid #D9DCE080",
            }}
          />
        </Box>
        {patientsChatList.length > 0 ? (
          <ChatList
            onClick={(user) => setOpenChat(user)}
            className="chat-list"
            dataSource={patientsChatList
              .filter((p) => {
                if (search) {
                  return p.user.full_name
                    .toLowerCase()
                    .includes(search.toLowerCase());
                }
                return true;
              })
              .map((p) => ({
                avatar: p.user.profile_pic || defaultImage,
                alt: p.user.full_name,
                title: p.user.full_name,
                subtitle: p.message.content.includes(";image;//")
                  ? "Image"
                  : p.message.content.includes(";file;//")
                  ? "File"
                  : p.message.content,
                date: new Date(p.message.timestamp),
                unread: p.unread_messages,
                id: p.user.id,
              }))}
          />
        ) : (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
            height="100%"
          >
            <Typography>{t("There is no patients to chat with")}</Typography>
          </Box>
        )}
      </Grid>
      <Grid item xs={9}>
        {openedChat ? (
          <ChatComponent key={openedChat.id} user={openedChat} token={token} />
        ) : (
          <Box
            width="100%"
            height="calc(100vh - 69px)"
            backgroundColor="#AEC3E4"
          />
        )}
      </Grid>
    </Grid>
  );
};

export default ChatPage;
