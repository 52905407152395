import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SemiBoldTypography from "../Typography/SemiBoldTypograpghy";
import EditIcon from "../../assets/icons/Edit";
import { useForm } from "react-hook-form";
import Modal from "../UI/Modal";
import CebForm from "../Form/CebForm";
import useApi from "../../hooks/useApi";
import main from "../../api/main";
import { useLocation, useParams } from "react-router-dom";
import theme from "../../styles/MUITheme";
import { getCurrentUser } from "../../auth/authService";
import usePermissions from "../../hooks/usePermissions";
import { modules } from "../../config/constants";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

export default function PatientSideBar({ drawer, toggleDrawer }) {
  const { t } = useTranslation();
  const location = useLocation();
  const { patientId } = useParams();
  const admissionUrl = useParams()?.["*"];
  const [openModal, setOpenModal] = useState(false);
  const [viewMode, setViewMode] = useState("form");
  const [openReferralModal, setOpenReferralModal] = useState(false);
  const [referralCondition, setReferralCondition] = useState(false);
  const [admissionId, setAdmissionId] = useState();

  //mobile view
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));

  //api
  const getPatientPersonalHistory = useApi(main.getPatientPersonalHistory);
  const editPatientPersonalHistory = useApi(main.editPatientPersonalHistory);
  const getAdmission = useApi(main.getAdmission);

  //permissions
  const referralPermissions = usePermissions(
    modules.ADMISSIOM,
    modules.ADMISSIOM_REFERRAL
  );

  // check referral condition
  useEffect(() => {
    if (location.pathname.includes("admission/")) {
      const currentAdmissionId = admissionUrl.match(/\d/g)?.join("");
      if (currentAdmissionId) {
        getAdmission.request(patientId, currentAdmissionId);
        setAdmissionId(currentAdmissionId);
      } else setReferralCondition(false);
    } else {
      setReferralCondition(false);
    }
  }, [location, admissionUrl]);
  useEffect(() => {
    if (getAdmission.data?.discharge_date === null) setReferralCondition(true);
    else setReferralCondition(false);
  }, [getAdmission.data]);

  //form
  useEffect(() => {
    setViewMode("form");
    getPatientPersonalHistory.request(patientId);
  }, []);

  const handleOpenModal = () => {
    setOpenModal((state) => !state);
  };

  const handleOpenReferralModal = () => {
    setOpenReferralModal((state) => !state);
  };

  const [fix, setFix] = useState(false);
  const [scrollY, setScrollY] = useState(0);

  const setFixed = () => {
    console.log(window.scrollY);
    setScrollY(window.scrollY);
    const scrollableHeight =
      document.documentElement.scrollHeight - window.innerHeight;

    if (window.scrollY >= scrollableHeight) {
      return;
    }
    if (window.scrollY >= 118) setFix(true);
    else setFix(false);
  };

  window.addEventListener("scroll", setFixed);

  const fields = [
    {
      type: "readOnly",
      label: "Name",
      value: getPatientPersonalHistory.data.full_name,
    },

    {
      type: "readOnly",
      label: "Date of birth",
      value: getPatientPersonalHistory.data.birth_date,
    },
    {
      type: "readOnly",
      label: "Age",
      value: getPatientPersonalHistory.data.age,
    },

    {
      type: "readOnly",
      label: "Sex",
      value: getPatientPersonalHistory.data.gender,
    },
    {
      type: "readOnly",
      label: "ID",
      value: getPatientPersonalHistory.data.username?.split("P")[1],
    },
    { label: "Occupation", name: "occupation", width: 200 },

    {
      isEnum: true,
      items: ["single", "married"],
      type: "select",
      label: "Marital status",
      name: "marital_status",
      width: 200,
      defaultValue: getPatientPersonalHistory.data?.marital_status,
    },
    { label: "Number of children", name: "num_children", width: 200 },

    {
      type: "date",
      label: "Date of birth of youngest",
      name: "birth_date_youngest_child",
      validation: { disableFuture: true },
      width: 200,
    },
    {
      type: "readOnly",
      label: "Age",
      value: getPatientPersonalHistory.data.child_age,
    },

    {
      type: "readOnly",
      label: "Residence",
      value: getPatientPersonalHistory.data.address_string,
    },
    {
      type: "readOnly",
      label: "Mobile Number",
      value: getPatientPersonalHistory.data.phone_number,
    },
    { label: "Other Phone Number", name: "alternative_phone", width: 200 },
  ];

  const onSubmit = async (data) => {
    await editPatientPersonalHistory.requestWithToast(
      t("Data updated successfully"),
      patientId,
      data
    );

    getPatientPersonalHistory.request(patientId);
    handleOpenModal();
    if (drawer) {
      toggleDrawer(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          width: 307,
          minHeight: window.innerHeight - 117 + scrollY,
          height: drawer ? "100vh" : "100%",
          position: fix ? "fixed" : "relative",
          top: 0,
          // pt: 6,
          background:
            "linear-gradient(46.59deg, #B80B14 -3.87%, #8E0201 24.23%, #003468 100%)",
        }}
      >
        <Stack
          gap={2}
          sx={{
            mx: 3,
            pt: 2,
          }}
        >
          {!mobileView && (
            <IconButton
              style={{ width: 30, height: 30, padding: 0, alignSelf: "end" }}
              onClick={handleOpenModal}
            >
              <EditIcon />
            </IconButton>
          )}
          <Avatar
            alt="Patient"
            src={getPatientPersonalHistory.data.profile_pic}
            sx={{ width: 134, height: 134, alignSelf: "center" }}
          />
          <SemiBoldTypography
            title="Patient Name"
            content={getPatientPersonalHistory.data.full_name}
          />
          <SemiBoldTypography
            title="Date of Birth"
            content={getPatientPersonalHistory.data.birth_date}
          />
          <SemiBoldTypography
            title="Age"
            content={getPatientPersonalHistory.data.age}
          />
          <SemiBoldTypography
            title="Sex"
            content={getPatientPersonalHistory.data.gender}
          />
          <SemiBoldTypography
            title="Blood group"
            content={getPatientPersonalHistory.data.blood_type}
          />
          <SemiBoldTypography
            title="ID"
            content={getPatientPersonalHistory.data.username?.split("P")[1]}
          />
          <SemiBoldTypography
            title="Mobile Number"
            content={getPatientPersonalHistory.data.phone_number}
          />
          {referralCondition && referralPermissions?.add && !mobileView && (
            <Button
              variant="outlined"
              color="secondary"
              sx={{
                textTransform: "none",
                color: "#fff",
                borderColor: "#fff",
                ":hover": {
                  backgroundColor: "#fff",
                  color: theme.palette.secondary.main,
                  borderColor: "#fff",
                },
              }}
              onClick={handleOpenReferralModal}
            >
              {t("Request a referral/transfer")}
            </Button>
          )}
          {/* <SemiBoldTypography
            title="Residence"
            content={getPatientPersonalHistory.data.address_string}
          />
          <SemiBoldTypography
            title="Marital Status"
            content={getPatientPersonalHistory.data.marital_status}
          />
          <SemiBoldTypography
            title="Number of children"
            content={getPatientPersonalHistory.data.num_children}
          /> */}
        </Stack>
      </Box>

      <Modal
        open={openReferralModal}
        handleOpen={handleOpenReferralModal}
        zIndex={1200}
      >
        <ReferralForm
          handleOpenModal={handleOpenReferralModal}
          toggleDrawer={toggleDrawer}
          patientId={patientId}
          admissionId={admissionId}
          currentLayer={getAdmission.data?.admitted_to_layer}
        />
      </Modal>
      <Modal open={openModal} handleOpen={handleOpenModal} zIndex={1200}>
        <CebForm
          initialValues={getPatientPersonalHistory.data}
          modalForm
          modalTitle="Edit Info"
          handleOpenModal={handleOpenModal}
          onSubmit={onSubmit}
          viewModeState={[viewMode, setViewMode]}
          fields={fields}
          listData={[]}
          addInList={true}
          requestErrors={editPatientPersonalHistory.error}
        />
      </Modal>
    </>
  );
}

const ReferralForm = ({
  handleOpenModal,
  patientId,
  admissionId,
  currentLayer,
  toggleDrawer,
}) => {
  const [doctors, setDoctors] = useState([]);
  const [viewMode, setViewMode] = useState("form");
  const [watchedFields, setWatchedFields] = useState();

  //api
  const getDoctors = useApi(main.getDoctorList);
  const getLayerList = useApi(main.getLayerList);
  const getFilteredDiseases = useApi(main.getFilteredDiseases);
  const createReferral = useApi(main.createReferral);

  useEffect(() => {
    getDoctors.request();
    getLayerList.request();
    getFilteredDiseases.request("*");
  }, []);

  useEffect(() => {
    const doctorList = getDoctors.data
      .filter((doc) => doc.id !== getCurrentUser()?.user_id)
      ?.map((doc) => ({
        label: doc.full_name,
        id: doc.id,
      }));
    setDoctors(doctorList);
  }, [getDoctors.data]);

  const diseasesChangeHandler = (value) => {
    getFilteredDiseases.request(value);
  };

  const fields = [
    {
      label: "Type of Request",
      name: "type",
      type: "select",
      items: ["Referral", "Transfer"],
      isEnum: true,
      validation: { required: true },
    },
    watchedFields?.[0] === "Transfer" && {
      label: "Department",
      name: "department",
      type: "select",
      items: getLayerList.data?.filter((item) => item.id !== currentLayer.id),
      validation: { required: true },
    },
    watchedFields?.[0] === "Referral" && {
      label: "Doctor",
      name: "doctor",
      type: "select",
      items: doctors,
      autoComplete: true,
      validation: { required: true },
    },
    {
      label: "Reason",
      name: "reason",
      validation: { required: true },
    },
    {
      label: "Diagnosis",
      name: "diagnosis",
      type: "select",
      onInputChange: diseasesChangeHandler,
      items: getFilteredDiseases.data,
      multiple: true,
      autoComplete: true,
      disableFilter: true,
      validation: { required: true },
    },
    { label: "Notes", name: "notes", multiline: true },
  ].filter((item) => item);

  const onSubmit = async (data) => {
    const body = {
      type: data.type,
      layer_or_doctor_uuid:
        data.type === "Referral" ? data.doctor.id : data.department,
      diagnosis: data.diagnosis.map((item) => item.id),
      reason: data.reason,
      notes: data.notes,
    };
    await createReferral.requestWithToast(
      t("Created successfully"),
      patientId,
      admissionId,
      body
    );

    handleOpenModal();
    if (toggleDrawer) toggleDrawer(false);
  };

  return (
    <CebForm
      modalForm
      modalTitle="Request a referral/transfer"
      formWidth="600px"
      handleOpenModal={handleOpenModal}
      onSubmit={onSubmit}
      viewModeState={[viewMode, setViewMode]}
      fields={fields}
      getWatchedFields={(f) => setWatchedFields(f)}
      fieldsToWatch={{ name: ["type"] }}
      loading={createReferral.loading}
      // listData={[]}
      // addInList={true}
      // requestErrors={editPatientPersonalHistory.error}
    />
  );
};
