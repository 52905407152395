import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import mainApi from "../../../api/main";
import CebForm from "../../../components/Form/CebForm";
import { modules } from "../../../config/constants";
import useApi from "../../../hooks/useApi";
import { t } from "i18next";

const MouthAndThroat = ({
  generalExaminationId,
  encounterViewModeKey,
  tabState,
}) => {
  const { patientId } = useParams();
  const [viewMode, setViewMode] = useState("form");
  const [encounterViewMode, setEncounterViewMode] = encounterViewModeKey;
  const [tabId, setTabId] = tabState;
  const [success, setSuccess] = useState();
  const [submitType, setSubmitType] = useState();

  //api
  const addGeneralExaminationMouthAndThroat = useApi(
    mainApi.addGeneralExaminationMouthAndThroat
  );
  const getGeneralExaminationMouthAndThroatList = useApi(
    mainApi.getGeneralExaminationMouthAndThroatList
  );

  useEffect(() => {
    getGeneralExaminationMouthAndThroatList.request();
  }, []);

  useEffect(() => {
    if (viewMode === "cards") {
      setEncounterViewMode("encounter");
      setViewMode("form");
    }
  }, [viewMode]);

  const fields = [
    {
      name: "mouth_and_throat",
      label: "Mouth & Throat",
      type: "select",
      multiple: true,
      items: getGeneralExaminationMouthAndThroatList.data,
    },
  ];

  const onSubmit = async (data) => {
    const res = await addGeneralExaminationMouthAndThroat.requestWithToast(
      t("Added successfully"),
      patientId,
      generalExaminationId,
      data
    );
    setSuccess(res.ok);
  };

  useEffect(() => {
    console.log("submit", success, submitType);
    if (success && submitType) {
      if (submitType === "next") {
        setTabId((prev) => prev + 1);
        window.scrollTo(0, 0);
      } else {
        setEncounterViewMode("encounter");
      }
      setSuccess(false);
      setSubmitType(null);
    }
  }, [success, submitType]);

  return (
    <CebForm
      viewModeState={[viewMode, setViewMode]}
      fields={fields}
      onSubmit={onSubmit}
      nextButtonHandler={() => {
        setSubmitType("next");
      }}
      cancelButtonLabel="Back"
      saveHandler={() => setSubmitType("save")}
      cancelHandler={() => setEncounterViewMode("encounter")}
      requestErrors={addGeneralExaminationMouthAndThroat.error}
      module={modules.ENCOUNTER}
    />
  );
};

export default MouthAndThroat;
