import { createTheme } from "@mui/material/styles";

// import green from "@material-ui/core/colors/green";

const theme = createTheme({
  palette: {
    primary: {
      main: "#003468",
      light: "#edf4f7",
    },
    secondary: {
      main: "#8E0201",
    },
    card: {
      main: "#F0F7FF",
      mainHover: "#a4ceff",
    },
    typography: {
      main: "#000305",
    },
    gradient: {
      main: "linear-gradient(46.59deg, #B80B14 -3.87%, #8E0201 24.23%, #003468 100%);",
    },
    gray: {
      main: "gray",
      200: "#F3F4F9",
      secondary: "#D9D9D9",
    },
    background: {
      default: "#ffff",
    },
    divider: "#A5D2FF",
    fieldBorder: "#00172F",
    white: "#ffff",
  },
  typography: {
    fontFamily: `'Ubuntu', sans-serif`,
    fontSize: 16,
    body2: {
      fontSize: 18,
      fontWeight: 500,
      lineHeight: "20px",
    },
    error: {
      fontSize: 16,
      fontWeight: 400,
      color: "#ff0000",
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          "@media (max-width: 900px)": {
            fontSize: 14,
          },
        },
        body2: {
          "@media (max-width: 900px)": {
            fontSize: 16,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "@media (max-width: 900px)": {
            fontSize: 14,
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          "@media (max-width: 900px)": {
            fontSize: 14,
          },
        },
      },
    },
    // MuiSelect: {
    //   styleOverrides: {
    //     root: {
    //       fontSize: 16,
    //       "@media (max-width: 800px)": {
    //         fontSize: 14,
    //       },
    //     },
    //   },
    // },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "@media (max-width: 900px)": {
            fontSize: 14,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "@media (max-width: 900px)": {
            fontSize: 14,
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "@media (max-width: 900px)": {
            fontSize: 14,
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          "@media (max-width: 900px)": {
            fontSize: 14,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "@media (max-width: 800px)": {
            fontSize: 14,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "@media (max-width: 800px)": {
            fontSize: 14,
          },
        },
      },
    },
  },
  props: {
    // Name of the component ⚛️
    // MuiButton: {
    //   color: "primary",
    //   variant: "contained",
    //   disableElevation: true,
    // },
  },
});

export default theme;
