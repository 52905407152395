import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.5 2.25v19.5h15V7.2l-.225-.225-4.5-4.5-.225-.225H4.5ZM6 3.75h7.5v4.5H18v12H6V3.75Zm9 1.05 1.95 1.95H15V4.8ZM8.25 8.25V15h1.5v-2.25h.45l1.875 3.3-1.575 2.7H12l.75-1.35.75 1.35H15l-1.5-2.7L15 13.5h-1.5l-.75 1.275-1.275-2.25c.75-.375 1.2-1.125 1.2-2.025 0-1.2-1.05-2.25-2.25-2.25H8.25Zm1.5 1.5h.75c.45 0 .75.3.75.75s-.3.75-.75.75h-.75v-1.5Z"
      fill="#003468"
    />
  </svg>
);

export default SvgComponent;
