import {
  Button,
  Grid,
  TextField,
  Typography,
  Select,
  MenuItem,
  InputAdornment,
  IconButton,
  InputBase,
  Box,
  Stack,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Logo from "../assets/icons/Logo";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import useApi from "../hooks/useApi";
import main from "../api/main";
import auth from "../auth/authService";
import {
  Language,
  LanguageOutlined,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import useYupResolver from "../hooks/useYupResolver";
import { useToasts } from "react-toast-notifications";
import { Context } from "../context/Context";
import theme from "../styles/MUITheme";
import { useTranslation } from "react-i18next";

const LoginPage = ({ webSocket }) => {
  const { t, i18n } = useTranslation();
  let navigate = useNavigate();
  const { addToast } = useToasts();
  const { getPermissions } = useContext(Context);
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    register,
    trigger,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: useYupResolver([
      {
        name: "username",

        validation: { required: true },
      },
      {
        name: "password",
        validation: { required: true },
      },
    ]),
    mode: "onTouched",
  });

  const [step, setStep] = useState(1);

  //mobile view
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));

  //api
  const login = useApi(main.login);
  const getEntitiesList = useApi(main.getEntitiesList);
  const loginWithEntity = useApi(main.loginWithEntity);
  const getClinicList = useApi(main.getClinicList);

  //prevent opening login page where user is logged in
  useEffect(() => {
    if (auth.getCurrentUser()?.workplace_id) navigate("/patients/list"); //workplace_id to check its not the first token.
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);
    if (step === 1) {
      data.username = "M" + data.username;
      const response = await login.request(data);
      if (response?.ok) {
        auth.login(response?.data?.data?.tokens?.access);
        getEntitiesList.request();
        setStep(2);
      }
    } else if (step === 2) {
      const response = await loginWithEntity.request({
        workplace: data.entity,
      });
      if (response?.ok) {
        auth.login(response.data.data.tokens.access);
        webSocket.handleWebSocket(response.data.data.tokens.access);

        //set first department of entity of type clinic
        if (!auth.getCurrentUser().workplace_type?.hospital_profile) {
          const response = await getClinicList.request();
          if (response.data?.data?.[0]?.id) {
            localStorage.setItem("dep_id", response.data?.data?.[0]?.id);
          } else {
            addToast(
              "You need to be assigned to at least one specialty of this clinic",
              {
                appearance: "error",
                autoDismiss: true,
              }
            );
            setLoading(false);
            return;
          }
        }

        getPermissions();
        if (location.state?.hasOwnProperty("from"))
          navigate(location.state.from);
        //check if user was trying to open another page.
        else navigate("/patients/list");
      }
    }
    setLoading(false);
  };

  const resetPasswordHandler = async () => {
    trigger("username");
    if (!errors["username"]) {
      const res = await main.requestResetEmail({
        username: "M" + watch("username"),
      });
      if (res.data.data?.success) {
        addToast(res.data.data.success, {
          appearance: "info",
        });
      } else if (res.data.data?.error) {
        addToast(res.data.data?.error, {
          appearance: "error",
          autoDismiss: true,
        });
      } else {
        addToast("Something went wrong", {
          appearance: "error",
          autoDismiss: true,
        });
      }
    } else
      addToast("Please enter valid ID and try again", {
        appearance: "error",
        autoDismiss: true,
      });
  };

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      sx={{
        background:
          "linear-gradient(46.59deg, #B80B14 -3.87%, #8E0201 24.23%, #003468 100%)",
        minHeight: "100vh",
        width: "100vw",
      }}
    >
      <Grid
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "white",
          borderRadius: "20px",
          p: mobileView ? "20px" : "50px",
          maxWidth: "700px",
          width: mobileView ? "90%" : "70%",
        }}
      >
        <Logo />
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: "contents" }}>
          {step === 1 && (
            <>
              <Typography
                variant="h2"
                fontSize={31}
                fontWeight="700"
                color="#003468"
                mb="15px"
              >
                {t("Login")}
              </Typography>
              {/* <Typography variant='body1' fontSize={13} color='rgba(0, 52, 104, 0.5)' sx={{ textDecoration: "underline" }} mb='30px'>
              Don’t have an account{" "}
              <span style={{ color: "#003468", fontWeight: "700" }}>Signup</span>
            </Typography> */}
              <LoginField
                title="ID"
                placeholder={"Enter your ID or email"}
                name="username"
                register={register}
                setValue={setValue}
                trigger={trigger}
                errors={errors}
              />
              <LoginPasswordField
                title="Password"
                placeholder={"Enter your password"}
                name="password"
                register={register}
                errors={errors}
              />
              {/* users helper */}

              <Button
                variant="contained"
                type="submit"
                sx={{
                  backgroundColor: "#003468",
                  px: "50px",
                  fontWeight: "600",
                  fontSize: "20px",
                  borderRadius: "10px",
                  mt: "40px",
                  textTransform: "none",
                }}
              >
                {t("Next")}
              </Button>
              <Typography
                variant="body1"
                fontSize={14}
                color="rgba(0, 52, 104, 0.5)"
                sx={{
                  textDecoration: "underline",
                  color: "#003468",
                  fontWeight: "700",
                  cursor: "pointer",
                  mt: "20px",
                }}
                onClick={resetPasswordHandler}
              >
                {t("Forgot password")}
              </Typography>
              <IconButton
                onClick={() => {
                  localStorage.setItem(
                    "lng",
                    i18n.language === "en" ? "ar" : "en"
                  );
                  i18n.changeLanguage(i18n.language === "en" ? "ar" : "en");
                }}
              >
                <Language
                  style={{
                    color: "#003468",
                    fontSize: "36px",
                    marginTop: "16px",
                  }}
                />
              </IconButton>
            </>
          )}

          {step === 2 && (
            <>
              <Typography
                variant="h2"
                fontSize={31}
                fontWeight="700"
                color="#003468"
                mb="15px"
              >
                {t("Choose Location")}
              </Typography>

              <LoginSelectField
                name="entity"
                title="Location"
                items={getEntitiesList.data}
                register={register}
              />

              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#003468",
                  px: "50px",
                  fontWeight: "600",
                  fontSize: "20px",
                  borderRadius: "10px",
                  mt: "60px",
                  textTransform: "none",
                }}
                type="submit"
                disabled={loading}
              >
                {loading ? <CircularProgress /> : t("Login")}
              </Button>
            </>
          )}
        </form>
      </Grid>
    </Grid>
  );
};

export default LoginPage;

export const LoginField = ({
  name,
  title,
  type = "text",
  errors,
  setValue,
  trigger,
  placeholder,
  register,
}) => {
  const { t } = useTranslation();
  return (
    <Grid container alignItems="center" my="20px">
      <Grid
        item
        xs={3}
        // sx={{
        //   display: "flex",
        //   alignItems: "center",
        //   justifyContent: "center",
        // }}
      >
        <Typography color="#003468" fontSize={"20px"} fontWeight="600">
          {t(title)}
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <InputBase
          sx={{
            width: "100%",
            borderRadius: "10px",
            border: "1px solid #005AB3",
            height: "45px",
            padding: 1,
          }}
          size="small"
          placeholder={t(placeholder)}
          {...register(name)}
          {...(setValue &&
            trigger &&
            type === "number" && {
              onChange: (e) => {
                setValue(name, e.target.value.replace(/\D/g, ""));
                if (errors) trigger(name);
              },
            })}
        />
        {/* <CebTextField type={type} {...props} errors={errors} />; */}
        {errors?.[name] && (
          <Box sx={{ mt: "4px" }}>
            <Typography variant="error">{errors[name].message}</Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export const LoginPasswordField = ({
  name,
  title,
  placeholder,
  register,
  errors = {},
}) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassowrd = () => {
    setShowPassword((state) => !state);
  };
  return (
    <Grid container alignItems="center" my="20px">
      <Grid
        item
        xs={3}
        // sx={{
        //   display: "flex",
        //   alignItems: "center",
        //   justifyContent: "center",
        // }}
      >
        <Typography color="#003468" fontSize={"20px"} fontWeight="600">
          {t(title)}
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <InputBase
          type={showPassword ? "text" : "password"}
          variant="outlined"
          sx={{
            width: "100%",
            border: "1px solid #005AB3",
            borderRadius: "10px",
            height: "45px",
            padding: 1,
          }}
          // size='small'
          placeholder={t(placeholder)}
          {...register(name)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleShowPassowrd}
                onMouseDown={(e) => e.preventDefault()}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        {errors[name] && (
          <Box sx={{ mt: "4px" }}>
            <Typography variant="error">{errors[name].message}</Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export const LoginSelectField = ({
  name,
  title,
  register,
  placeholder,
  items,
}) => {
  const { t } = useTranslation();
  return (
    <Grid container alignItems="center" my="20px">
      <Grid
        item
        xs={3}
        // sx={{
        //   display: "flex",
        //   alignItems: "center",
        //   justifyContent: "center",
        // }}
      >
        <Typography color="#003468" fontSize={"20px"} fontWeight="600">
          {t(title)}
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Select
          variant="outlined"
          {...register(name)}
          sx={{
            width: "100%",
            border: "1px solid #005AB3",
            borderRadius: "10px",
          }}
          size="small"
          placeholder={t(placeholder)}
          defaultValue=""
        >
          {items.map((item, index) => (
            <MenuItem key={index} value={item.id}>
              {item.full_name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  );
};
